import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useDeleteFolder} from "src/api/folders/useDeleteFolder.js";
import {useNavigate} from "react-router-dom";
import {useGetLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";
import {Button} from "src/primitives/button.jsx";

export function DeleteFolder({folderId}) {
    const [deleteFolder, {loading}] = useDeleteFolder();
    const navigate = useNavigate();
    const getLink = useGetLink();

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }

        deleteFolder(folderId).then(() => {
            navigate(getLink(LINKS.HOME));
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Wrapper>
            <Button
                theme="danger"
                onClick={handleSubmit}
                isLoading={loading}
            >
                Delete folder
            </Button>
        </Wrapper>
    );
}
DeleteFolder.propTypes = {
    folderId: PropTypes.string
};

const Wrapper = styled.div`
`;
