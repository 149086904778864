import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useClerk} from "@clerk/clerk-react";
import {CenteredContent, FlexLayout, SplitPage} from "src/primitives/layout/index.jsx";
import {Title} from "src/primitives/title.jsx";
import styled from "styled-components";
import {Button} from "src/primitives/button.jsx";
import {RhoHeader} from "src/pages/components/RhoHeader/index.jsx";


export function SignOutPage() {
    const {signOut} = useClerk();


    useEffect(() => {
        signOut();
    }, [signOut]);

    return (
        <SplitPage>
            <FlexLayout
                header={<RhoHeader/>}
            >
                <CenteredContent>
                    <Wrapper>
                        <Title>
                            <h1>You are now logged out</h1>
                        </Title>

                        <Link to="/sign-in">
                            <Button theme="secondary">
                                <span>Sign in</span>
                            </Button>
                        </Link>
                    </Wrapper>
                </CenteredContent>
            </FlexLayout>
        </SplitPage>
    );
}

const Wrapper = styled.div`
  width: 100%;
`;
