import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation DisablePublicLink ($tableId: String!) {
  disablePublicLink(tableId: $tableId) {
    ok
    errorCode
    table {
      id
      publicKey
      publicLinkEnabled
    }
  }
}
`;


export function useDisablePublicLink(tableId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        const variables = {tableId};

        return mutation({variables}).then((response) => {
            return response.data.disablePublicLink;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
