import {useCallback, useMemo} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_FOLDERS_QUERY} from "src/api/folders/useGetFolders.js";
import {CORE_FOLDER_FIELDS, SHARED_RESOURCE_FIELDS} from "src/api/fragments.js";

const MUTATION = gql`
${CORE_FOLDER_FIELDS}
${SHARED_RESOURCE_FIELDS}
mutation CreateFolder($data: CreateFolderInput!) {
  createFolder (data: $data) {
    ok
    errorCode
    folder {
      ... CoreFolderFields
      shareInfo {
        ...SharedResourceFields
      }      
    }
  }
}
`;

export function useCreateFolder(workspaceId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback(({name, parentFolderId}) => {
        return mutation({
            variables: {data: {workspaceId, name, parentFolderId}},
            refetchQueries: [{query: GET_FOLDERS_QUERY, variables: {id: workspaceId}}]
        }).then((response) => {
            return response.data.createFolder;
        });
    }, [mutation, workspaceId]);

    const result = useMemo(() => {
        return data?.createFolder;
    }, [data]);

    return [doMutate, {loading, error, result}];
}
