import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import sonomaLight from "./assets/14-Sonoma-Light-thumb.jpg";

export const GradientBackground = ({children, style}) => (
    <Wrapper className="rounded-md p-px" style={style}>
        {children}
    </Wrapper>
);

GradientBackground.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object
};

const Wrapper = styled.div`
  background-clip: padding-box;
  // background-image: linear-gradient(126deg,#04baf5,#22cd88);
  // background-image: linear-gradient(126deg,#a3e635,#f97316);
  // background-image: linear-gradient(126deg,#f97316,#a3e635);
  // background-image: linear-gradient(126deg, rgba(163, 230, 53, 0.5), rgba(249, 115, 22, 0.5));
  // background-image: linear-gradient(126deg, rgba(255, 255, 255, 0.6), rgba(249, 115, 22, 0.6));
  background-image: linear-gradient(126deg, rgba(255, 255, 255, 0.5), rgba(163, 230, 53, 0.5));
`;

export const MacOsBackground = ({children, style}) => (
    <MacOsWrapper className="rounded-lg p-4" style={style}>
        {children}
    </MacOsWrapper>
);

MacOsBackground.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object
};

const MacOsWrapper = styled.div`
  background-clip: padding-box;
  background-image: url(${sonomaLight});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
