import React from "react";
import PropTypes from "prop-types";
import {Dropdown} from "src/primitives/Dropdown/index.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiChevronDown} from "react-icons/fi";


export function SmallDropdown({options, onSelect, selectedValue}) {
    return (
        <Dropdown
            size="small"
            options={options}
            onSelect={onSelect}
        >
            {/* eslint-disable-next-line max-len */ }
            <div className="w-20 flex items-center justify-between border pl-2 pr-1 py-0.5 border-neutral-500 rounded-md hover:bg-neutral-100 text-neutral-900">
                <div className="text-sm">
                    {selectedValue}
                </div>
                <IconWrapper
                    size="xs"
                    icon={<FiChevronDown/>}
                    thickness={2}
                />
            </div>
        </Dropdown>
    );
}

SmallDropdown.propTypes = {
    selectedValue: PropTypes.node,
    options: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.node,
        icon: PropTypes.node,
        value: PropTypes.string
    })),
    onSelect: PropTypes.func
};
