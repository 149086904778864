import React from "react";
import PropTypes from "prop-types";
import {config} from "src/config.js";
import {Title} from "src/primitives/title.jsx";
import {coalesce} from "src/utils/misc.js";


export function PredictionUrl({modelId, features, featureData}) {
    const featureNames = features.map(({name}) => name);
    const featureParams = featureNames.map((name) => `${name}=${coalesce(featureData[name], "")}`);
    const baseUrl = `${config.API_URL}/v1/models/${modelId}/predict?${featureParams.join("&")}`;

    return (
        <div>
            <Title size="small">
                URL
            </Title>
            <div className="monospace text-sm text-blue-600 hover:text-blue-700">
                <a href={baseUrl} target="_blank" rel="noreferrer">
                    {baseUrl}
                </a>
            </div>
        </div>
    );
}

PredictionUrl.propTypes = {
    modelId: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
    })),
    featureData: PropTypes.object
};
