import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FiFilter} from "react-icons/fi";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {Overlay} from "src/primitives/Overlay/index.jsx";
import {Button} from "src/primitives/button.jsx";
import {Title} from "src/primitives/title.jsx";
import {Circle} from "src/primitives/circle.jsx";
import {overrideSystemHandling} from "src/utils/system.js";
import {useLoadWithOptions} from "src/pages/Home/pages/TablePage/pages/TableGridPage/table_data_context.jsx";
import {FilterOperator} from "src/components/TableGrid/constants.js";
import {ColumnFilter} from "./ColumnFilter.jsx";


export function ManageColumnFilter({columnInfo, stats, columnValues, activeFilter}) {
    const {removeFilter, handleFilter} = useLoadWithOptions();
    const [operator, setOperator] = useState(FilterOperator.EQUALS);
    const [textValue, setValue] = useState("");

    const updateValue = (value) => {
        setValue(value);
    };

    const resetStates = useCallback((tValue = "") => {
        setValue(tValue);
    }, [setValue]);

    const handleClear = (e) => {
        overrideSystemHandling(e);
        resetStates();
        // Remove and reload data!
        removeFilter(columnInfo.name);
    };

    const handleSubmit = (close) => {
        console.log("Submit");
        const {name} = columnInfo;
        const filterOption = textValue ? {[operator]: textValue} : null;
        handleFilter(name, filterOption);
        if (close) {
            close();
        }
    };

    useEffect(() => {
        resetStates(activeFilter?.value);
    }, [activeFilter, resetStates]);

    return (
        <Wrapper>
            <Overlay
                align="auto"
                onSubmit={handleSubmit}
                render={({open}) => (
                    <div className="relative">
                        <IconWrapper
                            icon={<FiFilter/>}
                            size="xs"
                            clickable={true}
                            isActive={open}
                        />
                        {activeFilter && (
                            <Circle
                                style={{position: "absolute", top: 0, right: 0}}
                            />
                        )}
                    </div>
                )}
                header={(
                    <Title size="xxs">
                        {columnInfo.name}
                    </Title>
                )}
                content={(
                    <div className="font-normal font-sans w-64 py-1">
                        <ColumnFilter
                            columnInfo={columnInfo}
                            stats={stats}
                            columnValues={columnValues}
                            clearFilter={handleClear}
                            textValue={textValue}
                            setValue={updateValue}
                            operator={operator}
                            setOperator={setOperator}
                        />
                    </div>
                )}
                footer={(
                    <div className="flex items-center gap-4">
                        <Button
                            size="xs"
                            theme="secondary"
                            isDisabled={activeFilter?.value === textValue}
                        >
                            Apply
                        </Button>
                        <Button
                            size="xs"
                            theme="outlineBlack"
                            isDisabled={!textValue}
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                    </div>
                )}
            />
        </Wrapper>
    );
}

ManageColumnFilter.propTypes = {
    columnInfo: PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    }),
    columnValues: PropTypes.array,
    stats: PropTypes.object,
    activeFilter: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        op: PropTypes.string
    })
};

const Wrapper = styled.div`
`;
