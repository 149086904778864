import React from "react";
import {Card} from "src/primitives/Card.jsx";
import {CenteredContent} from "src/primitives/layout/index.jsx";
import {useGoToUploadPage} from "src/utils/useGetLink.js";
import {Button} from "src/primitives/button.jsx";


export function TablesPageEmptyState() {
    const goToUploadPage = useGoToUploadPage();

    return (
        <div className="py-2 my-4">
            <div className="my-2 flex gap-2">
                <Card size="medium" onClick={goToUploadPage}>
                    <CenteredContent>
                        <Button theme="secondary" size="xs">
                            Create a table
                        </Button>
                    </CenteredContent>
                </Card>
            </div>
        </div>
    );
}

TablesPageEmptyState.propTypes = {
};
