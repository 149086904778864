import React from "react";
import PropTypes from "prop-types";
import {CenteredContent} from "src/primitives/layout/index.jsx";
import {Title} from "src/primitives/title.jsx";
import {DeleteTable} from "src/pages/Home/pages/TablePage/components/DeleteTable/index.jsx";
import {
    TriggerAnalysis
} from "src/pages/Home/pages/TablePage/pages/TableSettingsPage/components/TriggerAnalysis/index.jsx";


export function TableErrorPage({tableId}) {
    return (
        <CenteredContent>
            <div className="p-4 max-w-112 mx-auto">
                <Title>
                    Failed to load table
                </Title>

                <div className="mb-4">
                    It looks like the import of the table failed.
                    Try to delete the table an upload a new file.
                </div>
                <DeleteTable tableId={tableId}/>
            </div>
        </CenteredContent>
    );
}

TableErrorPage.propTypes = {
    tableId: PropTypes.string
};

export function TableNetworkError({error}) {
    return (
        <CenteredContent>
            <div className="p-4 max-w-112 mx-auto">
                <Title>
                    Failed to load the table
                </Title>

                <div className="mb-4">
                    This is most likely an internal error, please try again later.
                </div>

                <div className="mb-4">
                    <code className="text-xs monospace">
                        {JSON.stringify(error)}
                    </code>
                </div>

            </div>
        </CenteredContent>
    );
}

TableNetworkError.propTypes = {
    error: PropTypes.object
};

export function TableErrorBoundary({tableId}) {
    return (
        <CenteredContent>
            <div className="p-4 max-w-112 mx-auto">
                <Title>
                    Failed to load table
                </Title>

                <div className="mb-4">
                    Something went wrong when loading the table.
                    We can try to re-run analysis of the table.
                </div>
                <TriggerAnalysis tableId={tableId}/>
            </div>
        </CenteredContent>
    );
}

TableErrorBoundary.propTypes = {
    tableId: PropTypes.string.isRequired
};
