import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {CORE_FOLDER_FIELDS, SHARED_RESOURCE_FIELDS} from "../fragments.js";


export const GET_FOLDER_QUERY = gql`
${CORE_FOLDER_FIELDS}
${SHARED_RESOURCE_FIELDS}
query GetFolder($id: String!) {
  folder (folderId: $id) {
    ... CoreFolderFields

    sharedResources {
      ... SharedResourceFields
    }
  }
}
`;

export function useGetFolder(folderId) {
    const {data, loading, error, refetch} = useQuery(GET_FOLDER_QUERY, {variables: {id: folderId}});

    const folder = useMemo(() => {
        if (!data || !data.folder) {
            return null;
        }
        return data.folder;
    }, [data]);

    return {folder, loading, error, refetch};
}
