import React from "react";
import PropTypes from "prop-types";
import {useGetValues} from "src/api/tableData/useGetValues.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {Title} from "src/primitives/title.jsx";
import {FeatureVisualization} from "./components/FeeatureVisualization";


export function FeaturesOverview({tableId, features, target}) {
    const columnNames = features.map(({name}) => name);
    columnNames.push(target.name);
    const {data: values, loading: loadingValues} = useGetValues(tableId, columnNames);

    if (loadingValues) {
        return (
            <Spinner/>
        );
    }

    return (
        <div className="flex flex-wrap">
            {features.map((feature) => (
                <div
                    key={`feature-${feature.name}`}
                    className="basis-1/2 max-w-[44%] border border-neutral-200 rounded-md min-h-[280px] p-4 mr-4 mb-4"
                >
                    <Title size="xs">
                        {feature.name} vs. {target.name}
                    </Title>
                    <FeatureVisualization
                        tableId={tableId}
                        featureColumn={feature}
                        targetColumn={target}
                        values={values}
                    />
                </div>
            ))}
        </div>
    );
}

FeaturesOverview.propTypes = {
    tableId: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.object),
    target: PropTypes.object
};
