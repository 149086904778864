import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiDatabase, FiFolder} from "react-icons/fi";
import {formatInteger, formatTimestamp, humanFileSize} from "src/utils/formatting.js";
import {useNavigateOnClick} from "src/utils/useNavigateOnClick.js";
import {LINKS} from "src/links.js";
import {DataGrid} from "src/components/DataGrid/index.jsx";


const defaultSorting = [{
    desc: true,
    id: "createdAt"
}];

const columns = [{
    id: "name",
    header: "Name",
    render: (value, original) => (
        <div className="flex items-center gap-2">
            <IconWrapper size="small" icon={original.type === "FOLDER" ? <FiFolder/> : <FiDatabase/>}/>
            <span>{value}</span>
        </div>
    )
}, {
    id: "createdAt",
    header: "Created",
    render: (value) => (
        <span>
            {formatTimestamp(value)}
        </span>
    )
}, {
    id: "size.rows",
    header: "Rows",
    render: (value) => (
        <div>
            {typeof value === "number" ? formatInteger(value) : "-"}
        </div>
    )
}, {
    id: "size.bytes",
    header: "Size",
    render: (value) => {
        return (
            <span>
                {value ? humanFileSize(value) : "-"}
            </span>
        );
    }
}];


export function ContentGrid({tables, folders}) {
    const navigateOnClick = useNavigateOnClick();
    const handleRowClick = useCallback((event, row) => {
        let link;
        if (row.original.type === "FOLDER") {
            link = LINKS.folderPage(row.original.id);
        } else {
            link = LINKS.tablePage(row.original.id);
        }
        if (link) {
            navigateOnClick(event, link);
        }
    }, [navigateOnClick]);

    const formattedTables = useMemo(() => {
        return tables.map((item) => Object.assign({}, item, {type: "TABLE"}));
    }, [tables]);

    const formattedFolders = useMemo(() => {
        return folders.map((item) => Object.assign({}, item, {type: "FOLDER", size: {rows: null, bytes: null}}));
    }, [folders]);

    const items = formattedTables.concat(formattedFolders);

    return (
        <DataGrid
            data={items}
            columns={columns}
            onRowClick={handleRowClick}
            fullWidth={true}
            defaultSorting={defaultSorting}
        />
    );
}

ContentGrid.propTypes = {
    tables: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string
    })),
    folders: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string
    }))
};
