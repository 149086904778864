import React, {useCallback} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {flexRender} from "@tanstack/react-table";
import {Td, Tr} from "./cells.jsx";


export function TableRow({row, onRowClick}) {
    const handleClick = useCallback((event) => {
        onRowClick(event, row);
    }, [row, onRowClick]);

    return (
        <Row
            onClick={onRowClick ? handleClick : null}
            $clickable={!!onRowClick}
            className="bg-transparent transition-colors"
        >
            {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
            ))}
        </Row>
    );
}

TableRow.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.string,
        getVisibleCells: PropTypes.func
    }).isRequired,
    onRowClick: PropTypes.func
};


const Row = styled(Tr)`
  ${(props) => props.$clickable ? `
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
  ` : ""};
`;
