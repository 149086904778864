import React from "react";
import {PageContent, PageLayout} from "src/primitives/layout/index.jsx";
import {ContactForm} from "./components/ContactForm/index.jsx";


export function ContactPage() {
    return (
        <PageLayout title="Contact">
            <PageContent title="Contact us!">
                <div className="max-w-112 mt-2 mb-4">
                    {/* eslint-disable-next-line max-len */}
                    <p>We are always happy to hear from you! Please fill out the form below and we will get back to you as soon as possible.</p>
                </div>
                <ContactForm/>
            </PageContent>
        </PageLayout>
    );
}


ContactPage.propTypes = {
};
