import React from "react";
import PropTypes from "prop-types";
import {useGetWorkspaceResources} from "src/api/workspaces/useGetWorkspaceResources.js";
import {SelectInput} from "src/primitives/controls";
import {arrayToMap} from "src/utils/misc.js";
import {Title} from "src/primitives/title.jsx";
import {sortByString} from "src/utils/sorting.js";


export function SelectFolder({workspaceId, onChange, selectedFolderId}) {
    const {folders} = useGetWorkspaceResources(workspaceId);
    const foldersSorted = sortByString(folders);
    const foldersMap = arrayToMap(folders);

    const handleChange = (folder) => {
        if (onChange) {
            onChange(folder);
        }
    };

    const selectedOption = selectedFolderId ? foldersMap[selectedFolderId] : null;

    return (
        <div>
            <div className="mb-2">
                <Title size="xs">
                    Select folder:
                </Title>
            </div>
            <SelectInput
                onChange={handleChange}
                options={foldersSorted}
                selectedOption={selectedOption}
                getValue={(item) => item.id}
            />
        </div>
    );
}

SelectFolder.propTypes = {
    workspaceId: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    selectedFolderId: PropTypes.string
};
