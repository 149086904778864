import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {DB_BACKENDS} from "src/pages/CreateWorkspace/components/CreateWorkspaceForm/constants.js";
import {LogoImg} from "src/primitives/Logo/index.jsx";
import googleCloudLogo from "./assets/gcp_logo.png";

export function BackendLogo({size, backend}) {
    if (backend === DB_BACKENDS.gcp) {
        return (
            <GoogleCloudLogo size={size}/>
        );
    }

    if (backend === DB_BACKENDS.rho) {
        return (
            <LogoImg size={size}/>
        );
    }
    return null;
}

BackendLogo.propTypes = {
    backend: PropTypes.oneOf(Object.values(DB_BACKENDS)).isRequired,
    size: PropTypes.number
};


BackendLogo.defaultProps = {
    size: 44
};

const GoogleCloudLogo = styled.div`
  background: url("${googleCloudLogo}");
  background-size: contain;
  background-repeat: no-repeat;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

GoogleCloudLogo.propTypes = {
    $size: PropTypes.number
};
