import {DataTypes} from "src/utils/tableData.js";

export function formatFilterOption(name, option) {
    const operator = Object.keys(option)[0];
    const value = Object.values(option)[0].toString();

    return {
        name,
        value,
        op: operator
    };
}

export function formatSortOptions(sortOptions) {
    /**
     * Converts "sortOptions" object to array of sort options
     * @type {string[]}
     */
    if (!sortOptions) {
        return [];
    }

    const columnNames = Object.keys(sortOptions);

    return columnNames.map((name) => ({
        name,
        desc: sortOptions[name] === "DESC"
    }));
}

export function getCellClass(column) {
    if (!column) {
        return "";
    }

    if (column.dataType === DataTypes.STRING) {
        return "text";
    }

    if (column.dataType === DataTypes.INTEGER || column.dataType === DataTypes.FLOAT) {
        return "number";
    }

    return "";
}
