import {useCallback, useState} from "react";
import axios from "axios";

function getState({loading = false, error = null, data = null} = {}) {
    return {loading, error, data};
}

export function useUploadToStorage() {
    // TODO: Use axios to track progress
    // https://stackoverflow.com/questions/35711724/upload-progress-indicators-for-fetch

    const [state, setState] = useState(getState());
    const [progress, setProgress] = useState(null);

    const doUpload = useCallback(({file, url}) => {
        const headers = {
            "Content-Type": "application/octet-stream"
        };

        const handleUploadProgress = (uploadProgress) => {
            setProgress(uploadProgress);
        };

        setState(getState({loading: true}));

        return axios({
            url,
            method: "PUT",
            data: file,
            headers,
            onUploadProgress: handleUploadProgress
        }).then(() => {
            setState(getState({loading: false}));
        }).catch((error) => {
            console.error(error);
            setState(getState({error}));
            throw new Error(error);
        });
    }, [setState]);

    return [doUpload, state, progress];
}
