import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation UpdateColumnFeature($data: UpdateColumnInfoInput!) {
  updateColumnInfo(data: $data) {
    ok
    errorCode
    column {
      id
      isFeature
    }
  }
}
`;

export function useUpdateColumnFeature(tableId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((name, isFeature) => {
        const expectedResult = {
            ok: true,
            errorCode: null,
            column: {
                __typename: "Column",
                id: `${tableId}:${name}`,
                isFeature
            }
        };
        const optimisticResponse = {updateColumnInfo: expectedResult};
        const data = {tableId, name, isFeature};
        return mutation({
            optimisticResponse,
            variables: {data}
        }).then((response) => {
            return response.data.updateColumnInfo;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
