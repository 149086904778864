import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {SqlEditor} from "src/components/SqlEditor/index.jsx";
import {padString} from "src/components/SqlEditor/utils.js";
import {useGetTables} from "src/api/tables/useGetTables.js";


export function QueryEditor({submit, tableName, workspaceId}) {
    const {tables, loading: loadingTables} = useGetTables(workspaceId);
    const defaultSql = tableName ? `SELECT * \nFROM ${padString(tableName)} \nLIMIT 1000;\n` : null;

    return (
        <Wrapper>
            {!loadingTables && (
                <SqlEditor
                    key={tableName}
                    submit={submit}
                    tables={tables}
                    defaultText={defaultSql}
                />
            )}
        </Wrapper>
    );
}
QueryEditor.propTypes = {
    submit: PropTypes.func,
    tableName: PropTypes.string,
    workspaceId: PropTypes.string
};

const Wrapper = styled.div`
`;
