import {numericRenderer} from "handsontable/renderers";
import {getColor} from "./heatmap_color.js";

export function heatmapRenderer(hotInstance, td, row, column, prop, value, cellProperties) { // eslint-disable-line
    //  const darkMode = useContext(TableContext);
    // https://handsontable.com/docs/react-data-grid/cell-renderer/

    // Optionally include `BaseRenderer` which is responsible for
    // adding/removing CSS classes to/from the table cells.
    numericRenderer.apply(this, arguments); // eslint-disable-line

    const allColumnValues = hotInstance.getData().map((r) => r[column]);
    // eslint-disable-next-line no-param-reassign
    td.style.backgroundColor = getColor(value, allColumnValues);
    return td;
}
