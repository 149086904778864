import React, {useCallback, useRef, useState} from "react";
import PropTypes from "prop-types";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FiCheck, FiCopy} from "react-icons/fi";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {nanoId} from "src/utils/id.js";


export function IconCopy({text, onCopyText, iconColor}) {
    const itemId = useRef();
    itemId.current = nanoId();
    if (!itemId.current) {
        itemId.current = nanoId();
    }

    const [isCopiedState, setIsCopiedState] = useState(false);
    const {showSuccess} = useShowToast();

    const onCopy = useCallback(() => {
        showSuccess({message: onCopyText});
        setIsCopiedState(true);

        window.setTimeout(() => {
            setIsCopiedState(false);
        }, 5000);
    }, [showSuccess, onCopyText, setIsCopiedState]);

    const iconStyle = {
        color: isCopiedState ? "green" : iconColor
    };

    return (
        <CopyToClipboard
            text={text}
            onCopy={onCopy}
        >
            <IconWrapper
                itemId={itemId.current}
                size="small"
                icon={isCopiedState ? <FiCheck/> : <FiCopy/>}
                clickable={true}
                tooltip={!isCopiedState && "Copy to clipboard"}
                style={iconStyle}
            />
        </CopyToClipboard>
    );
}

IconCopy.propTypes = {
    text: PropTypes.string,
    onCopyText: PropTypes.string,
    iconColor: PropTypes.string
};

IconCopy.defaultProps = {
    onCopyText: "Copied to clipboard"
};
