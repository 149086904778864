import {gql} from "@apollo/client";


export const MODEL_RESULT_FIELDS = gql`
  fragment ModelResultFields on ModelResult {
    r2
    mse
    mae
    mape
  }
`;
