export function formatDistributionToLine(distribution) {
    const MIN_LENGTH = 3;

    if (!Array.isArray(distribution) || distribution.length < MIN_LENGTH) {
        return [];
    }

    const firstItem = {
        x: distribution[0].lower,
        y: distribution[0].frequency,
        lower: distribution[0].lower,
        upper: distribution[0].upper
    };
    const lastItem = {
        x: distribution[distribution.length - 1].upper,
        y: distribution[distribution.length - 1].frequency,
        lower: distribution[distribution.length - 1].lower,
        upper: distribution[distribution.length - 1].upper
    };
    const items = distribution.map((bucket) => {
        const bucketSize = bucket.upper - bucket.lower;
        return {
            x: bucket.lower + bucketSize / 2.0,
            y: bucket.frequency,
            lower: bucket.lower,
            upper: bucket.upper
        };
    });
    return [firstItem, ...items, lastItem];
}

export function minValueFromDistribution(distribution) {
    if (!Array.isArray(distribution)) {
        return null;
    }
    return distribution[0] ? distribution[0]?.lower : null;
}

export function maxValueFromDistribution(distribution) {
    if (!Array.isArray(distribution)) {
        return null;
    }
    const i = distribution.length - 1;

    return distribution[i] ? distribution[i]?.upper : null;
}
