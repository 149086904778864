import React from "react";
import {FiCalendar, FiType} from "react-icons/fi";
import {LuBinary} from "react-icons/lu";
import {isColumnBoolean, isColumnDate, isColumnNumeric, isColumnText} from "src/utils/tableData.js";
import {GoNumber} from "react-icons/go";


export function getIconForColumn(columnInfo) {
    if (isColumnDate(columnInfo)) {
        return <FiCalendar/>;
    }
    if (isColumnText(columnInfo)) {
        return <FiType/>;
    }
    if (isColumnNumeric(columnInfo)) {
        // return <FiHash/>;
        return <GoNumber/>;
    }
    if (isColumnBoolean(columnInfo)) {
        return <LuBinary/>;
    }

    return null;
}
