import PropTypes from "prop-types";
import React from "react";

export function FilesPreview({files}) {
    return (
        <ul className="text-neutral-700 monospace">
            {files.map((file) => (
                <li key={file.path}>
                    {file.path}
                </li>
            ))}
        </ul>
    );
}

FilesPreview.propTypes = {
    files: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
        lastModified: PropTypes.number,
        lastModifiedDate: PropTypes.object,
        name: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string
    }))
};

