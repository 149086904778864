import React from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";


export function ProgressBar({progress}) {
    const transform = progress - 100;

    return (
        <div className="relative w-full h-4 rounded-md bg-neutral-100 border border-neutral-100 overflow-hidden">
            <div
                style={{transform: `translateX(${transform}%)`}}
                className="w-full h-full bg-black/80 transition-all"
            />
        </div>
    );
}

ProgressBar.propTypes = {
    progress: PropTypes.number
};

export function ProgressBarLabeled({title, subtitle, progress}) {
    return (
        <div className="flex flex-col gap-1">
            <div className="flex items-center justify-between">
                <Title size="small" style={{margin: 0}}>
                    {title}
                </Title>
                <div className="text-neutral-500">
                    {subtitle}
                </div>
            </div>
            <ProgressBar progress={progress}/>
        </div>
    );
}


ProgressBarLabeled.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    progress: PropTypes.number
};
