import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Title} from "src/primitives/title.jsx";
import logoImg from "./assets/logo.png";
import rhoImg from "./assets/rho.png";


export const LogoImg = ({size = 36}) => (
    <Img
        className="rounded-lg"
        $size={size}
    />
);


LogoImg.propTypes = {
    size: PropTypes.number
};


const Img = styled.div`
  height: ${({$size}) => $size}px;
  width: ${({$size}) => $size}px;
  background-image: url(${logoImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export const LogoText = ({link}) => (
    <Title style={{margin: 0, fontFamily: "monospace"}} size="small">
        {link ? (
            <Link to={link} className="hover:underline">
                rho.store
            </Link>
        ) : (
            <span>
                rho.store
            </span>
        )}
    </Title>
);

LogoText.propTypes = {
    link: PropTypes.string
};


export const RhoImg = ({size, style}) => (
    <RhoImgWrapper
        className="rounded-lg"
        $size={size}
        style={style}
    />
);


RhoImg.propTypes = {
    size: PropTypes.number,
    style: PropTypes.object
};

RhoImg.defaultProps = {
    size: 36
};

const RhoImgWrapper = styled.div`
  height: ${({$size}) => $size}px;
  width: ${({$size}) => $size}px;
  background-image: url(${rhoImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
