import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useTransform} from "src/pages/Web/components/useTransform.jsx";
import {useIsVisible} from "src/utils/useIsVisible.js";

export function ZoomIn({from, to, ...props}) {
    const ref = useRef();
    const isVisible = useIsVisible(ref);
    const [x, start] = useTransform(450, 800);

    useEffect(() => {
        if (isVisible) {
            start();
        }
    }, [isVisible, start]);

    return (
        <div ref={ref} className="w-full h-full">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Image style={{backgroundSize: `${x}px`}} {...props} />
        </div>
    );
}

ZoomIn.propTypes = {
    from: PropTypes.number,
    to: PropTypes.number
};

export function Image({
    source,
    opacity,
    alignX,
    alignY,
    bgSize,
    style
}) {
    return (
        <Wrapper
            className="rounded-md border border-neutral-400/50"
            $source={source}
            $opacity={opacity}
            $alignX={alignX}
            $alignY={alignY}
            $bgSize={bgSize}
            style={style}
        />
    );
}

Image.propTypes = {
    source: PropTypes.string.isRequired,
    opacity: PropTypes.number,
    alignX: PropTypes.oneOf(["left", "right", "center"]),
    alignY: PropTypes.oneOf(["top", "bottom", "center"]),
    bgSize: PropTypes.string,
    style: PropTypes.object
};

Image.defaultProps = {
    opacity: 1.0,
    alignX: "left",
    alignY: "top",
    bgSize: "cover"
};

const Wrapper = styled.div`
  min-width: 20px;
  min-height: 20px;
  height: 100%;
  width: 100%;
  background-image: url(${({$source}) => $source});
  background-repeat: no-repeat;
  background-position: ${({$alignX, $alignY}) => `${$alignX} ${$alignY}`};
  background-size: ${({$bgSize}) => $bgSize};
  filter: opacity(${({$opacity}) => $opacity});
`;
