import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {overrideSystemHandling} from "src/utils/system.js";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {useDeleteApiKey} from "src/api/apiKeys/useDeleteApiKey.js";
import {ConfirmDeletionDialog} from "src/primitives/modals/ConfirmDeletionDialog.jsx";


export function RemoveApiKeyDialog({keyId, close}) {
    const {showError} = useShowToast();
    const [deleteApiKey, {loading}] = useDeleteApiKey();

    const handleRemoveKey = useCallback((e) => {
        overrideSystemHandling(e);
        deleteApiKey(keyId).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
            close();
        }).catch((error) => {
            console.error(error);
            showError();
        });
    }, [deleteApiKey, keyId, showError, close]);

    return (
        <ConfirmDeletionDialog
            loading={loading}
            close={close}
            confirm={handleRemoveKey}
        >

            <div>
                You are about to permanently delete an API key.
            </div>
        </ConfirmDeletionDialog>
    );
}

RemoveApiKeyDialog.propTypes = {
    keyId: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
};
