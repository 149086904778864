import React from "react";
import PropTypes from "prop-types";
import {HeatmapColor} from "src/components/TableGrid/utils/heatmap_color.js";
import {
    getColorSchemeColors
// eslint-disable-next-line max-len
} from "src/components/TableGrid/Header/ColumnHeaderSecondary/components/ColumnMenu/components/ColumnDisplaySettings/components/DisplayHeatmap/color_schemes.js";
import {RENDER_FUNCTIONS, renderValue} from "./renders.js";


function getBackgroundColor(displaySettings, stats, value) {
    if (!value) {
        return null;
    }

    if (displaySettings?.heatmap && stats) {
        const colors = getColorSchemeColors(displaySettings.heatmapColor);
        const helper = new HeatmapColor(stats, colors);
        return helper.getColor(value);
    }

    return null;
}


function render(value, displaySettings) {
    if (!value) {
        return "";
    }

    const prefix = displaySettings?.prefix ? `${displaySettings?.prefix} ` : "";
    const suffix = displaySettings?.suffix ? ` ${displaySettings?.suffix}` : "";

    return `${prefix}${value}${suffix}`;
}

export function Cell({stats, value, columnName, variableType, dataType, semanticType, displaySettings, ...props}) {
    const fontColor = null;
    const bgColor = getBackgroundColor(displaySettings, stats, value);

    const renderFunction = RENDER_FUNCTIONS[semanticType || variableType] || renderValue;
    const cellStyle = {
        backgroundColor: bgColor,
        color: fontColor
    };

    return (
        <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={cellStyle}
        >
            <span className="tg-cell-content">
                {render(renderFunction(value, displaySettings), displaySettings)}
            </span>
        </div>
    );
}

Cell.propTypes = {
    value: PropTypes.any,
    stats: PropTypes.shape({
        p50: PropTypes.number,
        avg: PropTypes.number,
        sd: PropTypes.number
    }),
    columnName: PropTypes.string,
    variableType: PropTypes.string,
    dataType: PropTypes.string,
    semanticType: PropTypes.string,
    displaySettings: PropTypes.shape({
        heatmap: PropTypes.bool
    })
};
