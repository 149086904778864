
const getConfig = () => {
    const backendUrl = import.meta.env.VITE_BACKEND_URL;

    return {
        CLIENT_ID: "web",
        CLIENT_URL: import.meta.env.VITE_CLIENT_URL,
        WS: `${backendUrl.replace("http", "ws")}/ws`,
        API_URL: backendUrl,
        GRAPHQL_URL: `${backendUrl}/graphql`,
        CLERK_PUBLIC_KEY: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY,
        OPENPANEL_CLIENT_ID: import.meta.env.VITE_OPENPANEL_CLIENT_ID,
        PUSHER_APP_KEY: import.meta.env.VITE_PUSHER_APP_KEY
    };
};

export function isLocal() {
    return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.01";
}

export const config = getConfig();
