import React from "react";
import PropTypes from "prop-types";
import {FiEye, FiEyeOff} from "react-icons/fi";
import {useUpdateColumnVisibility} from "src/api/tableColumns/useUpdateColumnVisibility.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {useShowToast} from "src/components/toasts/useShowToast.js";


export function ToggleColumnVisibility({tableId, column}) {
    const {showError} = useShowToast();
    const [updateColumn, {loading}] = useUpdateColumnVisibility(tableId, column);

    const toggleVisibility = () => {
        // Avoid multiple requests
        if (loading) {
            return;
        }
        const isHidden = !column.isHidden;
        updateColumn(column.name, isHidden).then((response) => {
            const {ok, errorCode} = response;
            if (!ok) {
                throw new Error(errorCode);
            }
        }).catch((error) => {
            console.warn(error);
            showError();
        });
    };

    const icon = column.isHidden ? <FiEyeOff/> : <FiEye/>;

    return (
        <div className="relative w-14 flex items-center justify-center">
            {!loading && (
                <IconWrapper
                    onClick={toggleVisibility}
                    icon={icon}
                    size="xs"
                    itemId={`col-visibility-${tableId}-${column.name}`}
                    tooltip="Change visibility"
                />
            )}
            {loading && (
                <div className="absolute inset-0 fade-in flex items-center justify-center">
                    <Spinner size="sm"/>
                </div>
            )}
        </div>
    );
}


ToggleColumnVisibility.propTypes = {
    tableId: PropTypes.string.isRequired,
    column: PropTypes.shape({
        name: PropTypes.string,
        isHidden: PropTypes.bool
    })
};
