import PropTypes from "prop-types";
import React from "react";
import {HistogramRaw} from "./HistogramRaw.jsx";


export function HistogramDate({values, ...props}) {
    const dateValues = values.map((bucket) => {
        return {
            index: bucket.index,
            frequency: bucket.frequency,
            lower: new Date(bucket.lower).getTime(),
            upper: new Date(bucket.upper).getTime()
        };
    });

    function displayDate(value) {
        const date = new Date(value);
        return date.toLocaleDateString("sv-SE");
    }

    function formatLabel(lower, upper) {
        return `${displayDate(lower)} - ${displayDate(upper)}`;
    }

    return (
        <HistogramRaw
            datasets={[dateValues]}
            xFormatter={displayDate}
            formatLabel={formatLabel}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

HistogramDate.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({
        index: PropTypes.number,
        frequency: PropTypes.number,
        lower: PropTypes.string,
        upper: PropTypes.string
    }))
};
