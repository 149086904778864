import React from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {DialogModal} from "./Dialog.jsx";


function ConfirmDialogFooter({close, confirm, loading}) {
    return (
        <div className="flex justify-between items-center h-6">
            <div className="flex items-center">
                {loading && (
                    <Spinner size="md" theme="gray"/>
                )}
            </div>
            <div className="flex gap-2 justify-end">
                <Button
                    size="xs"
                    theme="secondary"
                    onClick={close}
                    isDisabled={loading}
                >
                    No - abort
                </Button>
                <Button
                    size="xs"
                    theme="danger"
                    onClick={confirm}
                    isDisabled={loading}
                >
                    OK - Delete
                </Button>
            </div>
        </div>
    );
}

ConfirmDialogFooter.propTypes = {
    close: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

export function ConfirmDeletionDialog({
    close,
    confirm,
    loading,
    children,
    title = "Are you sure?"
}) {
    return (
        <DialogModal
            close={close}
            title={title}
            footer={<ConfirmDialogFooter close={close} confirm={confirm} loading={loading}/>}
        >
            {children}
        </DialogModal>
    );
}

ConfirmDeletionDialog.propTypes = {
    close: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    children: PropTypes.node,
    title: PropTypes.node
};
