import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
mutation UpdateFolder($folderId: String!, $data: UpdateFolderInput!) {
  updateFolder (folderId: $folderId, data: $data) {
    ok
    errorCode
    folder {
      id
      name
    }
  }
}
`;

export function useUpdateFolder(folderId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((data) => {
        const expectedResponse = {
            ok: true,
            errorCode: null,
            folder: {
                __typename: "Folder",
                id: folderId,
                ...data
            }
        };
        return mutation({
            variables: {folderId, data},
            optimisticResponse: {updateFolder: expectedResponse}
        }).then((response) => {
            return response.data.updateFolder;
        });
    }, [mutation, folderId]);

    return [doMutate, {loading, error}];
}
