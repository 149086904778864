import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
mutation UpdateChart($chartId: String!, $data: UpdateChartInput!) {
  updateChart (chartId: $chartId, data: $data) {
    ok
    errorCode
    chart {
      id
      config
    }
  }
}
`;

export function useUpdateChart(chartId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((config) => {
        const expectedResponse = {
            ok: true,
            errorCode: null,
            chart: {
                __typename: "Chart",
                id: chartId,
                config
            }
        };
        return mutation({
            variables: {chartId, data: {config}},
            optimisticResponse: {updateChart: expectedResponse}
        }).then((response) => {
            return response.data.updateChart;
        });
    }, [mutation, chartId]);

    return [doMutate, {loading, error}];
}
