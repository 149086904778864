import React from "react";
import {useGetWorkspaces} from "src/api/workspaces/useGetWorkspaces.js";
import {useSelectedWorkspace} from "src/utils/workspace.js";
import {Title} from "src/primitives/title.jsx";
import {WorkspaceItem} from "src/pages/Home/pages/SelectWorkspace/WorkspaceItem.jsx";
import {Link, Navigate} from "react-router-dom";
import {LINKS} from "src/links.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {SplitPage} from "src/primitives/layout/SplitPage.jsx";
import {EaseIn} from "src/primitives/EaseIn.jsx";
import {Button} from "src/primitives/button.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiArrowRight} from "react-icons/fi";
import {sortByDate} from "src/utils/sorting.js";
import {WorkspaceInvitations} from "./components/WorkspaceInvitations/index.jsx";


export function SelectWorkspace() {
    const {workspaces, loading} = useGetWorkspaces();
    const [workspaceId, setWorkspaceId] = useSelectedWorkspace();
    const getLink = useGetLink();

    if (loading) {
        return null;
    }

    if (workspaces.length === 1) {
        const wid = workspaces[0].id;
        return <Navigate to={`${LINKS.WORKSPACE}?wid=${wid}`}/>;
    }

    if (workspaceId) {
        return <Navigate to={getLink(LINKS.WORKSPACE)}/>;
    }

    if (workspaces.length === 0) {
        console.log("No workspaces - Go to start");
        return <Navigate to={getLink(LINKS.START)}/>;
    }
    const sortedWorkspaces = sortByDate(workspaces, "createdAt");
    sortedWorkspaces.reverse();

    return (
        <SplitPage>
            <EaseIn loading={loading} loadingHeight={300}>
                <Title size="large">
                    Select workspace
                </Title>

                <div>
                    <div className="mb-8">
                        {sortedWorkspaces.map((item) => (
                            <WorkspaceItem
                                key={item.id}
                                workspace={item}
                                selectWorkspace={setWorkspaceId}
                            />
                        ))}
                    </div>

                    <div className="mb-8">
                        <WorkspaceInvitations/>
                    </div>

                    {workspaces.length < 3 && (
                        <div>
                            <Link to={getLink(LINKS.CREATE_WORKSPACE)}>
                                <Button size="small">
                                    <div className="flex gap-2 items-center" style={{marginRight: -14}}>
                                        <span>Create new workspace</span>
                                        <IconWrapper icon={<FiArrowRight/>} size="xs"/>
                                    </div>
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
            </EaseIn>
        </SplitPage>
    );
}

SelectWorkspace.propTypes = {
};
