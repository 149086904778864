import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {FiHardDrive, FiUsers} from "react-icons/fi";
import {LINKS} from "src/links.js";
import {FoldersTree} from "src/components/FoldersTree/index.jsx";
import {Button} from "src/primitives/button.jsx";
import {useGetLink} from "src/utils/useGetLink.js";
import {useGetSharedResources} from "src/api/sharing/useGetSharedResources.js";
import {useGetWorkspace} from "src/api/workspaces/useGetWorkspace.js";
import {OnboardingButton} from "src/pages/Home/components/Sidebar/components/SidebarActions/OnboardingButton.jsx";
import {AdminLink} from "src/pages/Home/components/Sidebar/components/SidebarActions/AdminLink.jsx";
import {RhoDocsLink} from "src/pages/Home/components/Sidebar/components/SidebarActions/RhoDocsLink.jsx";
import {useGetWorkspaceResources} from "src/api/workspaces/useGetWorkspaceResources.js";
import {ContactLink} from "src/pages/Home/components/Sidebar/components/SidebarActions/ContactLink.jsx";
import {useCurrentTableId} from "src/pages/Home/contexts/current_table_context.jsx";
import {useCurrentFolderId} from "src/pages/Home/contexts/current_folder_context.jsx";
import {TreeHeader} from "./components/TreeHeader.jsx";
import {SidebarHeader} from "./components/SidebarHeader.jsx";
import {NewFileButton} from "./components/SidebarActions/NewFileButton.jsx";
import {NewFolderButton} from "./components/SidebarActions/NewFolderButton.jsx";


export function Sidebar({workspaceId}) {
    const [treeLoaded, setTreeLoaded] = useState(false);
    const currentTableId = useCurrentTableId();
    const currentFolderId = useCurrentFolderId();
    const {workspace, loading: loadingWorkspace} = useGetWorkspace(workspaceId);
    const {tables, folders, loading: loadingWorkspaceResources} = useGetWorkspaceResources(workspaceId, true);
    const {folders: sharedFolders, tables: sharedTables} = useGetSharedResources();

    const hasSharedResources = useMemo(() => {
        return sharedFolders.length > 0 || sharedTables.length > 0;
    }, [sharedFolders, sharedTables]);

    const getLink = useGetLink();
    const isSomethingLoading = loadingWorkspace || loadingWorkspaceResources;
    const activeNodeId = currentTableId || currentFolderId;

    return (
        <Wrapper>
            <Top className="border-b border-b-white/20 gap-4">
                <SidebarHeader/>
            </Top>

            <Body>
                <div className="flex items-center border-b border-b-white/20 h-[39px] mb-1">
                    <TreeHeader
                        icon={<FiHardDrive/>}
                        title={workspace?.name}
                        loading={isSomethingLoading}
                        link={LINKS.WORKSPACE}
                        showMenu={true}
                        workspaceId={workspaceId}
                    />
                </div>
                <DynamicWrapper className="mx-1 mt-2 mb-2">
                    <FoldersTree
                        key={workspaceId}
                        loading={isSomethingLoading}
                        tables={tables}
                        folders={folders}
                        onLoaded={setTreeLoaded.bind(null, true)}
                        activeNodeId={activeNodeId}
                    />
                </DynamicWrapper>
                {hasSharedResources && (
                    <>
                        <TreeHeader
                            icon={<FiUsers/>}
                            title="Shared with you"
                            link={LINKS.SHARED}
                        />
                        <div className="mx-1 mb-8">
                            <FoldersTree
                                key={workspaceId}
                                tables={sharedTables}
                                folders={sharedFolders}
                                // should not re-order shared items
                                disableDnd={true}
                                activeNodeId={activeNodeId}
                            />
                        </div>
                    </>
                )}
                {(!isSomethingLoading && treeLoaded) && (
                    <>
                        <div className="h-0.5 border-t border-white/10 my-4"/>
                        <NewFileButton/>
                        <NewFolderButton workspaceId={workspaceId}/>

                        <div className="h-0.5 border-t border-white/10 my-4"/>
                        <OnboardingButton/>
                        <RhoDocsLink/>
                        <ContactLink/>
                        <AdminLink/>
                    </>
                )}
            </Body>

            {/* TODO: Decide what do show in footer */}
            {window.showFooter && (
                <Bottom>
                    <Link to={getLink(LINKS.UPLOAD)}>
                        <Button theme="outline" size="small">
                            New file
                        </Button>
                    </Link>
                </Bottom>
            )}
        </Wrapper>
    );
}

Sidebar.propTypes = {
    workspaceId: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  // expand over resizer
  margin-right: -4px;
  border-right: 2px solid var(--accent-white);
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  // dark theme
  // background-color: #2a2a2d;
  background-color: var(--bg-dark-primary);
  color: #fdfdfd;
`;

const Top = styled.div`
  flex: 0 0 49px;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Bottom = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  margin-top: 10px;
  padding-top: 8px;
  flex: 0 0 46px;
  text-align: center;
`;

const DynamicWrapper = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 420px);
`;
