import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {LINKS} from "src/links.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {cva} from "cva";
import {FiTable, FiSliders} from "react-icons/fi";
import {IconWrapper} from "src/primitives/Icon.jsx";

const itemClasses = cva({
    base: "h-full px-6 flex items-center gap-2 hover:bg-white/15 border-r border-slate-400 transition-all",
    variants: {
        mode: {
            active: "bg-blue-600/80 hover:bg-blue-600/85"
        },
        order: {
            last: "border-none"
        }
    },
    defaultVariants: {}
});


export function Navbar({tableId}) {
    const getLink = useGetLink();

    return (
        <div className="flex text-sm font-semibold">
            <NavLink
                to={getLink(LINKS.publicTable(tableId))}
                end={true}
            >
                {({isActive}) => (
                    <div className={itemClasses({mode: isActive ? "active" : ""})}>
                        <IconWrapper icon={<FiTable/>} size="xxs" style={{marginLeft: -4}}/>
                        <span>
                            Data
                        </span>
                    </div>
                )}
            </NavLink>
            <NavLink
                to={getLink(LINKS.publicTableProfiling(tableId))}
                end={true}
            >
                {({isActive}) => (
                    <div className={itemClasses({mode: isActive ? "active" : "", order: "last"})}>
                        <IconWrapper icon={<FiSliders/>} size="xxs" style={{marginLeft: -4}}/>
                        <span>
                            Profiling
                        </span>
                    </div>
                )}
            </NavLink>
        </div>
    );
}

Navbar.propTypes = {
    tableId: PropTypes.string.isRequired
};
