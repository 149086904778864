import React from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";
import {useDeleteUser} from "src/api/users/useDeleteUser.js";
import {ConfirmDeletionDialog} from "src/primitives/modals/ConfirmDeletionDialog.jsx";
import {useBooleanState} from "src/utils/hooks/useBooleanState.jsx";


export function DeleteAccount({userId, onDeleted}) {
    const [deleteUser, {loading}] = useDeleteUser();
    const [showConfirmDialog, show, hide] = useBooleanState(false);

    const doDeleteUser = () => {
        deleteUser(userId).then((result) => {
            if (result.ok) {
                if (onDeleted) {
                    onDeleted();
                }
            } else {
                throw new Error(result.errorCode);
            }
        });
    };

    return (
        <>
            <Button
                size="small"
                theme="danger"
                onClick={show}
                isDisabled={loading}
            >
                Delete account
            </Button>

            {showConfirmDialog && (
                <ConfirmDeletionDialog
                    close={hide}
                    title="Delete workspace"
                    confirm={doDeleteUser}
                    loading={loading}
                >
                    <div>
                        This action is non recoverable. You cannot recover the account.
                    </div>
                </ConfirmDeletionDialog>
            )}
        </>
    );
}
DeleteAccount.propTypes = {
    userId: PropTypes.string.isRequired,
    onDeleted: PropTypes.func
};
