import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
  mutation DeleteTable($tableId: String!) {
    deleteTable(tableId: $tableId) {
      ok
      errorCode
      tableId
    }
  }
`;

function removeFromCache(cache, response) {
    const {ok, tableId} = response.data.deleteTable;
    if (!ok) {
        return;
    }
    const model = {
        id: tableId,
        __typename: "Table"
    };
    const normalizedId = cache.identify(model);
    cache.evict({id: normalizedId});
    cache.gc();
}

export function useDeleteTable({onSuccess, onError} = {}) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((tableId) => {
        return mutation({
            variables: {tableId},
            update(cache, response) {
                removeFromCache(cache, response);
            }
        }).then((response) => {
            const {errorCode, ok} = response.data.deleteTable;
            if (ok) {
                if (onSuccess) {
                    onSuccess();
                }
            } else {
                throw new Error(errorCode);
            }
        }).catch((err) => {
            console.error(err);
            if (onError) {
                onError();
            }
        });
    }, [mutation, onSuccess, onError]);

    return [doMutate, {loading, error}];
}
