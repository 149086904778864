
export function getAxisTitleStyle() {
    return {
        color: "#404040", // text-neutral-700
        fontSize: 14,
        fontFamily: "monospace",
        fontWeight: "bold"
    };
}

export function getAxisLabelStyle() {
    return {
        color: "#171717", // text-neutral-900
        fontSize: 12,
        fontFamily: "Inter, sans-serif, monospace",
        fontWeight: "normal"
    };
}

export function getDefaultTextStyle() {
    return {
        color: "#404040", // text-neutral-700
        fontFamily: "\"Geist Variable\", Inter, sans-serif, monospace"
    };
}
