import React from "react";
import PropTypes from "prop-types";
import {useGetWorkspaceResources} from "src/api/workspaces/useGetWorkspaceResources.js";
import {SelectInput} from "src/primitives/controls";
import {arrayToMap} from "src/utils/misc.js";
import {Title} from "src/primitives/title.jsx";
import {sortByString} from "src/utils/sorting.js";


export function SelectTable({workspaceId, onChange, selectedTableId}) {
    const {tables} = useGetWorkspaceResources(workspaceId);
    const tablesSorted = sortByString(tables);
    const tablesMap = arrayToMap(tables);

    const handleChange = (folder) => {
        if (onChange) {
            onChange(folder);
        }
    };

    const selectedOption = selectedTableId ? tablesMap[selectedTableId] : null;

    return (
        <div>
            <div className="mb-2">
                <Title size="xs">
                    Select table:
                </Title>
            </div>
            <SelectInput
                onChange={handleChange}
                options={tablesSorted}
                selectedOption={selectedOption}
                getValue={(item) => item.id}
            />
        </div>
    );
}

SelectTable.propTypes = {
    workspaceId: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    selectedTableId: PropTypes.string
};
