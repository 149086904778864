import React from "react";
import PropTypes from "prop-types";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiChevronDown} from "react-icons/fi";


export function ChevronToggle({isOpen, toggle, size, tooltip}) {
    const handleClick = () => {
        toggle(!isOpen);
    };

    return (
        <IconWrapper
            icon={<FiChevronDown/>}
            onClick={handleClick}
            size={size}
            flip={isOpen}
            tooltip={tooltip}
        />
    );
}

ChevronToggle.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    tooltip: PropTypes.node
};
