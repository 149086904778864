import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {cva} from "cva";
import {SizesType} from "./types.jsx";


const badgeClasses = cva({
    base: "inline-flex items-center justify-center rounded-lg transition-colors duration-300 font-normal",
    variants: {
        theme: {
            orange: "bg-orange-100 text-orange-500",
            orangeOutline: "border border-orange-500 bg-orange-500/10 text-orange-300",
            blue: "bg-blue-100 text-blue-600",
            red: "bg-red-200 text-red-700",
            black: "bg-gray-700 text-white",
            green: "bg-lime-200 text-lime-700",
            white: "bg-white text-black",
            whiteOutline: "border border-white text-white/80"
        },
        mode: {
            clickable: "cursor-pointer"
        },
        status: {
            active: "outline outline-2"
        },
        size: {
            xs: "py-0.5 px-2 text-[11px]",
            small: "py-1 px-3 text-[13px]",
            medium: "py-2 px-6 font-bold text-[14px]"
        },
        width: {
            sm: "w-16",
            md: "w-24",
            lg: "w-32"
        }
    },
    compoundVariants: [{
        theme: "orange", mode: "clickable", class: "hover:bg-orange-200"
    }, {
        theme: "blue",
        mode: "clickable",
        class: "hover:bg-blue-200"
    }, {
        theme: "blue", status: "active", class: "bg-blue-600 text-blue-100"
    }, {
        theme: "red", mode: "clickable", class: "hover:bg-red-300"
    }, {
        theme: "black", mode: "clickable", class: "hover:bg-gray-950"
    }, {
        theme: "green", mode: "clickable", class: "hover:bg-lime-300"
    }],
    defaultVariants: {
        theme: "orange",
        size: "small"
    }
});

export function Badge({children, text, theme, size, onClick, isActive, width, isClickable}) {
    const mode = onClick || isClickable ? "clickable" : "";
    const status = isActive ? "active" : "";

    return (
        <Wrapper className={badgeClasses({theme, size, mode, status, width})}>
            {children || text}
        </Wrapper>
    );
}

Badge.propTypes = {
    text: PropTypes.node,
    children: PropTypes.node,
    theme: PropTypes.oneOf(["orange", "orangeOutline", "blue", "red", "black", "green", "white", "whiteOutline"]),
    size: PropTypes.oneOf(["xs", "small", "medium"]),
    width: SizesType,
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    isClickable: PropTypes.bool
};

const Wrapper = styled.div``;
