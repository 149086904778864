import React from "react";
import PropTypes from "prop-types";


export function DescriptionList({items}) {
    return (
        <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
                {items.map((item) => (
                    <div
                        key={item.key}
                        className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                    >
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            {item.key}
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {item.value}
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    );
}
DescriptionList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.node,
        value: PropTypes.node
    }))
};
