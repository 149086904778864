import React from "react";
import PropTypes from "prop-types";
import {formatNumber, formatPercent} from "src/utils/formatting.js";


export function ModelResult({result}) {
    return (
        <div>
            <div className="monospace text-sm">
                <ul className="list-disc ml-4">
                    <li>R2: {formatNumber(result?.r2)}</li>
                    <li>Mean Squared Error: {formatNumber(result?.mse)}</li>
                    <li>Mean Absolute Error: {formatNumber(result?.mae)}</li>
                    <li>Mean Absolute Percentage Error: {formatPercent(result?.mape)}</li>
                </ul>
            </div>
        </div>
    );
}


ModelResult.propTypes = {
    result: PropTypes.shape({
        r2: PropTypes.number,
        mse: PropTypes.number,
        mae: PropTypes.number,
        mape: PropTypes.number
    })
};
