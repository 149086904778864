import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";


const QUERY = gql`
query GetUser {
  user {
    id
    createdAt
    email
    firstName
    lastName
    isAdmin
  }
}
`;


export function useGetUser() {
    const {data, loading, error, refetch} = useQuery(QUERY);

    const user = useMemo(() => {
        return data?.user;
    }, [data]);

    return {
        user, loading, error, refetch
    };
}
