import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {CHART_FIELDS} from "../fragments.js";

export const GET_CHART_QUERY = gql`
${CHART_FIELDS}
query GetChart ($chartId: String!) {
  chart (chartId: $chartId) {
    ...ChartFields
  }
}
`;

export function useGetChart(chartId) {
    const variables = {chartId};
    const {data, loading, error, refetch} = useQuery(GET_CHART_QUERY, {variables});

    const chart = useMemo(() => {
        return data?.chart || null;
    }, [data]);

    return {
        chart, loading, error, refetch
    };
}
