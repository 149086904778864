import React from "react";
import PropTypes from "prop-types";
import {useGetWorkspaceResources} from "src/api/workspaces/useGetWorkspaceResources.js";
import {TextLink} from "src/primitives/TextLink.jsx";
import {EXTERNAL_LINKS, LINKS} from "src/links.js";
import {CopyCode} from "src/primitives/Terminal/CopyCode.jsx";


export function PythonSdkInstructions({workspaceId}) {
    const {tables} = useGetWorkspaceResources(workspaceId);
    const initClientCode = `
import pandas as pd
from rho_store import RhoClient

client = RhoClient(api_key="$API_KEY")    
    `.trim();

    const exampleTable = tables ? tables[0] : null;
    let exampleTableId = "TABLE_ID";
    let fetchExampleTableCode = "";
    if (exampleTable) {
        exampleTableId = exampleTable.id;
        fetchExampleTableCode += `# use table ${exampleTable.name}`;
    }
    fetchExampleTableCode += `
table_id = "${exampleTableId}"

table = client.get_table(table_id)

print(table.data.head())
    `;

    return (
        <div className="flex flex-col gap-4 text-base">
            <div>
                Install the Python SDK using pip:
                <div className="bg-black rounded-md mt-2">
                    <CopyCode
                        code="pip install rho-store --upgrade --no-cache"
                        language="bash"
                        iconColor="rgba(255, 255, 255, 0.8)"
                    />
                </div>
            </div>
            <div>
                {/* eslint-disable-next-line max-len */}
                Get an API key from <TextLink to={LINKS.ACCOUNT} target="_blank">account settings</TextLink> to initialize the client:
                <div className="bg-black rounded-md mt-2">
                    <CopyCode
                        code={initClientCode}
                        language="python"
                        iconColor="rgba(255, 255, 255, 0.8)"
                    />
                </div>
            </div>
            <div>
                Fetch data from a table:
                <div className="bg-black rounded-md mt-2">
                    <CopyCode
                        code={fetchExampleTableCode}
                        language="python"
                        iconColor="rgba(255, 255, 255, 0.8)"
                    />
                </div>
            </div>
            <div>
                {/* eslint-disable-next-line max-len */}
                For full documentation, visit the <TextLink to={EXTERNAL_LINKS.RHO_DOCS} target="_blank">documentation portal</TextLink>.
            </div>
        </div>
    );
}

PythonSdkInstructions.propTypes = {
    workspaceId: PropTypes.string.isRequired
};
