import React from "react";
import PropTypes from "prop-types";
import {useUpdateFolder} from "src/api/folders/useUpdateFolder.js";
import {TextEditable} from "src/primitives/controls/TextEditable.jsx";


export function EditFolderName({folderId, name}) {
    const [updateFolder] = useUpdateFolder(folderId);

    const handleSubmit = (newName) => {
        updateFolder({name: newName});
    };


    return (
        <TextEditable
            text={name}
            onSubmit={handleSubmit}
        />
    );
}

EditFolderName.propTypes = {
    folderId: PropTypes.string.isRequired,
    name: PropTypes.string
};
