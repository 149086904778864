import {useCallback, useEffect, useRef, useState} from "react";

export function useTransform(from, to) {
    const intervalRef = useRef();
    const [value, setValue] = useState(from);

    const stop = useCallback(() => {
        console.debug("stop");
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
    }, []);

    const incrementValue = useCallback(() => {
        setValue((v) => {
            if (v < to) {
                return v + 4;
            }
            stop();
            return v;
        });
    }, [setValue, to, stop]);

    const start = useCallback(() => {
        console.debug("starting");
        intervalRef.current = window.setInterval(incrementValue, 25);
    }, [incrementValue]);

    /*
    Starting automatic..
    useEffect(() => {
        intervalRef.current = window.setInterval(incrementValue, 40);
    }, [incrementValue]);

     */

    useEffect(() => {
        if (value >= to && intervalRef.current) {
            stop();
        }
    }, [value, to, stop]);

    return [value, start];
}
