import React from "react";
import PropTypes from "prop-types";
import {DialogModal} from "src/primitives/modals/Dialog.jsx";
import {
    EditColumnName
// eslint-disable-next-line max-len
} from "src/pages/Home/pages/TablePage/pages/TableSettingsPage/components/ColumnSettings/components/EditColumnName/index.jsx";
import {ColumnDisplaySettings} from "./components/ColumnDisplaySettings/index.jsx";


export function ColumnMenu({column, isOpen, close}) {
    return (
        <DialogModal
            title={(
                <div className="flex items-center gap-2">
                    <span>Display options:</span>
                    <EditColumnName tableId={column?.tableId} name={column?.name}/>
                </div>
            )}
            isOpen={isOpen}
            close={close}
            verticalAlign="top"
        >
            <ColumnDisplaySettings column={column}/>
        </DialogModal>
    );
}

ColumnMenu.propTypes = {
    column: PropTypes.shape({
        tableId: PropTypes.string,
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    }),
    isOpen: PropTypes.bool,
    close: PropTypes.func
};
