import React from "react";
import {Title} from "src/primitives/title.jsx";
import {Link} from "react-router-dom";
import {LINKS} from "src/links.js";


export function AdminSidebar() {
    return (
        <div className="bg-gray-800 flex-1 text-white flex flex-col">
            <div className="h-[48px] flex items-center px-4 border-b border-white/10 mb-4">
                <Title size="small" style={{margin: 0}}>
                    Admin sidebar
                </Title>
            </div>
            <div className="flex flex-col gap-2 px-4 mb-8">
                <Link to={LINKS.ADMIN_USERS}>
                    Users
                </Link>
                <Link to={LINKS.ADMIN_WORKSPACES}>
                    Workspaces
                </Link>
            </div>
            <div className="flex flex-col gap-2 px-4">
                <Link to={LINKS.HOME}>
                    Home
                </Link>
            </div>
        </div>
    );
}
