import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
    mutation VerifyGcpConnection($data: JSON!) {
        verifyGcpConnection(data: $data) {
            ok
            errorCode
        }
    }
`;

export function useVerifyGcpConnection() {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((data) => {
        return mutation({variables: {data}}).then((response) => {
            return response.data.verifyGcpConnection;
        });
    }, [mutation]);

    return [doMutate, {loading, error}];
}
