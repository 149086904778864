import React from "react";
import PropTypes from "prop-types";
import {FiCheck, FiInfo} from "react-icons/fi";
import {Title} from "src/primitives/title.jsx";
import {CheckboxInput} from "src/primitives/controls/Checkbox.jsx";
import {DialogModal} from "src/primitives/modals/Dialog.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {useBooleanState} from "src/utils/hooks/useBooleanState.jsx";


export function ChecklistItem({
    name,
    isCompleted,
    title,
    description,
    instructions,
    successMessage
}) {
    const [helpActive, showHelp, closeHelp] = useBooleanState(false);
    const hasInstructions = !!instructions;

    return (
        <>
            <DialogModal
                isOpen={helpActive}
                close={closeHelp}
                title={title}
                size="xl"
                verticalAlign="top"
            >
                {instructions}
            </DialogModal>

            <div className="flex items-center">
                <div className="py-2 px-2 mr-2">
                    <CheckboxInput
                        name={name}
                        isChecked={isCompleted}
                    />
                </div>

                <div className="flex-1 flex flex-col">
                    <div className="w-full flex items-center justify-between">
                        <Title style={{margin: 0}} size="small">
                            {title}
                        </Title>
                        {hasInstructions && (
                            <IconWrapper
                                size="xs"
                                icon={<FiInfo/>}
                                isActive={helpActive}
                                onClick={showHelp}
                                itemId={`help-${name}`}
                                tooltip="Show help"
                                tooltipProps={{
                                    delayShow: 500,
                                    delayHide: 50,
                                    place: "right"
                                }}
                            />
                        )}
                    </div>

                    {(isCompleted && successMessage) ? (
                        <div className="text-green-800 flex items-center">
                            <IconWrapper
                                size="xs"
                                icon={<FiCheck/>}
                                style={{
                                    marginLeft: "-4px",
                                    marginBottom: "-2px"
                                }}
                            />
                            <div>
                                {successMessage}
                            </div>
                        </div>
                    ) : (
                        <div className="text-neutral-600">
                            {description}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

ChecklistItem.propTypes = {
    name: PropTypes.string,
    isCompleted: PropTypes.bool,
    title: PropTypes.node,
    description: PropTypes.node,
    successMessage: PropTypes.node,
    instructions: PropTypes.node
};

ChecklistItem.defaultProps = {
};
