import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {Title} from "src/primitives/title.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {DeleteTable} from "src/pages/Home/pages/TablePage/components/DeleteTable/index.jsx";
import {isTableReady} from "src/pages/Home/pages/TablePage/utils.js";

export function NewTablePage({tableId}) {
    const {table, startPolling, stopPolling} = useGetTable(tableId);

    useEffect(() => {
        startPolling(1000);
    }, [startPolling]);

    useEffect(() => {
        if (isTableReady(table)) {
            stopPolling();
        }
    }, [table, stopPolling]);

    return (
        <div className="flex flex-grow justify-center mt-36">
            <div className="max-w-96 flex flex-col gap-4">
                <Title>
                    {table?.name}
                </Title>
                <div className="flex items-center gap-3 mb-4">
                    <Spinner size="md"/>
                    <span>Processing data</span>
                </div>
                <div>
                    <DeleteTable tableId={tableId}/>
                </div>
            </div>
        </div>
    );
}

NewTablePage.propTypes = {
    tableId: PropTypes.string
};
