import React, {useCallback} from "react";
import {cva} from "cva";
import PropTypes from "prop-types";
import {DB_BACKENDS} from "src/pages/CreateWorkspace/components/CreateWorkspaceForm/constants.js";
import {BackendLogo} from "./BackendLogo.jsx";


export const backendButtonClasses = cva({
    // eslint-disable-next-line max-len
    base: "border-2 border-neutral-200 rounded-md p-4 w-64 h-48 transition-colors hover:bg-black-2 hover:border-neutral-300",
    variants: {
        state: {
            active: "!border-lime-500"
        }
    }
});


export function WorkspaceBackend({selectedBackend, onChange}) {
    const handleChange = useCallback((value) => {
        if (onChange) {
            onChange(value);
        }
    }, [onChange]);

    return (
        <div className="flex gap-8">
            <button
                type="button"
                className={backendButtonClasses({state: selectedBackend === DB_BACKENDS.rho ? "active" : ""})}
                onClick={handleChange.bind(null, DB_BACKENDS.rho)}
            >
                <div className="flex flex-col h-full pt-4">
                    <div className="flex justify-center">
                        <BackendLogo backend={DB_BACKENDS.rho} size={44}/>
                    </div>
                    <div className="my-2 text-sm">
                        Hosted service.<br/> This is the default option.
                    </div>
                    <div className="text-xs text-neutral-500">
                        We take care of everything for you.
                    </div>
                </div>
            </button>

            <button
                type="button"
                className={backendButtonClasses({state: selectedBackend === DB_BACKENDS.gcp ? "active" : ""})}
                onClick={handleChange.bind(null, DB_BACKENDS.gcp)}
            >
                <div className="flex flex-col h-full pt-4">
                    <div className="flex justify-center">
                        <BackendLogo backend={DB_BACKENDS.gcp} size={44}/>
                    </div>
                    <div className="my-2 text-sm">
                        Configure your own GCP backend.
                    </div>
                    <div className="text-xs text-neutral-500">
                        You host and connect your own database.
                    </div>
                </div>
            </button>
        </div>
    );
}

WorkspaceBackend.propTypes = {
    selectedBackend: PropTypes.string,
    onChange: PropTypes.func
};
