import React from "react";
import PropTypes from "prop-types";


export function Switch({
    value,
    onChange,
    renderLabel,
    isDisabled
}) {
    const handleChange = (e) => {
        if (onChange) {
            onChange(e.target.checked);
        }
    };

    const isChecked = !!value;

    return (
        <div>
            {/* eslint-disable-next-line max-len */}
            <label className="relative inline-flex items-center cursor-pointer p-1 -ml-1 rounded-lg hover:bg-neutral-200/50 transition-all">
                <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={isChecked}
                    disabled={isDisabled}
                    onChange={handleChange}
                />
                {/* eslint-disable-next-line max-len */}
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1.5 after:start-[6px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
                <div className="mx-3 text-sm text-neutral-900 hover:text-neutral-950">
                    {renderLabel ? renderLabel({isChecked}) : "Checked toggle"}
                </div>
            </label>
        </div>
    );
}

Switch.propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
    renderLabel: PropTypes.func,
    isDisabled: PropTypes.bool
};
