import React from "react";
import PropTypes from "prop-types";
import {formatNumber, formatPercent, formatTitleCase} from "src/utils/formatting.js";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {Badge} from "src/primitives/badge.jsx";
import {ColumnDataType} from "src/pages/Home/pages/TablePage/components/ColumnDataType.jsx";


export function ColumnsGeneralTable({
    columns,
    selectColumn,
    tableStats
}) {
    const data = columns.map((column) => {
        return {
            name: column.name,
            values: column.name,
            dataType: column.dataType,
            variableType: column.variableType,
            unique: tableStats[column.name].distinct
        };
    });

    const cols = [{
        key: "dataType",
        header: " ",
        style: {width: "28px"},
        render: (v, row) => (
            <ColumnDataType column={row} onlyIcon={true}/>
        )
    }, {
        key: "name",
        header: "Name",
        render: (v) => (
            <div className="flex shrink">
                <button
                    className="hover:underline hover:text-neutral-800"
                    type="button"
                    onClick={selectColumn.bind(null, v)}
                >
                    {v}
                </button>
            </div>
        )
    }, {
        key: "variableType",
        header: "Variable type",
        render: (v) => (
            <div>
                <Badge theme="orange" size="small">
                    {formatTitleCase(v)}
                </Badge>
            </div>
        )
    }, {
        key: "values",
        header: "Values",
        render: (v, row) => (
            <div className="flex gap-4 mr-2">
                <span>
                    {formatNumber(tableStats[row.name].values, 0)}
                </span>
                <span className="text-neutral-600">
                    ({formatPercent(tableStats[row.name].values / tableStats[row.name].rows, 2)})
                </span>
            </div>
        )
    }, {
        key: "unique",
        header: "Unique values",
        render: (v) => formatNumber(v, 0)
    }, {
        key: "placeholder",
        header: " ",
        render: () => (
            <div className="flex flex-1 text-transparent px-8">-</div>
        )
    }];

    return (
        <SimpleTable
            data={data}
            columns={cols}
        />
    );
}

ColumnsGeneralTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    })),
    selectColumn: PropTypes.func,
    tableStats: PropTypes.object
};
