import {useMakePivotQuery} from "src/api/data/useMakePivotQuery.js";
import {useEffect, useMemo} from "react";
import {
    formatDataFromQueryResult,
    isColumnCategorical,
    isColumnContinuous,
    isColumnNumeric
} from "src/utils/tableData.js";
import {sortByValue} from "src/utils/sorting.js";


export function usePivotData(tableId, columns, targets) {
    const [makePivotQuery, {result, loading, errors, refetch}] = useMakePivotQuery(tableId);

    useEffect(() => {
        const aggregations = [];
        targets.forEach((column) => {
            if (isColumnNumeric(column)) {
                aggregations.push({
                    value: column.name,
                    aggregation: "stats"
                });
            }
            if (isColumnContinuous(column)) {
                aggregations.push({
                    value: column.name,
                    aggregation: "dist"
                });
            }
            if (isColumnCategorical(column)) {
                aggregations.push({
                    value: column.name,
                    aggregation: "group"
                });
            }
        });

        makePivotQuery({
            columns,
            aggregations
        });
    }, [makePivotQuery, columns, targets]);

    const data = useMemo(() => {
        if (!result) {
            return [];
        }
        const items = formatDataFromQueryResult(result.columns, result.rows);
        return sortByValue(items, "__total", "desc");
    }, [result]);

    return {
        result,
        data,
        loading,
        errors,
        refetch
    };
}
