import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {SelectInput} from "src/primitives/controls/index.jsx";
import {arrayToMap} from "src/utils/misc.js";
import {Label} from "src/primitives/controls/Label.jsx";
import {ColumnOption} from "../ColumnOption.jsx";


export function SelectXaxis({columns, value, onChange}) {
    const handleChange = (column) => {
        onChange(column?.name || "");
    };

    const columnsMap = useMemo(() => {
        return arrayToMap(columns, "name");
    }, [columns]);

    return (
        <div>
            <Label label="X-axis"/>
            <SelectInput
                showClearIcon={false}
                placeholder="Select X-axis"
                onChange={handleChange}
                options={columns}
                getValue={(col) => col.name}
                selectedOption={columnsMap[value]}
                renderOption={(col) => <ColumnOption column={col}/>}
            />
        </div>
    );
}

SelectXaxis.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
    }))
};
