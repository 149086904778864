import React from "react";
import PropTypes from "prop-types";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {formatTimestamp} from "src/utils/formatting.js";
import {DeleteUser} from "src/pages/Admin/pages/Users/components/DeleteUser/index.jsx";


const columns = [{
    key: "id",
    header: "ID"
}, {
    key: "createdAt",
    header: "Created",
    render: (value) => (
        <span>{formatTimestamp(value)}</span>
    )
}, {
    key: "email",
    header: "Email"
}, {
    key: "events",
    header: "Events",
    render: (value) => (
        <span>{Array.isArray(value) ? value.length : "-"} events</span>
    )
}, {
    key: "delete_user",
    header: " ",
    render: (_, row) => (
        <DeleteUser userId={row.id}/>
    )
}];

export function UsersTable({users}) {
    return (
        <div>
            <SimpleTable
                data={users}
                columns={columns}
            />
        </div>
    );
}

UsersTable.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        createdAt: PropTypes.string,
        email: PropTypes.string
    }))
};
