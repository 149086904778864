import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";


export const CurrentFolderContext = createContext(null);


export function CurrentFolderContextProvider({children}) {
    const {folderId} = useParams();

    return (
        <CurrentFolderContext.Provider value={folderId}>
            {children}
        </CurrentFolderContext.Provider>
    );
}

CurrentFolderContextProvider.propTypes = {
    children: PropTypes.element
};

export function useCurrentFolderId() {
    return useContext(CurrentFolderContext);
}
