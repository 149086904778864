import React from "react";
import styled from "styled-components";
import {SkeletonText} from "@chakra-ui/react";


export function TreeLoading() {
    return (
        <Wrapper>
            <SkeletonText
                noOfLines={10}
                spacing="6"
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
  margin: 20px 0;
  padding-left: 4px;
  padding-right: 20px;
`;
