import {useRemoveMember} from "src/api/workspaceMembers/useRemoveMember.js";
import {overrideSystemHandling} from "src/utils/system.js";
import {Button} from "src/primitives/button.jsx";
import PropTypes from "prop-types";
import React from "react";

export function RemoveMemberCell({memberId, member}) {
    const [removeMember, {loading}] = useRemoveMember();

    const handleClick = (e) => {
        overrideSystemHandling(e);
        removeMember(memberId);
    };

    if (member.status === "OWNER") {
        // Cannot delete owner
        return null;
    }

    if (!memberId) {
        return null;
    }

    return (
        <Button size="xs" theme="danger" onClick={handleClick} isLoading={loading}>
            Remove
        </Button>
    );
}

RemoveMemberCell.propTypes = {
    memberId: PropTypes.string,
    member: PropTypes.shape({
        status: PropTypes.string
    })
};
