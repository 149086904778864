import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";
import {overrideSystemHandling} from "src/utils/system.js";
import {useKeyPress} from "src/utils/useKeyPress.js";


export function PromptArea({askQuestion, loading}) {
    const [value, setValue] = useState("");

    const handleUpdate = (e) => {
        setValue(e.target.value);
    };

    const handleSubmit = (e) => {
        overrideSystemHandling(e);
        if (!value) {
            return;
        }
        askQuestion(value);
    };

    useKeyPress(handleSubmit, ["Enter"], {metaKey: true});

    return (
        <div className="max-w-lg mx-auto my-2">
            <form onSubmit={handleSubmit}>
                <div className="flex gap-2 items-center">
                    <textarea
                        className="rounded-md shadow-sm ring-1 ring-inset ring-neutral-300 w-112 resize-none p-2"
                        value={value}
                        onChange={handleUpdate}
                    />
                    <div>
                        <Button
                            type="submit"
                            isLoading={loading}
                            isDisabled={!value}
                            theme="secondary"
                        >
                            Send
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

PromptArea.propTypes = {
    askQuestion: PropTypes.func,
    loading: PropTypes.bool
};
