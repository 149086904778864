import React, {useState, useCallback} from "react";
import PropTypes from "prop-types";
import {TextInput} from "src/primitives/controls/TextInput.jsx";
import {Button} from "src/primitives/button.jsx";
import {overrideSystemHandling} from "src/utils/system.js";


export function TextForm({
    onSubmit,
    loading,
    inputType,
    buttonText,
    validator,
    hasError
}) {
    const [value, setValue] = useState("");
    const [isValid, setIsValid] = useState(false);

    const handleSubmit = (e) => {
        if (e) {
            overrideSystemHandling(e);
        }

        if (!value) {
            return;
        }
        if (validator && !validator(value)) {
            setIsValid(true);
            return;
        }
        onSubmit(value);
    };

    const handleChange = useCallback((e) => {
        setValue(e.target.value);
        setIsValid(false);
    }, [setValue, setIsValid]);

    return (
        <form onSubmit={handleSubmit} className="flex gap-2 items-center">
            <TextInput
                type={inputType}
                onChange={handleChange}
                value={value}
                size="small"
                placeholder="Type email"
                hasError={isValid || hasError}
            />
            <Button
                theme="secondary"
                isLoading={loading}
                size="xs"
            >
                {buttonText}
            </Button>
        </form>
    );
}

TextForm.propTypes = {
    onSubmit: PropTypes.func,
    validator: PropTypes.func,
    loading: PropTypes.bool,
    buttonText: PropTypes.node,
    inputType: PropTypes.oneOf(["text", "email"]),
    hasError: PropTypes.bool
};

TextForm.defaultProps = {
    inputType: "text",
    buttonText: "Save"
};
