import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {CategoryItem} from "src/charts/CategoryDistribution/CategoryItem.jsx";


export function CategoryDistribution({values, maxItems, height, showMore}) {
    const totalValues = values.reduce((acc, item) => acc + item.value, 0);
    const notVisible = values.slice(maxItems);
    const notVisibleTotal = notVisible.reduce((acc, item) => acc + item.value, 0);

    return (
        <Wrapper $maxHeight={height}>
            {values.slice(0, maxItems).map((item) => {
                return (
                    <CategoryItem
                        key={item.name}
                        name={item.name}
                        value={item.value}
                        color={item.color}
                        totalValues={totalValues}
                    />
                );
            })}
            {(showMore && notVisible.length > 0) && (
                <CategoryItem
                    key="other"
                    name="Other"
                    value={notVisibleTotal}
                    totalValues={totalValues}
                />
            )}
        </Wrapper>
    );
}

CategoryDistribution.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.number,
        color: PropTypes.string
    })),
    maxItems: PropTypes.number,
    height: PropTypes.number,
    showMore: PropTypes.bool
};


CategoryDistribution.defaultProps = {
    maxItems: 10,
    showMore: false
};

const Wrapper = styled.div`
  width: 100%;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow-y: auto;
  max-height: ${({$maxHeight}) => $maxHeight ? `${$maxHeight}px` : "none"};
`;
