import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";
import {FiUser} from "react-icons/fi";
import {IconWrapper} from "src/primitives/Icon.jsx";


function initials(firstName, lastName) {
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
}

function getInitials(user) {
    if (!user) {
        return "";
    }
    const {firstName, lastName, email} = user;

    if (firstName && lastName) {
        return initials(firstName, lastName);
    }
    if (email) {
        return email[0].toUpperCase();
    }
    return "";
}

const wrapperClasses = cva({
    base: "relative inline-flex items-center justify-center overflow-hidden rounded-full bg-lime-600 text-white",
    variants: {
        size: {
            xs: "w-5 h-5",
            small: "w-7 h-7",
            medium: "w-8 h-8"
        },
        theme: {
            green: "bg-lime-600",
            blue: "bg-blue-600",
            orange: "bg-orange-600"
        }
    },
    defaultVariants: {
        size: "medium",
        theme: "green"
    }
});


const textClasses = cva({
    base: "font-semibold",
    variants: {
        size: {
            xs: "text-xxs",
            small: "text-xs",
            medium: "text-sm"
        }},
    defaultVariants: {
        size: "medium"
    }
});


export function Avatar({user, size, theme}) {
    const content = getInitials(user);

    return (
        <div className={wrapperClasses({size, theme})}>
            {content ? (
                <span className={textClasses({size})}>
                    {content}
                </span>
            ) : (
                <IconWrapper
                    icon={<FiUser/>}
                    size="xxs"
                />
            )}
        </div>
    );
}

Avatar.propTypes = {
    user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
    }),
    size: PropTypes.oneOf(["xs", "small", "medium"]),
    theme: PropTypes.oneOf(["green", "orange", "blue"])
};
