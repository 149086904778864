import {useCallback, useEffect, useRef, useState} from "react";


export function minutesFromDate(date) {
    if (!date) {
        return null;
    }

    const now = (new Date()).toUTCString();
    const dateString = (new Date(date)).toUTCString();
    const diffMilliseconds = Math.abs(new Date(now) - new Date(dateString));

    // minutes
    const minutes = Math.floor(diffMilliseconds / (1000 * 60));
    console.debug("minutes: ", minutes);
    return minutes;
}

export function useMinutesFromDate(date) {
    const timer = useRef();
    const [value, setValue] = useState(minutesFromDate(date));

    const updateValue = useCallback(() => {
        setValue(minutesFromDate(date));
    }, [date, setValue]);

    useEffect(() => {
        timer.current = window.setInterval(updateValue, 5000);

        return () => {
            window.clearInterval(timer.current);
        };
    }, [updateValue]);

    return value;
}
