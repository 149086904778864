import React from "react";
import PropTypes from "prop-types";
import {LogoImg, LogoText} from "src/primitives/Logo/index.jsx";
import {LINKS} from "src/links.js";


export function RhoHeader({showText}) {
    return (
        <div className="gap-2 flex items-center">
            <LogoImg/>
            {showText && (
                <LogoText link={LINKS.WEB}/>
            )}
        </div>
    );
}

RhoHeader.propTypes = {showText: PropTypes.bool};
RhoHeader.defaultProps = {showText: true};
