import React from "react";
import PropTypes from "prop-types";
import {useReadFolder} from "src/api/folders/useGetFolders.js";
import {PageLayout} from "src/primitives/layout/PageLayout.jsx";
import {Navigate, Route, Routes} from "react-router-dom";
import {FolderContent} from "src/pages/Home/pages/FolderPage/pages/FolderContent/index.jsx";
import {FolderSettings} from "src/pages/Home/pages/FolderPage/pages/FolderSettings/index.jsx";
import {LINKS} from "src/links.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {ShareFolder} from "src/pages/Home/components/ShareResource/ShareFolder.jsx";
import {FolderPageHeader} from "src/pages/Home/pages/FolderPage/components/FolderPageHeader/index.jsx";


export function FolderPage({folderId, workspaceId}) {
    const getLink = useGetLink();
    const {folder, exists} = useReadFolder(folderId);
    const tabs = [
        {link: LINKS.folderPage(folderId), title: "Overview"},
        {link: LINKS.folderSettings(folderId), title: "Settings"}
    ];

    // TODO: Logic for when to navigate to home (aka. 404)
    if (!exists) {
        return null;
    }

    if (!folder) {
        // Folder not found
        return <Navigate to={getLink(LINKS.HOME)}/>;
    }

    return (
        <PageLayout
            title={(
                <FolderPageHeader
                    folderId={folderId}
                    workspaceId={workspaceId}
                />
            )}
            navLinks={tabs}
            navbarSecondary={(
                <ShareFolder
                    folderId={folderId}
                />
            )}
        >
            <Routes>
                <Route
                    path=""
                    element={(
                        <FolderContent
                            key={folderId}
                            workspaceId={workspaceId}
                            folderId={folderId}
                        />
                    )}
                />
                <Route
                    path="/settings"
                    element={(
                        <FolderSettings
                            key={folderId}
                            folderId={folderId}
                        />
                    )}
                />
            </Routes>
        </PageLayout>
    );
}

FolderPage.propTypes = {
    workspaceId: PropTypes.string,
    folderId: PropTypes.string
};
