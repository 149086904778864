import React from "react";
import PropTypes from "prop-types";
import {sortByValue} from "src/utils/sorting.js";
import {DistributionDisplayNames, DistributionIcons} from "src/components/distributions/index.jsx";
import {formatNumber} from "src/utils/formatting.js";


export function BestDistributionFitCell({fittedDistributions}) {
    if (!fittedDistributions) {
        return null;
    }

    const ALPHA = 0.05;
    const sortedDistributions = sortByValue(fittedDistributions, "p_value", "desc");
    const bestFit = sortedDistributions[0];
    if (bestFit.p_value < ALPHA) {
        return null;
    }
    const name = bestFit.distribution_name;
    const displayName = DistributionDisplayNames[name];

    return (
        <div className="flex flex-col">
            <img
                className="w-8 ml-[-4px] mt-[-8px]"
                src={DistributionIcons[name]}
                alt={name}
            />
            <div className="flex mt-[-10px]">
                <div>
                    {`${displayName.replace("distribution", "").trim()};`}
                </div>
                <div className="monospace">
                    p={formatNumber(bestFit.p_value, 2)}
                </div>
            </div>
        </div>
    );
}

BestDistributionFitCell.propTypes = {
    fittedDistributions: PropTypes.arrayOf(PropTypes.shape({
        distribution_name: PropTypes.string,
        p_value: PropTypes.number
    }))
};
