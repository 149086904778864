import React, {useCallback, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";
import {Link} from "react-router-dom";
import {LINKS} from "src/links.js";
import {Button} from "src/primitives/button.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiArrowRight} from "react-icons/fi";
import {Spinner} from "src/primitives/spinner.jsx";
import {useInitializeAccount} from "src/api/users/useInitializeAccount.js";
import {userDataFromClerkUser} from "src/api/users/useUpdateUser.js";
import {LoadingBar} from "src/pages/StartPage/components/InitAccount/LoadingBar.jsx";


function getTitle(workspaceId, loading, error, mutationError) {
    if (workspaceId) {
        return "Account is ready!";
    }
    if (loading) {
        return "Setting up your account...";
    }
    if (error || mutationError) {
        return "Something went wrong";
    }
    return null;
}


export function InitAccount({user}) {
    const ref = useRef();
    const [workspaceId, setWorkspaceId] = useState("");
    const [error, setError] = useState(null);
    const [initAccount, {loading, error: mutationError}] = useInitializeAccount();

    const doInitAccount = useCallback(() => {
        if (!user) {
            return;
        }

        if (ref.current) {
            // only call once
            return;
        }

        ref.current = true;
        const userData = userDataFromClerkUser(user);
        initAccount(userData).then((result) => {
            if (result.ok) {
                setWorkspaceId(result.workspace.id);
            } else {
                throw new Error(result.errorCode);
            }
        }).catch((e) => {
            console.error(e);
            setError(e);
        });
    }, [initAccount, user]);

    useEffect(() => {
        doInitAccount();
    }, [doInitAccount]);

    return (
        <div className="h-72 w-full px-4 flex flex-col">
            <Title size="large">
                {getTitle(workspaceId, loading, error, mutationError)}
            </Title>
            <div>
                {workspaceId && (
                    <Link to={`${LINKS.HOME}/workspace?wid=${workspaceId}`}>
                        <Button size="small" theme="green">
                            <div className="flex gap-2 items-center" style={{marginRight: -14}}>
                                <span>Get started</span>
                                <IconWrapper icon={<FiArrowRight/>} size="xs"/>
                            </div>
                        </Button>
                    </Link>
                )}

                {loading && (
                    <div className="fade-in h-6">
                        <LoadingBar completedState={<Spinner/>}/>
                    </div>
                )}
            </div>
        </div>
    );
}

InitAccount.propTypes = {
    user: PropTypes.object
};
