import React from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {formatNumber} from "src/utils/formatting.js";
import {getDefaultTextStyle} from "src/charts/options.js";


function getOption(data, {title, subTitle}) {
    const base = 0;

    return {
        textStyle: getDefaultTextStyle(),
        title: {
            text: title,
            subtext: subTitle,
            left: "center",
            textStyle: {
                fontSize: 16
            }
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                animation: false,
                label: {
                    backgroundColor: "#ccc",
                    borderColor: "#aaa",
                    borderWidth: 1,
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    color: "#222"
                }
            },
            formatter(params) {
                const lower = params[0].value;
                // params[1] is the diff.
                const upper = params[1].value + lower;
                const {name, value} = params[2];
                return `
Date: <strong>${name}</strong><br/>
Average value: <strong>${formatNumber(value)}</strong><br/>
Range: <strong>${formatNumber(lower)} - ${formatNumber(upper)}</strong><br/>
                `;
            }
        },
        grid: {
            left: "2%",
            right: "2%",
            bottom: "2%",
            containLabel: true
        },
        xAxis: {
            type: "category",
            data: data.map((item) => {
                return item.date;
            }),
            axisLabel: {
                formatter(value) {
                    const date = new Date(value);
                    return date.toLocaleDateString("sv-SE");
                }
            },
            boundaryGap: false
        },
        yAxis: {
            axisLabel: {
                formatter(val) {
                    return formatNumber(val);
                }
            },
            axisPointer: {
                label: {
                    formatter(params) {
                        return formatNumber(params.value);
                    }
                }
            },
            splitNumber: 3
        },
        series: [
            {
                name: "Lower band",
                type: "line",
                data: data.map((item) => {
                    return item.l + base;
                }),
                lineStyle: {
                    opacity: 0
                },
                stack: "confidence-band",
                symbol: "none"
            },
            {
                name: "Upper band",
                type: "line",
                data: data.map((item) => {
                    return item.u - item.l;
                }),
                lineStyle: {
                    opacity: 0
                },
                areaStyle: {
                    color: "#65a30d",
                    opacity: 0.2
                },
                stack: "confidence-band",
                symbol: "none"
            },
            {
                name: title,
                type: "line",
                data: data.map((item) => {
                    return item.value + base;
                }),
                itemStyle: {
                    color: "#65a30d"
                },
                showSymbol: false
            }
        ]
    };
}


export function LineChart({data, title}) {
    /**
     * https://echarts.apache.org/examples/en/editor.html?c=confidence-band
     */
    const option = getOption(data, {title});

    return (
        <ChartWrapper option={option}/>
    );
}

LineChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        date: PropTypes.string,
        l: PropTypes.number,
        u: PropTypes.number
    })),
    title: PropTypes.string
};

LineChart.defaultProps = {
    // Example
    data: [
        {
            value: 0.0115231406,
            date: "2012-11-05",
            l: -0.0663484142,
            u: 0.0214084056
        },
        {
            value: -0.0032634994,
            date: "2012-11-06",
            l: -0.0793170451,
            u: 0.0355159827
        },
        {
            value: -0.0108985452,
            date: "2012-11-07",
            l: -0.0846123893,
            u: 0.0409797057
        },
        {
            value: -0.0092766813,
            date: "2012-11-08",
            l: -0.0802668328,
            u: 0.0373886301
        },
        {
            value: 0.0095972086,
            date: "2012-11-09",
            l: -0.0623739694,
            u: 0.0194918693
        },
        {
            value: -0.0111809358,
            date: "2012-11-10",
            l: -0.0819555908,
            u: 0.038335749
        },
        {
            value: -0.0023572296,
            date: "2012-11-11",
            l: -0.0745443377,
            u: 0.0306093592
        },
        {
            value: 0.0084213775,
            date: "2012-11-12",
            l: -0.0657707155,
            u: 0.0227270619
        },
        {
            value: 0.0107446453,
            date: "2012-11-13",
            l: -0.0617995017,
            u: 0.0196547867
        },
        {
            value: 0.009457792,
            date: "2012-11-14",
            l: -0.0597697849,
            u: 0.0191832343
        },
        {
            value: 0.0031194779,
            date: "2012-11-15",
            l: -0.0589126783,
            u: 0.0186409442
        },
        {
            value: -0.0115128213,
            date: "2012-11-16",
            l: -0.0767105447,
            u: 0.0370292452
        },
        {
            value: 0.0058347339,
            date: "2012-11-17",
            l: -0.0592236472,
            u: 0.0198181452
        },
        {
            value: -0.0235630436,
            date: "2012-11-18",
            l: -0.083529944,
            u: 0.046280909
        },
        {
            value: -0.0479795964,
            date: "2012-11-19",
            l: -0.1086422529,
            u: 0.0113044645
        },
        {
            value: -0.0218184359,
            date: "2012-11-21",
            l: -0.0881634878,
            u: 0.0448568265
        },
        {
            value: -0.0071361172,
            date: "2012-11-28",
            l: -0.0807350229,
            u: 0.0453599734
        },
        {
            value: -0.0151966912,
            date: "2012-12-05",
            l: -0.089995793,
            u: 0.0558329569
        },
        {
            value: -0.0097784855,
            date: "2012-12-12",
            l: -0.089466481,
            u: 0.0550191387
        },
        {
            value: -0.0095681495,
            date: "2012-12-19",
            l: -0.090513354,
            u: 0.057073314
        },
        {
            value: -0.0034165915,
            date: "2012-12-27",
            l: -0.0907151292,
            u: 0.0561479112
        },
        {
            value: 0.3297981389,
            date: "2012-12-31",
            l: 0.1537781522,
            u: 0.3499473316
        }
    ]
};
