import {useCallback, useMemo} from "react";
import {gql, useMutation} from "@apollo/client";
import {CORE_TABLE_FIELDS} from "src/api/fragments.js";
import {GET_TABLES_QUERY} from "src/api/tables/useGetTables.js";

const MUTATION = gql`
${CORE_TABLE_FIELDS}
mutation CreateTable($data: CreateTableInput!) {
  createTable (data: $data) {
    ok
    errorCode
    table {
      ... CoreTableFields
    }
  }
}
`;

export function useCreateTable(workspaceId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback(({name, folderId}) => {
        return mutation({
            variables: {data: {workspaceId, name, folderId}},
            refetchQueries: [{query: GET_TABLES_QUERY, variables: {id: workspaceId}}]
        }).then((response) => {
            return response.data.createTable;
        });
    }, [mutation, workspaceId]);

    const result = useMemo(() => {
        return data?.createTable;
    }, [data]);

    return [doMutate, {loading, error, result}];
}
