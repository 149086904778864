import React from "react";
import PropTypes from "prop-types";


export function Label({inputId, label, children}) {
    return (
        <div className="mb-2">
            <label htmlFor={inputId} className="block text-sm font-medium leading-6 text-gray-900">
                {label || children}
            </label>
        </div>
    );
}

Label.propTypes = {
    inputId: PropTypes.string,
    label: PropTypes.node,
    children: PropTypes.node
};
