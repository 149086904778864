import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactSlider from "react-slider";
import {abbreviateNumber} from "src/utils/formatting.js";


export function SelectRange({
    lower,
    upper,
    min,
    max,
    onChange,
    changeCallback,
    formatLabel
}) {
    const [tempValues, setTempValues] = useState([lower, upper]);

    const handleChange = (value) => {
        setTempValues(value);
        if (changeCallback) {
            changeCallback(value);
        }
    };

    const handleAfterChange = (value) => {
        setTempValues(value);
        if (onChange) {
            onChange(value);
        }
    };

    useEffect(() => {
        setTempValues([lower, upper]);
    }, [lower, upper]);

    return (
        <Wrapper>
            <SliderWrapper>
                <ReactSlider
                    min={min}
                    max={max}
                    value={tempValues}
                    // onBeforeChange={(value, index) => console.log(`onBeforeChange: ${JSON.stringify({value, index})}`)}
                    onChange={handleChange}
                    onAfterChange={handleAfterChange}
                    className="horizontal-slider"
                    thumbClassName="slider-thumb bg-blue-600 hover:bg-blue-700"
                    trackClassName="slider-track"
                    renderThumb={(props, state) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <div {...props}>
                            {state.valueNow}
                        </div>
                    )}
                />
            </SliderWrapper>
            <NumbersWrapper className="flex items-center justify-between mt-2 monospace text-xs">
                <Number>
                    {formatLabel(tempValues[0])}
                </Number>
                <Number>
                    {formatLabel(tempValues[1])}
                </Number>
            </NumbersWrapper>
        </Wrapper>
    );
}


SelectRange.propTypes = {
    lower: PropTypes.number,
    upper: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    changeCallback: PropTypes.func,
    formatLabel: PropTypes.func
};

SelectRange.defaultProps = {
    min: 0,
    max: 100,
    lower: 10,
    upper: 92,
    formatLabel: (value) => abbreviateNumber(value)
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SliderWrapper = styled.div`
  height: 16px;
  margin-left: -1px;
  margin-right: -1px;

  .slider-track {
    top: 7px;
    height: 5px;
    background: rgb(221, 221, 221);
  }

  .slider-track.slider-track-0 {
    border-radius: 4px 0 0 4px;
  }

  .slider-track.slider-track-1 {
    // background: rgba(58, 113, 202, 0.61);
    background: #2563eb9e; // blue-600, with some opacity
  }

  .slider-track.slider-track-2 {
    border-radius: 0 2px 2px 0;
  }

  .slider-thumb {
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 1);
    height: 18px;
    width: 18px;
    cursor: pointer;
    // background: #3a71ca;
    // &:hover {
    //   background: #2c5ba4;
    // }

    // content format, disabled for now
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    color: #fff;
  }
`;

const NumbersWrapper = styled.div``;

const Number = styled.div``;
