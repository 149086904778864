import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {formatInteger, formatNumber, formatPercent, formatTimestamp} from "src/utils/formatting.js";
import {isColumnContinuous, isColumnDate, isColumnInteger, isColumnNumeric} from "src/utils/tableData.js";
import {Divider} from "../../Divider.jsx";


const basicStats = [{
    name: "Table rows",
    key: "rows",
    render: formatInteger
}, {
    name: "Total values",
    key: "values",
    render: formatInteger
}, {
    name: "Total values (%)",
    key: "valuesPercent",
    render: formatPercent
}, {
    name: "Missing/Null",
    key: "nulls",
    render: formatInteger
}, {
    name: "Missing/Null (%)",
    key: "nullsPercent",
    render: formatPercent
}, {
    name: "Unique values",
    key: "distinct",
    render: formatInteger
}, {
    name: "Unique values (%)",
    key: "distinctPercent",
    render: formatPercent
}];


export function ColumnStats({stats, column}) {
    let config;

    const augmentedStats = Object.assign({}, stats, {
        valuesPercent: stats.values / stats.rows,
        nullsPercent: stats.nulls / stats.rows,
        distinctPercent: stats.distinct / stats.rows
    });

    if (isColumnContinuous(column) && isColumnNumeric(column)) {
        const render = isColumnInteger(column) ? formatInteger : formatNumber;
        config = [
            ...basicStats,
            {name: "DIVIDER", key: "divider-1"},
            {name: "Average", key: "avg"},
            {name: "Standard Deviation", key: "sd"},
            {name: "DIVIDER", key: "divider-2"},
            {name: "Min", key: "min", render},
            {name: "Percentile 1", key: "p1", render},
            {name: "Percentile 5", key: "p5", render},
            {name: "Percentile 25 (Q1)", key: "p25", render},
            {name: "Percentile 50 (Median)", key: "p50", render},
            {name: "Percentile 75 (Q3)", key: "p75", render},
            {name: "Percentile 95", key: "p95", render},
            {name: "Percentile 99", key: "p99", render},
            {name: "Max", key: "max", render}
        ];
    } else if (isColumnDate(column)) {
        const dateStats = [{
            name: "Min",
            key: "min",
            render: (v) => formatTimestamp(v)
        }, {
            name: "Max",
            key: "max",
            render: (v) => formatTimestamp(v)
        }];
        config = [
            ...basicStats,
            {name: "DIVIDER", key: "divider"},
            ...dateStats
        ];
    } else {
        config = basicStats.slice();
    }

    return (
        <div className="text-xs monospace my-2 -ml-2 flex flex-col max-w-96">
            {config.map((item) => {
                if (item.name === "DIVIDER") {
                    return (
                        <Divider key={item.key}/>
                    );
                }

                return (
                    <div
                        key={item.name}
                        className="flex justify-between py-1 px-2 rounded-md hover:bg-black/5"
                    >
                        <Definition
                            className="text-neutral-800 basis-[180px]"
                        >
                            {item.name}
                        </Definition>
                        <Value className="text-neutral-950 text-right">
                            {item.render
                                ? item.render(augmentedStats[item.key])
                                : formatNumber(augmentedStats[item.key])}
                        </Value>
                    </div>
                );
            })}
        </div>
    );
}

ColumnStats.propTypes = {
    column: PropTypes.shape({
        dataType: PropTypes.string,
        variableType: PropTypes.string
    }),
    stats: PropTypes.shape({
        min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        p1: PropTypes.number,
        p5: PropTypes.number,
        p25: PropTypes.number,
        p50: PropTypes.number,
        p75: PropTypes.number,
        p95: PropTypes.number,
        p99: PropTypes.number,
        rows: PropTypes.number,
        values: PropTypes.number,
        nulls: PropTypes.number,
        distinct: PropTypes.number
    })
};

const Definition = styled.span`
  font-weight: 600;
  :after {
    content: ":";
  }
`;

const Value = styled.span`
`;
