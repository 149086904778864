import {Badge} from "src/primitives/badge.jsx";
import React from "react";
import PropTypes from "prop-types";

export function ColumnDetailsHeader({column}) {
    if (!column) {
        return null;
    }

    return (
        <div className="flex items-center gap-4">
            {column.name}
            <Badge size="xs">
                {column.variableType}
            </Badge>
        </div>
    );
}

ColumnDetailsHeader.propTypes = {
    column: PropTypes.shape({
        name: PropTypes.string,
        variableType: PropTypes.string
    })
};
