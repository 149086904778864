import {ContextMenu} from "handsontable/plugins";
import {getColumnIndicesFromSelection} from "src/components/Table/utils.js";

export function buildMenu(tableRef, tableActions, updateColumnFormats) {
    const getHotInstance = () => tableRef.current.hotInstance;

    const getColumnNamesFromSelection = (selection) => {
        const columnIndexes = getColumnIndicesFromSelection(selection);
        const hotInstance = getHotInstance();
        return columnIndexes.map((index) => hotInstance.getColHeader(index));
    };

    return {
        items: {
            sortAscending: {
                name() {
                    return "Sort Ascending";
                }
            },
            sortDescending: {
                name() {
                    return "Sort Descending";
                }
            },
            separator: ContextMenu.SEPARATOR,
            plot: {
                name() {
                    return "Plot";
                }
            },
            separator2: ContextMenu.SEPARATOR,
            formatColumn: {
                name: "Format",
                submenu: {
                    items: [{
                        // Key must be in the form 'parent_key:child_key'
                        key: "formatColumn:heatmap",
                        name: "Heatmap",
                        callback(key, selection) {
                            const columnNames = getColumnNamesFromSelection(selection);
                            const formatData = columnNames.reduce((acc, columnName) => {
                                return {...acc, [columnName]: "heatmap"};
                            }, {});
                            updateColumnFormats(formatData);
                        }
                    }, {
                        // Key must be in the form 'parent_key:child_key'
                        key: "formatColumn:clear",
                        name: "Clear",
                        callback(key, selection) {
                            const columnNames = getColumnNamesFromSelection(selection);
                            const formatData = columnNames.reduce((acc, columnName) => {
                                return {...acc, [columnName]: null};
                            }, {});
                            updateColumnFormats(formatData);
                        }
                    }]
                }
            },
            separator3: ContextMenu.SEPARATOR,
            transformColumn: {
                name: "Transform",
                submenu: {
                    items: [{
                        // Key must be in the form 'parent_key:child_key'
                        key: "transformColumn:numeric",
                        name: "Numeric",
                        callback(key, selection) {
                            if (!tableActions.convertColumnToPercentage) {
                                return;
                            }
                            const columnIndexes = getColumnIndicesFromSelection(selection);
                            const hotInstance = getHotInstance();
                            const columnNames = columnIndexes.map((index) => hotInstance.getColHeader(index));
                            columnNames.forEach((columnName) => {
                                tableActions.convertColumnToPercentage(columnName);
                            });
                        }
                    }]
                }
            },
            separator4: ContextMenu.SEPARATOR,
            removeColumn: {
                name() {
                    return `
                        <span style="color: red;font-weight: bold;">Remove column</span>
                    `;
                },
                callback(key, selection) {
                    if (!tableActions.removeColumn) {
                        return;
                    }
                    const hotInstance = getHotInstance();
                    const columnIndexes = getColumnIndicesFromSelection(selection);
                    const columnNames = columnIndexes.map((index) => hotInstance.getColHeader(index));

                    const plugin = hotInstance.getPlugin("hiddenColumns");
                    plugin.hideColumns(columnIndexes);
                    hotInstance.render();

                    columnNames.forEach((columnName) => {
                        tableActions.removeColumn(columnName);
                    });
                }
            },
            about: { // Own custom option
                name() { // `name` can be a string or a function
                    return "<b>Custom option</b>"; // Name can contain HTML
                },
                // Callback for specific option
                callback(key, selection, clickEvent) { // eslint-disable-line
                    setTimeout(() => {
                        // eslint-disable-next-line no-alert
                        alert("Hello world!"); // Fire alert after menu close (with timeout)
                    }, 0);
                }
            }
        }
    };
}
