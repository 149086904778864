import React, {useMemo, useState} from "react";
import styled from "styled-components";
import {overrideSystemHandling} from "src/utils/system.js";
import {TextInput} from "src/primitives/controls/TextInput.jsx";
import {Button} from "src/primitives/button.jsx";
import PropTypes from "prop-types";
import {isValidEmail} from "src/utils/validators.js";
import {Title} from "src/primitives/title.jsx";
import {useInviteUser} from "src/api/workspaceMembers/useInviteUser.js";


export function InviteUser({workspaceId, existingEmails}) {
    const [email, setEmail] = useState("");
    const [inviteUser, {loading}] = useInviteUser(workspaceId);

    const handleSubmit = (e) => {
        overrideSystemHandling(e);
        inviteUser({email}).then(() => {
            setEmail("");
        }).catch((error) => {
            console.error(error);
        });
    };

    const handleChange = (e) => {
        setEmail(e.target.value.trim());
    };

    const isValid = useMemo(() => {
        if (!email) {
            return false;
        }
        if (existingEmails && existingEmails.has(email)) {
            return false;
        }
        return isValidEmail(email);
    }, [email, existingEmails]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-1">
                <Title size="xs">
                    Add member
                </Title>
            </div>
            <Wrapper className="w-96 flex items-center gap-4">
                <TextInput
                    type="email"
                    value={email}
                    onChange={handleChange}
                    placeholder="Email"
                    isSuccess={isValid}
                    isDisabled={loading}
                />
                <Button
                    theme="secondary"
                    isDisabled={!isValid}
                    isLoading={loading}
                >
                    Invite
                </Button>
            </Wrapper>
        </form>
    );
}

InviteUser.propTypes = {
    workspaceId: PropTypes.string.isRequired,
    existingEmails: PropTypes.object
};

const Wrapper = styled.div`
`;
