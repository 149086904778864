export function getState({
    active,
    selected,
    disabled
}) {
    if (active) {
        return "active";
    }
    if (selected) {
        return "selected";
    }
    if (disabled) {
        return "disabled";
    }
    return "normal";
}
