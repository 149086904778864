import React, {useCallback} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {CellBaseClass} from "src/components/TableGrid/components.jsx";
import {FiArrowDown, FiArrowUp} from "react-icons/fi";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {getNewDirection, SortDirection} from "src/utils/tableData.js";
import {ColumnResizer} from "src/components/TableGrid/Header/ColumnResizer.jsx";
import {ColumnHeaderSecondary} from "src/components/TableGrid/Header/ColumnHeaderSecondary/index.jsx";
import {ColumnIllustration} from "./ColumnIllustration/index.jsx";
import {ManageColumnFilter} from "./ColumnFilter/index.jsx";


export function TableGridHeaderCell({
    name,
    stats,
    columnInfo,
    columnValues,
    sort,
    sortedDirection,
    activeFilter,
    updateColumnWidth
}) {
    const sortColumn = useCallback(() => {
        const newDirection = getNewDirection(sortedDirection);
        sort(name, newDirection);
    }, [sort, name, sortedDirection]);

    return (
        <HeaderCell>
            <Title>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <NameWrapper className="flex items-center gap-2 flex-1 cursor-pointer" onClick={sortColumn}>
                    <Name className="truncate">{name}</Name>
                    <SortIcon>
                        {sortedDirection && (
                            <IconWrapper
                                icon={sortedDirection === SortDirection.DESC ? <FiArrowUp/> : <FiArrowDown/>}
                                clickable={true}
                                size="xs"
                            />
                        )}
                    </SortIcon>
                </NameWrapper>

                <div>
                    <ManageColumnFilter
                        columnInfo={columnInfo}
                        stats={stats}
                        columnValues={columnValues}
                        activeFilter={activeFilter}
                    />
                </div>
            </Title>

            <ColumnHeaderSecondary
                column={columnInfo}
            />

            <ColumnIllustration
                stats={stats}
                columnInfo={columnInfo}
                values={columnValues}
            />

            <ColumnResizer
                name={name}
                updateColumnWidth={updateColumnWidth}
            />
        </HeaderCell>
    );
}

TableGridHeaderCell.propTypes = {
    name: PropTypes.string,
    stats: PropTypes.shape({
        avg: PropTypes.number
    }),
    columnInfo: PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    }),
    columnValues: PropTypes.array,
    sort: PropTypes.func,
    sortedDirection: PropTypes.oneOf(Object.values(SortDirection)),
    activeFilter: PropTypes.object,
    updateColumnWidth: PropTypes.func
};


const HeaderCell = styled(CellBaseClass)`
  height: 202px;
  position: relative;
  border-top: 4px solid #148314;
  border-bottom: 1px solid rgba(0,0, 0, 0.4);
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: unset;
  padding: 0;
  box-shadow: rgb(0 0 0 / 5%) 0 2px 0 0;
`;

const Title = styled.div`
  padding: 4px 8px;
  background-color: var(--bg-accent-primary);
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NameWrapper = styled.div`
  max-width: calc(100% - 24px);
`;

const Name = styled.div`
  &:hover {
    text-decoration: underline;
  }
`;

const SortIcon = styled.div`
  flex: 0 0 24px;
`;
