import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation DeleteUser ($userId: String!) {
  deleteUser (userId: $userId) {
    ok
    errorCode
    userId
  }
}
`;

function removeUserFromCache(cache, response) {
    const {ok, userId} = response.data.deleteUser;
    if (!ok) {
        return;
    }
    const model = {
        id: userId,
        __typename: "User"
    };
    const normalizedId = cache.identify(model);
    cache.evict({id: normalizedId});
    cache.gc();
}


export function useDeleteUser() {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((userId) => {
        return mutation({
            variables: {userId},
            update: (cache, response) => {
                removeUserFromCache(cache, response);
            }
        }).then((response) => {
            return response.data.deleteUser;
        });
    }, [mutation]);

    return [doMutate, {loading, error}];
}
