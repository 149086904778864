import {useGetTable} from "src/api/tables/useGetTable.js";


export function useGetColumns(tableId) {
    const {table, loading, error, refetch} = useGetTable(tableId);

    return {
        columns: table?.columns || [], loading, error, refetch
    };
}
