import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";


const wrapperClasses = cva({
    base: "border border-neutral-400 rounded-lg flex shadow-sm",
    variants: {
        size: {
            auto: "",
            small: "w-64 h-32",
            medium: "w-72 h-80"
        },
        mode: {
            clickable: "cursor-pointer hover:shadow-md hover:border-neutral-500 transition"
        },
        state: {
            active: "border-neutral-500 bg-neutral-200/10"
        }
    },
    defaultVariants: {
        size: "auto"
    }
});

export function Card({children, size, onClick, state}) {
    const mode = onClick ? "clickable" : "";

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className={wrapperClasses({size, mode, state})} onClick={onClick}>
            <div className="p-4 w-full h-full">
                {children}
            </div>
        </div>
    );
}

Card.propTypes = {
    children: PropTypes.node,
    size: PropTypes.string,
    state: PropTypes.oneOf(["active"]),
    onClick: PropTypes.func
};
