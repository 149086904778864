import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {CHART_COLORS} from "src/charts/colors.js";
import {formatPercent} from "src/utils/formatting.js";


export function CategoryItem({name, value, color, totalValues}) {
    const percentage = value / totalValues;

    return (
        <Wrapper>
            <Header>
                <div className="truncate pr-2">
                    <span className={name ? "" : "text-neutral-600"}>{name || "missing/null"}</span>
                </div>
                <div>
                    {formatPercent(percentage, 1)}
                </div>
            </Header>
            <BarWrapper>
                <Bar $percentage={100 * percentage} $color={color}/>
            </BarWrapper>
        </Wrapper>
    );
}


CategoryItem.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.number,
    totalValues: PropTypes.number,
    color: PropTypes.string
};

CategoryItem.defaultProps = {
    color: CHART_COLORS.green
};


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BarWrapper = styled.div`
  height: 8px;
  width: 100%;
  background-color: #ccc;
  border-radius: 4px;
  & div {
    border-radius: 4px;    
  }
`;

const Bar = styled.div`
  height: 100%;
  width: ${({$percentage}) => $percentage}%;
  background-color: ${({$color}) => $color};
`;
