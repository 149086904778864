import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import {RetryLink} from "@apollo/client/link/retry";
import {setContext} from "@apollo/client/link/context";
import {getUrlParams, getWorkspaceId, WORKSPACE_HEADER_KEY} from "src/utils/workspace.js";
import {generateId} from "src/utils/id.js";
import {removeUndefinedValues} from "src/utils/misc.js";
import {config} from "./config.js";

const REQUEST_ID_HEADER_KEY = "X-Request-Id";
const PUBLIC_KEY_HEADER_KEY = "X-Public-Key";
const CLIENT_ID_HEADER_KEY = "X-Client-Id";


function getCookies() {
    const cookieMap = {};
    window.document.cookie.split(";").forEach((item) => {
        if (!item) {
            return;
        }
        const [key, value] = item.split("=");
        cookieMap[key.trim()] = value.trim();
    });
    return cookieMap;
}

function getCookie(cookieName) {
    const cookies = getCookies();

    return cookies[cookieName];
}

export function getToken() {
    return getCookie("__session");
}

export function getAuthToken() {
    const token = getToken();
    return token ? `Bearer ${token}` : "";
}

function getPublicKey() {
    const name = "pk";
    const params = getUrlParams();

    return params[name];
}

const customFetch = (uri, options) => {
    const {operationName} = JSON.parse(options.body);

    return fetch(`${uri}?operation=${operationName}`, options);
};

export const getClient = () => {
    const link = createHttpLink({
        uri: config.GRAPHQL_URL,
        fetch: customFetch
        // credentials: "include",
    });

    const authLink = setContext((_, {headers}) => {
        const additionalHeaders = {
            authorization: getAuthToken(),
            [WORKSPACE_HEADER_KEY]: getWorkspaceId(),
            [PUBLIC_KEY_HEADER_KEY]: getPublicKey(),
            [REQUEST_ID_HEADER_KEY]: generateId(),
            [CLIENT_ID_HEADER_KEY]: config.CLIENT_ID
        };

        return {
            headers: {...headers, ...removeUndefinedValues(additionalHeaders)}
        };
    });
    const retryLink = new RetryLink({
        delay: {
            initial: 300,
            max: Infinity,
            jitter: false
        },
        attempts: {
            max: 3,
            retryIf: (error, _operation) => {
                let operationType = null;
                _operation.query.definitions.forEach((definition) => {
                    if (definition.kind === "OperationDefinition") {
                        operationType = definition.operation;
                    }
                });
                // Should one retry mutations?
                return !!error && operationType !== "mutation";
            }
        }
    });

    // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
    const typePolicies = {
        Workspace: {
            merge: true,
            fields: {
                folders: {
                    merge(existing, incoming) {
                        return incoming;
                    }
                },
                members: {
                    merge(existing, incoming) {
                        return incoming;
                    }
                }
            }
        }
    };
    return new ApolloClient({
        link: authLink.concat(retryLink).concat(link),
        cache: new InMemoryCache({typePolicies})
    });
};
