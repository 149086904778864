import React, {useState} from "react";
import {Title} from "src/primitives/title.jsx";
import {TextInput} from "src/primitives/controls/index.jsx";
import {Label} from "src/primitives/controls/Label.jsx";
import {DB_BACKENDS} from "src/pages/CreateWorkspace/components/CreateWorkspaceForm/constants.js";
import {Navigation} from "src/pages/CreateWorkspace/components/CreateWorkspaceForm/Navigation.jsx";
import {CreateWorkspace} from "./components/CreateWorkspace/index.jsx";
import {ConfigureGcpBackend} from "./components/ConfigureGcpBackend/index.jsx";
import {WorkspaceBackend} from "./components/WorkspaceBackend/index.jsx";
import {LineDivider} from "./components/divider.jsx";


export function CreateWorkspaceForm() {
    const [name, setName] = useState("Personal workspace");
    const [backend, setBackend] = useState(DB_BACKENDS.rho);
    const [backendCredentials, setBackendCredentials] = useState(null);
    const [step, setStep] = useState(0);

    const handleContinue = () => {
        setStep(step + 1);
    };

    const handleSetBackendCredentials = (data) => {
        setBackendCredentials(data);
        setStep(step + 1);
    };

    return (
        <div
            className="py-4 px-6 rounded-sm shadow-sm min-h-96 min-w-112 w-144 bg-white flex flex-col"
        >
            <Title style={{margin: 0}} size="small">
                <div className="text-center text-neutral-700">
                    New workspace
                </div>
            </Title>

            <LineDivider/>

            {step === 0 && (
                <div>
                    <TextInput
                        label="Workspace name:"
                        placeholder="Workspace name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <div className="my-4">
                        <Label label="Select backend:"/>
                        <div className="mb-2 text-sm">
                            <p>
                                You can choose to use our hosted service, or configure your own backend.
                            </p>
                        </div>
                        <WorkspaceBackend
                            selectedBackend={backend}
                            onChange={setBackend}
                        />
                    </div>
                    <Navigation
                        prev={null}
                        prevText="Back"
                        next={handleContinue}
                        nextText="Continue"
                        loading={false}
                        isDisabled={false}
                    />
                </div>
            )}

            {(step === 1 && backend === DB_BACKENDS.gcp) && (
                <div>
                    <ConfigureGcpBackend
                        onSave={handleSetBackendCredentials}
                        abort={() => setStep(0)}
                    />
                </div>
            )}
            {(step === 1 && backend === DB_BACKENDS.rho) && (
                <CreateWorkspace
                    name={name}
                    backend={backend}
                    goBack={() => setStep(0)}
                />
            )}
            {(step === 2) && (
                <CreateWorkspace
                    name={name}
                    backend={backend}
                    backendCredentials={backendCredentials}
                    goBack={() => setStep(0)}
                />
            )}
        </div>
    );
}

CreateWorkspaceForm.propTypes = {
};
