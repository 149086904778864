import React from "react";
import PropTypes from "prop-types";
import {useGetTableInfo} from "src/api/tables/useGetTableInfo.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiHelpCircle} from "react-icons/fi";
import {Overlay} from "src/primitives/Overlay/index.jsx";
import {Title} from "src/primitives/title.jsx";


export function TableInfo({tableId}) {
    const {table, loading, error} = useGetTableInfo(tableId);

    if (loading || error) {
        return null;
    }

    if (!table) {
        return null;
    }

    const {createdByUser} = table;

    return (
        <div>
            <Overlay
                align="right"
                header={(
                    <div>
                        <Title size="xs">
                            Table info
                        </Title>
                    </div>
                )}
                content={(
                    <div className="w-64 text-sm my-2">
                        The creator and owner of this table is {createdByUser && createdByUser.email}.
                    </div>
                )}
            >
                <IconWrapper
                    icon={<FiHelpCircle/>}
                    size="small"
                    clickable={true}
                />
            </Overlay>
        </div>
    );
}
TableInfo.propTypes = {
    tableId: PropTypes.string.isRequired
};
