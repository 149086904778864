import React from "react";
import PropTypes from "prop-types";
import {HistogramDate} from "src/charts/index.js";


export function ColumnDatesDistribution({values, name}) {
    if (!values) {
        return null;
    }

    return (
        <div className="mb-4">
            <HistogramDate
                name={name}
                values={values}
                height={240}
                showY={true}
                showTooltip={true}
                showXLabel={true}
            />
        </div>
    );
}

ColumnDatesDistribution.propTypes = {
    name: PropTypes.string,
    values: PropTypes.array
};
