import React from "react";
import PropTypes from "prop-types";
import {ControlWrapper} from "src/components/ChartBuilder/layouts.jsx";
import {TextInput} from "src/primitives/controls/index.jsx";
import {SelectYaxis} from "./components/SelectYaxis/index.jsx";
import {SelectRegression} from "./components/SelectRegression/index.jsx";


export function ConfigSeriesItem({item, updateSeriesConfig}) {
    const handleNameChange = (e) => {
        updateSeriesConfig(item.id, {name: e.target.value});
    };

    const handleYaxisChange = (option) => {
        updateSeriesConfig(item.id, {yAxis: option?.value});
    };

    const handleRegressionTypeChange = (option) => {
        updateSeriesConfig(item.id, {regressionType: option?.value});
    };

    return (
        <div>
            <ControlWrapper>
                <TextInput
                    label="Series name"
                    value={item.name}
                    onChange={handleNameChange}
                />
            </ControlWrapper>

            <ControlWrapper>
                <SelectYaxis
                    handleYaxisChange={handleYaxisChange}
                    value={item.yAxis}
                />
            </ControlWrapper>

            <ControlWrapper>
                <SelectRegression
                    value={item.regressionType}
                    onChange={handleRegressionTypeChange}
                />
            </ControlWrapper>
        </div>
    );
}

ConfigSeriesItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        yAxis: PropTypes.string,
        regressionType: PropTypes.string
    }),
    updateSeriesConfig: PropTypes.func
};
