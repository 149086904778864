import React, {createContext, useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {PersistentStorage} from "src/utils/localstorage.js";


function getIntValue(value) {
    return value ? 1 : 0;
}

function getBoolean(value) {
    return !!value;
}

export function useSidebarState() {
    const key = "sidebar:state";
    const defaultValue = true;

    const storage = useMemo(() => {
        const defaultValueInt = getIntValue(defaultValue);
        return new PersistentStorage(key, defaultValueInt);
    }, [key, defaultValue]);

    const storedValue = storage.getInt();
    const [state, setState] = useState(getBoolean(storedValue));

    const setValue = useCallback((v) => {
        setState(v);
    }, [setState]);

    const toggle = useCallback(() => {
        setState((prevState) => !prevState);
    }, []);

    useEffect(() => {
        const stateValueInt = getIntValue(state);
        if (storage.getInt() !== stateValueInt) {
            storage.storeInt(stateValueInt);
        }
    }, [state, storage]);

    // memoize output
    return useMemo(() => {
        return {isOpen: state, setIsOpen: setValue, toggle};
    }, [state, setValue, toggle]);
}

export const SidebarContext = createContext({});

export function SidebarContextProvider({children}) {
    const data = useSidebarState();

    return (
        <SidebarContext.Provider value={data}>
            {children}
        </SidebarContext.Provider>
    );
}

SidebarContextProvider.propTypes = {
    children: PropTypes.element
};
