import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {formatNumber} from "src/utils/formatting.js";
import {cva} from "cva";
import {isNumber} from "src/utils/validators.js";


const markerClasses = cva({
    // eslint-disable-next-line max-len
    base: "w-full bg-lime-600 hover:bg-lime-600/85",
    variants: {
        size: {
            small: "h-2",
            medium: "h-6",
            large: "h-8"
        }
    }
});


export function BoxPlot({
    data,
    width,
    height,
    showDescriptions,
    showLabels,
    minValue,
    maxValue
}) {
    // small margin on each end
    const start = data.p1;
    const end = data.p99;
    // either given min/max or start/end of dataset
    const spread = (isNumber(minValue) && isNumber(maxValue)) ? maxValue - minValue : end - start;

    const getLeftPosition = (val) => {
        return (val - start) / spread;
    };

    const descriptions = [{
        left: 100 * getLeftPosition(data.p1),
        label: "P1",
        value: data.p1
    }, {
        left: 100 * getLeftPosition(data.p25),
        label: "P25 (Q1)",
        value: data.p25
    }, {
        left: 100 * getLeftPosition(data.p50),
        label: "P50 (median)",
        value: data.p50
    }, {
        left: 100 * getLeftPosition(data.p75),
        label: "P75 (Q3)",
        value: data.p75
    }, {
        left: 100 * getLeftPosition(data.p99),
        label: "P99",
        value: data.p99
    }];

    return (
        <div
            className="flex flex-col gap-2"
            style={{width}}
        >
            <div
                className="border border-neutral-200"
                style={{height}}
            >
                <Wrapper
                    className="relative w-full h-full"
                >
                    <ItemWrapper
                        className="z-10"
                        $left={100 * getLeftPosition(data.p1)}
                        $width={2}
                    >
                        <div className={markerClasses({size: "large"})}/>
                    </ItemWrapper>

                    <ItemWrapper
                        $left={100 * getLeftPosition(data.p1)}
                        $width={width * ((data.p25 - data.p1) / spread)}
                    >
                        <div className={markerClasses({size: "small"})}/>
                    </ItemWrapper>

                    <ItemWrapper
                        $left={100 * getLeftPosition(data.p25)}
                        $width={width * ((data.p50 - data.p25) / spread)}
                    >
                        <div className={markerClasses({size: "medium"})}/>
                    </ItemWrapper>

                    <ItemWrapper
                        className="z-10"
                        $left={100 * getLeftPosition(data.p50)}
                        $width={2}
                    >
                        <div className="w-full h-6 bg-white/60"/>
                    </ItemWrapper>


                    <ItemWrapper
                        $left={100 * getLeftPosition(data.p50)}
                        $width={width * ((data.p75 - data.p50) / spread)}
                    >
                        <div className={markerClasses({size: "medium"})}/>
                    </ItemWrapper>

                    <ItemWrapper
                        $left={100 * getLeftPosition(data.p75)}
                        $width={width * ((data.p99 - data.p75) / spread)}
                    >
                        <div className={markerClasses({size: "small"})}/>
                    </ItemWrapper>

                    <ItemWrapper
                        $left={100 * getLeftPosition(data.p99)}
                        $width={2}
                    >
                        <div className={markerClasses({size: "large"})}/>
                    </ItemWrapper>
                </Wrapper>
            </div>

            {showLabels && (
                <div className="monospace text-xs">
                    <div className="flex items-center justify-between font-bold">
                        <div>{formatNumber(start)}</div>
                        <div>{formatNumber(end)}</div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div>p1</div>
                        <div>p99</div>
                    </div>
                </div>
            )}

            {showDescriptions && (
                <>
                    <div className="w-full h-8 relative">
                        {descriptions.map((item) => {
                            return (
                                <ItemWrapper
                                    key={`desc-${item.label}`}
                                    $left={item.left}
                                >
                                    {item.label}
                                </ItemWrapper>
                            );
                        })}
                    </div>
                    <div className="w-full h-8 relative">
                        {descriptions.map((item) => {
                            return (
                                <ItemWrapper
                                    key={`value-${item.label}`}
                                    $left={item.left}
                                >
                                    {item.value}
                                </ItemWrapper>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
}

BoxPlot.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.number,
    data: PropTypes.shape({
        min: PropTypes.number,
        p1: PropTypes.number,
        p25: PropTypes.number,
        p50: PropTypes.number,
        p75: PropTypes.number,
        p99: PropTypes.number,
        max: PropTypes.number
    }),
    showDescriptions: PropTypes.bool,
    showLabels: PropTypes.bool,
    minValue: PropTypes.number,
    maxValue: PropTypes.number
};

BoxPlot.defaultProps = {
    width: 320,
    height: 60,
    showLabels: true
};

const Wrapper = styled.div`
  height: ${({$height}) => `${$height}px`};
  width: ${({$width}) => `${$width}px`};
`;

const ItemWrapper = styled.div`
  position: absolute;
  height: 100%;
  ${({$left}) => $left ? `left: ${$left}%;` : ""};
  ${({$right}) => $right ? `right: ${$right}%;` : ""};
  ${({$width}) => $width ? `width: ${$width}px;` : ""};
  display: flex;
  align-items: center;
`;
