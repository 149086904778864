import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";


export function FlexLayout({header, footer, children, className}) {
    return (
        <Wrapper className={className}>
            {header}
            <Body>
                {children}
            </Body>
            {footer}
        </Wrapper>
    );
}
FlexLayout.propTypes = {
    header: PropTypes.element,
    footer: PropTypes.element,
    children: PropTypes.element,
    className: PropTypes.string
};


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;
