import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Link, Navigate} from "react-router-dom";
import {FiChevronRight, FiHome} from "react-icons/fi";
import {useGetLink} from "src/utils/useGetLink.js";
import {useGetAllResources} from "src/api/useGetAllResources.js";
import {arrayToMap} from "src/utils/misc.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {LINKS} from "src/links.js";
import {EditFolderName} from "src/pages/Home/pages/FolderPage/components/EditFolderName/index.jsx";
import {getParents} from "./utils.js";


export function FolderPageTitle({folderId, workspaceId}) {
    const MAX_PARENTS = 2;
    const getLink = useGetLink();
    const {folders, loading: loadingFolders} = useGetAllResources(workspaceId);
    const foldersMap = arrayToMap(folders, "id");

    if (loadingFolders) {
        return null;
    }

    const currentFolder = foldersMap[folderId];
    if (!currentFolder) {
        // invalid id.
        return <Navigate to={getLink(LINKS.HOME)}/>;
    }

    const parents = getParents(folderId, foldersMap);
    const visibleParents = parents.slice(0, MAX_PARENTS);
    visibleParents.reverse();

    return (
        <Wrapper className="flex items-center gap-1">
            <Link to={getLink(LINKS.HOME)}>
                <IconWrapper
                    clickable={true}
                    icon={<FiHome/>}
                    size="small"
                    itemId={`home-from-${folderId}`}
                    tooltip={(
                        <span className="text-sm">
                            Go to home
                        </span>
                    )}
                    tooltipProps={{
                        delayShow: 500,
                        delayHide: 100,
                        place: "right"
                    }}
                />
            </Link>


            {visibleParents.length < parents.length ? (
                <div className="text-neutral-600 mr-1">
                    <span className="text-lg">...</span>
                </div>
            ) : (
                <div className="text-neutral-600 ml-[-8px] mr-[-4px]">
                    <IconWrapper
                        icon={<FiChevronRight/>}
                        size="small"
                    />
                </div>
            )}

            {visibleParents.map((item) => (
                <div
                    key={`bc-link-${item.id}`}
                    className="text-neutral-600 flex items-center gap-1"
                >
                    <div className="hover:text-neutral-950">
                        <Link to={getLink(LINKS.folderPage(item.id))}>
                            {item.name}
                        </Link>
                    </div>
                    <div>
                        <IconWrapper
                            icon={<FiChevronRight/>}
                            size="small"
                        />
                    </div>
                </div>
            ))}

            <EditFolderName
                folderId={currentFolder.id}
                name={currentFolder.name}
            />
        </Wrapper>
    );
}

FolderPageTitle.propTypes = {
    folderId: PropTypes.string.isRequired,
    workspaceId: PropTypes.string
};

const Wrapper = styled.div`
`;
