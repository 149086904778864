import React, {useCallback, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {SetNewFileContext} from "src/pages/Home/contexts/file_context.jsx";
import {useGoToUploadPage} from "src/utils/useGetLink.js";

export function DropzonePage() {
    /**
     * Inspiration:
     * https://codepen.io/nekobog/pen/JjoZvBm
     */
    const setNewFile = useContext(SetNewFileContext);
    const goToUploadPage = useGoToUploadPage();

    const [isVisible, setIsVisible] = useState(false);

    const showDropZone = useCallback(() => {
        setIsVisible(true);
    }, [setIsVisible]);
    const hideDropZone = useCallback(() => {
        setIsVisible(false);
    }, [setIsVisible]);

    const handleDragEnter = useCallback((event) => {
        const isFile = event.dataTransfer.types.indexOf("Files") >= 0;
        if (isFile) {
            showDropZone();
        }
    }, [showDropZone]);

    const handleDrop = useCallback((event) => {
        const files = [...event.dataTransfer.files];
        if (files.length > 0 && files[0]) {
            event.preventDefault();
            setNewFile(files[0]);
            hideDropZone();
            goToUploadPage();
        }
    }, [hideDropZone, setNewFile, goToUploadPage]);

    useEffect(() => {
        window.addEventListener("dragenter", handleDragEnter);

        return () => {
            hideDropZone();
            window.removeEventListener("dragenter", handleDragEnter);
        };
    }, [handleDragEnter, hideDropZone]);

    useEffect(() => {
        const dropZone = document.getElementById("_dropzone");
        dropZone.addEventListener("dragleave", () => {
            hideDropZone();
        });
    }, [hideDropZone]);

    useEffect(() => {
        const dropZone = document.getElementById("_dropzone");
        dropZone.addEventListener("drop", handleDrop);
    }, [handleDrop]);

    return (
        <Wrapper $visible={isVisible} id="_dropzone">
            <Content>
                <div>
                    Hello!
                </div>
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => props.$visible ? "block" : "none"};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;

  background: rgba(96, 167, 220, .25);
  border: 4px dashed rgb(96, 167, 220);
`;

Wrapper.propTypes = {
    $visible: PropTypes.bool
};

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
