import React, {useState} from "react";
import PropTypes from "prop-types";
import {useGetColumns} from "src/api/tableColumns/useGetColumns.js";
import {isColumnContinuous, isColumnNumeric} from "src/utils/tableData.js";
import {Badge} from "src/primitives/badge.jsx";
import {arrayToMap} from "src/utils/misc.js";
import {SelectColumnOverlay} from "./components/SelectColumn/index.jsx";
import {SelectFeatureColumn} from "./components/SelectFeatureColumn/index.jsx";
import {ColumnPivot} from "./components/ColumnPivot/index.jsx";


export function ColumnAggregation({tableId, columnName}) {
    const [selectedTarget, setSelectedTarget] = useState(null);
    const {columns} = useGetColumns(tableId);
    if (!columns) {
        return null;
    }
    const columnMap = arrayToMap(columns, "name");
    const targetColumn = columns.find((column) => column.isTarget);
    const potentialColumns = columns.filter((col) => isColumnNumeric(col) && isColumnContinuous(col));

    if (!targetColumn) {
        return (
            <div>
                <SelectFeatureColumn tableId={tableId} columns={potentialColumns}/>
            </div>
        );
    }

    const pivotColumn = selectedTarget && columnMap[selectedTarget] ? columnMap[selectedTarget] : targetColumn;

    return (
        <div>
            <div className="text-sm my-1 flex items-center">
                <span className="pr-1">Showing </span>
                <SelectColumnOverlay
                    columns={potentialColumns}
                    selected={pivotColumn?.name}
                    onSelect={setSelectedTarget}
                >
                    <Badge text={pivotColumn?.name} theme="green" isClickable={true}/>
                </SelectColumnOverlay>
                <span className="px-1">, split by</span>
                <Badge text={columnName} theme="blue"/>
            </div>
            <ColumnPivot
                tableId={tableId}
                columns={[columnName]}
                targets={[pivotColumn]}
            />
        </div>
    );
}

ColumnAggregation.propTypes = {
    tableId: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired
};
