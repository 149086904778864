import React, {useState} from "react";
import PropTypes from "prop-types";
import {FiFilePlus, FiFolderPlus, FiPlusCircle} from "react-icons/fi";
import {Dropdown} from "src/primitives/Dropdown/index.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {useGoToFolder, useGoToUploadPage} from "src/utils/useGetLink.js";
import {DialogModal} from "src/primitives/modals/Dialog";
import {NewFolderForm} from "./components/NewFolderForm";


export function AddItemMenu({
    workspaceId,
    folderId,
    children,
    goToCreatedFolder
}) {
    const goToFolderPage = useGoToFolder();
    const goToUploadPage = useGoToUploadPage();
    const [newFolder, setNewFolderState] = useState(false);

    const closeDialog = () => {
        setNewFolderState(false);
    };

    const onSuccess = (result) => {
        const newFolderId = result?.folder?.id;
        if (!newFolderId) {
            return;
        }
        closeDialog();
        if (goToCreatedFolder) {
            goToFolderPage(newFolderId);
        }
    };

    const options = [{
        title: "New table",
        icon: <FiFilePlus/>,
        action: () => goToUploadPage(folderId)
    }, {
        title: "New folder",
        icon: <FiFolderPlus/>,
        action: () => setNewFolderState(true)
    }];

    return (
        <>
            {newFolder && (
                <DialogModal close={closeDialog} title="Folder name">
                    <NewFolderForm
                        onSuccess={onSuccess}
                        workspaceId={workspaceId}
                        parentId={folderId}
                    />
                </DialogModal>
            )}
            <Dropdown options={options}>
                {children || (
                    <IconWrapper size="small" clickable={true}>
                        <FiPlusCircle/>
                    </IconWrapper>
                )}
            </Dropdown>
        </>
    );
}

AddItemMenu.propTypes = {
    workspaceId: PropTypes.string.isRequired,
    folderId: PropTypes.string,
    children: PropTypes.node,
    goToCreatedFolder: PropTypes.bool
};

AddItemMenu.defaultProps = {
    goToCreatedFolder: true
};
