import React from "react";
import PropTypes from "prop-types";
import {useEvaluateModel} from "src/api/dataModels/useEvaluateModel.js";
import {Button} from "src/primitives/button.jsx";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";


export function ModelEvaluation({tableId}) {
    const [evaluate, {loading, result}] = useEvaluateModel(tableId);

    return (
        <div>
            <Button
                isLoading={loading}
                onClick={() => evaluate()}
                theme="secondary"
            >
                Evaluate model
            </Button>
            <div>
                {result && (
                    <SimpleTable data={result}/>
                )}
            </div>
        </div>
    );
}

ModelEvaluation.propTypes = {
    tableId: PropTypes.string.isRequired
};
