import React from "react";
import PropTypes from "prop-types";
import {usePingServer} from "src/api/usePing.js";
import {CenteredContent} from "src/primitives/layout/index.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {Title} from "src/primitives/title.jsx";
import {Link} from "react-router-dom";
import {LINKS} from "src/links.js";


export function RequireServerReady({children}) {
    const {serverReady, serverFailed, attempts} = usePingServer();

    if (!serverReady && attempts < 1) {
        // avoid flashing screen if server is 'soon' ready
        return null;
    }

    if (!serverReady) {
        console.log("Ping: ", attempts);

        return (
            <CenteredContent className="fade-in bg-neutral-100">
                <div className="flex flex-col gap-4 w-112 h-52">
                    {serverFailed ? (
                        <>
                            <Title>
                                Failed to start server. Please try again later.
                            </Title>
                            <Link to={LINKS.WEB} className="text-blue-600">
                                Go to start page
                            </Link>
                        </>
                    ) : (
                        <>
                            <div className="mb-1">
                                <Spinner theme="orange" />
                            </div>
                            <Title style={{margin: 0}}>
                                Starting up the server...
                            </Title>
                            {attempts >= 2 && (
                                <div>
                                    Cold starts can take up to 60 seconds, just hang on a little bit more.
                                </div>
                            )}
                        </>
                    )}
                </div>
            </CenteredContent>
        );
    }

    return children;
}

RequireServerReady.propTypes = {
    children: PropTypes.node
};
