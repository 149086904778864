import React, {createContext, useState} from "react";
import PropTypes from "prop-types";


export const NewFileContext = createContext(null);
export const SetNewFileContext = createContext(null);


export function NewFileContextProvider({children}) {
    const [newFile, setNewFile] = useState(null);

    return (
        <NewFileContext.Provider value={newFile}>
            <SetNewFileContext.Provider value={setNewFile}>
                {children}
            </SetNewFileContext.Provider>
        </NewFileContext.Provider>
    );
}

NewFileContextProvider.propTypes = {
    children: PropTypes.element
};
