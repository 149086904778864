import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_TABLE_QUERY} from "src/api/tables/useGetTable.js";

const MUTATION = gql`
  mutation RemoveColumn($tableId: String!, $columnName: String!) {
    removeColumn(tableId: $tableId, columnName: $columnName) {
      ok
      errorCode
    }
  }
`;

export function useRemoveColumn(tableId, {onCompleted} = {}) {
    const [mutation, {loading, error}] = useMutation(MUTATION, {onCompleted});

    const doMutate = useCallback((columnName) => {
        return mutation({
            variables: {tableId, columnName},
            refetchQueries: [{query: GET_TABLE_QUERY, variables: {id: tableId}}],
            awaitRefetchQueries: true
        }).then((response) => response.data.removeColumn);
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
