import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {useDropzone} from "react-dropzone";
import {Label} from "src/primitives/controls/Label.jsx";
import {FilesPreview} from "./FilesPreview.jsx";

const baseStyle = {
    outline: "none"
};

const focusedStyle = {
    borderColor: "#212728"
};

const acceptStyle = {
    borderColor: "#16a34a"
};

const rejectStyle = {
    borderColor: "#dc2626"
};

export function FileUploader({onChange, accept, maxFiles, label, isSuccess}) {
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({accept, maxFiles, onDrop: onChange});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...((isDragAccept || isSuccess) ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [isFocused, isDragAccept, isDragReject, isSuccess]);

    return (
        <div className="flex flex-col">
            {label && (
                <Label label={label}/>
            )}
            <div
                /* eslint-disable-next-line max-len */
                className="border-2 border-dashed border-neutral-300 rounded-md p-4 text-center flex items-center justify-center bg-black-1 hover:bg-black-3 hover:border-neutral-400 cursor-pointer transition-all"
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...getRootProps({style})}
            >
                <input
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...getInputProps()}
                />
                {Array.isArray(acceptedFiles) && acceptedFiles.length > 0 ? (
                    <FilesPreview files={acceptedFiles}/>
                ) : (
                    <div>
                        <p>Drop a file, or click to select</p>
                    </div>
                )}
            </div>
        </div>
    );
}

FileUploader.propTypes = {
    label: PropTypes.node,
    onChange: PropTypes.func,
    accept: PropTypes.object,
    maxFiles: PropTypes.number,
    isSuccess: PropTypes.bool
};

FileUploader.defaultProps = {
    onChange: () => {},
    accept: {},
    maxFiles: 1
};
