import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FiMinimize2} from "react-icons/fi";
import {Title} from "src/primitives/title.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";


export function DrawerWrapper({children}) {
    return (
        <div className="flex flex-col bg-neutral-50 border-l border-neutral-100 h-full">
            {children}
        </div>
    );
}

DrawerWrapper.propTypes = {
    children: PropTypes.node
};

export function DrawerHeader({title, close}) {
    return (
        <Header className="px-4 flex items-center justify-between border-b border-white">
            <Title size="small" style={{margin: 0}}>
                {title}
            </Title>
            <IconWrapper
                size="small"
                onClick={close}
                icon={<FiMinimize2/>}
                thickness={1.5}
                itemId="close-drawer"
                tooltip="Close"
            />
        </Header>

    );
}

DrawerHeader.propTypes = {
    title: PropTypes.node,
    close: PropTypes.func
};

const Header = styled.div`
  height: 49px;
`;

export function DrawerBody({children}) {
    return (
        <div className="flex-1 overflow-y-auto px-4 my-[10px]">
            {children}
        </div>
    );
}
DrawerBody.propTypes = {
    children: PropTypes.node
};

export function DrawerFooter({children}) {
    return (
        <Footer className="px-4 h-12 flex items-center border-t border-white">
            {children}
        </Footer>
    );
}
DrawerFooter.propTypes = {
    children: PropTypes.node
};

const Footer = styled.div`
`;
