import {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {useSelectedWorkspace} from "src/utils/workspace.js";
import {useSendEvent} from "src/api/events/useSendEvent.js";


export function TriggerEvent({name, tableId, data}) {
    const [workspaceId] = useSelectedWorkspace();
    const [sendEvent] = useSendEvent(workspaceId, name);
    const dataRef = useRef();
    const tableIdRef = useRef();
    dataRef.current = data;
    tableIdRef.current = tableId;

    useEffect(() => {
        if (workspaceId && name) {
            const payload = {tableId: tableIdRef.current, data: dataRef.current};
            sendEvent(payload).then(({ok, errorCode}) => {
                if (!ok) {
                    throw new Error(errorCode);
                }
            }).catch((error) => {
                console.warn("Failed to send event", error);
            });
        }
    }, [sendEvent, workspaceId, name]);

    return null;
}

TriggerEvent.propTypes = {
    name: PropTypes.string.isRequired,
    tableId: PropTypes.string,
    data: PropTypes.object
};
