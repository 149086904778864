import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {HistogramRaw, HorizontalBarChart} from "src/charts/index.js";
import {isColumnDate, isColumnNumeric, VariableTypes} from "src/utils/tableData.js";
import {CenteredContent} from "src/primitives/layout/index.jsx";
import {HistogramDate} from "src/charts/HistogramDate.jsx";
import {formatBooleanValues} from "src/charts/CategoryDistribution/utils.js";
import {BarChart} from "src/charts/BarChart.jsx";
import {Title} from "src/primitives/title.jsx";
import {ColumnSample} from "./components/ColumnSample/index.jsx";


function formatBooleanData(values) {
    const booleanValues = formatBooleanValues(values);

    return booleanValues.map((item) => {
        return {
            ...item,
            itemStyle: {color: item.color}
        };
    });
}

export function ColumnVisualization({column, columnStats, values}) {
    const {name, variableType} = column;
    const isNumeric = isColumnNumeric(column);
    const isDate = isColumnDate(column);

    return (
        <CenteredContent>
            {(isNumeric && variableType === VariableTypes.CONTINUOUS) && (
                <HistogramRaw
                    datasets={[values]}
                    height={240}
                />
            )}

            {(isDate && variableType !== VariableTypes.CATEGORICAL) && (
                <HistogramDate values={values} height={200}/>
            )}

            {(variableType === VariableTypes.CATEGORICAL) && (
                <HorizontalBarChart
                    title={name}
                    data={values}
                    height={200}
                    maxItems={5}
                />
            )}

            {(variableType === VariableTypes.BINARY) && (
                <BarChart
                    title={name}
                    data={formatBooleanData(values)}
                    height={200}
                />
            )}

            {(variableType === VariableTypes.CONSTANT) && (
                <Wrapper>
                    <Title size="xs">
                        <span className="font-normal">
                            {columnStats.values} entries of
                        </span> <TextExample>{values[0]?.name}</TextExample>
                    </Title>
                </Wrapper>
            )}
            {(!isDate && (variableType === VariableTypes.TEXT || variableType === VariableTypes.IDENTIFIER)) && (
                <ColumnSample values={values} columnName={name}/>
            )}
        </CenteredContent>
    );
}

ColumnVisualization.propTypes = {
    column: PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    }),
    columnStats: PropTypes.shape({
        values: PropTypes.number
    }),
    values: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string
        })
    ])
};

const Wrapper = styled.div``;


const TextExample = styled.span`
  font-weight: bold;
  :before {
    content: '"';
  }
  :after {
    content: '"';
  }
`;
