import React from "react";
import PropTypes from "prop-types";
import {Outlet} from "react-router-dom";
import {SidebarContextProvider} from "src/primitives/layout/sidebar_context.jsx";
import {NewFileContextProvider} from "src/pages/Home/contexts/file_context.jsx";
import {LatestFolderContextProvider} from "src/pages/Home/contexts/latest_folder_context.jsx";
import {AuthUserContextProvider} from "src/pages/contexts/user_context.jsx";
import {Resizeable} from "src/primitives/layout";
import {Sidebar} from "src/pages/Home/components/Sidebar/index.jsx";
import {DropzonePage} from "src/pages/Home/pages/DropzonePage/index.jsx";
import {CodeHintsSidebar} from "src/pages/Home/components/CodeHints/index.jsx";
import {ErrorComponent} from "src/components/ErrorComponent/index.jsx";
import {ErrorPage} from "src/components/ErrorComponent/ErrorPage.jsx";
import {CurrentTableContextProvider} from "src/pages/Home/contexts/current_table_context.jsx";


export function HomeLayout({workspaceId}) {
    return (
        <CurrentTableContextProvider>
            <LatestFolderContextProvider>
                <AuthUserContextProvider>
                    <SidebarContextProvider>
                        <NewFileContextProvider>
                            <>
                                <Resizeable
                                    id="primary-sidebar"
                                    sidebar={<Sidebar workspaceId={workspaceId}/>}
                                >
                                    <ErrorComponent fallback={(
                                        <ErrorPage content={(
                                            <div>
                                                Failed to load page
                                            </div>
                                        )}
                                        />
                                    )}
                                    >
                                        <Outlet/>
                                    </ErrorComponent>
                                </Resizeable>
                                <CodeHintsSidebar workspaceId={workspaceId}/>
                                <DropzonePage/>
                            </>
                        </NewFileContextProvider>
                    </SidebarContextProvider>
                </AuthUserContextProvider>
            </LatestFolderContextProvider>
        </CurrentTableContextProvider>
    );
}

HomeLayout.propTypes = {
    workspaceId: PropTypes.string
};
