import React from "react";
import PropTypes from "prop-types";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {formatTimestamp} from "src/utils/formatting.js";


const columns = [{
    key: "id",
    header: "ID"
}, {
    key: "createdAt",
    header: "Created",
    render: (value) => (
        <span>{formatTimestamp(value)}</span>
    )
}, {
    key: "name",
    header: "Name"
}, {
    key: "owner",
    header: "Owner",
    render: (owner) => (
        <span>{owner?.email}</span>
    )
}, {
    key: "usage",
    header: "Usage",
    render: (v) => (
        <span>{v?.totalTables} tables</span>
    )
}];

export function WorkspacesTable({workspaces}) {
    return (
        <div>
            <SimpleTable
                data={workspaces}
                columns={columns}
            />
        </div>
    );
}

WorkspacesTable.propTypes = {
    workspaces: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string
    }))
};
