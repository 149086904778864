import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_WORKSPACES_QUERY} from "src/api/workspaces/useGetWorkspaces.js";

const MUTATION = gql`
    mutation CreateWorkspace ($data: CreateWorkspaceInput!) {
        createWorkspace (data: $data) {
            ok
            errorCode
            workspace {
                id
                name
                backend
            }
        }
    }
`;

export function useCreateWorkspace() {
    const [mutation, {loading, error}] = useMutation(MUTATION, {
        refetchQueries: [{query: GET_WORKSPACES_QUERY}]
    });

    const doMutate = useCallback(({name, backend, backendCredentials}) => {
        const variables = {data: {name, backend, backendCredentials}};
        return mutation({variables}).then((response) => {
            return response.data.createWorkspace;
        });
    }, [mutation]);

    return [doMutate, {loading, error}];
}
