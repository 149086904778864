import React from "react";
import PropTypes from "prop-types";
import {TextInput} from "src/primitives/controls/index.jsx";


export function TextFilter({value, updateValue, clear}) {
    const handleChange = (e) => {
        updateValue(e.target.value);
    };

    return (
        <div>
            <TextInput
                size="small"
                placeholder="Filter"
                autoFocus={true}
                value={value}
                onChange={handleChange}
                onClear={clear}
            />
        </div>
    );
}
TextFilter.propTypes = {
    value: PropTypes.string,
    updateValue: PropTypes.func,
    clear: PropTypes.func
};
