import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {IconButton} from "src/primitives/button.jsx";
import {SelectInput} from "src/primitives/controls/index.jsx";
import {arrayToMap} from "src/utils/misc.js";
import {Label} from "src/primitives/controls/Label.jsx";
import {formatPrettyString} from "src/utils/formatting.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiMoreHorizontal, FiPlus, FiX} from "react-icons/fi";
import {DataTypes} from "src/utils/tableData.js";
import {SelectAggregation} from "src/components/ChartBuilder/components/SelectAggregation.jsx";
import {emptySeries} from "src/components/ChartBuilder/components/EditSeries/utils.js";
import {ColumnOption} from "../ColumnOption.jsx";


export function EditSeries({series, updateSeries, columns, setActiveSeriesId, aggregateData}) {
    const addSeries = () => {
        const newSeries = [...series, emptySeries()];
        updateSeries(newSeries);
    };

    const removeSeries = (seriesId) => {
        const newSeries = series.filter((item) => item.id !== seriesId);
        updateSeries(newSeries);
    };

    const updateSeriesConfig = (id, data) => {
        const newSeries = series.map((seriesItem) => {
            return (seriesItem.id === id) ? Object.assign({}, seriesItem, data) : seriesItem;
        });
        updateSeries(newSeries);
    };

    const handleColumnChange = (id, column) => {
        const data = {
            column: column?.name || "",
            name: column?.name ? formatPrettyString(column.name) : ""
        };
        updateSeriesConfig(id, data);
    };

    const handleAggregationChange = (id, aggregation) => {
        const newData = {aggregation};

        updateSeriesConfig(id, newData);
    };

    const columnsMap = useMemo(() => {
        return arrayToMap(columns, "name");
    }, [columns]);

    return (
        <div>
            <Label label="Y-axis"/>
            <div>
                {series.map((item, order) => (
                    <div key={item.id} className="mb-4 flex items-center">
                        <SelectInput
                            showClearIcon={false}
                            placeholder="Select column"
                            onChange={handleColumnChange.bind(null, item.id)}
                            options={columns}
                            getValue={(col) => col.name}
                            selectedOption={columnsMap[item.column]}
                            isDisabled={(col) => col.dataType === DataTypes.STRING}
                            renderOption={(col) => <ColumnOption column={col}/>}
                        />
                        <div className="ml-2 w-8">
                            <IconWrapper
                                icon={<FiMoreHorizontal/>}
                                size="xs"
                                onClick={setActiveSeriesId.bind(null, item.id)}
                            />
                        </div>
                        <div className="w-8">
                            {(series.length > 1 && (order > 0 || item.name)) && (
                                <IconWrapper
                                    icon={<FiX/>}
                                    size="xs"
                                    onClick={removeSeries.bind(null, item.id)}
                                />
                            )}
                        </div>
                        {aggregateData && (
                            <div className="w-24">
                                <SelectAggregation
                                    value={item.aggregation}
                                    onChange={handleAggregationChange.bind(null, item.id)}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div>
                <IconButton
                    onClick={addSeries}
                    size="small"
                    icon={<FiPlus/>}
                    theme="secondary"
                    text="Add series"
                />
            </div>
        </div>
    );
}

EditSeries.propTypes = {
    series: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        column: PropTypes.string,
        name: PropTypes.string
    })),
    updateSeries: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.object),
    setActiveSeriesId: PropTypes.func,
    aggregateData: PropTypes.bool
};
