import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
  mutation RemoveAccess ($id: String!) {
    removeAccess (objectId: $id) {
      ok
      errorCode
      objectId
    }
  }
`;


function updateCache(cache, response) {
    const {ok, objectId} = response.data.removeAccess;
    if (!ok) {
        return;
    }
    const model = {
        id: objectId,
        __typename: "SharedResource"
    };
    const normalizedId = cache.identify(model);
    cache.evict({id: normalizedId});
    cache.gc();
}


export function useRemoveAccess() {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((objectId) => {
        const variables = {id: objectId};

        return mutation({
            variables,
            update(cache, response) {
                updateCache(cache, response);
            }
        }).then((response) => response.data?.removeAccess);
    }, [mutation]);

    return [doMutate, {loading, error}];
}
