import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {Switch} from "src/primitives/controls/Switch/index.jsx";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {useEnablePublicLink} from "src/api/publicLink/useEnablePublicLink.js";
import {useDisablePublicLink} from "src/api/publicLink/useDisablePublicLink.js";
import {useShowToast} from "src/components/toasts/useShowToast.js";


export function TogglePublicLink({tableId}) {
    const {showSuccess, showLoading} = useShowToast();
    const [enable] = useEnablePublicLink(tableId);
    const [disable] = useDisablePublicLink(tableId);

    const {table, loading} = useGetTable(tableId);

    const toggle = useCallback(() => {
        if (table.publicLinkEnabled) {
            showLoading();
            disable().then(() => {
                showSuccess({message: "Public link disabled"});
            });
        } else {
            showLoading();
            enable().then(() => {
                showSuccess({message: "Public link enabled"});
            });
        }
    }, [enable, disable, table, showSuccess, showLoading]);

    if (loading) {
        return null;
    }

    return (
        <Switch
            value={!!table.publicLinkEnabled}
            onChange={toggle}
            renderLabel={({isChecked}) => (
                <div>
                    {isChecked ? "Enabled" : "Disabled"}
                </div>
            )}
        />
    );
}
TogglePublicLink.propTypes = {
    tableId: PropTypes.string.isRequired
};
