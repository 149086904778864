import React from "react";
import {Title} from "src/primitives/title.jsx";
import {TextImage} from "./components.jsx";
import sharingHighlighted from "./assets/sharing_highlighted.png";
import shareFolder from "./assets/share_folder.png";
import shareFolderEmail from "./assets/share_folder_email.png";
import shareTableEmail from "./assets/share_table_email.png";


export function ShareResourceInstructions() {
    return (
        <div className="flex flex-col gap-4 text-base">
            <div>
                <Title size="small" style={{margin: 0}}>
                    Sharing a table
                </Title>
                To share a table, click on the <b>share</b> button on the top right corner of the table.
            </div>
            <div className="max-w-112 mx-auto">
                <TextImage
                    src={sharingHighlighted}
                    alt="share table"
                />
            </div>
            <div>
                Type in the email address of the person you want to share the table with.
            </div>
            <div className="max-w-80 mx-auto">
                <TextImage
                    src={shareTableEmail}
                    alt="share table email"
                />
            </div>
            <div>
                Then click the <b>invite</b> button. The person will receive an email with a link to the table.
            </div>
            <div>
                <Title size="small" style={{margin: 0}}>
                    Sharing a folder
                </Title>
                {/* eslint-disable-next-line max-len */}
                To share a folder, navigate to a folder and then click on the <b>share</b> button in the top right corner.
            </div>
            <div className="max-w-112 mx-auto">
                <TextImage
                    src={shareFolder}
                    alt="share folder"
                />
            </div>
            <div>
                Type in the email address of the person you want to share the folder with, and press <b>invite</b>.
            </div>
            <div className="max-w-80 mx-auto">
                <TextImage
                    src={shareFolderEmail}
                    alt="share folder email"
                />
            </div>
        </div>
    );
}
