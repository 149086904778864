import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";


export function RetryError({retry, error}) {
    const [retries, setRetries] = useState(0);
    useEffect(() => {
        console.log(error);
        if (retries < 1 && error && error.name === "ApolloError") {
            console.log("RETRY");
            setRetries((v) => v + 1);
            retry();
        }
    }, [retries, retry, error]);

    if (retries < 1) {
        return null;
    }

    return (
        <div>
            <Button
                theme="outlineBlack"
                onClick={() => retry()}
            >
                Retry
            </Button>
        </div>
    );
}

RetryError.propTypes = {
    retry: PropTypes.func.isRequired,
    error: PropTypes.object
};
