import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";
import {Button, IconButton} from "src/primitives/button.jsx";
import {FiFilter} from "react-icons/fi";
import {Overlay} from "src/primitives/Overlay/index.jsx";
import {
    useLatestOptions,
    useLoadWithOptions
} from "src/pages/Home/pages/TablePage/pages/TableGridPage/table_data_context.jsx";
import {arrayToMap} from "src/utils/misc.js";
import {QueryFilters} from "./QueryFilters/index.jsx";


export function ManageFilters({table}) {
    const {clearFilters} = useLoadWithOptions();
    const {filters} = useLatestOptions();
    const usedFilters = Array.isArray(filters) ? filters.length : 0;
    const disableFilterButton = usedFilters === 0;
    const columnsMap = useMemo(() => arrayToMap(table?.columns, "name"), [table]);

    return (
        <Overlay
            align="right"
            disabled={disableFilterButton}
            header={(
                <Title size="xs">
                    <span>Active filters</span>
                </Title>
            )}
            content={(
                <div>
                    <QueryFilters
                        filters={filters}
                        columnsMap={columnsMap}
                    />
                </div>
            )}
            footer={(
                <div className="py-1">
                    <Button
                        size="xs"
                        theme="secondary"
                        onClick={clearFilters}
                    >
                        Clear all filters
                    </Button>
                </div>
            )}
        >
            <IconButton
                theme="outlineBlack"
                size="xs"
                isDisabled={disableFilterButton}
                icon={<FiFilter/>}
                text={`${usedFilters} active filters`}
            />
        </Overlay>
    );
}

ManageFilters.propTypes = {
    table: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.object)
    })
};
