import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation IndexTable($tableId: String!) {
  indexTable (tableId: $tableId) {
    ok
    errorCode
    table {
      id
      searchIndex
    }
  }
}
`;


export function useIndexTable(tableId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        const variables = {tableId};

        return mutation({variables});
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
