import {v4} from "uuid";

export function generateId() {
    return v4();
}

export function nanoId(n = 12) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    for (let i = 0; i < n; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}
