export function getAutoCompleter(words, meta, completerId) {
    /**
     * Inspiration:
     * https://jsitor.com/tdQTZKMlU
     * https://stackoverflow.com/questions/30041816/ace-editor-autocomplete-custom-strings
     */
    const wordList = words.map((word) => ({
        word,
        flags: "bc",
        syllables: 1
    }));

    return {
        id: completerId,
        getCompletions: (editor, session, pos, prefix, callback) => {
            if (prefix.length === 0) {
                callback(null, []);
                return;
            }

            callback(null, wordList.map((ea) => ({
                name: ea.word,
                value: ea.word,
                meta
            })));
        }
        /*
        getDocTooltip: (item) => {
            return `
                <div>
                <div>${item.name}</div>
                <div>${item.value}</div>
                <div>${item.meta}</div>
                <div data-id="${item.id}">ID: ${item.id}</div>
                </div>
            `;
        }
        */
    };
}

export function addOrReplaceAutoCompleter(editor, autoCompleter) {
    const existingIndex = editor.completers.findIndex((item) => item.id === autoCompleter.id);
    if (existingIndex >= 0) {
        // Update
        editor.completers[existingIndex] = autoCompleter; // eslint-disable-line
    } else {
        // Push
        editor.completers.push(autoCompleter);
    }
}
