import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
  mutation SetParentFolder($tableId: String, $folderId: String, $parentId: String) {
    setParentFolder (tableId: $tableId, folderId: $folderId, parentId: $parentId) {
      ok
      errorCode
      table {
        id
        folderId
      }
      folder {
        id
        parentFolderId
      }
    }
  }
`;

export function useSetParentFolder() {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(({tableId, folderId, parentId}) => {
        const tableResponse = {
            __typename: "Table",
            id: tableId,
            folderId: parentId
        };
        const folderResponse = {
            __typename: "Folder",
            id: folderId,
            parentFolderId: parentId
        };

        const expectedResponse = {
            ok: true,
            errorCode: null,
            table: tableId ? tableResponse : null,
            folder: folderId ? folderResponse : null
        };

        return mutation({
            variables: {tableId, folderId, parentId},
            optimisticResponse: {
                setParentFolder: expectedResponse
            }
        }).then((response) => {
            return response.data.setParentFolder;
        });
    }, [mutation]);

    return [doMutate, {loading, error}];
}
