import React from "react";
import PropTypes from "prop-types";
import {PageContent} from "src/primitives/layout";
import {useGetWorkspace} from "src/api/workspaces/useGetWorkspace.js";
import {LargeCard} from "src/pages/Home/pages/TablePage/components/LargeCard.jsx";
import {EaseIn} from "src/primitives/EaseIn.jsx";
import {WorkspaceUsage} from "./components/WorkspaceUsage";
import {WorkspaceMembers} from "./components/WorkspaceMembers";
import {DeleteWorkspace} from "./components/DeleteWorkspace";


export function WorkspaceSettingsPage({workspaceId}) {
    const {loading} = useGetWorkspace(workspaceId);

    return (
        <PageContent
            title="Settings"
            size="md"
        >
            <EaseIn loading={loading}>
                <WorkspaceUsage workspaceId={workspaceId}/>

                <LargeCard title="Members">
                    <WorkspaceMembers workspaceId={workspaceId}/>
                </LargeCard>

                <LargeCard title="Delete workspace">
                    <p className="mb-2 italic">
                        This actions is non recoverable. Once you delete the workspace, it cannot be recovered.
                    </p>
                    <DeleteWorkspace workspaceId={workspaceId}/>
                </LargeCard>
            </EaseIn>
        </PageContent>
    );
}

WorkspaceSettingsPage.propTypes = {
    workspaceId: PropTypes.string
};
