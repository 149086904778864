import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {FiChevronRight, FiDatabase, FiFolder} from "react-icons/fi";
import {useGetLink, useIsActiveLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";
import {NODE_TYPE} from "src/components/FoldersTree/folderTree.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {NewBadge} from "src/components/FoldersTree/components/Node/NewBadge.jsx";
import {minutesFromDate} from "src/utils/dateUtils.jsx";


function getIcon(nodeType) {
    switch (nodeType) {
    case NODE_TYPE.TABLE:
        return <FiDatabase/>;
    case NODE_TYPE.FOLDER:
        return <FiFolder/>;
    default:
        return null;
    }
}

function isTable(node) {
    return node?.data?.type === NODE_TYPE.TABLE;
}

function isFolder(node) {
    return node?.data?.type === NODE_TYPE.FOLDER;
}


export function Node({node, style, dragHandle}) {
    const getLink = useGetLink();
    const isActiveLink = useIsActiveLink(true);

    const nodeId = useMemo(() => node.data.id, [node.data]);
    const nodeType = useMemo(() => node.data.type, [node.data]);

    const link = useMemo(() => {
        if (nodeType === NODE_TYPE.TABLE) {
            return LINKS.tablePage(nodeId);
        }
        if (nodeType === NODE_TYPE.FOLDER) {
            return LINKS.folderPage(nodeId);
        }
        return null;
    }, [nodeType, nodeId]);

    const handleToggle = useCallback((e) => {
        e.preventDefault();
        node.toggle();
    }, [node]);

    const isActive = isActiveLink(link);
    const icon = getIcon(nodeType);

    return (
        <Wrapper
            className="rounded-md transition-colors"
            ref={dragHandle}
            style={style}
            $isActive={isActive}
            to={link ? getLink(link) : null}
        >
            <ExpandCollapseWrapper $isActive={node.isOpen}>
                {isFolder(node) && (
                    <IconWrapper
                        aria-label="expand-folder"
                        icon={<FiChevronRight/>}
                        onClick={handleToggle}
                        size="sm"
                    />
                )}
            </ExpandCollapseWrapper>

            {icon && (
                <div className="flex-center h-full w-6 mr-1">
                    <IconWrapper icon={icon} size="sm"/>
                </div>
            )}

            <div className="flex-1 pr-2 truncate">
                <span>
                    {node.data.name}
                </span>
            </div>
            {(isTable(node) && node.data?.createdAt && minutesFromDate(node.data.createdAt) < 10) && (
                <div className="mx-2">
                    <NewBadge item={node.data}/>
                </div>
            )}
        </Wrapper>
    );
}

Node.propTypes = {
    node: PropTypes.shape({
        toggle: PropTypes.func,
        data: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            type: PropTypes.string,
            createdAt: PropTypes.string
        }),
        parent: PropTypes.any,
        isOpen: PropTypes.bool,
        isLeaf: PropTypes.bool,
        level: PropTypes.number
    }),
    style: PropTypes.object,
    dragHandle: PropTypes.func
};

const Wrapper = styled(NavLink)`
  height: 100%;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  color: rgba(255, 255, 255, 0.8);

  &:hover {
    background: rgba(0, 0, 0, 0.75);
    color: rgba(255, 255, 255, 1.0);
  }

  ${({$isActive}) => $isActive && `
    // background: rgba(0, 0, 0, 0.8);
    // color: #fff;
    color: #a3e635; // lime-400
    &:hover {
      color: #a3e635;    
    }
  `};
`;

const ExpandCollapseWrapper = styled.div`
  height: 32px;
  width: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  & span {
    transition: transform 0.3s;
    ${({$isActive}) => $isActive ? `
        transform: rotate(90deg);
    ` : ""};
  }
`;


ExpandCollapseWrapper.propTypes = {
    $isActive: PropTypes.bool
};
