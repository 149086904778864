import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {Heatmap} from "src/charts/index.js";
import {useGetCorrelationMatrix} from "src/api/tableData/useGetCorrelationMatrix.js";
import {EaseIn} from "src/primitives/EaseIn.jsx";
import {useSelectedColumns} from "src/pages/Home/pages/TablePage/pages/ProfilingPage/states.jsx";
import {getNumericalColumnNames} from "src/utils/tableData.js";
import {LargeCard} from "src/pages/Home/pages/TablePage/components/LargeCard.jsx";


function getCorrelationMatrix(columnNames, correlationMatrix) {
    const matrix = [];
    columnNames.forEach((c1, i) => {
        columnNames.forEach((c2, j) => {
            let rho = "-";
            if (correlationMatrix && correlationMatrix[c1] && correlationMatrix[c1][c2]) {
                // rho = Math.round(100 * correlationMatrix[c1][c2]);
                rho = (100 * correlationMatrix[c1][c2]).toFixed(2);
            }
            matrix.push([i, j, rho]);
        });
    });

    return matrix;
}


export function CorrelationAnalysis({tableId, columns}) {
    const selectColumns = useSelectedColumns((state) => state.select);
    const {data: correlation, loading} = useGetCorrelationMatrix(tableId);

    const columnNames = getNumericalColumnNames(columns).filter((name) => correlation[name]);
    columnNames.reverse(); // heatmap have revered order

    const matrix = getCorrelationMatrix(columnNames, correlation);

    const handleClick = useCallback((data) => {
        selectColumns([data.x, data.y]);
    }, [selectColumns]);

    return (
        <LargeCard
            title="Correlation of continuous variables"
        >
            <EaseIn loading={loading}>
                <Heatmap
                    title="Correlation matrix"
                    xValues={columnNames}
                    yValues={columnNames}
                    matrix={matrix}
                    minValue={-100}
                    maxValue={100}
                    onClick={handleClick}
                />

                {columnNames.length === 0 && (
                    <div>
                        No numerical columns available for correlation analysis.
                    </div>
                )}
            </EaseIn>
        </LargeCard>
    );
}

CorrelationAnalysis.propTypes = {
    tableId: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        isHidden: PropTypes.bool
    }))
};
