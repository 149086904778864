import React from "react";
import PropTypes from "prop-types";
import {Code} from "./code.jsx";


export function Terminal({code, language, hideTopBar}) {
    return (
        <div className="h-full flex flex-col border border-white/20 rounded-xl">
            {!hideTopBar && (
                <div className="flex py-3 px-4 space-x-3 bg-white/10 border-b border-white/[0.05] rounded-t-xl">
                    <div className="h-2.5 w-2.5 rounded-full bg-red-500 border border-white/20" />
                    <div className="h-2.5 w-2.5 rounded-full bg-yellow-500 border border-white/20" />
                    <div className="h-2.5 w-2.5 rounded-full bg-green-500 border border-white/20" />
                </div>
            )}
            <div className="flex-1 px-3.5 py-4 overflow-auto bg-black/10">
                <Code code={code} language={language}/>
            </div>
        </div>
    );
}

Terminal.propTypes = {
    code: PropTypes.string,
    language: PropTypes.string,
    hideTopBar: PropTypes.bool
};

Terminal.defaultProps = {
    code: "\nhello = 'world' \n",
    language: "python"
};
