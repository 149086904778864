import React from "react";
import clickColumnDetails from "./assets/columns_details_click.png";
import {TextImage} from "./components.jsx";


export function ColumnDetailsInstructions() {
    return (
        <div className="flex flex-col gap-4 text-base">
            <div>
                {/* eslint-disable-next-line max-len */}
                Every uploaded table is automatically analysed. To see the analysis for each column, click on the column illustration in the table overview.
            </div>
            <div className="max-w-112 mx-auto">
                <TextImage
                    src={clickColumnDetails}
                    alt="view column details"
                />
            </div>
            <div>
                The analysis is based on the variable type for the column.
                <ul className="list-disc mx-6">
                    <li className="py-2">
                        {/* eslint-disable-next-line max-len */}
                        For continuous variables, you will see the distribution, theoretical distribution fit, correlations with other continuous columns and basic descriptive statistics.
                    </li>
                    <li className="py-2">
                        {/* eslint-disable-next-line max-len */}
                        For categorical variables, you will see the distribution of values. You can also see a pivot table where you can see other columns split by the categorical variable.
                    </li>
                </ul>
            </div>
        </div>
    );
}

ColumnDetailsInstructions.propTypes = {
};
