import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const QUERY = gql`
query GetAllWorkspaces {
  allWorkspaces {
    id
    createdAt
    name
    owner {
      id
      email
    }
    usage {
      totalTables
      totalBytes
    }  
  }
}
`;

export function useGetAllWorkspaces() {
    const {data, loading, error, refetch} = useQuery(QUERY);

    const workspaces = useMemo(() => {
        return data?.allWorkspaces ? data.allWorkspaces : [];
    }, [data]);

    return {
        workspaces, loading, error, refetch
    };
}
