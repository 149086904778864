import React from "react";
import PropTypes from "prop-types";
import {Tfoot, Th, Tr} from "./components.jsx";


export function TableFooter({keys, footers}) {
    return (
        <Tfoot>
            <Tr>
                {footers.map((footer, index) => (
                    <Th key={`footer-${keys[index]}`}>
                        {footer}
                    </Th>
                ))}
            </Tr>
        </Tfoot>
    );
}

TableFooter.propTypes = {
    keys: PropTypes.arrayOf(PropTypes.string),
    footers: PropTypes.arrayOf(PropTypes.node)
};
