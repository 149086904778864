import React from "react";
import {MarkdownContent} from "src/primitives/Markdown/index.jsx";
import {PageWrapper} from "src/pages/Web/components/layouts.jsx";


const termsOfService = `
# Terms of Service:

### Acceptance of Terms

- By accessing or using rho store, you agree to these Terms of Service.

### User Accounts

- You must provide accurate information when creating an account.
- Keep your login credentials confidential.

### Payment and Subscription

- rho store may require payment for certain features. Payment details are handled securely.

### Intellectual Property

- Our service and its original content are protected by copyright, trademark, and other intellectual property laws.

### Prohibited Activities

- You may not engage in any activity that violates laws or infringes on the rights of others.

### Termination

- We reserve the right to terminate or suspend your account for violating these Terms of Service.

### Limitation of Liability

- rho store is not liable for any direct, indirect, incidental, or consequential damages.

### Changes to Terms
- We may update these Terms of Service. Continued use after changes constitutes acceptance.

### Governing Law
- These Terms are governed by and construed in accordance with the laws of Sweden.

### Contact Information

- For questions about these Terms, contact us at \`contact[at]rho.store\`.
`;


export function TermsPage() {
    return (
        <PageWrapper>
            <MarkdownContent content={termsOfService}/>
        </PageWrapper>
    );
}
