export const RANGE_DIVIDER = ";";
export const CATEGORY_DIVIDER = ":";

export const FilterOperator = {
    EQUALS: "equals",
    MATCHES: "matches",
    IN_RANGE: "in_range",
    IN: "in",
    IS: "is"
};

export const COLUMN_SIZES = {
    MIN_WIDTH: 120,
    MAX_WIDTH: 500,
    DEFAULT_WIDTH: 180,
    ROW_ID_WIDTH: 50
};
