import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {FiDelete} from "react-icons/fi";
import {useGetCharts} from "src/api/charts/useGetCharts.js";
import {useDeleteChart} from "src/api/charts/useDeleteChart.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {LINKS} from "src/links.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {overrideSystemHandling} from "src/utils/system.js";


export function ChartList({tableId}) {
    const {charts, loading, error} = useGetCharts(tableId);
    const {showLoading, showError, showSuccess} = useShowToast();
    const [deleteChart] = useDeleteChart();


    const doDeleteChart = (chartId, e) => {
        overrideSystemHandling(e);

        showLoading();
        deleteChart(chartId).then((result) => {
            const {ok, errorCode} = result;
            if (ok) {
                showSuccess({message: "Chart deleted"});
            } else {
                throw new Error(errorCode);
            }
        }).catch((err) => {
            console.error(err);
            showError();
        });
    };

    if (loading) {
        return (
            <Spinner/>
        );
    }

    if (error) {
        console.error(error);
        return (
            <div>
                Failed to load charts
            </div>
        );
    }

    return (
        <div>
            {charts.map((chart) => (
                <div
                    key={chart.id}
                    className="mb-2 flex items-center justify-between"
                >
                    <Link
                        to={LINKS.editChart(tableId, chart.id)}
                        className="px-2 py-1 flex-1 rounded-md hover:bg-black/10"
                    >
                        {chart.title}
                    </Link>
                    <IconWrapper
                        itemId={`delete-chart-${chart.id}`}
                        tooltip="Delete chart"
                        icon={<FiDelete/>}
                        size="small"
                        onMouseDown={doDeleteChart.bind(null, chart.id)}
                        style={{zIndex: 10}}
                    />
                </div>
            ))}
        </div>
    );
}

ChartList.propTypes = {
    tableId: PropTypes.string.isRequired
};
