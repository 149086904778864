import React from "react";
import PropTypes from "prop-types";


const tailwindShades = [
    "text-orange-800",
    "text-orange-700",
    "text-orange-700",
    "text-orange-600",
    "text-orange-600",
    "text-orange-500",
    "text-orange-500",
    "text-orange-400",
    "text-orange-400",
    "text-orange-400",
    "text-orange-300"
];

const getColorClass = (rank) => {
    const maxIndex = tailwindShades.length;
    const index = Math.min(rank, maxIndex);

    return tailwindShades[index - 1];
};


const getFontSize = (frequency, maxFrequency, minSize = 12, maxSize = 52) => {
    // Calculate the base size based on frequency
    let size = minSize + (frequency / maxFrequency) * (maxSize - minSize);

    // Round to the nearest multiple of 4
    size = Math.round(size / 4) * 4;

    // Ensure the size is within the specified range
    return Math.min(size, maxSize);
};


export function WordCloud({data, maxValues}) {
    if (!Array.isArray(data)) {
        return null;
    }

    // Normalize word sizes
    const maxFrequency = Math.max(...data.map(({count}) => count));

    return (
        <div className="flex flex-wrap items-baseline gap-2 p-2 font-bold">
            {data.slice(0, maxValues).map(({word, count, rank}) => {
                const fontSize = getFontSize(count, maxFrequency);

                return (
                    <span
                        key={word}
                        style={{
                            fontSize: `${fontSize}px`
                        }}
                        className={`
                        transition-transform transform hover:scale-110 ${getColorClass(rank)}
                        `.trim()}
                    >
                        {word}
                    </span>
                );
            })}
        </div>
    );
}

WordCloud.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        word: PropTypes.string,
        count: PropTypes.number,
        rank: PropTypes.number
    })),
    maxValues: PropTypes.number
};

WordCloud.defaultProps = {
    maxValues: 40
};
