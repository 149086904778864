import React from "react";
import PropTypes from "prop-types";
import {isColumnNumeric} from "src/utils/tableData.js";
import {DisplayHeatmap} from "./components/DisplayHeatmap";
import {ColumnSuffix} from "./components/ColumnSuffix";
import {ColumnPrefix} from "./components/ColumnPrefix";
import {DisplayDecimals} from "./components/DisplayDecimals";
import {DisplayFormat} from "./components/DisplayFormat";


const BlockDivider = ({children}) => (
    <div className="mb-2 pb-2">
        {children}
    </div>
);

BlockDivider.propTypes = {
    children: PropTypes.node
};

export function ColumnDisplaySettings({column}) {
    return (
        <div className="flex flex-col gap-2 mb-8">
            {isColumnNumeric(column) && (
                <>
                    <BlockDivider>
                        <DisplayHeatmap
                            column={column}
                        />
                    </BlockDivider>
                    <BlockDivider>
                        <DisplayDecimals
                            column={column}
                        />
                    </BlockDivider>
                </>
            )}
            <BlockDivider>
                <DisplayFormat
                    column={column}
                />
            </BlockDivider>
            <BlockDivider>
                <ColumnPrefix
                    column={column}
                />
            </BlockDivider>
            <BlockDivider>
                <ColumnSuffix
                    column={column}
                />
            </BlockDivider>
        </div>
    );
}


ColumnDisplaySettings.propTypes = {
    column: PropTypes.shape({
        tableId: PropTypes.string,
        name: PropTypes.string,
        displaySettings: PropTypes.object
    })
};
