import React from "react";
import PropTypes from "prop-types";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {config} from "src/config.js";
import {LINKS} from "src/links.js";
import {CopyLink} from "src/pages/Home/components/ShareResource/components/TablePublicLink/CopyLink.jsx";


function getPublicLink(tableId, pk) {
    return `${config.CLIENT_URL}${LINKS.publicTable(tableId)}?pk=${pk}`;
}

export function TablePublicLink({tableId}) {
    const {table, loading} = useGetTable(tableId);

    if (loading) {
        return null;
    }
    const {publicKey, publicLinkEnabled} = table;

    if (!publicLinkEnabled) {
        return null;
    }

    return (
        <div>
            <CopyLink link={getPublicLink(tableId, publicKey)}/>
            <p className="text-sm mt-2">Everyone with this link can access the data</p>
        </div>
    );
}

TablePublicLink.propTypes = {
    tableId: PropTypes.string
};
