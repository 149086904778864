import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation UpdateUser ($userId: String!, $data: UpdateUserInput!) {
  updateUser (userId: $userId, data: $data) {
    ok
    errorCode
    user {
      id
      createdAt
      email
      firstName
      lastName
    }
  }
}
`;


export function useUpdateUser() {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((userId, data) => {
        return mutation({variables: {userId, data}}).then((response) => {
            return response.data.updateUser;
        });
    }, [mutation]);

    return [doMutate, {loading, error}];
}

export function userDataFromClerkUser(clerkUser) {
    return {
        firstName: clerkUser.firstName,
        lastName: clerkUser.lastName,
        email: clerkUser.primaryEmailAddress.toString(),
        createdAt: new Date(clerkUser.createdAt).toISOString()
    };
}
