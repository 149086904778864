import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {Label} from "src/primitives/controls/Label.jsx";
import {TextInput} from "src/primitives/controls/TextInput.jsx";


export function InputRange({value, onChange, label, minValue, maxValue, hideNumber}) {
    const handleChange = useCallback((e) => {
        if (onChange) {
            onChange(parseInt(e.target.value, 10));
        }
    }, [onChange]);

    return (
        <div className="flex flex-col flex-1">
            {label && (
                <div>
                    <Label label={label}/>
                </div>
            )}
            <div className="flex gap-2">
                <input
                    className="block w-full rounded-md border-0 bg-transparent py-1.5 pl-2"
                    type="range"
                    value={value}
                    onChange={handleChange}
                    min={minValue}
                    max={maxValue}
                />
                {!hideNumber && (
                    <div className="min-w-24 text-right">
                        <TextInput
                            onChange={handleChange}
                            value={value}
                            type="number"
                            min={minValue}
                            max={maxValue}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

InputRange.propTypes = {
    value: PropTypes.number,
    label: PropTypes.node,
    onChange: PropTypes.func,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    hideNumber: PropTypes.bool
};
