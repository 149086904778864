import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const GET_COLUMN_DISTRIBUTIONS_QUERY = gql`
query GetColumnDistributions($id: String!, $columns: [String!]) {
  table (tableId: $id) {
    id
    distributions(columns: $columns)
  }
}
`;

export function useGetColumnDistributions(tableId, columns, skip = false) {
    const options = {
        variables: {id: tableId, columns},
        skip: skip || (!Array.isArray(columns) || columns.length === 0)
    };
    const {data, loading, error, refetch} = useQuery(GET_COLUMN_DISTRIBUTIONS_QUERY, options);

    const distributions = useMemo(() => {
        if (!data?.table?.distributions) {
            return {};
        }

        const cd = data.table.distributions;
        window.cd = cd;
        return cd;
    }, [data]);

    return {
        data: distributions, loading, error, refetch
    };
}
