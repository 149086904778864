import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {
    useLatestOptions,
    useTableDataResult
} from "src/pages/Home/pages/TablePage/pages/TableGridPage/table_data_context.jsx";
import {formatNumber} from "src/utils/formatting.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {safeArrayLength} from "src/utils/misc.js";


export function ResultInfo({tableSize}) {
    const showText = false;
    const {data, result, loading} = useTableDataResult();
    const latestOptions = useLatestOptions();

    const areFiltersUsed = safeArrayLength(latestOptions.filters) > 0;
    const percentage = useMemo(() => {
        if (!tableSize?.rows || !result?.totalRows) {
            return null;
        }

        return 100 * (result.totalRows / tableSize.rows);
    }, [tableSize, result]);

    if (loading) {
        return (
            <div className="flex items-center gap-3">
                <Spinner size="sm" theme="gray"/>
                {showText && (
                    <span className="italic text-neutral-800">
                        Loading...
                    </span>
                )}
            </div>
        );
    }

    if (areFiltersUsed && Number.isInteger(result?.totalRows)) {
        // Filters are used
        return (
            <span className="monospace text-xs">
                {result?.totalRows} rows matching filter ({formatNumber(percentage, 1)}%)
            </span>
        );
    }

    return (
        <span>
            Showing {data.length} rows
        </span>
    );
}

ResultInfo.propTypes = {
    tableSize: PropTypes.shape({
        rows: PropTypes.number
    })
};
