import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {COLUMN_FIELDS} from "src/api/fragments.js";


const MUTATION = gql`
${COLUMN_FIELDS}
mutation UpdateColumnInfo($data: UpdateColumnInfoInput!) {
  updateColumnInfo(data: $data) {
    ok
    errorCode
    column {
       ...ColumnFields
    }
  }
}
`;

export function useUpdateColumn(tableId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(({name, ...data}) => {
        return mutation({
            variables: {data: {tableId, name, ...data}}
        }).then((response) => {
            return response.data.updateColumnInfo;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
