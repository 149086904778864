import React from "react";
import PropTypes from "prop-types";
import {formatNumber} from "src/utils/formatting.js";
import {cva} from "cva";


const barClasses = cva({
    base: "absolute h-full",
    variants: {
        direction: {
            left: "rounded-l-md bg-blue-500",
            right: "rounded-r-md bg-orange-500"
        }
    },
    defaultVariants: {
        direction: "left"
    }
});

export function CorrelationBar({value}) {
    // eslint-disable-next-line no-param-reassign,operator-assignment
    value = 100.0 * value;
    const direction = value > 0 ? "right" : "left";
    const left = value > 0 ? 50 : 50 + (value / 2.0);
    const minWidth = 2;
    const width = Math.max(Math.abs(value) / 2, minWidth);
    const style = {
        left: `${left}%`,
        width: `${width}%`
    };

    const dividerStyle = {
        left: "49.5%",
        width: "1%"
    };

    return (
        <div className="rounded-md relative bg-neutral-200 h-2">
            <div className="absolute h-full bg-neutral-100" style={dividerStyle}/>
            <div className={barClasses({direction})} style={style}>
                <div className="text-xs hidden">
                    {formatNumber(value)}
                </div>
            </div>
        </div>
    );
}
CorrelationBar.propTypes = {
    value: PropTypes.number
};
