import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
mutation GetUploadUrl($fileName: String!, $workspaceId: String!) {
  getUploadUrl(fileName: $fileName, workspaceId: $workspaceId) {
    ok
    errorCode
    fileId
    url
  }
}
`;

export function useGetUploadUrl(workspaceId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((fileName) => {
        return mutation({
            variables: {fileName, workspaceId}
        }).then((response) => {
            return response.data.getUploadUrl;
        });
    }, [mutation, workspaceId]);

    return [doMutate, {loading, error}];
}
