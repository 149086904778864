import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_WORKSPACES_QUERY} from "src/api/workspaces/useGetWorkspaces.js";


const MUTATION = gql`
mutation InitializeAccount ($data: UpdateUserInput!) {
  initializeAccount (data: $data) {
    ok
    errorCode
    workspace {
      id
      name
    }
  }
}
`;


export function useInitializeAccount() {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((userData) => {
        return mutation({
            variables: {data: userData},
            refetchQueries: [{query: GET_WORKSPACES_QUERY}],
            awaitRefetchQueries: true
        }).then((response) => {
            return response.data.initializeAccount;
        });
    }, [mutation]);

    return [doMutate, {loading, error, data}];
}
