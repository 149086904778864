import React from "react";
import PropTypes from "prop-types";
import {Label} from "src/primitives/controls/Label.jsx";
import {Options} from "src/primitives/controls/Options.jsx";


export function SelectYaxis({value, handleYaxisChange}) {
    const yAxisOptions = [{
        value: "",
        name: "Auto"
    }, {
        value: "left",
        name: "Left"
    }, {
        value: "right",
        name: "Right"
    }];

    return (
        <div>
            <Label label="Y-axis position"/>
            <Options
                options={yAxisOptions}
                selectedValue={value}
                onChange={handleYaxisChange}
            />
        </div>
    );
}

SelectYaxis.propTypes = {
    value: PropTypes.string,
    handleYaxisChange: PropTypes.func
};
