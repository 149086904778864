/* eslint-disable max-len */
import React from "react";
import {MarkdownContent} from "src/primitives/Markdown";
import {PageWrapper} from "src/pages/Web/components/layouts.jsx";


const privacyPolicy = `
# Privacy policy

Welcome to rho store! This Privacy Policy explains how we collect, use, and protect your personal information. By using our service, you agree to the terms outlined in this policy.

### Information We Collect

- Personal Information: We may collect personal information such as your name, email address, and payment details.
- Usage Information: We collect data on how you interact with our service, including your device information, IP address, and browsing history.

### How We Use Your Information

- To provide and maintain our service.
- To improve and customize your experience.
- To process transactions and send relevant information.

### Sharing Your Information

- We do not sell, trade, or transfer your personal information to third parties without your consent, except as required by law.

### Security Measures

- We implement security measures to protect your personal information, but no method of transmission over the internet is 100% secure.

### Your Choices

- You can opt-out of certain data collection by adjusting your account settings.

### Changes to this Privacy Policy

- We reserve the right to update our Privacy Policy. Check this page for any changes.

### Contact Us
- If you have questions or concerns about our Privacy Policy, please contact us at \`contact[at]rho.store\`.
`;

export function PrivacyPage() {
    return (
        <PageWrapper>
            <MarkdownContent content={privacyPolicy}/>
        </PageWrapper>
    );
}
