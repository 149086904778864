import React from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {getAxisLabelStyle, getDefaultTextStyle} from "src/charts/options.js";
import {CHART_COLORS} from "src/charts/colors.js";
import {abbreviateNumber} from "src/utils/formatting.js";


function getGradient(color) {
    if (!color) {
        return null;
    }
    return {
        type: "linear",
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
            offset: 0,
            color // color at 0%
        }, {
            offset: 0.4,
            color // color at 40%
        }, {
            offset: 1,
            color: "rgba(255, 255, 255, 0.5)" // color at 100%
        }]
    };
}

function getOption(data, options = {}) {
    const {showXLabel, showYLabel, colors, tooltipFormat} = options;

    let tooltipOptions = {};
    if (tooltipFormat) {
        tooltipOptions = {
            show: true,
            confine: true,
            trigger: "axis",
            formatter(params) {
                return tooltipFormat(params);
            }
        };
    }

    const primaryColor = (colors && colors[0]) ? colors[0] : CHART_COLORS.blue;

    return {
        animation: false,
        textStyle: getDefaultTextStyle(),
        tooltip: tooltipOptions,
        grid: {
            show: false,
            top: "0%",
            left: showYLabel ? "20%" : "5%",
            width: showYLabel ? "75%" : "90%",
            bottom: showXLabel ? "25%" : "5%"
        },
        xAxis: {
            type: "value",
            axisLabel: {
                show: showXLabel,
                formatter: (value) => {
                    return abbreviateNumber(value);
                },
                ...getAxisLabelStyle()
            },
            axisLine: {
                show: true,
                lineStyle: {
                    width: 1,
                    color: "rgba(0, 0, 0, 0.25)"
                }
            },
            axisTick: {
                show: showXLabel
            },
            splitLine: {
                show: false
            },
            min: options.xMin,
            max: options.xMax
        },
        yAxis: {
            type: "value",
            axisLabel: {
                show: showYLabel,
                formatter: (value) => {
                    return abbreviateNumber(value);
                },
                ...getAxisLabelStyle()
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
        series: [
            {
                data: data.map(({x, y, lower, upper}) => [x, y, lower, upper]),
                dimensions: ["x", "y", "lower", "upper"],
                type: "line",
                smooth: true,
                showSymbol: false,
                lineStyle: {
                    width: 2
                },
                areaStyle: {
                    opacity: 0.25,
                    color: getGradient(primaryColor)
                },
                color: primaryColor
            }
        ]
    };
}


export function SmoothLineChart({data, height, options}) {
    /**
     * https://echarts.apache.org/examples/en/editor.html?c=confidence-band
     */
    const option = getOption(data, options);

    return (
        <ChartWrapper option={option} height={height}/>
    );
}

SmoothLineChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    })),
    height: PropTypes.number,
    options: PropTypes.shape({
        title: PropTypes.string,
        showXLabel: PropTypes.bool,
        showYLabel: PropTypes.bool,
        xMin: PropTypes.number,
        xMax: PropTypes.number,
        colors: PropTypes.arrayOf(PropTypes.string),
        tooltipFormat: PropTypes.func
    })
};

SmoothLineChart.defaultProps = {
    // Example
    data: [],
    options: {
        showXLabel: true,
        showYLabel: true,
        colors: [CHART_COLORS.blue]
    }
};
