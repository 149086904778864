import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";


const textClasses = cva({
    base: "text-white/80 mb-2",
    variants: {
        size: {
            small: "text-sm lg:text-md",
            medium: "text-md lg:text-lg",
            large: "text-lg lg:text-xl"
        }
    },
    defaultVariants: {
        size: "medium"
    }
});

export function Text({children, size}) {
    return (
        <div className={textClasses({size})}>
            {children}
        </div>
    );
}

Text.propTypes = {
    children: PropTypes.node,
    size: PropTypes.oneOf(["small", "medium", "large"])
};

const classes = cva({
    base: "max-w-md lg:max-w-lg",
    variants: {
        align: {
            left: "text-left",
            center: "text-center",
            right: "text-right"
        }
    },
    defaultVariants: {
        size: "medium",
        align: "center"
    }
});


export function TextBlock({children, size, align}) {
    return (
        <div className={classes({align})}>
            <Text size={size}>
                {children}
            </Text>
        </div>
    );
}

TextBlock.propTypes = {
    children: PropTypes.node,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    align: PropTypes.oneOf(["left", "center", "right"])
};
