import {isColumnDate, VariableTypes} from "src/utils/tableData.js";
import {AXIS_TYPES} from "src/components/ChartBuilder/constants.jsx";

export function getDefaultTextStyle() {
    // https://echarts.apache.org/en/option.html#textStyle
    return {
        color: "#262626", // text-neutral-800
        fontSize: 12,
        fontFamily: "Inter"
    };
}

export function getAxisTitleFontStyle() {
    return {
        // color: "#404040", // text-neutral-700,
        color: "#262626", // text-neutral-800
        fontSize: 14
        // fontFamily: "monospace"
        // fontWeight: "bold"
    };
}

export function getAxisLabelFontStyle() {
    return {
        // color: "#404040", // text-neutral-700,
        color: "#262626", // text-neutral-800
        fontSize: 11
        // fontFamily: "monospace"
        // fontWeight: "bold"
    };
}


export function getAxisTypeByColumn(column) {
    if (column.variableType === VariableTypes.CONTINUOUS) {
        return isColumnDate(column) ? AXIS_TYPES.TIME : AXIS_TYPES.VALUE;
    }
    if (column.variableType === VariableTypes.CATEGORICAL) {
        return AXIS_TYPES.CATEGORY;
    }

    // fallback
    return AXIS_TYPES.VALUE;
}
