import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
mutation UpdateWorkspace($workspaceId: String!, $data: UpdateWorkspaceInput!) {
  updateWorkspace (workspaceId: $workspaceId, data: $data) {
    ok
    errorCode
    workspace {
      id
      name
    }
  }
}
`;


export function useUpdateWorkspace(workspaceId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((data) => {
        const variables = {workspaceId, data};
        const expectedResult = {
            ok: true,
            errorCode: null,
            workspace: {
                __typename: "Workspace",
                id: workspaceId,
                ...data
            }
        };
        const optimisticResponse = {updateWorkspace: expectedResult};
        const options = {variables, optimisticResponse};

        return mutation(options).then((response) => {
            return response.data.updateWorkspace;
        });
    }, [mutation, workspaceId]);

    return [doMutate, {loading, error}];
}
