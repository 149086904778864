import {useCallback} from "react";
import {COLUMN_SIZES} from "src/components/TableGrid/constants.js";
import {usePersistentJSON} from "src/utils/localstorage.js";


export function getGridTemplate(columns, columnSpecs) {
    const rowId = `${COLUMN_SIZES.ROW_ID_WIDTH}px`;
    const defaultWidth = `${COLUMN_SIZES.DEFAULT_WIDTH}px`;

    const columnWidths = columns.map((column) => {
        return columnSpecs[column] ? `${columnSpecs[column]}px` : defaultWidth;
    });
    return [rowId, ...columnWidths];
}

export function useColumnWidth(tableId) {
    const key = `column-width--${tableId}`;
    const {value, setValue} = usePersistentJSON(key, {});

    const updateColumnWidth = useCallback((name, v) => {
        setValue((prevData) => {
            return Object.assign({}, prevData, {[name]: v});
        });
    }, [setValue]);

    return [value, updateColumnWidth];
}
