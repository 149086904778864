export const NODE_TYPE = {
    ROOT: "ROOT",
    TABLE: "TABLE",
    FOLDER: "FOLDER"
};

export function createNode({id, name, ...data}, type, children = []) {
    return {
        id,
        name,
        type,
        children,
        ...data
    };
}

function createFoldersMap(folders) {
    return folders.reduce((acc, folder) => {
        const folderItem = {...folder, children: []};

        return {...acc, [folder.id]: folderItem};
    }, {});
}

function addTablesToTree(rootNode, tables, folderMap) {
    tables.forEach((table) => {
        const node = createNode(table, NODE_TYPE.TABLE);
        const {folderId} = table;

        if (!folderId) {
            rootNode.children.push(node);
        } else {
            const parentFolder = folderMap[folderId];
            if (parentFolder) {
                parentFolder.children.push(node);
            } else {
                console.warn(`Parent folder not found for table '${table.name}' - ${table.id}`);
                // Push to root to make it appear
                rootNode.children.push(node);
            }
        }
    });
}

function addFoldersToTree(rootNode, folderMap) {
    Object.values(folderMap).forEach((folder) => {
        const node = createNode(folder, NODE_TYPE.FOLDER, folder.children);
        const parentId = folder.parentFolderId;

        if (!parentId) {
            rootNode.children.push(node);
        } else {
            const parentFolder = folderMap[parentId];
            if (parentFolder) {
                parentFolder.children.push(node);
            } else if (folder.shareInfo?.resourceId === folder.id) {
                // the folder is directly shared, meaning that the user does not have access to parent folder
                rootNode.children.push(node);
            } else {
                console.warn(`Parent folder not found for folder '${folder.name} - ${folder.id}'`);
                // Push to root to make it appear
                rootNode.children.push(node);
            }
        }
    });
}

export function mergeArraysIntoTree(folders, tables) {
    const foldersMap = createFoldersMap(folders);
    const rootNode = createNode({id: null, name: "Root"}, NODE_TYPE.ROOT);

    addTablesToTree(rootNode, tables, foldersMap);
    addFoldersToTree(rootNode, foldersMap);

    return rootNode.children;
}
