import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {TextInput} from "src/primitives/controls/index.jsx";
import {overrideSystemHandling} from "src/utils/system.js";


export function PageForm({currentPage, totalPages, selectPage}) {
    const [isEditable, setIsEditable] = useState(false);
    const [tempPageNumber, setTempPageNumber] = useState(currentPage);

    const showForm = () => {
        setIsEditable(true);
    };

    const hideForm = () => {
        setIsEditable(false);
    };

    const handleChange = (e) => {
        setTempPageNumber(e.target.value);
    };

    const handleBlur = () => {
        hideForm();
        setTempPageNumber(currentPage);
    };

    const handleSubmit = (e) => {
        overrideSystemHandling(e);
        hideForm();

        const newNumber = parseInt(tempPageNumber, 10);
        if (!Number.isNaN(newNumber) && newNumber > 0 && newNumber <= totalPages) {
            selectPage(newNumber);
        }
    };

    useEffect(() => {
        setTempPageNumber(currentPage);
    }, [currentPage]);

    return (
        <>
            {isEditable ? (
                <form onSubmit={handleSubmit}>
                    <TextInput
                        type="number"
                        value={tempPageNumber.toString()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoFocus={true}
                        min="1"
                        max={totalPages.toString()}
                        style={{
                            width: 72,
                            textAlign: "center",
                            padding: "2px 0px",
                            fontSize: 12
                        }}
                    />
                </form>
            ) : (
                <button
                    onClick={showForm}
                    type="button"
                    className="py-2 px-3 rounded-md hover:bg-black/5 hover:font-bold"
                >
                    <span>
                        {currentPage} / {Number.isNaN(totalPages) ? "-" : totalPages}
                    </span>
                </button>
            )}
        </>
    );
}

PageForm.propTypes = {
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    selectPage: PropTypes.func
};
