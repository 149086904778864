import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Navigate} from "react-router-dom";
import {Button} from "src/primitives/button.jsx";
import {useDeleteWorkspace} from "src/api/workspaces/useDeleteWorkspace.js";
import {LINKS} from "src/links.js";
import {ConfirmDeletionDialog} from "src/primitives/modals/ConfirmDeletionDialog.jsx";
import {useBooleanState} from "src/utils/hooks/useBooleanState.jsx";


export function DeleteWorkspace({workspaceId}) {
    const [deleteWorkspace, {loading}] = useDeleteWorkspace(workspaceId);
    const [showConfirmDialog, show, hide] = useBooleanState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const handleDelete = () => {
        deleteWorkspace().then((result) => {
            const {ok, errorCode} = result;
            if (ok) {
                setIsDeleted(true);
            } else {
                throw new Error(errorCode);
            }
        }).catch((error) => {
            console.error(error);
        });
    };

    if (isDeleted) {
        return <Navigate to={LINKS.WORKSPACES}/>;
    }

    return (
        <>
            <Wrapper>
                <Button
                    onClick={show}
                    isLoading={loading}
                    theme="danger"
                >
                    Delete workspace
                </Button>
            </Wrapper>

            {showConfirmDialog && (
                <ConfirmDeletionDialog
                    close={hide}
                    title="Delete workspace"
                    confirm={handleDelete}
                    loading={loading}
                >
                    <div>
                        This action is non recoverable. Once you delete the workspace, it cannot be recovered.
                    </div>
                </ConfirmDeletionDialog>
            )}
        </>
    );
}

DeleteWorkspace.propTypes = {
    workspaceId: PropTypes.string
};

const Wrapper = styled.div`
`;
