import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Badge} from "src/primitives/badge.jsx";


export function ColumnHighlights({column}) {
    const {dataType, variableType} = column;

    return (
        <Wrapper className="flex gap-2">
            <Badge theme="green" width="md">
                {dataType}
            </Badge>
            {variableType && (
                <Badge theme="orange" width="lg">
                    {variableType}
                </Badge>
            )}
        </Wrapper>

    );
}

ColumnHighlights.propTypes = {
    column: PropTypes.shape({
        dataType: PropTypes.string,
        variableType: PropTypes.string
    })
};

const Wrapper = styled.div`
`;
