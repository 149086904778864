import {EVENTS} from "src/api/events/events.js";

export const OnboardingEventNames = {
    FILE_PROCESSED: "FILE_PROCESSED",
    DATA_FETCHED: "DATA_FETCHED",
    PUBLIC_LINK_ENABLED: "PUBLIC_LINK_ENABLED",
    TABLE_SHARED: "TABLE_SHARED",
    FOLDER_SHARED: "FOLDER_SHARED",
    COLUMN_DETAILS_VIEWED: EVENTS.COLUMN_DETAILS_VIEWED
};

export const ClientId = {
    WEB: "web",
    SDK: "python-sdk"
};
