/**
 * Source:
 * https://web.dev/articles/control-focus-with-tabindex
 * @type {{DOWN: number, LEFT: number, RIGHT: number, UP: number}}
 */

const KEYCODE = {
    LEFT: 37,
    RIGHT: 39,
    DOWN: 40,
    UP: 38
};

const DIRECTIONS = {
    LEFT: "LEFT",
    RIGHT: "RIGHT",
    DOWN: "DOWN",
    UP: "UP"
};


function getActiveElement() {
    return window.document.activeElement;
}

function getElement(row, col) {
    const query = `[data-row-index="${row}"][data-col-index="${col}"]`;
    return window.document.querySelector(query);
}

let allCells = null;

function getCells() {
    /**
     * Needs to be optimized!
     * We want to re-calculate existing cells when grid is changed.
     */
    // TODO: Optimize this..
    if (allCells) {
        return allCells;
    }
    allCells = Array.from(window.document.querySelectorAll(".cell"));
    return allCells;
}


function getIndexData(element) {
    const row = parseInt(element.dataset.rowIndex, 10);
    const col = parseInt(element.dataset.colIndex, 10);
    const maxRow = parseInt(element.dataset.maxRow, 10);
    const maxCol = parseInt(element.dataset.maxCol, 10);

    return {row, col, maxRow, maxCol};
}


function activate(item, direction) {
    /**
     * This is where the roving tabindex magic happens!
     */
    // Set all the cells to tabindex -1
    const cells = getCells();
    cells.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.tabIndex = -1;
    });

    // Make the current cell "active"
    // eslint-disable-next-line no-param-reassign
    item.tabIndex = 0;
    item.focus();

    // if item is on the edge, we need to force scrolling into view
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
    const {row, col, maxRow, maxCol} = getIndexData(item);
    if (direction === DIRECTIONS.UP && row === 0) {
        item.scrollIntoView(false);
    }
    if (direction === DIRECTIONS.DOWN && row === maxRow - 1) {
        item.scrollIntoView(false);
    }
    if (direction === DIRECTIONS.LEFT && col === 0) {
        item.scrollIntoViewIfNeeded();
    }
    if (direction === DIRECTIONS.RIGHT && col === maxCol - 1) {
        // item.scrollIntoView(false);
        item.scrollIntoViewIfNeeded();
    }
}

function focusRightItem(metaKey) {
    const element = getActiveElement();
    const {row, col, maxCol} = getIndexData(element);
    const nextCol = metaKey ? maxCol - 1 : col + 1;
    const nextElement = getElement(row, nextCol);
    if (nextElement) {
        activate(nextElement, DIRECTIONS.RIGHT);
    }
}

function focusLeftItem(metaKey) {
    const element = getActiveElement();
    const {row, col} = getIndexData(element);
    const nextCol = metaKey ? 0 : col - 1;
    const nextElement = getElement(row, nextCol);
    if (nextElement) {
        activate(nextElement, DIRECTIONS.LEFT);
    }
}

function focusDownItem(metaKey) {
    const element = getActiveElement();
    const {row, col, maxRow} = getIndexData(element);
    const nextRow = metaKey ? maxRow - 1 : row + 1;
    const downElement = getElement(nextRow, col);

    if (downElement) {
        activate(downElement, DIRECTIONS.DOWN);
    }
}

function focusUpItem(metaKey) {
    const element = getActiveElement();
    const {row, col} = getIndexData(element);
    const nextRow = metaKey ? 0 : row - 1;
    const upElement = getElement(nextRow, col);

    if (upElement) {
        activate(upElement, DIRECTIONS.UP);
    }
}


export function handelCellClick(event) {
    const cells = getCells();
    if (cells.indexOf(event.target) === -1) {
        return;
    }
    activate(event.target);
}


export function navigateOnKeyDown(event) {
    const {keyCode, metaKey} = event;
    switch (keyCode) {
    case KEYCODE.RIGHT:
        event.preventDefault();
        focusRightItem(metaKey);
        break;
    case KEYCODE.LEFT:
        event.preventDefault();
        focusLeftItem(metaKey);
        break;
    case KEYCODE.UP:
        event.preventDefault();
        focusUpItem(metaKey);
        break;
    case KEYCODE.DOWN:
        event.preventDefault();
        focusDownItem(metaKey);
        break;
    default:
    }
}


export function registerEventListeners(wrapper) {
    if (!wrapper) {
        return;
    }
    wrapper.addEventListener("keydown", navigateOnKeyDown);
    wrapper.addEventListener("click", handelCellClick);
}

export function removeEventListeners(wrapper) {
    if (!wrapper) {
        return;
    }
    wrapper.removeEventListener("keydown", navigateOnKeyDown);
    wrapper.removeEventListener("click", handelCellClick);
}
