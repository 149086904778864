import {useCallback, useEffect, useMemo} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import {formatQueryResult} from "src/api/utils.js";
import {QUERY_RESULT_FIELDS} from "src/api/fragments.js";


const QUERY = gql`
${QUERY_RESULT_FIELDS}
query GetData(
    $tableId: String!, 
    $sort: [SortOption!]!, 
    $filters: [FilterOption!], 
    $page: Int, 
    $q: String
) {
  getData (tableId: $tableId, sort: $sort, filters: $filters, page: $page, q: $q) {
    ... QueryResultFields
  }
}
`;


export function useGetTableData(tableId) {
    const options = {
        fetchPolicy: "cache-and-network"
    };
    const [query, {
        data, loading, error, refetch
    }] = useLazyQuery(QUERY, options);

    const result = useMemo(() => {
        if (!data || !data.getData) {
            return null;
        }
        return formatQueryResult(data.getData);
    }, [data]);

    const errors = useMemo(() => {
        if (!error) {
            return null;
        }
        // Generally we are only interested in the GraphqlErrors
        return error.graphQLErrors.map(({message}) => message);
    }, [error]);

    const makeQuery = useCallback(({q, sort, filters, page = 1} = {}) => {
        if (!Array.isArray(sort) || sort.length < 1) {
            // eslint-disable-next-line no-param-reassign
            sort = [{name: "_id", desc: false}];
        }
        if (!Array.isArray(filters) || filters.length < 1) {
            // eslint-disable-next-line no-param-reassign
            filters = null;
        }

        const variables = {tableId, sort, page, filters, q};

        return query({variables}).then((response) => {
            if (!response.data || !response.data.getData) {
                return null;
            }
            return formatQueryResult(response.data.getData);
        });
    }, [tableId, query]);

    return [makeQuery, {
        result, loading, errors, refetch
    }];
}

export function useTableData(tableId) {
    const [loadData, result] = useGetTableData(tableId);

    useEffect(() => {
        if (!result.result && !result.loading) {
            loadData();
        }
    }, [loadData, result]);

    return result;
}
