import React from "react";
import PropTypes from "prop-types";
import {useUpdateColumnDisplaySettings} from "src/api/tableColumns/useUpdateColumnDisplaySettings.js";
import {Options} from "src/primitives/controls/Options.jsx";
import {DisplayFormats} from "src/components/TableGrid/Cell/constants.js";
import {Title} from "src/primitives/title.jsx";


export function DisplayFormat({column}) {
    const {tableId, name, displaySettings} = column;
    const [updateDisplaySettings] = useUpdateColumnDisplaySettings(tableId, name);
    const displayFormat = displaySettings?.displayFormat || DisplayFormats.AUTO;

    const updateFormat = (newFormat) => {
        const settings = {displayFormat: newFormat};
        updateDisplaySettings(settings, displaySettings)
            .then((result) => {
                if (!result.ok) {
                    throw new Error(result.errorCode);
                }
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    const handleChange = (option) => {
        updateFormat(option.value);
    };

    return (
        <div>
            <div className="mb-2">
                <Title
                    size="xs"
                >
                    Display format
                </Title>
            </div>
            <div className="max-w-64">
                <Options
                    options={[
                        {value: DisplayFormats.AUTO, name: "Auto"},
                        {value: DisplayFormats.PLAIN, name: "Plain"}

                    ]}
                    selectedValue={displayFormat}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
}
DisplayFormat.propTypes = {
    column: PropTypes.shape({
        tableId: PropTypes.string,
        name: PropTypes.string,
        displaySettings: PropTypes.object
    })
};
