import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useGetAllResources} from "src/api/useGetAllResources.js";
import {EaseIn} from "src/primitives/EaseIn.jsx";
import {ContentGrid} from "./ContentGrid.jsx";


export function ContentList({folderId, workspaceId, empty}) {
    const {tables, folders, loading} = useGetAllResources(workspaceId);
    const relevantTables = tables.filter((item) => {
        return item.folderId === folderId;
    }).map((item) => {
        return Object.assign({}, item, {type: "TABLE"});
    });

    const relevantFolders = folders.filter((item) => {
        return item.parentFolderId === folderId;
    }).map((item) => {
        return Object.assign({}, item, {type: "FOLDER", size: {rows: null, bytes: null}});
    });

    return (
        <EaseIn loading={loading}>
            <Wrapper>
                {(relevantTables.length > 0 || relevantFolders.length > 0) ? (
                    <ContentGrid
                        tables={relevantTables}
                        folders={relevantFolders}
                    />
                ) : (
                    <Wrapper>
                        {empty}
                    </Wrapper>
                )}
            </Wrapper>
        </EaseIn>
    );
}


ContentList.propTypes = {
    workspaceId: PropTypes.string.isRequired,
    folderId: PropTypes.string,
    empty: PropTypes.node
};

ContentList.defaultProps = {
    empty: (<div>No data</div>)
};

const Wrapper = styled.div`
`;
