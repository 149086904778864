import React from "react";
import PropTypes from "prop-types";
import {FiUsers} from "react-icons/fi";
import {IconButton} from "src/primitives/button.jsx";
import {Overlay} from "src/primitives/Overlay/index.jsx";
import {ManageSharing} from "./ManageSharing.jsx";


export function ShareResource({resourceId, resourceType, data}) {
    return (
        <div>
            <Overlay
                align="right"
                content={(
                    <div className="w-112">
                        <ManageSharing
                            resourceId={resourceId}
                            data={data}
                            resourceType={resourceType}
                        />
                    </div>
                )}
            >
                <IconButton
                    size="xs"
                    theme="outlineBlack"
                    text="Share"
                    icon={<FiUsers/>}
                />
            </Overlay>
        </div>
    );
}

ShareResource.propTypes = {
    resourceId: PropTypes.string,
    resourceType: PropTypes.string,
    data: PropTypes.array
};
