import React from "react";
import publicLinkEnabled from "./assets/public_link_enabled.png";
import sharingHighlighted from "./assets/sharing_highlighted.png";
import {TextImage} from "./components.jsx";


export function PublicLinkInstructions() {
    return (
        <div className="flex flex-col gap-4 text-base">
            <div>
                To enable a public link, click on the <b>share</b> button on the top right corner of the table.
            </div>
            <div className="max-w-112 mx-auto">
                <TextImage
                    src={sharingHighlighted}
                    alt="share table"
                />
            </div>
            <div>
                In the dropdown menu, click the checkbox to enable the public link.
            </div>
            <div className="max-w-88 mx-auto">
                <TextImage
                    src={publicLinkEnabled}
                    alt="public link enabled"
                />
            </div>
            <div>
                The dataset is now available to everyone with the link.
            </div>
            <div>
                Please note that the public link only gives read access, so users will not be able to edit the data.
            </div>
        </div>
    );
}

PublicLinkInstructions.propTypes = {
};

