
export function randomInt(max = 20) {
    return Math.floor(Math.random() * max);
}

export function getArray(n = 20) {
    return new Array(n).fill(null);
}

export function getMockValues() {
    const array = getArray();

    return array.map(() => [randomInt(), randomInt()]);
}
