import React from "react";
import PropTypes from "prop-types";
import {Field, Listbox, ListboxButton, ListboxOption, ListboxOptions} from "@headlessui/react";
import {FiChevronDown, FiX} from "react-icons/fi";
import {cva} from "cva";
import {overrideSystemHandling} from "src/utils/system.js";
import {getState} from "src/primitives/controls/SelectInput/utils.js";
import {Label} from "../Label.jsx";
import {SelectOption} from "./option.jsx";


const button = cva({
    // eslint-disable-next-line max-len
    base: "w-full text-left text-sm relative py-2 pl-2 pr-10 rounded-md ring-1 ring-inset ring-neutral-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300"
});


const listboxOption = cva({
    base: "group select-none cursor-pointer",
    variants: {
        state: {
            normal: "text-neutral-900",
            active: "bg-amber-100 text-amber-900",
            disabled: "text-neutral-500"
        }
    },
    defaultVariants: {
        state: "normal"
    }
});


export function SelectInput({
    label,
    options,
    selectedOption,
    getValue,
    onChange,
    placeholder,
    renderOption,
    showClearIcon,
    isDisabled
}) {
    const handleChange = (newOption) => {
        onChange(newOption);
    };

    const clear = (e) => {
        overrideSystemHandling(e);
        onChange(null);
    };

    return (
        <Field className="w-full">
            {label && (
                <Label label={label}/>
            )}
            <Listbox value={selectedOption || ""} onChange={handleChange}>
                <ListboxButton className={button()}>
                    <span className="block truncate">
                        {selectedOption && (
                            <span>
                                {renderOption ? renderOption(selectedOption) : selectedOption.name}
                            </span>
                        )}
                        {!selectedOption && (
                            <span className="text-neutral-500">{placeholder}</span>
                        )}
                    </span>
                    {(selectedOption && showClearIcon) && (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,max-len
                        <span className="absolute inset-y-0 right-8 flex items-center" onClick={clear}>
                            <FiX
                                className="h-4 w-4 text-neutral-600 hover:text-neutral-800"
                                aria-hidden="true"
                            />
                        </span>
                    )}
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <FiChevronDown
                            className="h-5 w-5 text-neutral-600 hover:text-neutral-800"
                            aria-hidden="true"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions
                    // eslint-disable-next-line max-len
                    className="mt-1 max-h-60 min-w-72 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
                    transition={true}
                    anchor="bottom start"
                >
                    {options.map((option) => (
                        <ListboxOption
                            key={getValue(option)}
                            className={({active, disabled}) => listboxOption({
                                state: getState({active, disabled})
                            })}
                            value={option}
                            disabled={isDisabled(option)}
                        >
                            {({active, selected, disabled, checked}) => (
                                <SelectOption
                                    option={option}
                                    active={active}
                                    selected={selected}
                                    disabled={disabled}
                                    checked={checked}
                                    render={renderOption}
                                />
                            )}
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </Listbox>
        </Field>
    );
}


SelectInput.propTypes = {
    label: PropTypes.node,
    placeholder: PropTypes.string,
    selectedOption: PropTypes.object,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    getValue: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    renderOption: PropTypes.func,
    showClearIcon: PropTypes.bool,
    isDisabled: PropTypes.func
};

SelectInput.defaultProps = {
    placeholder: "Select",
    getValue: (item) => item.value,
    showClearIcon: true,
    isDisabled: () => false
};
