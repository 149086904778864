import {smartFormat} from "src/utils/formatting.js";

export function renderDistributionTooltip(params) {
    const closest = params[0];
    if (!closest) {
        return null;
    }

    const {marker} = closest;
    const [, frequency, lower, upper] = closest.value;

    return `
    <div class="flex items-center gap-2">
        ${marker}
        <div>
            <div class="flex flex-col gap-1">
                <span>
                     Frequency: <b>${smartFormat(frequency)}</b>
                </span>
            </div>
            <div>
                <span>
                    Range: [${smartFormat(lower)} - ${smartFormat(upper)}]        
                </span>
            </div>        
        </div>
    </div>
    `.trim();
}
