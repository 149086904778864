import React from "react";
import PropTypes from "prop-types";
import {Label} from "src/primitives/controls/Label.jsx";
import {SelectInput} from "src/primitives/controls/index.jsx";
import {REGRESSION_TYPES} from "src/components/ChartBuilder/constants.jsx";


export function SelectRegression({value, onChange, isDisabled}) {
    const options = [{
        value: REGRESSION_TYPES.LINEAR,
        name: "Linear"
    }, {
        value: REGRESSION_TYPES.EXPONENTIAL,
        name: "Exponential"
    }, {
        value: REGRESSION_TYPES.LOGARITHMIC,
        name: "Logarithmic"
    }, {
        value: REGRESSION_TYPES.POLYNOMIAL,
        name: "Polynomial"
    }];

    const selectedOption = options.find((item) => item.value === value);

    return (
        <div>
            <Label label="Show regression line"/>
            <SelectInput
                onChange={onChange}
                options={options}
                selectedOption={selectedOption}
                isDisabled={isDisabled}
            />
        </div>
    );
}

SelectRegression.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool
};
