
export const CHART_COLORS = [
    // https://tailwindcss.com/docs/customizing-colors
    "#2563eb", // blue 600
    "#65a30d", // lime 600
    "#ea580c", // orange 600
    "#7c3aed", // violet 600
    "#0284c7", // sky 600
    "#3f6212", // lime 800
    "#1e3a8a", // blue 900
    "#10b981", // emerald 500
    "#facc15", // yellow 400
    "#e11d48" // rose 600,
];
