import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation MakeContact($data: MakeContactInput!) {
  makeContact (data: $data) {
    ok
    errorCode
    messageId
  }
}
`;


export function useMakeContact() {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((message) => {
        return mutation({
            variables: {data: {message}}
        }).then((response) => {
            return response.data.makeContact;
        });
    }, [mutation]);

    return [doMutate, {loading, error, data}];
}
