import {useGetColumnDistributions} from "src/api/tableData/useGetColumnDistributions.js";
import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";
import {getNumericalColumnNames} from "src/utils/tableData.js";
import {useIsTableDataLoading} from "src/pages/Home/pages/TablePage/pages/TableGridPage/table_data_context.jsx";


export const DistributionsContext = createContext(null);

export function DistributionsContextProvider({children, tableId, columns}) {
    const loadingTableData = useIsTableDataLoading();
    // Hack to not load distributions until table data is loading
    const {data, loading} = useGetColumnDistributions(tableId, getNumericalColumnNames(columns), loadingTableData);

    return (
        <DistributionsContext.Provider value={{loading, data}}>
            {children}
        </DistributionsContext.Provider>
    );
}

DistributionsContextProvider.propTypes = {
    children: PropTypes.node,
    tableId: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

export function useColumnDistribution(columnName) {
    const {data, loading} = useContext(DistributionsContext);

    return {
        data: data ? data[columnName] : null,
        loading
    };
}
