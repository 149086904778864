import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {Badge} from "src/primitives/badge.jsx";
import {getIconForColumn} from "src/components/TableGrid/Header/utils.jsx";
import {FiMoreVertical} from "react-icons/fi";
import {useBooleanState} from "src/utils/hooks/useBooleanState.jsx";
import {ColumnMenu} from "src/components/TableGrid/Header/ColumnHeaderSecondary/components/ColumnMenu/index.jsx";
import {useAuthenticatedUser} from "src/pages/contexts/user_context.jsx";


export function ColumnHeaderSecondary({column}) {
    const authUser = useAuthenticatedUser();
    const {name, dataType, variableType} = column;
    const icon = getIconForColumn({dataType});
    const [isOpen, open, close] = useBooleanState(false);

    return (
        <Wrapper className="flex items-center gap-1 px-1">
            <IconWrapper
                icon={icon}
                size="xs"
                itemId={`column-data-type-${name}`}
                tooltip={`Data type is: ${dataType}`}
            />
            <div className="flex-1">
                {variableType && (
                    <Badge theme="orange">
                        {variableType}
                    </Badge>
                )}
            </div>

            {/* TODO: Better way to toggle features when logged in and not */}
            {authUser && (
                <>
                    <ColumnMenu
                        column={column}
                        isOpen={isOpen}
                        close={close}
                    />
                    <div className="text-right mr-1">
                        <button
                            type="button"
                            onClick={open}
                        >
                            <IconWrapper
                                icon={<FiMoreVertical/>}
                                size="xs"
                                isActive={isOpen}
                                clickable={true}
                            />
                        </button>
                    </div>
                </>
            )}
        </Wrapper>
    );
}

ColumnHeaderSecondary.propTypes = {
    column: PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataType: PropTypes.string.isRequired,
        variableType: PropTypes.string
    })
};

const Wrapper = styled.div`
  flex: 0 0 40px;
  border-bottom: 1px solid gray;
`;
