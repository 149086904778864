import React, {Fragment, memo, useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import {cva} from "cva";
import {Popover, Transition} from "@headlessui/react";
import {overrideSystemHandling} from "src/utils/system.js";


const popupStyle = cva({
    base: "absolute z-20 mt-3",
    variants: {
        align: {
            left: "left-0",
            right: "right-0",
            auto: "left-1/2 -translate-x-1/2 transform"
        }
    },
    defaultVariants: {
        align: "left"
    }
});

function InnerContent({header, content, footer, contentStyle}) {
    return (
        <>
            {header && (
                <div className="bg-neutral-100 rounded-t-lg py-2 px-3 text-neutral-600">
                    {header}
                </div>
            )}
            <div className="py-2 px-3 text-neutral-900" style={contentStyle}>
                {content}
            </div>
            {footer && (
                <div className="bg-neutral-100 rounded-b-lg py-2 px-3 text-neutral-700">
                    {footer}
                </div>
            )}
        </>
    );
}

InnerContent.propTypes = {
    content: PropTypes.node.isRequired,
    contentStyle: PropTypes.object,
    header: PropTypes.node,
    footer: PropTypes.node
};

const MemodInnerContent = memo(InnerContent);


export function Overlay({
    children,
    render,
    content,
    contentStyle,
    header,
    footer,
    onSubmit,
    disabled,
    align
}) {
    /**
     * https://headlessui.com/react/popover
     */

    const handleSubmit = useCallback((close, e) => {
        overrideSystemHandling(e);
        if (onSubmit) {
            onSubmit(close);
        }
    }, [onSubmit]);

    const useForm = useMemo(() => !!onSubmit, [onSubmit]);

    return (
        <Popover className="relative">
            {({open, close}) => (
                <>
                    <Popover.Button as="div" disabled={disabled}>
                        {render ? render({open}) : children}
                    </Popover.Button>

                    {!disabled && (
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className={popupStyle({align})}>
                                <div className="rounded-md shadow-lg bg-white ring-1 ring-black/5">
                                    {useForm ? (
                                        <form onSubmit={handleSubmit.bind(null, close)}>
                                            <MemodInnerContent
                                                header={header}
                                                content={content}
                                                footer={footer}
                                                contentStyle={contentStyle}
                                            />
                                        </form>
                                    ) : (
                                        <MemodInnerContent
                                            header={header}
                                            content={content}
                                            footer={footer}
                                            contentStyle={contentStyle}
                                        />
                                    )}
                                </div>
                            </Popover.Panel>
                        </Transition>
                    )}
                </>
            )}
        </Popover>
    );
}

Overlay.propTypes = {
    children: PropTypes.node,
    render: PropTypes.func,
    content: PropTypes.node.isRequired,
    contentStyle: PropTypes.object,
    header: PropTypes.node,
    footer: PropTypes.node,
    onSubmit: PropTypes.func,
    disabled: PropTypes.bool,
    align: PropTypes.string
};

Overlay.defaultProps = {
    align: "left"
};
