import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {CHART_COLORS} from "src/components/ChartBuilder/colors.js";
import {formatPercent} from "src/utils/formatting.js";
import {Title} from "src/primitives/title.jsx";


export function ChartLegend({title, treeData, totalValue, totalItems}) {
    return (
        <Wrapper className="p-4 w-96">
            {title && (
                <div className="ml-[-8px]">
                    <Title size="xs" style={{margin: 9}}>
                        {title}
                    </Title>
                </div>
            )}
            <div className="flex flex-col gap-2 text-sm">
                {treeData.map((item, index) => (
                    <div
                        key={item.name}
                        className="flex gap-1 rounded-md text-white px-2 py-1"
                        style={{background: CHART_COLORS[index]}}
                    >
                        <div>
                            {item.name}:
                        </div>
                        <div className="font-bold">
                            {item.value}
                        </div>
                        <div>
                            ({formatPercent(item.value / totalValue)})
                        </div>
                    </div>
                ))}

                {totalItems > treeData.length && (
                    <div className="px-2 py-1">
                        {totalItems - treeData.length} more...
                    </div>
                )}
            </div>
        </Wrapper>
    );
}

ChartLegend.propTypes = {
    title: PropTypes.node,
    treeData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        path: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.number
    })),
    totalValue: PropTypes.number,
    totalItems: PropTypes.number
};

const Wrapper = styled.div`
`;
