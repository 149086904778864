import React from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {getMockValues} from "src/charts/mocks.js";
import {getDefaultTextStyle} from "src/charts/options.js";


function getOption(values, options) {
    const {showLabel, showTooltip, showY, showXLabel, xFormatter} = options;

    return {
        textStyle: getDefaultTextStyle(),
        grid: {
            top: "5%",
            left: showY ? "20%" : "5%",
            width: showY ? "75%" : "90%",
            bottom: showXLabel ? "20%" : "5%"
        },
        dataset: [{
            source: values
        }, {
            transform: {
                type: "ecStat:histogram",
                config: {
                    // method: "scott",
                    method: "sturges"
                }
            }
        }, {
            transform: {
                type: "ecStat:histogram",
                config: {
                    // method: "scott",
                    method: "sturges",
                    dimensions: [1]
                }
            }
        }],
        tooltip: {
            show: showTooltip
        },
        xAxis: [{
            scale: true,
            gridIndex: 0,
            axisLabel: {
                show: showXLabel,
                formatter: xFormatter
            }
        }],
        yAxis: [{
            gridIndex: 0,
            show: showY
        }],
        series: [{
            name: "histogram",
            type: "bar",
            barWidth: "40%",
            label: {
                show: showLabel,
                position: "top"
            },
            encode: {x: 0, y: 1},
            datasetIndex: 1
        }, {
            name: "histogram 2",
            type: "bar",
            barWidth: "40%",
            label: {
                show: showLabel,
                position: "top"
            },
            encode: {x: 0, y: 1},
            datasetIndex: 2
        }]
    };
}


export function HistogramMulti({
    values, height, showTooltip, showLabel, showY, showXLabel, xFormatter
}) {
    /**
     * https://echarts.apache.org/examples/en/editor.html?c=bar-histogram
     * https://github.com/ecomfe/echarts-stat#histogram
     */

    const option = getOption(
        values,
        {showLabel, showTooltip, showY, showXLabel, xFormatter}
    );

    return (
        <ChartWrapper option={option} height={height}/>
    );
}

HistogramMulti.propTypes = {
    values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    height: PropTypes.number,
    showTooltip: PropTypes.bool,
    showLabel: PropTypes.bool,
    showY: PropTypes.bool,
    showXLabel: PropTypes.bool,
    xFormatter: PropTypes.func
};

HistogramMulti.defaultProps = {
    values: getMockValues(),
    showTooltip: true,
    showLabel: false,
    showY: true,
    showXLabel: true,
    // https://echarts.apache.org/en/option.html#xAxis.axisLabel.formatter
    xFormatter: null
};
