import {useMemo} from "react";
import {gql, useFragment, useLazyQuery, useQuery} from "@apollo/client";
import {CORE_TABLE_FIELDS} from "../fragments.js";


export const GET_TABLES_QUERY = gql`
${CORE_TABLE_FIELDS}
query GetTables ($id: String!) {
  workspace (workspaceId: $id) {
    tables {
      ... CoreTableFields
    }
  }
}
`;


export function useGetTables(workspaceId) {
    const options = {
        pollInterval: 0,
        variables: {id: workspaceId}
    };
    const {
        data, loading, error, refetch
    } = useQuery(GET_TABLES_QUERY, options);

    const tables = useMemo(() => {
        return data?.workspace?.tables ? data.workspace.tables : [];
    }, [data]);

    return {
        tables, loading, error, refetch
    };
}

export function useGetTablesLazy() {
    const options = {
        fetchPolicy: "network-only"
    };
    const [query, {data, loading, error}] = useLazyQuery(GET_TABLES_QUERY, options);

    const tables = useMemo(() => {
        return data?.workspace?.tables ? data.workspace.tables : [];
    }, [data]);

    return [query, {tables, loading, error}];
}


export function useReadTable(tableId) {
    const object = {
        id: tableId,
        __typename: "Table"
    };

    const result = useFragment({from: object, fragment: CORE_TABLE_FIELDS});

    return {
        table: result.data,
        exists: result.complete
    };
}
