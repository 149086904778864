import {useCallback, useMemo} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
mutation RemoveUniqueConstraint ($tableId: String!, $column: String!) {
  removeUniqueConstraint(tableId: $tableId, column: $column) {
    ok
    errorCode
    table {
      id
      uniqueConstraints
    }
  }
}
`;

export function useRemoveUniqueConstraint(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((column) => {
        return mutation({
            variables: {tableId, column}
        }).then((response) => {
            return response.data.removeUniqueConstraint;
        });
    }, [mutation, tableId]);

    const result = useMemo(() => {
        return data?.removeUniqueConstraint;
    }, [data]);

    return [doMutate, {loading, error, result}];
}
