import React from "react";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiExternalLink} from "react-icons/fi";
import {LINKS} from "src/links.js";
import {NavLink} from "react-router-dom";
import {useGetUser} from "src/api/users/useGetUser.js";
import {buttonClasses} from "./styles.jsx";

export function AdminLink() {
    const {user, loading} = useGetUser();

    if (loading || !user?.isAdmin) {
        return null;
    }

    return (
        <div className="mb-1 text-sm">
            <NavLink to={LINKS.ADMIN}>
                {({isActive}) => (
                    <div className={buttonClasses({state: isActive ? "active" : ""})}>
                        <IconWrapper
                            icon={<FiExternalLink />}
                            size="xs"
                            style={{marginRight: 4}}
                        />
                        <div>
                            Admin
                        </div>
                    </div>
                )}
            </NavLink>
        </div>
    );
}
