
function getParentElement() {
    // return root element
    return window.document.getElementById("tg-body");
}


function highlightTermInElement(term, element) {
    const text = element.innerText;
    const regex = new RegExp(`(${term})`, "gi");
    // Check if the element's text contains the search term
    if (text.match(regex)) {
        // Split the text into parts (matched and unmatched)
        const parts = text.split(regex);
        // Create a new HTML structure with the matched term highlighted
        // Replace the original text with the highlighted version

        // eslint-disable-next-line no-param-reassign
        element.innerHTML = parts.map((part) => {
            if (part.match(regex)) {
                return `<span class="text-highlight">${part}</span>`;
            }
            return part;
        }).join("");
    }
}

export function highlightText(searchTerm) {
    /**
     * Source:
     * https://medium.com/@stheodorejohn/custom-javascript-function-to-highlight-text-when-a-searched-term-appears-6acd12dd1164
     * @type {HTMLElement}
     */
    // Get all elements containing text
    const tableBody = getParentElement();
    if (!tableBody) {
        return;
    }
    const elements = tableBody.querySelectorAll(".cell.text");
    const terms = searchTerm.split(" ");

    elements.forEach((element) => {
        terms.forEach((term) => {
            highlightTermInElement(term, element);
        });
    });
}

export function clearHighlightedText() {
    const tableBody = getParentElement();
    if (!tableBody) {
        return;
    }
    const className = "text-highlight";
    const elements = tableBody.querySelectorAll(`.${className}`);
    elements.forEach((element) => {
        element.classList.remove(className);
    });
}
