import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {DataGrid} from "src/components/DataGrid/index.jsx";
import {RemoveMemberCell} from "./RemoveMemberCell.jsx";


const columns = [{
    id: "email",
    header: "Email"
}, {
    id: "status",
    header: "Status"
}, {
    id: "id",
    header: " ",
    render: (value, original) => (
        <RemoveMemberCell
            memberId={value}
            member={original}
        />
    )
}];


export function MemberTable({members}) {
    return (
        <Wrapper>
            <DataGrid
                data={members}
                columns={columns}
            />
        </Wrapper>
    );
}

MemberTable.propTypes = {
    members: PropTypes.arrayOf(PropTypes.shape({
        email: PropTypes.string,
        status: PropTypes.string,
        user: PropTypes.shape({
            email: PropTypes.string
        })
    }))
};

const Wrapper = styled.div`
`;
