import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Button} from "src/primitives/button.jsx";
import {formatDate} from "src/utils/formatting.js";


export function WorkspaceItem({workspace, selectWorkspace}) {
    return (
        <Wrapper>
            <div
                className={`
                p-2 flex items-center justify-between rounded-lg border-2 border-gray-500
                hover:border-gray-700 hover:bg-gray-50 cursor-pointer
                `.trim()}
                role="button"
                tabIndex={0}
                onClick={() => selectWorkspace(workspace.id)}
            >
                <div className="flex flex-col">
                    <span>
                        {workspace.name}
                    </span>
                    <span className="text-xs text-gray-500">
                        Created {formatDate(workspace.createdAt)}
                    </span>
                </div>
                <div>
                    <Button
                        theme="secondary"
                        size="small"
                    >
                        Select
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
}

WorkspaceItem.propTypes = {
    workspace: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        createdAt: PropTypes.string
    }),
    selectWorkspace: PropTypes.func
};


const Wrapper = styled.div`
  margin: 12px 0;
`;
