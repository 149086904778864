import {formatDate, formatInteger, formatNumber, formatPercent} from "src/utils/formatting.js";
import {SemanticTypes, VariableTypes} from "src/utils/tableData.js";
import {coalesceNumber} from "src/utils/misc.js";
import {DisplayFormats} from "./constants.js";


export function renderBoolean(value) {
    if (value === true) {
        return "True";
    }

    if (value === false) {
        return "False";
    }

    return null;
}

export const DEFAULT_DECIMALS = 2;


export function renderPercentage(value, displaySettings) {
    if (value === null) {
        return null;
    }
    const decimals = coalesceNumber(displaySettings?.decimals, DEFAULT_DECIMALS);
    return formatPercent(value, decimals);
}


export function renderValue(value, displaySettings) {
    const decimals = coalesceNumber(displaySettings?.decimals, DEFAULT_DECIMALS);

    if (displaySettings?.displayFormat === DisplayFormats.PLAIN) {
        return value;
    }

    if (typeof value === "number") {
        if (!displaySettings?.decimals) {
            // decimals no specified, so use default
            if (value === parseInt(value, 10)) {
                // Integer!
                return formatInteger(value);
            }
        }
        return formatNumber(value, decimals);
    }

    if (value instanceof Date) {
        return formatDate(value);
    }

    if (typeof value === "boolean") {
        return renderBoolean(value);
    }

    return value;
}

export const RENDER_FUNCTIONS = {
    [VariableTypes.IDENTIFIER]: (v) => v,
    [SemanticTypes.PERCENTAGE]: renderPercentage
};
