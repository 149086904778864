import React from "react";
import PropTypes from "prop-types";
import {useGetCategoricalRelations} from "src/api/tableData/useGetCategoricalRelations.js";
import {VariableTypes} from "src/utils/tableData.js";
import {EaseIn} from "src/primitives/EaseIn.jsx";
import {LargeCard} from "src/pages/Home/pages/TablePage/components/LargeCard.jsx";
import {Title} from "src/primitives/title.jsx";
import {ChartLegend} from "./components/CategoriesTreeMap/Legend.jsx";
import {CategoriesTreeMap} from "./components/CategoriesTreeMap/index.jsx";
import {formatDataSafeValues, transformData} from "./components/CategoriesTreeMap/data.js";


export function CategoricalRelationsAnalysis({tableId, columns}) {
    const MAX_ITEMS = 10;
    const {data, loading} = useGetCategoricalRelations(tableId);

    const categoricalVisibleColumns = columns.filter(({variableType}) => {
        return variableType === VariableTypes.CATEGORICAL;
    });

    // categoricalVisibleColumns.reverse();
    const names = categoricalVisibleColumns.map(({name}) => name);

    const safeData = formatDataSafeValues(data, names);
    // const filteredData = safeData.filter((item) => item.hasNull === false);
    const tree = transformData(safeData, names);
    const totalValue = tree.reduce((acc, item) => acc + item.value, 0);
    const totalRootItems = tree.length;
    const selectedTree = tree.slice(0, MAX_ITEMS);

    return (
        <LargeCard
            title="Categorical distributions"
        >
            <EaseIn loading={loading}>
                {categoricalVisibleColumns.length === 0 && (
                    <div>
                        No categorical columns in table.
                    </div>
                )}

                {categoricalVisibleColumns.length > 0 && (
                    <div className="flex flex-col gap-2 pr-4">
                        <div className="flex">
                            <CategoriesTreeMap
                                treeData={selectedTree}
                                totalValue={totalValue}
                            />
                            <ChartLegend
                                title={names[0]}
                                treeData={selectedTree}
                                totalValue={totalValue}
                                totalItems={totalRootItems}
                            />
                        </div>
                        <div className="flex justify-center">
                            <Title size="xs" style={{margin: 0}}>
                                {names.join(" / ")}
                            </Title>
                        </div>
                    </div>
                )}
            </EaseIn>
        </LargeCard>
    );
}

CategoricalRelationsAnalysis.propTypes = {
    tableId: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        variableType: PropTypes.string
    }))
};

CategoricalRelationsAnalysis.defaultProps = {
    columns: []
};
