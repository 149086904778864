import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation SendEvent ($data: EventInput!) {
  sendEvent (data: $data) {
    ok
    errorCode
  }
}
`;


export function useSendEvent(workspaceId, name) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(({tableId, data}) => {
        const variables = {
            data: {
                workspaceId,
                name,
                tableId,
                data
            }
        };
        return mutation({variables}).then((response) => {
            return response.data.sendEvent;
        });
    }, [mutation, workspaceId, name]);

    return [doMutate, {loading, error}];
}
