import React from "react";
import {RhoImg} from "src/primitives/Logo/index.jsx";
import {Link} from "react-router-dom";
import {LINKS} from "src/links.js";
import {Badge} from "src/primitives/badge.jsx";
import {useGetLink} from "src/utils/useGetLink.js";


export function SidebarHeader() {
    const getLink = useGetLink();

    return (
        <div className="full flex-center">
            <RhoImg/>
            <Link to={getLink(LINKS.WORKSPACE)}>
                <span className="hover:underline">rho.store</span>
            </Link>
            <div className="flex-grow flex items-center justify-end mr-2">
                <Badge theme="orangeOutline" size="xs">
                    <span className="monospace">
                        beta
                    </span>
                </Badge>
            </div>
        </div>
    );
}
