import React from "react";
import PropTypes from "prop-types";
import {SmoothLineChart} from "src/charts/SmoothLineChart.jsx";
import {formatDistributionToLine} from "src/components/TableGrid/Header/ColumnFilter/components/utils.js";
import {CHART_COLORS} from "src/charts/colors.js";
import {renderDistributionTooltip} from "./distributionTooltip.jsx";


export function ColumnDistribution({
    distribution,
    height,
    showXLabel,
    colors,
    xMin,
    xMax,
    showTooltip
}) {
    if (!distribution) {
        return null;
    }

    // const data = formatDistributionToLine(distribution);

    const chartOptions = {
        showXLabel,
        colors,
        tooltipFormat: showTooltip ? renderDistributionTooltip : null,
        // xMin: xMin || data[0]?.lower,
        // xMax: xMax || data[data.length - 1]?.upper,
        xMin,
        xMax
    };

    return (
        <SmoothLineChart
            height={height}
            options={chartOptions}
            data={formatDistributionToLine(distribution)}
        />
    );
}

ColumnDistribution.propTypes = {
    distribution: PropTypes.array,
    height: PropTypes.number,
    showXLabel: PropTypes.bool,
    showTooltip: PropTypes.bool,
    colors: PropTypes.arrayOf(PropTypes.string),
    xMin: PropTypes.number,
    xMax: PropTypes.number
};

ColumnDistribution.defaultProps = {
    height: 200,
    showXLabel: true,
    showTooltip: true,
    colors: [CHART_COLORS.lime]
};
