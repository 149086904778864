import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {Menu} from "@headlessui/react";
import {Link} from "react-router-dom";
import {cva} from "cva";
import {IconWrapper} from "src/primitives/Icon.jsx";


const menuItemStyles = cva({
    base: "px-3 w-full text-sm flex items-center gap-2 transition-all",
    variants: {
        state: {
            normal: "text-neutral-800",
            active: "bg-neutral-100 text-neutral-950",
            disabled: "text-neutral-400 hover:text-neutral-400",
            selected: "text-orange-600"
        },
        size: {
            small: "py-1",
            medium: "py-2"
        },
        order: {
            first: "rounded-t-md",
            last: "rounded-b-md"
        }
    },
    defaultVariants: {
        state: "normal",
        size: "medium"
    }
});


function getState({isDisabled, isSelected, isActive}) {
    if (isDisabled) {
        return "disabled";
    }
    if (isSelected) {
        return "selected";
    }
    if (isActive) {
        return "active";
    }

    return "normal";
}

function DropdownMenuItem({
    item,
    size,
    isActive,
    isDisabled,
    isSelected,
    order
}) {
    const state = getState({isDisabled, isActive, isSelected});

    if (item.link) {
        return (
            <Link
                to={item.link}
                onClick={item.action}
                className={menuItemStyles({size, state, order})}
            >
                {item.icon && (
                    <IconWrapper size="small" icon={item.icon}/>
                )}
                {item.title}
            </Link>
        );
    }

    return (
        <button
            type="button"
            onClick={item.action}
            className={menuItemStyles({size, state, order})}
            disabled={isDisabled}
        >
            {item.icon && (
                <IconWrapper size="small" icon={item.icon}/>
            )}
            <div>
                {item.title}
            </div>
        </button>
    );
}


DropdownMenuItem.propTypes = {
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    item: PropTypes.shape({
        title: PropTypes.node,
        link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        icon: PropTypes.node,
        action: PropTypes.func
    }),
    size: PropTypes.string,
    order: PropTypes.oneOf(["first", "last"])
};

const menuItemsStyle = cva({
    // eslint-disable-next-line max-len
    base: "absolute z-10 right-0 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none",
    variants: {
        size: {
            small: "w-20",
            medium: "w-52"
        },
        position: {
            right: "right-0",
            left: "left-0"
        }
    },
    defaultVariants: {
        size: "medium",
        position: "right"
    }
});

export function Dropdown({
    children,
    options,
    size,
    render,
    onSelect,
    isSelectedItem,
    position
}) {
    const getOrder = useCallback((index) => {
        if (index === 0) {
            return "first";
        }

        if (index === options.length - 1) {
            return "last";
        }
        return null;
    }, [options]);

    const anchorX = position === "right" ? "end" : "start";

    return (
        <Menu as="div" className="relative">
            {({open}) => (
                <>
                    <div>
                        <Menu.Button as="div" className="cursor-pointer">
                            {render ? render({open}) : children}
                        </Menu.Button>
                    </div>
                    <Menu.Items
                        anchor={`bottom ${anchorX}`}
                        className={menuItemsStyle({size, position})}
                    >
                        <div className="divide-y divide-neutral-200">
                            {options.map((item, index) => (
                                <Menu.Item key={item.title}>
                                    {({active}) => (
                                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                        <div onClick={onSelect.bind(null, item)}>
                                            <DropdownMenuItem
                                                item={item}
                                                size={size}
                                                isActive={active}
                                                isDisabled={item.isDisabled}
                                                isSelected={isSelectedItem(item)}
                                                order={getOrder(index)}
                                            />
                                        </div>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </>
            )}
        </Menu>
    );
}

Dropdown.propTypes = {
    children: PropTypes.node,
    render: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.node,
        link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        icon: PropTypes.node,
        action: PropTypes.func,
        isDisabled: PropTypes.bool
    })),
    onSelect: PropTypes.func,
    isSelectedItem: PropTypes.func,
    size: PropTypes.string,
    position: PropTypes.oneOf(["right", "left"])
};


Dropdown.defaultProps = {
    options: [{
        title: "Home",
        link: "/"
    }, {
        title: "Google",
        link: "www.google.com"
    }],
    children: <span>Menu</span>,
    onSelect: () => null,
    isSelectedItem: () => false
};
