import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {CellBaseClass} from "src/components/TableGrid/components.jsx";


export function RowId({rowId}) {
    return (
        <Wrapper>
            {rowId}
        </Wrapper>
    );
}
RowId.propTypes = {
    rowId: PropTypes.number
};

const Wrapper = styled(CellBaseClass)`
  height: 100%;
  border: none;
  background: rgb(242 242 242);
  color: rgb(120 115 115);
  border-bottom: 1px solid #dadada;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 8px;
  border-right: 1px solid #788378;
`;
