import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Skeleton, Spinner, Text} from "@chakra-ui/react";
import {round} from "mathjs";
import {useMakeQuery, usePreview} from "src/api/data/useMakeQuery.js";
import {QueryEditor} from "src/pages/Home/pages/TablePage/components/QueryEditor/index.jsx";
import {QueryResult} from "src/pages/Home/pages/TablePage/components/QueryResult/index.jsx";
import {useTableActions} from "src/pages/Home/pages/TablePage/pages/TableOverviewPage/useTableActions.js";
import {useUpdateData} from "src/api/tables/useUpdateData.js";
import {CenteredContent, FlexStack} from "src/primitives/layout";


export function TableOverviewPage({tableId, table}) {
    const [makeQuery, {result, loading, errors, refetch}] = useMakeQuery();
    const previewResult = usePreview(tableId);
    const [updateData] = useUpdateData(tableId);

    const onEdit = (data) => {
        updateData(data);
    };

    const onCompleted = () => {
        if (result) {
            refetch();
        }
        previewResult.refetch();
    };

    const [tableActions] = useTableActions(tableId, {onCompleted});

    return (
        <Wrapper>
            <EditorWrapper style={{display: "none"}}>
                <QueryEditor
                    submit={makeQuery}
                    tableName={table?.name}
                    workspaceId={table?.workspaceId}
                />
                <EditorFooter>
                    <Skeleton isLoaded={!loading}>
                        <Text fontSize="xs">
                            {result && (
                                <span>
                                    Fetched <b>{result.rows.length}</b> rows
                                    in <b>{round(result.time, 3)}</b> seconds.
                                </span>
                            )}
                            {(!result && previewResult?.result) && (
                                <span>
                                    Fetched <b>{previewResult.result.rows.length}</b> rows in
                                    <b>{round(previewResult.result.time, 3)}</b> seconds.
                                </span>
                            )}
                            {errors && (
                                <span>Error</span>
                            )}
                        </Text>
                    </Skeleton>
                </EditorFooter>
            </EditorWrapper>

            {errors && (
                <div>
                    {errors.map((errorMessage) => (
                        <div key={errorMessage}>
                            {errorMessage}
                        </div>
                    ))}
                </div>
            )}

            {(previewResult?.loading || loading) && (
                <CenteredContent style={{height: 200}}>
                    <Spinner size="lg"/>
                </CenteredContent>
            )}

            {(!errors && !loading && !previewResult?.loading) && (
                <FlexStack>
                    {result ? (
                        // Query result
                        <QueryResult result={result}/>
                    ) : (
                        // Preview
                        <QueryResult
                            result={previewResult?.result}
                            columns={table?.columns}
                            statsMap={table?.statsMap}
                            actions={tableActions}
                            onEdit={onEdit}
                        />
                    )}
                </FlexStack>
            )}
        </Wrapper>
    );
}

TableOverviewPage.propTypes = {
    tableId: PropTypes.string.isRequired,
    table: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        workspaceId: PropTypes.string,
        columns: PropTypes.array,
        statsMap: PropTypes.object
    })
};

const Wrapper = styled(FlexStack)`
`;

const EditorWrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 8px;
`;

const EditorFooter = styled.div`
  height: 24px;
  text-align: center;
  background-color: rgb(228 228 228);
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
