import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {SelectInput} from "src/primitives/controls";
import {isColumnDate, isColumnNumeric, isColumnIdentifier} from "src/utils/tableData.js";
import {Title} from "src/primitives/title.jsx";


export function TimeSeriesOptions({columns, timeColumn, valueColumn, onChange}) {
    const timeColumns = columns.filter((column) => isColumnDate(column));
    const valueColumns = columns.filter((column) => isColumnNumeric(column) && !isColumnIdentifier(column));

    const selectedTimeColumn = timeColumns.find(({name}) => name === timeColumn);
    const selectedValueColumn = valueColumns.find(({name}) => name === valueColumn);

    const handleChangeTimeColumn = (option) => {
        onChange({timeColumn: option?.name});
    };

    const handleChangeValueColumn = (option) => {
        onChange({valueColumn: option?.name});
    };

    return (
        <Wrapper className="flex items-center gap-8">
            <div className="flex flex-col">
                <Title size="xs">
                    Time dimension
                </Title>
                <SelectInput
                    onChange={handleChangeTimeColumn}
                    options={timeColumns}
                    selectedOption={selectedTimeColumn}
                    getValue={(option) => option.name}
                />
            </div>
            <div className="flex flex-col gap-2">
                <Title size="xs">
                    Value dimension
                </Title>
                <SelectInput
                    onChange={handleChangeValueColumn}
                    options={valueColumns}
                    selectedOption={selectedValueColumn}
                    getValue={(option) => option.name}
                />
            </div>
        </Wrapper>
    );
}

TimeSeriesOptions.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    })),
    timeColumn: PropTypes.string,
    valueColumn: PropTypes.string,
    onChange: PropTypes.func
};

const Wrapper = styled.div`
`;
