import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {useConvertPercentageColumn} from "src/api/tableColumns/useConvertPercentageColumn.js";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {Button} from "src/primitives/button.jsx";
import {DataTypes} from "src/utils/tableData.js";


export function EditColumnDataType({tableId, column}) {
    const [convertColumnToPercentage, {loading}] = useConvertPercentageColumn(tableId);
    const {showLoading, showSuccess, showError} = useShowToast();

    const doConvertColumnToPercentage = useCallback(() => {
        showLoading();
        convertColumnToPercentage(column.name)
            .then((result) => {
                if (!result.ok) {
                    throw new Error(result.errorCode);
                }
                showSuccess({message: "Column converted to percentage"});
            })
            .catch((error) => {
                showError(error);
            });
    }, [convertColumnToPercentage, column.name, showError, showSuccess, showLoading]);

    return (
        <div>
            <div className="flex flex-col gap-4">
                <div>
                    <div className="mb-2">
                        <p>Text columns can be converted to percentage columns.</p>
                    </div>
                    <Button
                        size="xs"
                        onClick={doConvertColumnToPercentage}
                        isDisabled={column.dataType !== DataTypes.STRING}
                        isLoading={loading}
                    >
                        Convert to percentage
                    </Button>
                </div>
            </div>
        </div>
    );
}

EditColumnDataType.propTypes = {
    tableId: PropTypes.string.isRequired,
    column: PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataType: PropTypes.oneOf(Object.values(DataTypes))
    })
};
