import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
mutation RefreshWorkspace($workspaceId: String!) {
  refreshWorkspace (workspaceId: $workspaceId) {
    ok
    errorCode
    workspace {
      id
      usage {
        totalTables
        totalBytes
      }
    }
  }
}
`;


export function useRefreshWorkspace(workspaceId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        const variables = {workspaceId};
        const options = {variables};
        return mutation(options).then((response) => {
            return response.data.refreshWorkspace;
        });
    }, [mutation, workspaceId]);

    return [doMutate, {loading, error}];
}
