import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Title} from "src/primitives/title.jsx";
import {SmoothLineChart} from "src/charts/SmoothLineChart.jsx";
import {BoxPlot} from "src/charts/BoxPlot/index.jsx";
import {isColumnContinuous, isColumnNumeric} from "src/utils/tableData.js";
import {ColumnMenu} from "./components/ColumnMenu/index.jsx";
import {ColumnStatistics} from "./components/ColumnStatistics/index.jsx";
import {ColumnVisualization} from "./components/ColumnVisualization/index.jsx";


export function ColumnProfile({tableId, column, columnStats, values, distribution}) {
    const [showMore, setShowMore] = useState(false);
    const showDistribution = false;
    const {name} = column;

    if (!values) {
        // bad data
        console.log("No data for column: ", column);
        return null;
    }

    return (
        <Wrapper className="p-4 mb-4 rounded-md">
            <div className="flex items-center justify-between border-b border-gray-200 mb-4 pb-2">
                <Title size="small" style={{margin: 0}}>
                    {name}
                </Title>
                <ColumnMenu
                    tableId={tableId}
                    column={column}
                    columnStats={columnStats}
                    showMore={showMore}
                    setShowMore={setShowMore}
                />
            </div>

            <div className="flex justify-between">
                <Left>
                    {columnStats && (
                        <ColumnStatistics
                            column={column}
                            columnStats={columnStats}
                        />
                    )}
                </Left>

                <Right>
                    <ColumnVisualization
                        column={column}
                        columnStats={columnStats}
                        values={values}
                    />
                </Right>
            </div>

            {showMore && (
                <div className="my-4">
                    {(isColumnNumeric(column) && isColumnContinuous(column)) && (
                        <div>
                            <BoxPlot data={columnStats}/>
                        </div>
                    )}
                </div>
            )}

            {(distribution && showDistribution) && (
                <div>
                    <SmoothLineChart
                        data={distribution.map((bucket) => {
                            const bucketSize = bucket.upper - bucket.lower;
                            return {
                                x: bucket.lower + bucketSize / 2.0,
                                y: bucket.frequency
                            };
                        })}
                    />
                </div>
            )}
        </Wrapper>
    );
}

ColumnProfile.propTypes = {
    tableId: PropTypes.string,
    column: PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    }),
    columnStats: PropTypes.object,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    distribution: PropTypes.arrayOf(PropTypes.object)
};

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.015);
`;

const Left = styled.div`
  flex: 0 0 48%;
  display: flex;
  flex-direction: column;
`;

const Right = styled.div`
  flex: 0 0 48%;
`;
