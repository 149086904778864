import React from "react";
import PropTypes from "prop-types";
import {Tooltip} from "react-tooltip";
import {formatPercent} from "src/utils/formatting.js";


export function ColumnCompleteness({columnStats, tableId, columnName}) {
    const id = `cmplts-${tableId}-${columnName}`;
    const {rows, values, distinct} = columnStats;
    const percentText = formatPercent(values / rows, 2);
    const uniquePercentText = formatPercent(distinct / values, 2);

    return (
        <>
            <div
                id={id}
                className="w-28 h-6 relative rounded-md bg-blue-200/40 outline outline-blue-200 mr-1"
            >
                <div
                    className="rounded-md absolute h-full left-0 top-0 bg-blue-200"
                    style={{width: percentText}}
                />
                <div className="absolute w-full h-full flex items-center justify-center z-10">
                    <span className="text-xs text-blue-950 monospace">
                        {percentText}
                    </span>
                </div>
            </div>

            <Tooltip
                anchorSelect={`#${id}`}
                style={{zIndex: 10}}
                delayShow={250}
                delayHide={50}
                place="bottom"
            >
                <div>
                    <ul className="list-disc ml-3 text-sm">
                        <li className="mb-1">
                            {values === rows ? (
                                <span>{percentText} of rows have values</span>
                            ) : (
                                <span>{values} of {rows} rows have a value ({percentText})</span>
                            )}
                        </li>
                        <li>
                            {distinct === values ? (
                                <span>{uniquePercentText} unique values</span>
                            ) : (
                                <span>{distinct} unique values ({uniquePercentText})</span>
                            )}
                        </li>
                    </ul>
                </div>
            </Tooltip>
        </>
    );
}

ColumnCompleteness.propTypes = {
    columnStats: PropTypes.shape({
        rows: PropTypes.number,
        distinct: PropTypes.number,
        values: PropTypes.number
    }),
    tableId: PropTypes.string,
    columnName: PropTypes.string
};
