import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useGetLink} from "src/utils/useGetLink.js";

export function useNavigateOnClick() {
    const getLink = useGetLink();
    const navigate = useNavigate();

    return useCallback((event, path) => {
        if (!path) {
            return;
        }
        const link = getLink(path);

        if (event.metaKey || event.ctrlKey) {
            const {pathname, search} = link;
            const url = `${pathname}${search}`;
            const newWindow = window.open(url, "_blank");
            newWindow?.focus();
        } else {
            navigate(link);
        }
    }, [navigate, getLink]);
}
