import React, {useCallback, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {SelectRange} from "src/primitives/controls/SelectRange/index.jsx";
import {toRangeValue} from "src/components/TableGrid/Header/ColumnFilter/utils.jsx";
import {SmoothLineChart} from "src/charts/SmoothLineChart.jsx";
import {useColumnDistribution} from "src/pages/Home/pages/TablePage/pages/TableGridPage/distribution_context.jsx";
import {CHART_COLORS} from "src/charts/colors.js";
import {formatDistributionToLine, maxValueFromDistribution, minValueFromDistribution} from "./utils.js";


export function NumericFilter({columnInfo, value, updateRange}) {
    const {data: distribution} = useColumnDistribution(columnInfo.name);
    const leftOverlayRef = useRef();
    const rightOverlayRef = useRef();
    const xMin = minValueFromDistribution(distribution);
    const xMax = maxValueFromDistribution(distribution);
    // Other method
    // const [xMin, xMax = [Math.floor(stats.min), Math.ceil(stats.max)];
    const fallback = [xMin, xMax];
    const rangeValue = toRangeValue(value, fallback);
    const chartOptions = {
        showXLabel: false,
        showYLabel: false,
        xMin,
        xMax,
        colors: [CHART_COLORS.blue]
    };

    const changeCallback = useCallback((values) => {
        if (!leftOverlayRef.current || !rightOverlayRef.current) {
            return;
        }

        const selectLength = xMax - xMin;

        const leftDistance = values[0] - xMin;
        const leftPercent = 100 * (leftDistance / selectLength);
        leftOverlayRef.current.style.width = `${leftPercent}%`;

        const rightDistance = xMax - values[1];
        const rightPercent = 100 * (rightDistance / selectLength);
        rightOverlayRef.current.style.width = `${rightPercent}%`;
    }, [xMin, xMax]);

    useEffect(() => {
        changeCallback(rangeValue);
    }, [rangeValue, changeCallback]);

    return (
        <div>
            <div style={{height: 80}} className="relative w-full mb-2">
                {distribution && (
                    <SmoothLineChart
                        height={80}
                        options={chartOptions}
                        data={formatDistributionToLine(distribution)}
                    />
                )}
                <div ref={leftOverlayRef} className="absolute bg-white/80 blur left-0 top-0 bottom-0 w-0"/>
                <div ref={rightOverlayRef} className="absolute bg-white/80 blur right-0 top-0 bottom-0 w-0"/>
            </div>

            <SelectRange
                min={xMin}
                max={xMax}
                lower={Number(rangeValue[0])}
                upper={Number(rangeValue[1])}
                onChange={updateRange}
                changeCallback={changeCallback}
            />
        </div>
    );
}

NumericFilter.propTypes = {
    columnInfo: PropTypes.object,
    // stats: PropTypes.object,
    value: PropTypes.string,
    updateRange: PropTypes.func
};
