import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";


const wrapperClasses = cva({
    base: "rounded-md shadow-sm border text-neutral-600 mb-4",
    variants: {
        variant: {
            primary: "border-blue-300 bg-blue-100/50",
            danger: "bg-red-50 border-red-300",
            success: "bg-lime-50 border-lime-500"
        },
        size: {
            xs: "text-xs py-1 px-2",
            sm: "text-sm py-2 px-3",
            md: "text-base py-3 px-4"
        }
    },
    defaultVariants: {
        variant: "primary",
        size: "sm"
    }
});


export function Callout({children, variant, size}) {
    return (
        <div className={wrapperClasses({variant, size})}>
            {children}
        </div>
    );
}

Callout.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.oneOf(["primary", "danger", "success"]),
    size: PropTypes.oneOf(["xs", "sm", "md"])
};

Callout.defaultProps = {
};
