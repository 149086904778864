import React, {useState} from "react";
import PropTypes from "prop-types";
import {FiFolderPlus} from "react-icons/fi";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {useGoToFolder} from "src/utils/useGetLink.js";
import {DialogModal} from "src/primitives/modals/Dialog.jsx";
import {NewFolderForm} from "src/pages/Home/components/AddItemMenu/components/NewFolderForm/index.jsx";
import {buttonClasses} from "./styles.jsx";


export function NewFolderButton({workspaceId}) {
    const [newFolder, showNewFolder] = useState(false);
    const goToFolderPage = useGoToFolder();

    const showDialog = () => {
        showNewFolder(true);
    };

    const closeDialog = () => {
        showNewFolder(false);
    };

    const onSuccess = (result) => {
        const newFolderId = result?.folder?.id;
        if (!newFolderId) {
            return;
        }
        closeDialog();
        goToFolderPage(newFolderId);
    };

    return (
        <>
            {newFolder && (
                <DialogModal close={closeDialog} title="Folder name">
                    <NewFolderForm
                        onSuccess={onSuccess}
                        workspaceId={workspaceId}
                    />
                </DialogModal>
            )}
            <div className="mb-1 text-sm">
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                    onClick={showDialog}
                    className={buttonClasses({state: newFolder ? "active" : ""})}
                >
                    <IconWrapper
                        icon={<FiFolderPlus />}
                        size="xs"
                        style={{marginRight: 4}}
                    />
                    <div>
                        New folder
                    </div>
                </div>
            </div>
        </>
    );
}

NewFolderButton.propTypes = {
    workspaceId: PropTypes.string.isRequired
};
