import React from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {CHART_COLORS} from "src/components/ChartBuilder/colors.js";
import {getOptions} from "./options.js";


export function CategoriesTreeMap({treeData}) {
    if (treeData.length === 0) {
        return null;
    }

    const option = getOptions(treeData, CHART_COLORS);

    return (
        <ChartWrapper option={option}/>

    );
}

CategoriesTreeMap.propTypes = {
    treeData: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        path: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.number
    }))
};
