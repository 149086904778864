import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {CHART_COLORS} from "src/charts/colors.js";
import {getDefaultTextStyle} from "src/charts/options.js";


function getOption(datasets, options) {
    const {showLabel, showTooltip, showY, showXLabel, xFormatter, name} = options;

    return {
        textStyle: getDefaultTextStyle(),
        grid: {
            top: "5%",
            left: showY ? "10%" : "5%",
            width: showY ? "80%" : "90%",
            bottom: showXLabel ? "10%" : "5%"
        },
        dataset: datasets.map((dataset) => ({source: dataset})),
        tooltip: {
            show: showTooltip
        },
        xAxis: {
            type: "value",
            scale: true,
            gridIndex: 0,
            axisLabel: {
                show: showXLabel,
                formatter: xFormatter
            },
            min: options.xMin,
            max: options.xMax
        },
        yAxis: {
            type: "value",
            gridIndex: 0,
            show: showY
        },
        series: [{
            name,
            type: "bar",
            barWidth: "75%",
            color: CHART_COLORS.gray,
            label: {
                show: showLabel,
                position: "top"
            },
            encode: {x: 0, y: 1, itemName: 4},
            datasetIndex: 0
        }]
    };
}


function formatDatasetRow(row, formatLabel) {
    const {lower, upper, frequency} = row;
    const width = upper - lower;
    const x = lower + width / 2.0;
    const y = frequency;
    const label = formatLabel ? formatLabel(lower, upper) : `${lower} - ${upper}`;
    return [
        x,
        y,
        lower,
        upper,
        label
    ];
}

export function getMinValue(datasets) {
    let minValue = Number.MAX_VALUE;

    datasets.forEach((dataset) => {
        dataset.forEach(({lower}) => {
            if (lower < minValue) {
                minValue = lower;
            }
        });
    });
    return minValue;
}

export function getMaxValue(datasets) {
    let maxValue = Number.MIN_VALUE;

    datasets.forEach((dataset) => {
        dataset.forEach(({upper}) => {
            if (upper > maxValue) {
                maxValue = upper;
            }
        });
    });
    return maxValue;
}

export function HistogramRaw({
    datasets,
    height,
    showTooltip,
    showLabel,
    showY,
    showXLabel,
    xFormatter,
    formatLabel,
    name
}) {
    /**
     * https://echarts.apache.org/examples/en/editor.html?c=bar-histogram
     * https://github.com/ecomfe/echarts-stat#histogram
     */
    const formattedDatasets = datasets.map((dataset) => {
        return dataset.map((row) => formatDatasetRow(row, formatLabel));
    });
    const minValue = getMinValue(datasets);
    const maxValue = getMaxValue(datasets);

    const option = getOption(
        formattedDatasets,
        {showLabel, showTooltip, showY, showXLabel, xFormatter, xMin: minValue, xMax: maxValue, name}
    );

    const handleClick = useCallback((params) => {
        console.log(params);
    }, []);

    const events = useMemo(() => {
        return {
            click: handleClick
        };
    }, [handleClick]);

    return (
        <ChartWrapper option={option} height={height} events={events}/>
    );
}

HistogramRaw.propTypes = {
    datasets: PropTypes.arrayOf(PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number,
            lower: PropTypes.number,
            upper: PropTypes.number,
            frequency: PropTypes.number
        })
    )),
    height: PropTypes.number,
    showTooltip: PropTypes.bool,
    showLabel: PropTypes.bool,
    showY: PropTypes.bool,
    showXLabel: PropTypes.bool,
    xFormatter: PropTypes.func,
    formatLabel: PropTypes.func,
    name: PropTypes.string
};

HistogramRaw.defaultProps = {
    showTooltip: true,
    showLabel: false,
    showY: true,
    showXLabel: true,
    // https://echarts.apache.org/en/option.html#xAxis.axisLabel.formatter
    xFormatter: null
};
