import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Box} from "@chakra-ui/react";
import "handsontable/dist/handsontable.full.min.css";
import {registerAllModules} from "handsontable/registry";
import {registerRenderer} from "handsontable/renderers";
import {HotColumn, HotTable} from "@handsontable/react";
import {formatColumnData} from "src/components/Table/columns.js";
import {heatmapRenderer} from "src/components/Table/heatmap_renderer.js";
import {buildMenu} from "src/components/Table/menu.js";
import {SearchInput} from "src/components/Table/SearchInput.jsx";
import {buildSearchFunction} from "src/components/Table/search.js";
import "./style.css";
import {DataHelper} from "src/components/Table/data_helper.js";
import {MemoizedHeatmapCell} from "src/components/Table/cells/heatmap.jsx";
import useResizeObserver from "use-resize-observer";
import {FlexStack} from "src/primitives/layout";
import {getSidebarButton} from "src/primitives/layout/PageHeader.jsx";
import {FullScreenLoading} from "src/components/FullScreenLoading/FullScrenLoading.jsx";


registerAllModules();
registerRenderer("heatmap", heatmapRenderer);


export function Table({rows, headers, columnTypes, actions, onEdit, statsMap}) {
    const [showTable, setShowTable] = useState(false);
    const timerRef = useRef(null);
    const tableRef = useRef(null);
    const {ref, width, height} = useResizeObserver();
    // const [tableWidth, setTableWidth] = useState(width);
    const [columnFormats, setColumnFormats] = useState({});
    const updateColumnFormats = (newData) => {
        setColumnFormats((prevState) => Object.assign({}, prevState, newData));
    };
    const menu = buildMenu(tableRef, actions, updateColumnFormats);
    const searchCallback = buildSearchFunction(tableRef, rows);
    const dataHelper = new DataHelper(tableRef);

    const deselectCell = () => {
        tableRef.current?.hotInstance?.deselectCell();
    };

    const handleChange = (changes, source) => {
        if (["edit", "UndoRedo.undo", "UndoRedo.redo"].includes(source)) {
            const formattedChanges = changes.map((change) => dataHelper.formatChangeArray(change));
            const actualChanges = formattedChanges.filter((item) => item.from !== item.to);
            if (actualChanges.length > 0 && onEdit) {
                onEdit(actualChanges);
            }
        }
    };

    // eslint-disable-next-line no-unused-vars
    const handleSelection = (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        // https://handsontable.com/docs/javascript-data-grid/api/hooks/#afterselection

        if (row === -1) {
            // row = -1 => header selected
            if (column === column2) {
                // One column selected
                // preventScrolling.value = true;
                const columnName = dataHelper.getColumnName(column);
                if (selectionLayerLevel === 0) {
                    // first selection level = new selection!
                    console.log(columnName);
                } else {
                    // another selection level => additional selection
                    console.log(columnName);
                }
            } else {
                const names = dataHelper.getColumnNames(column, column2);
                console.log(names);
            }
        }
    };

    const handleDeselect = () => {
        console.log("DESELECT");
        // setColumns([]);
    };

    const columns = formatColumnData(headers, columnTypes, columnFormats);

    useEffect(() => {
        if (!width) {
            return;
        }
        // console.log(`New width: ${width}`);
        // width changed for parent to table
        setShowTable(false);
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setShowTable(true);
        }, 10);
    }, [width]);

    useEffect(() => {
        /**
         * When hiding/showing sidebar, the screen is resized.
         * This becomes very slow when the table is large.
         * Easiest options is to hide the table, do the resizing, then HotTable calculates
         * the width/height and renders the table.
         */

        function hideTable() {
            setShowTable(false);
        }
        const element = getSidebarButton();

        element.addEventListener("click", hideTable);

        return () => {
            element.removeEventListener("click", hideTable);
        };
    }, []);

    return (
        <FlexStack style={{flex: 1}}>
            <Box flex="0" py="4" px="2" display="flex">
                <SearchInput callback={searchCallback} onFocus={deselectCell}/>
            </Box>
            <Box
                flex="1"
                display="flex"
                px="1"
                py="0"
                style={{outline: "1px solid #f0f0f0", position: "relative"}}
                bg=""
                ref={ref}
                className="fadeIn"
            >
                {(showTable) && (width && height) ? (
                    <HotTable
                        // key={`${width}-${height}`}
                        ref={tableRef}
                        data={rows}
                        // columns={columns}
                        rowHeaders={true}
                        rowHeaderWidth={40}
                        colHeaders={headers}
                        columnSorting={true}
                        // size!
                        // width="100%"
                        // height={600}
                        // height="100%"
                        width={width - 6}
                        height={height - 1}
                        licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                        hiddenColumns={{
                            columns: [0],
                            indicators: true
                        }}
                        fixedRowsTop={0}
                        manualColumnResize={true}
                        manualRowResize={false}
                        // Menus!
                        dropdownMenu={menu}
                        // contextMenu={menu}
                        // search plugin
                        search={true}
                        // hidden rows plugin
                        hiddenRows={{rows: []}}
                        // events!
                        afterChange={handleChange}
                        afterSelection={handleSelection}
                        afterDeselect={handleDeselect}
                        // afterGetColHeader={afterGetColHeader}
                        // Obs! Should disable for large datasets
                        // https://handsontable.com/docs/react-data-grid/row-virtualization/
                        renderAllRows={false}
                    >
                        {columns.map((column) => {
                            if (column.renderer === "heatmap") {
                                return (
                                    <HotColumn key={column.data} columnSettings={column}>
                                        <MemoizedHeatmapCell hot-renderer={true} stats={statsMap[column.title]}/>
                                    </HotColumn>
                                );
                            }
                            return (
                                <HotColumn key={column.data} columnSettings={column}/>
                            );
                        })}
                    </HotTable>
                ) : (
                    <FullScreenLoading/>
                )}
            </Box>
        </FlexStack>
    );
}


Table.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.array),
    headers: PropTypes.arrayOf(PropTypes.string),
    columnTypes: PropTypes.object,
    statsMap: PropTypes.object,
    actions: PropTypes.object,
    onEdit: PropTypes.func
};


Table.defaultProps = {
    onEdit: () => null
};
