import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {LINKS} from "src/links.js";
import {AdminLayout} from "src/pages/Admin/AdminLayout.jsx";
import {UsersPage} from "src/pages/Admin/pages/Users/index.jsx";
import {useGetUser} from "src/api/users/useGetUser.js";
import {WorkspacesPage} from "src/pages/Admin/pages/Workspaces/index.jsx";


export function AdminPage() {
    const {user, loading} = useGetUser();
    if (loading) {
        return null;
    }

    if (!user?.isAdmin) {
        return (
            <Navigate to={LINKS.HOME}/>
        );
    }

    // slice paths to make them relative to the root
    return (
        <Routes>
            <Route path="*" element={<AdminLayout/>}>
                <Route
                    path="users"
                    element={<UsersPage/>}
                />
                <Route
                    path="workspaces"
                    element={<WorkspacesPage/>}
                />
                <Route
                    path="*"
                    element={<Navigate to={`${LINKS.ADMIN}/users`} />}
                />
            </Route>
        </Routes>
    );
}
