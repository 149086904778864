import React from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {getDefaultTextStyle} from "src/charts/options.js";


function getOption(data, title) {
    return {
        textStyle: getDefaultTextStyle(),
        tooltip: {
            trigger: "item"
        },
        legend: {
            show: false
        },
        series: [
            {
                name: title,
                type: "pie",
                radius: "75%",
                data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)"
                    }
                }
            }
        ]
    };
}


export function PieChart({data, title}) {
    /**
     * https://echarts.apache.org/examples/en/editor.html?c=pie-simple
     */
    const option = getOption(data, title);

    return (
        <ChartWrapper option={option}/>
    );
}

PieChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string
    })),
    title: PropTypes.string
};

PieChart.defaultProps = {
    // Example
    data: [
        {value: 1048, name: "Search Engine"},
        {value: 735, name: "Direct"},
        {value: 580, name: "Email"},
        {value: 484, name: "Union Ads"},
        {value: 300, name: "Video Ads"}
    ]
};
