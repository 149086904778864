import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {cva} from "cva";


const circleClasses = cva({
    base: "rounded-full shadow-sm border",
    variants: {
        theme: {
            orange: "bg-orange-400 border-orange-400",
            blue: "bg-blue-500 border-blue-500",
            red: "bg-red-200 border-red-200",
            black: "bg-neutral-700 border-neutral-700",
            green: "bg-lime-400 border-lime-400"
        },
        size: {
            small: "w-2 h-2",
            medium: "w-3 h-3"
        },
        mode: {
            outline: "!bg-transparent"
        }
    },
    defaultVariants: {
        theme: "orange",
        size: "small"
    }
});

export function Circle({theme, size, mode, style}) {
    return (
        <Wrapper
            className={circleClasses({theme, size, mode})}
            style={style}
        />
    );
}

Circle.propTypes = {
    theme: PropTypes.string,
    size: PropTypes.string,
    mode: PropTypes.string,
    style: PropTypes.object
};

const Wrapper = styled.div`
  margin-top: 2px;
`;
