import React from "react";
import PropTypes from "prop-types";
import {Navigate, Route, Routes} from "react-router-dom";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {PageLayout} from "src/primitives/layout/PageLayout.jsx";
import {LINKS} from "src/links.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {ShareTable} from "src/pages/Home/components/ShareResource/ShareTable.jsx";
import {ToggleCodeHintsButton} from "src/pages/Home/components/CodeHints/ToggleCodeHintsButton.jsx";
import {ColumnDetailsConditional} from "src/pages/Home/pages/TablePage/components/ColumnDetailsSidebar/index.jsx";
import {isTableReady} from "src/pages/Home/pages/TablePage/utils.js";
import {isLocal} from "src/config.js";
import {ErrorComponent} from "src/components/ErrorComponent/index.jsx";
import {TableErrorBoundary, TableErrorPage, TableNetworkError} from "./components/ErrorPage";
import {TablePageHeader} from "./components/TablePageHeader/index.jsx";
import {
    NewTablePage,
    TableChatPage,
    TableDashboardPage,
    TableExplorePage,
    TableGridPageWrapper,
    TableOverviewPage,
    TableProfilingPage,
    TableSettingsPage,
    TablePredictPage
} from "./pages";


export function TablePage({tableId, workspaceId}) {
    const getLink = useGetLink();
    const {table, loading, error} = useGetTable(tableId);

    const tabs = [
        {link: LINKS.tablePage(tableId), title: "Data"},
        {link: LINKS.tableProfiling(tableId), title: "Profiling"},
        // {link: LINKS.tableExplore(tableId), title: "Explore"},
        // {link: LINKS.tableChat(tableId), title: "Chat"},
        {link: LINKS.tableSettings(tableId), title: "Settings"}
    ];

    if (isLocal()) {
        tabs.push({
            link: LINKS.tablePredict(tableId),
            title: "Predict"
        });
        tabs.push({
            link: LINKS.tableExplore(tableId),
            title: "Explore"
        });
        tabs.push({
            link: LINKS.dashboard(tableId),
            title: "Dashboard"
        });
    }

    if (!loading && !table && !error) {
        return (
            <Navigate to={getLink(LINKS.WORKSPACE)}/>
        );
    }

    if (error) {
        return (
            <TableNetworkError error={error}/>
        );
    }

    if (!loading && table?.status === "ERROR") {
        return (
            <TableErrorPage tableId={tableId}/>
        );
    }

    if (!loading && !isTableReady(table)) {
        return (
            <PageLayout
                title={<TablePageHeader tableId={tableId} workspaceId={workspaceId}/>}
                navLinks={tabs}
            >
                <NewTablePage tableId={tableId}/>
            </PageLayout>
        );
    }

    return (
        <PageLayout
            title={<TablePageHeader tableId={tableId} workspaceId={workspaceId}/>}
            navLinks={tabs}
            navbarSecondary={(
                <div className="flex items-center gap-2">
                    <ShareTable tableId={tableId}/>
                    <ToggleCodeHintsButton/>
                </div>
            )}
        >
            <>
                <ErrorComponent fallback={<TableErrorBoundary tableId={tableId}/>}>

                    {!loading ? (
                        <Routes>
                            <Route
                                path=""
                                element={(
                                    <TableGridPageWrapper
                                        tableId={tableId}
                                        table={table}
                                    />
                                )}
                            />
                            <Route
                                path={"/hot/*"}
                                element={(
                                    <TableOverviewPage
                                        key={tableId}
                                        table={table}
                                        tableId={tableId}
                                    />
                                )}
                            />
                            <Route
                                path={"/profiling/*"}
                                element={<TableProfilingPage table={table}/>}
                            />
                            <Route
                                path={"/explore/*"}
                                element={<TableExplorePage table={table}/>}
                            />
                            <Route
                                path={"/chat/*"}
                                element={<TableChatPage tableId={tableId}/>}
                            />
                            <Route
                                path={"/dashboard/*"}
                                element={<TableDashboardPage tableId={tableId}/>}
                            />
                            <Route
                                path={"/predict/*"}
                                element={<TablePredictPage tableId={tableId}/>}
                            />
                            <Route
                                path="/settings"
                                element={<TableSettingsPage tableId={tableId} table={table}/>}
                            />
                        </Routes>
                    ) : null}
                </ErrorComponent>

                {table && (
                    <ColumnDetailsConditional table={table}/>
                )}
            </>
        </PageLayout>
    );
}

TablePage.propTypes = {
    tableId: PropTypes.string.isRequired,
    workspaceId: PropTypes.string.isRequired
};
