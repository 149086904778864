import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {NewFileContext} from "src/pages/Home/contexts/file_context.jsx";
import {useLatestFolderId} from "src/pages/Home/contexts/latest_folder_context.jsx";
import {PageContent, PageLayout} from "src/primitives/layout/index.jsx";
import {Title} from "src/primitives/title.jsx";
import {humanFileSize} from "src/utils/formatting.js";
import {SelectMergeIndex} from "src/pages/Home/pages/UploadFilePage/components/SelectMergeIndex/index.jsx";
import {TextInput} from "src/primitives/controls/index.jsx";
import {ChooseFile} from "./components/ChooseFile/index.jsx";
import {SelectFolder} from "./components/SelectFolder/index.jsx";
import {SelectTable} from "./components/SelectTable/index.jsx";
import {getStrategy, NEW_FILE_STRATEGY, OVERALL_STRATEGY} from "./components/options/strategies.js";
import {SelectStrategy} from "./components/options/SelectStrategy.jsx";
import {OverallStrategy} from "./components/options/OverallStrategy.jsx";
import {FileUploader} from "./components/FileUploader/index.jsx";


export function UploadFilePage({workspaceId}) {
    const latestFolderId = useLatestFolderId(); // latest navigated folder
    const file = useContext(NewFileContext);
    const [overallStrategy, setOverallStrategy] = useState(OVERALL_STRATEGY.CREATE_NEW_TABLE);
    const [folderId, setFolderId] = useState(latestFolderId);
    const [tableId, setTableId] = useState(null);
    const [strategy, setStrategy] = useState(NEW_FILE_STRATEGY.APPEND);
    const [mergeOnColumn, setMergeOnColumn] = useState(null);
    const [tableName, setTableName] = useState("");

    const handleSelectFolder = (folder) => {
        setFolderId(folder?.id);
    };
    const handleSelectTable = (table) => {
        setTableId(table?.id);
        setMergeOnColumn(null);
    };

    useEffect(() => {
        setTableName(file?.name || "");
    }, [file, setTableName]);

    return (
        <PageLayout title="New file">
            <PageContent title="Upload file" size="md">
                <div>
                    <div className="flex mb-4">
                        <ChooseFile/>
                    </div>

                    <div className="flex h-4 mb-6">
                        {file && (
                            <Title size="xs" style={{margin: 0}}>
                                File size: {humanFileSize(file.size)}
                            </Title>
                        )}
                    </div>

                    <div className="mb-6">
                        <div className="mb-2">
                            <Title size="xs">
                                Upload strategy:
                            </Title>
                        </div>
                        <OverallStrategy
                            strategy={overallStrategy}
                            onChange={setOverallStrategy}
                        />
                    </div>

                    <div className="mb-6 max-w-96">
                        {overallStrategy === OVERALL_STRATEGY.CREATE_NEW_TABLE && (
                            <div className="flex flex-col gap-6">

                                <SelectFolder
                                    workspaceId={workspaceId}
                                    onChange={handleSelectFolder}
                                    selectedFolderId={folderId}
                                />

                                <div>
                                    <div className="mb-2">
                                        <Title size="xs">
                                            Table name:
                                        </Title>
                                    </div>
                                    <TextInput
                                        value={tableName}
                                        onChange={(e) => setTableName(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}

                        {overallStrategy === OVERALL_STRATEGY.USE_EXISTING_TABLE && (
                            <div className="flex flex-col gap-6">
                                <SelectTable
                                    workspaceId={workspaceId}
                                    onChange={handleSelectTable}
                                    selectedTableId={tableId}
                                />
                                <SelectStrategy
                                    strategy={strategy}
                                    onChange={setStrategy}
                                    excludeStrategies={[NEW_FILE_STRATEGY.NEW_TABLE, NEW_FILE_STRATEGY.NEW_VERSION]}
                                />
                                {strategy === NEW_FILE_STRATEGY.MERGE && (
                                    <SelectMergeIndex
                                        tableId={tableId}
                                        selectedColumn={mergeOnColumn}
                                        onChange={setMergeOnColumn}
                                    />
                                )}
                            </div>
                        )}
                    </div>

                    {file && (
                        <FileUploader
                            workspaceId={workspaceId}
                            file={file}
                            folderId={folderId}
                            tableId={tableId}
                            strategy={getStrategy(overallStrategy, strategy)}
                            mergeOnColumn={mergeOnColumn}
                            tableName={tableName}
                        />
                    )}
                </div>
            </PageContent>
        </PageLayout>
    );
}

UploadFilePage.propTypes = {
    workspaceId: PropTypes.string.isRequired
};
