import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import {Box, Input, InputGroup, InputLeftElement, InputRightElement, Kbd} from "@chakra-ui/react";
import {FiSearch} from "react-icons/fi";
import {useKeyPress} from "src/utils/useKeyPress.js";
import debounce from "lodash.debounce";

export function SearchInput({callback, onFocus}) {
    const [value, setValue] = useState("");
    const inputRef = useRef();
    const debouncedCallback = debounce(callback, 75, {leading: false, trailing: true});

    const updateValue = (val) => {
        setValue(val);
        debouncedCallback(val);
    };

    const setFocus = () => {
        if (inputRef.current) {
            if (onFocus) {
                onFocus();
            }
            inputRef.current.focus();
        }
    };

    const blur = () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const hasFocus = () => {
        return inputRef.current && inputRef.current === document.activeElement;
    };
    const hasNotFocus = () => !hasFocus();

    const reset = () => {
        updateValue("");
        blur();
    };

    const handleChange = (e) => {
        updateValue(e.target.value);
    };

    useKeyPress(setFocus, ["/"], {shift: true}, hasFocus);
    useKeyPress(reset, ["Backspace"], {shift: true});
    useKeyPress(blur, ["Escape"], {}, hasNotFocus);

    return (
        <Box flex="1" py="0" px="0">
            <InputGroup>
                <InputLeftElement>
                    <FiSearch color="grey.800"/>
                </InputLeftElement>
                <Input
                    name="search-data"
                    placeholder="Search"
                    ref={inputRef}
                    type="search"
                    onChange={handleChange}
                    value={value}
                />
                <InputRightElement w="100px">
                    <span>
                        <Kbd>shift</Kbd> + <Kbd>/</Kbd>
                    </span>
                </InputRightElement>
            </InputGroup>
        </Box>
    );
}

SearchInput.propTypes = {
    callback: PropTypes.func,
    onFocus: PropTypes.func
};
