import React, {useRef} from "react";
import PropTypes from "prop-types";
import {useRemoveAccess} from "src/api/sharing/useRemoveAccess.js";
import {Overlay} from "src/primitives/Overlay/index.jsx";
import {Button} from "src/primitives/button.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiChevronDown} from "react-icons/fi";
import {useShowToast} from "src/components/toasts/useShowToast.js";


export function EditAccess({objectId, disabled, email}) {
    const [removeAccess] = useRemoveAccess();
    const {showLoading, showSuccess, showError} = useShowToast();

    // Hack since 'email' will be deleted when we show the success message.
    const emailRef = useRef();
    if (email) {
        emailRef.current = email;
    }

    const handleClick = () => {
        showLoading();
        removeAccess(objectId).then(({ok, errorCode}) => {
            if (!ok) {
                throw new Error(errorCode);
            }
            showSuccess({message: `Access removed for user with email "${emailRef.current}".`});
        }).catch((error) => {
            console.error(error);
            showError();
        });
    };

    return (
        <Overlay
            align="right"
            content={(
                <div className="text-sm w-44">
                    <button
                        onClick={handleClick}
                        type="submit"
                        className="w-full text-left hover:text-blue-900 hover:bg-black/5 px-2 py-1 rounded-md"
                    >
                        Remove access
                    </button>
                </div>
            )}
        >
            <Button
                theme="white"
                size="xs"
                isDisabled={disabled}
            >
                <div className="flex items-center gap-2" style={{marginRight: -4}}>
                    <span>Edit</span>
                    <IconWrapper icon={<FiChevronDown/>} size="xxs" style={{paddingTop: 2}}/>
                </div>
            </Button>
        </Overlay>
    );
}

EditAccess.propTypes = {
    objectId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    email: PropTypes.string
};
