import React, {useState} from "react";
import PropTypes from "prop-types";
import {useAskQuestion} from "src/api/chat/useAskQuestion.js";
import {PromptArea} from "src/pages/Home/pages/TablePage/pages/ChatPage/components/PrompArea/index.jsx";
import {HistoryItem} from "src/pages/Home/pages/TablePage/pages/ChatPage/components/HistoryItem/index.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {useRealtimeUpdates} from "src/utils/realtime/useRealtimeUpdates.jsx";


export function TableChatPage({tableId}) {
    const events = useRealtimeUpdates();
    const [askQuestion, {loading}] = useAskQuestion(tableId);
    const [history, setHistory] = useState([]);

    const doAskQuestion = (question) => {
        askQuestion({question}).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
            setHistory((existingHistory) => {
                return [...existingHistory, result.result];
            });
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <div className="flex-1 w-full h-full relative pb-28">
            <div className="h-full overflow-y-auto">
                <div className="py-4 px-10 flex flex-col gap-2">
                    {history.map((item, index) => (
                        <HistoryItem
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            item={item}
                        />
                    ))}
                </div>
                <div>
                    {events[0]?.content}
                </div>
                {loading && (
                    <div className="px-4 flex items-center justify-center">
                        <Spinner/>
                    </div>
                )}
            </div>

            <div className="absolute bottom-1 w-full bg-neutral-100/80 border-t border-neutral-200 py-3">
                <PromptArea
                    askQuestion={doAskQuestion}
                    loading={loading}
                />
            </div>
        </div>
    );
}

TableChatPage.propTypes = {
    tableId: PropTypes.string.isRequired
};
