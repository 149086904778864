import React from "react";
import {Outlet} from "react-router-dom";
import {Resizeable} from "src/primitives/layout";
import {SidebarContextProvider} from "src/primitives/layout/sidebar_context.jsx";
import {AdminSidebar} from "./components/AdminSidebar/index.jsx";


export function AdminLayout() {
    return (
        <SidebarContextProvider>
            <Resizeable
                id="admin-layout"
                sidebar={<AdminSidebar/>}
            >
                <Outlet/>
            </Resizeable>
        </SidebarContextProvider>
    );
}
