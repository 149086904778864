import React from "react";


export function useBooleanState(defaultValue = false) {
    const [value, setValue] = React.useState(defaultValue);
    const setTrue = React.useCallback(() => {
        setValue(true);
    }, [setValue]);
    const setFalse = React.useCallback(() => {
        setValue(false);
    }, [setValue]);

    const toggle = React.useCallback(() => {
        setValue((prevValue) => !prevValue);
    }, [setValue]);

    return [value, setTrue, setFalse, toggle, setValue];
}
