import React, {useCallback, useEffect, useMemo, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Tree} from "react-arborist";
import {useSetParentFolder} from "src/api/folders/useSetParentFolder.js";
import {TreeLoading} from "src/components/FoldersTree/TreeLoading.jsx";
import {arrayToMap} from "src/utils/misc.js";
import {mergeArraysIntoTree, NODE_TYPE} from "src/components/FoldersTree/folderTree.js";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {Node} from "./components/Node";


export function FoldersTree({
    width,
    height,
    loading,
    folders,
    tables,
    disableDnd,
    onLoaded,
    activeNodeId
}) {
    const wrapperRef = useRef();

    const [setParentFolder] = useSetParentFolder();
    const {showError, showSuccess} = useShowToast();
    const tablesMap = useMemo(() => arrayToMap(tables), [tables]);
    const foldersMap = useMemo(() => arrayToMap(folders), [folders]);

    const setParentFolderOnDrop = useCallback((dragId, parentId) => {
        const payload = {parentId};

        if (tablesMap[dragId]) { // check if table
            // dragged item is a table
            if (tablesMap[dragId].folderId === parentId) {
                // dropped in existing parent -> do nothing
                return;
            }
            payload.tableId = dragId;
        } else if (foldersMap[dragId]) { // check if folder
            // dragged item is a folder
            if (foldersMap[dragId].parentFolderId === parentId) {
                // dropped in existing parent -> do nothing
                return;
            }
            payload.folderId = dragId;
        } else {
            // neither a table nor a folder
            return;
        }

        setParentFolder(payload).then((result) => {
            if (result.ok) {
                showSuccess();
            } else {
                throw new Error(result.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
            showError();
        });
    }, [setParentFolder, tablesMap, foldersMap, showSuccess, showError]);

    const tree = useMemo(() => {
        if (loading) {
            // No purpose to build tree if something is loading
            return [];
        }
        return mergeArraysIntoTree(folders, tables);
    }, [folders, tables, loading]);

    const onMove = ({dragIds, parentId}) => {
        dragIds.forEach((dragId) => {
            setParentFolderOnDrop(dragId, parentId);
        });
    };

    const disableDrop = ({parentNode}) => {
        // Disable drop if it's not a folder
        return parentNode.data.type !== NODE_TYPE.FOLDER;
    };

    const setAutoHeight = useCallback(() => {
        if (!wrapperRef.current) {
            return;
        }
        console.debug("Setting height");
        // Show wrapper after rendered (and we have the height)
        wrapperRef.current.style.opacity = 1;
        const firstChild = wrapperRef.current.children[0];
        firstChild.style.height = "auto";
        const secondChild = firstChild.children[0];
        secondChild.style.height = "auto";

        if (onLoaded) {
            onLoaded();
        }
    }, [onLoaded]);

    useEffect(() => {
        window.setTimeout(() => {
            setAutoHeight();
        }, 75);
    }, [tree, setAutoHeight]);

    if (loading) {
        return (
            <Wrapper>
                <TreeLoading/>
            </Wrapper>
        );
    }

    return (
        <Wrapper ref={wrapperRef}>
            <Tree
                // TODO: Decide how to re-render Tree when new tables
                // key={tree.length}
                // initialData={tree}
                data={tree}
                openByDefault={true}
                // size!
                width={width}
                height={height}
                // format
                indent={20}
                rowHeight={32}
                // show all nodes
                overscanCount={100}
                onMove={onMove}
                // onActivate={onActivate}
                // Config
                disableMultiSelection={true}
                disableDrop={disableDrop}
                disableDrag={disableDnd}
                // sync with current page
                selection={activeNodeId}
            >
                {Node}
            </Tree>
        </Wrapper>
    );
}

FoldersTree.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number]),
    loading: PropTypes.bool,
    tables: PropTypes.arrayOf(PropTypes.object),
    folders: PropTypes.arrayOf(PropTypes.object),
    disableDnd: PropTypes.bool,
    onLoaded: PropTypes.func,
    activeNodeId: PropTypes.string
};

FoldersTree.defaultProps = {
    width: "auto",
    // height: 500,
    tables: [],
    folders: [],
    disableDnd: false
};

const Wrapper = styled.div`
  // Hide during resize
  opacity: 0;
  font-size: 14px;
  & div {
    overflow-x: hidden;
  }
`;
