import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {PageHeader} from "src/primitives/layout/PageHeader.jsx";
import {TabsNavigation} from "src/components/Tabs/index.jsx";
import {FlexLayout} from "src/primitives/layout/FlexLayout.jsx";


export function PageLayout({
    title,
    loading,
    navLinks,
    navbarSecondary,
    footer,
    children
}) {
    return (
        <FlexLayout
            className="min-w-page-md"
            header={(
                <div className="border-b border-black/20">
                    <PageHeader
                        title={title}
                        loading={loading}
                    />

                    {navLinks && (
                        <NavbarWrapper className="px-4 border-t border-black/20">
                            <TabsNavigation
                                tabs={navLinks}
                            />
                            {navbarSecondary}
                        </NavbarWrapper>
                    )}
                </div>
            )}
            footer={footer}
        >
            {children}
        </FlexLayout>
    );
}

PageLayout.propTypes = {
    title: PropTypes.node,
    loading: PropTypes.bool,
    navLinks: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.node
    })),
    navbarSecondary: PropTypes.node,
    footer: PropTypes.element,
    children: PropTypes.element
};


const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
