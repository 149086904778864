import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiArrowDown, FiArrowUp} from "react-icons/fi";


export function NumberInput({value, onChange}) {
    const [tempValue, setTempValue] = useState(value);

    const decreaseNumber = () => {
        const intValue = parseInt(value, 10);
        if (intValue >= 0) {
            setTempValue(intValue - 1);
            onChange(intValue - 1);
        }
    };

    const increaseNumber = () => {
        const intValue = parseInt(value, 10);
        setTempValue(intValue + 1);
        onChange(intValue + 1);
    };

    useEffect(() => {
        setTempValue(value);
    }, [value, setTempValue]);

    return (
        <div className="flex items-center gap-1">
            <input
                className="w-12 px-2 py-0.5 border border-black/50 rounded-md text-center text-sm"
                type="number"
                value={tempValue}
                disabled={true}
            />
            <button
                type="button"
                className="border border-black/50 rounded-md"
                disabled={tempValue <= 0}
            >
                <IconWrapper
                    icon={<FiArrowDown/>}
                    size="xs"
                    itemId="number-input-decrease"
                    // tooltip="Decrease"
                    onMouseDown={decreaseNumber}
                    isDisabled={tempValue <= 0}
                />
            </button>
            <button
                type="button"
                className="border border-black/50 rounded-md"
            >
                <IconWrapper
                    icon={<FiArrowUp/>}
                    size="xs"
                    itemId="number-input-increase"
                    // tooltip="Increase"
                    onMouseDown={increaseNumber}
                />
            </button>
        </div>
    );
}


NumberInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func
};
