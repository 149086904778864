import {useMemo} from "react";
import {useGetChart} from "src/api/charts/useGetChart.js";
import {useTableData} from "src/api/data/useGetTableData.js";
import {formatDataFromQueryResult} from "src/utils/tableData.js";
import {buildOptions} from "src/components/ChartBuilder/options.js";


export function useChartOptions(chartId, tableId) {
    const {chart, loading: loadingChart} = useGetChart(chartId);
    const {result, loading: loadingData} = useTableData(tableId);

    const isLoading = useMemo(() => {
        return loadingChart || loadingData;
    }, [loadingChart, loadingData]);

    const data = useMemo(() => {
        return result ? formatDataFromQueryResult(result.columns, result.rows) : [];
    }, [result]);

    const options = useMemo(() => {
        if (!data || !chart?.config) {
            return null;
        }
        return buildOptions(data, chart.config.config, chart.config.series);
    }, [data, chart]);

    return {loading: isLoading, options};
}
