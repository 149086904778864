import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const GET_TABLE_PREVIEW = gql`
    query GetTablePreview($id: String!) {
        table (tableId: $id) {
            id
            preview
        }
    }
`;

export function useGetTablePreview(tableId) {
    const {
        data, loading, error, refetch
    } = useQuery(GET_TABLE_PREVIEW, {variables: {id: tableId}});

    const preview = useMemo(() => {
        const tp = data?.table?.preview;
        window.tp = tp;
        return tp;
    }, [data]);

    return {
        data: preview, loading, error, refetch
    };
}
