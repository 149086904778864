import React from "react";
import PropTypes from "prop-types";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {useSelectedWorkspace} from "src/utils/workspace.js";
import {getRelativePath, LINKS} from "src/links.js";
import {useGetWorkspace} from "src/api/workspaces/useGetWorkspace.js";
import {WorkspaceError} from "src/pages/Home/components/WorkspaceError/index.jsx";
import {
    AccountPage,
    ContactPage,
    FolderPage,
    GettingStartedPage,
    SelectWorkspace,
    SharedItemsPage,
    TablePage,
    TestPage,
    UploadFilePage,
    WorkspacePage
} from "./pages";
import {UpdateUser} from "./UpdateUser.jsx";
import {HomeLayout} from "./HomeLayout.jsx";


function RenderTablePage({workspaceId}) {
    const {tableId} = useParams();

    console.log("Render table page");

    return (
        <TablePage
            key={tableId}
            tableId={tableId}
            workspaceId={workspaceId}
        />
    );
}


RenderTablePage.propTypes = {
    workspaceId: PropTypes.string.isRequired
};

function RenderFolderPage({workspaceId}) {
    const {folderId} = useParams();

    return (
        <FolderPage
            key={folderId}
            folderId={folderId}
            workspaceId={workspaceId}
        />
    );
}

RenderFolderPage.propTypes = {
    workspaceId: PropTypes.string.isRequired
};


export function Home() {
    const [workspaceId] = useSelectedWorkspace();
    const {workspace, loading, error, refetch} = useGetWorkspace(workspaceId);

    if (loading) {
        return null;
    }

    if (error) {
        return (
            <WorkspaceError
                workspaceId={workspaceId}
                error={error}
                retry={refetch}
            />
        );
    }

    if (!workspaceId) {
        return (
            <>
                <SelectWorkspace/>
            </>
        );
    }

    if (!workspace) {
        return (
            <Navigate to={LINKS.START}/>
        );
    }

    // slice paths to make them relative to the root
    return (
        <>
            <UpdateUser/>
            <Routes>
                <Route path="*" element={<HomeLayout workspaceId={workspaceId}/>}>
                    <Route
                        path={getRelativePath(LINKS.tablePage(":tableId", "/*"))}
                        element={<RenderTablePage workspaceId={workspaceId}/>}
                    />
                    <Route
                        path={getRelativePath(LINKS.folderPage(":folderId", "/*"))}
                        element={<RenderFolderPage workspaceId={workspaceId}/>}
                    />
                    <Route
                        path={`${getRelativePath(LINKS.WORKSPACE)}/*`}
                        element={<WorkspacePage workspaceId={workspaceId}/>}
                    />
                    <Route
                        path={getRelativePath(LINKS.WORKSPACES)}
                        element={<SelectWorkspace/>}
                    />
                    <Route
                        path={getRelativePath(LINKS.SHARED)}
                        element={<SharedItemsPage/>}
                    />
                    <Route
                        path={`${getRelativePath(LINKS.ACCOUNT)}/*`}
                        element={<AccountPage workspaceId={workspaceId}/>}
                    />
                    <Route
                        path={`${getRelativePath(LINKS.ONBOARDING)}/*`}
                        element={<GettingStartedPage workspaceId={workspaceId}/>}
                    />
                    <Route
                        path={getRelativePath(LINKS.UPLOAD)}
                        element={<UploadFilePage workspaceId={workspaceId}/>}
                    />
                    <Route
                        path={`${getRelativePath(LINKS.CONTACT)}/*`}
                        element={<ContactPage workspaceId={workspaceId}/>}
                    />
                    <Route
                        path="test"
                        element={<TestPage workspaceId={workspaceId}/>}
                    />
                    <Route
                        path="*"
                        element={<Navigate to={`${LINKS.WORKSPACE}?wid=${workspaceId}`} />}
                    />
                </Route>
            </Routes>
        </>
    );
}
