import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useGetWorkspaceMembers} from "src/api/workspaceMembers/useGetWorkspaceMembers.js";
import {MemberTable} from "./MemberTable/index.jsx";
import {InviteUser} from "./InviteUser/index.jsx";


export function WorkspaceMembers({workspaceId}) {
    const {members, owner, loading} = useGetWorkspaceMembers(workspaceId);
    const all = [{status: "OWNER", user: owner, email: owner?.email}, ...members];

    const existingEmails = new Set(members.map(({email, user}) => user?.email || email));

    if (owner && owner.email) {
        existingEmails.add(owner.email);
    }

    return (
        <Wrapper>
            <MemberTable
                members={all}
                loading={loading}
            />
            <div className="my-2 p-2">
                <InviteUser
                    workspaceId={workspaceId}
                    existingEmails={existingEmails}
                />
            </div>
        </Wrapper>
    );
}

WorkspaceMembers.propTypes = {
    workspaceId: PropTypes.string
};

const Wrapper = styled.div`
`;
