import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "./style.css";

export function GalaxyWrapper() {
    return (
        <div className="galaxy-wrapper"/>
    );
}

export function Galaxy({children}) {
    return (
        <div className="w-full h-96 relative overflow-hidden">
            <div className="galaxy-wrapper"/>
            <Wrapper className="absolute inset-0">
                <div className="w-full h-full flex items-center justify-center">
                    {children}
                </div>
            </Wrapper>
        </div>
    );
}
Galaxy.propTypes = {
    children: PropTypes.node
};

const Wrapper = styled.div`
`;
