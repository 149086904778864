export function nextDirection(direction) {
    if (!direction) {
        return "asc";
    }
    if (direction === "asc") {
        return "desc";
    }
    if (direction === "desc") {
        return null;
    }

    return null;
}
