import React from "react";
import PropTypes from "prop-types";
import {sortByValue} from "src/utils/sorting.js";
import {formatNumber} from "src/utils/formatting.js";
import {DistributionDisplayNames, DistributionIcons} from "src/components/distributions/index.jsx";


export function ColumnFittedDistributions({fittedDistributions}) {
    const ALPHA = 0.05;
    const sortedDistributions = sortByValue(fittedDistributions, "p_value", "desc");

    return (
        <div className="flex flex-col gap-2 my-2">
            {sortedDistributions.map((item) => (
                <div
                    key={item.distribution_name}
                    className="flex items-center justify-between hover:bg-black/5 rounded-md mx-[-8px] px-2"
                >
                    <div className="flex items-center gap-2">
                        <img
                            className="w-10"
                            src={DistributionIcons[item.distribution_name]}
                            alt={item.distribution_name}
                        />
                        <div className="text-sm">
                            {DistributionDisplayNames[item.distribution_name]}
                        </div>
                    </div>
                    {/* eslint-disable-next-line max-len */}
                    <div className={item.p_value >= ALPHA ? "text-lime-600" : ""}>
                        <span className="monospace text-sm">
                            p = {formatNumber(item.p_value, 3)}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
}


ColumnFittedDistributions.propTypes = {
    fittedDistributions: PropTypes.arrayOf(PropTypes.shape({
        distribution_name: PropTypes.string,
        p_value: PropTypes.number,
        params: PropTypes.object
    }))
};
