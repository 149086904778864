import React from "react";
import PropTypes from "prop-types";
import {QueryFilterItem} from "./FilterItem.jsx";


export function QueryFilters({filters, columnsMap}) {
    return (
        <div>
            {filters.map((filterItem) => (
                <div
                    key={filterItem.name + filterItem.value}
                    className="py-4 border-b border-neutral-100 last:border-b-0"
                >
                    <QueryFilterItem
                        filterItem={filterItem}
                        columnInfo={columnsMap[filterItem.name]}
                    />
                </div>
            ))}
        </div>
    );
}

QueryFilters.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        op: PropTypes.string
    })),
    columnsMap: PropTypes.object
};
