import React from "react";
import PropTypes from "prop-types";
import {useUpdateColumnDisplaySettings} from "src/api/tableColumns/useUpdateColumnDisplaySettings.js";
import {Title} from "src/primitives/title.jsx";
import {Switch} from "src/primitives/controls/Switch/index.jsx";
import {SelectColorSchema} from "./components/SelectColorScheme";
import {getDefaultColorScheme, isValidColorScheme} from "./color_schemes.js";


export function DisplayHeatmap({column}) {
    const {tableId, name, displaySettings} = column;
    const [updateDisplaySettings] = useUpdateColumnDisplaySettings(tableId, name);
    const displayHeatmap = displaySettings?.heatmap;

    const toggleHeatmap = () => {
        const settings = {heatmap: !displayHeatmap};
        // if no color is selected, set it to the default
        if (!isValidColorScheme(displaySettings?.heatmapColor)) {
            settings.heatmapColor = getDefaultColorScheme();
        }
        updateDisplaySettings(settings, displaySettings).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
        }).catch((error) => {
            console.warn(error);
        });
    };

    const setHeatmapColor = (color) => {
        const settings = {heatmap: true, heatmapColor: color};
        updateDisplaySettings(settings, displaySettings).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
        }).catch((error) => {
            console.warn(error);
        });
    };

    return (
        <div>
            <div className="mb-2">
                <Title
                    size="xs"
                >
                    Display heatmap
                </Title>
            </div>
            <Switch
                value={displayHeatmap}
                onChange={toggleHeatmap}
                renderLabel={({isChecked}) => {
                    return isChecked ? "Heatmap is enabled" : "Heatmap is disabled";
                }}
            />
            {displayHeatmap && (
                <div className="my-2">
                    <SelectColorSchema
                        onSelect={setHeatmapColor}
                        selectedColorScheme={displaySettings?.heatmapColor}
                    />
                </div>
            )}
        </div>
    );
}

DisplayHeatmap.propTypes = {
    column: PropTypes.shape({
        tableId: PropTypes.string,
        name: PropTypes.string,
        displaySettings: PropTypes.object
    })
};
