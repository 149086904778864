import React from "react";
import PropTypes from "prop-types";

export const ControlWrapper = ({children}) => {
    return (
        <div className="mb-4">
            {children}
        </div>
    );
};


ControlWrapper.propTypes = {
    children: PropTypes.node
};
