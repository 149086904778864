/* eslint-disable max-len */
import React from "react";
import styled from "styled-components";
import {TitleText} from "src/pages/Web/components/Title.jsx";
import {Rho} from "src/pages/Web/components/Rho.jsx";
import {Text} from "src/pages/Web/components/TextBlock.jsx";


export function About() {
    return (
        <Wrapper className="py-28 px-4 w-full">
            <div className="w-192 max-w-full mx-auto">
                <div className="flex flex-col gap-6">
                    <div>
                        <TitleText>About <Rho/></TitleText>
                    </div>

                    <div className="flex flex-col gap-1 max-w-192">
                        <Text size="medium">
                            <Rho/> was created to simplify data storage for data scientists.
                        </Text>
                        <Text size="medium">
                            We recognized a big gap in how data professionals are working with data. On one hand, there are established products like BigQuery or Snowflake etc., that are built to handle petabytes at scale. On the other, data teams are emailing CSV files or storing pickled objects in GitHub.
                        </Text>
                        <Text size="medium">
                            <Rho/> offers a middle ground: an easy-to-use storage solution with built-in data features. It’s designed to make data management effortless, whether you’re working alone or with a team.
                        </Text>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  background: linear-gradient(
          to bottom, rgba(255, 255, 255, 0.005), rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.005)
  );
`;
