import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {API_KEY_FIELDS} from "src/api/fragments.js";

export const GET_USER_API_KEYS = gql`
${API_KEY_FIELDS}
query GetUserApiKeys($workspaceId: String!) {
  user {
    id
    personalApiKeys(workspaceId: $workspaceId) {
      ...ApiKeyFields
    }
  }
}
`;

export function useGetUserApiKeys(workspaceId) {
    const {data, loading, error, refetch} = useQuery(GET_USER_API_KEYS, {variables: {workspaceId}});

    const apiKeys = useMemo(() => {
        return data?.user?.personalApiKeys || [];
    }, [data]);

    return {
        apiKeys, loading, error, refetch
    };
}
