/* eslint-disable max-len */
import React from "react";
import {Title} from "src/pages/Web/components/Title.jsx";
import {Accordion} from "src/primitives/Accordion/index.jsx";
import {Text} from "src/pages/Web/components/TextBlock.jsx";
import {Rho} from "src/pages/Web/components/Rho.jsx";


export function FAQ() {
    const size = "medium";

    return (
        <div className="w-full flex flex-col">
            <div className="mb-4">
                <Title>
                    <span>
                        Frequently asked questions
                    </span>
                </Title>
            </div>

            <Accordion title="Is this a database?" size={size}>
                <Text size={size}>
                    Kind of! Technically, <Rho/> is an abstraction layer over a database. All datasets are stored in a PostgreSQL database. Then, <Rho/> is a set of APIs and SDKs on top of the actual database, with the purpose to simplify common operations for data scientists.
                </Text>
            </Accordion>

            <Accordion title="Who is this for?" size={size}>
                <Text size={size}>
                    <Rho/> is built for data scientists that wants a storage solution with maximum flexibility.
                </Text>
                <Text size={size}>
                    <Rho/> aims to be a more efficient way to manage datasets than emailing CSVs back and forth, and more flexible than implementing a full data warehouse solution.
                </Text>
            </Accordion>

            <Accordion title="Do I need this if I use {BigQuery|Snowflake|...}?" size={size}>
                <Text size={size}>
                    Maybe! The large data warehouses are great for storing and capturing large amounts of production data.
                    Their core benefits lies in the scalability and reliability, enforcing schemas and lineage across the organization.
                </Text>
                <Text size={size}>
                    But sometimes, that is not necessarily what you want. Sometimes you want to focus more on the content of the data rather than the rules around it.
                    Somtimes you want maximum flexibility to create a prototype as fast as possible.
                </Text>
                <Text size={size}>
                    That is where we hope <Rho/> can help you!
                </Text>
            </Accordion>
        </div>
    );
}

FAQ.propTypes = {
};
