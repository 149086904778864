import React, {useCallback} from "react";
import {useToast} from "@chakra-ui/react";


export function useShowToast() {
    const toast = useToast();
    const toastIdRef = React.useRef();

    const showToast = useCallback((options) => {
        toastIdRef.current = toast(options);
    }, [toast]);

    const showOrUpdatedToast = useCallback((options) => {
        if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
            toast.update(toastIdRef.current, options);
        } else {
            toastIdRef.current = toast(options);
        }
    }, [toast]);

    const showError = useCallback(({message, callback} = {}) => {
        const options = {
            status: "error",
            description: message || "Something went wrong"
        };
        showOrUpdatedToast(options);
        if (callback) {
            callback();
        }
    }, [showOrUpdatedToast]);

    const showSuccess = useCallback(({message, callback} = {}) => {
        const options = {
            status: "success",
            description: message || "Great success!"
        };
        showOrUpdatedToast(options);
        if (callback) {
            callback();
        }
    }, [showOrUpdatedToast]);

    const showLoading = useCallback(({message, callback} = {}) => {
        const options = {
            status: "loading",
            description: message || "Loading"
        };
        showOrUpdatedToast(options);
        if (callback) {
            callback();
        }
    }, [showOrUpdatedToast]);

    return {
        toast,
        showToast,
        showLoading,
        showError,
        showSuccess
    };
}
