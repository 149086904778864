import React from "react";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiNavigation} from "react-icons/fi";
import {LINKS} from "src/links.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {NavLink} from "react-router-dom";
import {
    useOnboardingProgress
} from "src/pages/Home/pages/GettingStarted/components/OnboardingChecklist/useOnboardingChecklist.jsx";
import {buttonClasses} from "./styles.jsx";

export function OnboardingButton() {
    const {totalItems, completedItems} = useOnboardingProgress();
    const getLink = useGetLink();

    return (
        <div className="mb-1 text-sm">
            <NavLink to={getLink(LINKS.ONBOARDING)}>
                {({isActive}) => (
                    <div className={buttonClasses({state: isActive ? "active" : ""})}>
                        <IconWrapper
                            icon={<FiNavigation />}
                            size="xs"
                            style={{marginRight: 4}}
                        />
                        <div>
                            Onboarding
                        </div>
                        <div
                            className="flex-1 text-right text-xs transition-opacity"
                            style={{opacity: isActive ? 1 : 0}}
                        >
                            <span>{completedItems} / {totalItems}</span>
                        </div>
                    </div>
                )}
            </NavLink>
        </div>
    );
}
