import React, {useState} from "react";
import PropTypes from "prop-types";
import {TextInput} from "src/primitives/controls/index.jsx";
import {Title} from "src/primitives/title.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiHelpCircle} from "react-icons/fi";
import {useBooleanState} from "src/utils/hooks/useBooleanState.jsx";
import {useVerifyGcpConnection} from "src/api/workspaces/useVerifyGcpConnection.js";
import {FileUploader} from "src/primitives/controls/FileUploader/index.jsx";
import {Navigation} from "src/pages/CreateWorkspace/components/CreateWorkspaceForm/Navigation.jsx";
import {Callout} from "src/primitives/Callout/index.jsx";
import {ConfigureGcpInstructions} from "./ConfigureGcpInstructions/index.jsx";
import {isValidGcpServiceAccountData, isValidInstanceConnectionName} from "./validators.js";


function isValidData(credentials, formData) {
    if (!isValidGcpServiceAccountData(credentials)) {
        return false;
    }
    if (!isValidInstanceConnectionName(formData.instance_connection_name)) {
        return false;
    }
    if (!formData.username) {
        return false;
    }
    if (!formData.password) {
        return false;
    }
    return !!formData.database;
}

const acceptJson = {"application/json": [".json"]};


export function ConfigureGcpBackend({onSave, abort}) {
    const [verifyConnection, {loading}] = useVerifyGcpConnection();
    const [showInstructions, , hide, toggle] = useBooleanState(false);
    const [credentials, setCredentials] = useState(null);
    const [formData, setFormData] = useState({
        instance_connection_name: "",
        username: "",
        password: "",
        database: ""
    });
    const [error, setError] = useState("");

    const handleFilesChanged = (files) => {
        const file = files?.[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const parsedCredentials = JSON.parse(e.target.result);
                setCredentials(parsedCredentials);
                setError("");
            } catch (err) {
                setError("Invalid JSON file. Please upload a valid service account credentials file.");
            }
        };

        if (file) {
            reader.readAsText(file);
        } else {
            setCredentials(null);
        }
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!isValidData(credentials, formData)) {
            return;
        }
        const backendCredentials = {
            ...formData,
            credentials
        };

        verifyConnection(backendCredentials).then((response) => {
            if (response.ok) {
                if (onSave) {
                    onSave(backendCredentials);
                }
            } else {
                setError(response.errorCode);
            }
        });
    };

    return (
        <>
            <ConfigureGcpInstructions
                show={showInstructions}
                hide={hide}
            />
            <div>
                <div className="flex items-center justify-between mb-2">
                    <Title style={{margin: 0}}>
                        Configure GCP backend
                    </Title>
                    <button
                        type="button"
                        className="flex items-center hover:bg-black/5 pl-4 pr-2 rounded-md"
                        onClick={toggle}
                    >
                        <span>
                            Help
                        </span>
                        <IconWrapper
                            size="small"
                            icon={<FiHelpCircle/>}
                            thickness={2}
                        />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4 mb-4 max-w-112">
                    <div>
                        <FileUploader
                            label="Upload Service Account Credentials:"
                            onChange={handleFilesChanged}
                            accept={acceptJson}
                            isSuccess={isValidGcpServiceAccountData(credentials)}
                        />
                    </div>

                    <div>
                        <TextInput
                            label="Instance Connection Name:"
                            placeholder="project-id:region:instance-name"
                            type="text"
                            name="instance_connection_name"
                            value={formData.instance_connection_name}
                            onChange={handleInputChange}
                            required={true}
                            isSuccess={isValidInstanceConnectionName(formData.instance_connection_name)}
                            hasError={(
                                !!formData.instance_connection_name
                                && !isValidInstanceConnectionName(formData.instance_connection_name)
                            )}
                        />
                    </div>

                    <div>
                        <TextInput
                            label="Username:"
                            placeholder="username"
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleInputChange}
                            required={true}
                            isSuccess={!!formData.username}
                        />
                    </div>

                    <div>
                        <TextInput
                            label="Password:"
                            type="password"
                            name="password"
                            placeholder="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required={true}
                            isSuccess={!!formData.password}
                        />
                    </div>

                    <div>
                        <TextInput
                            label="Database:"
                            type="text"
                            name="database"
                            placeholder="databasename"
                            value={formData.database}
                            onChange={handleInputChange}
                            required={true}
                            isSuccess={!!formData.database}
                        />
                    </div>

                    {error && (
                        <Callout variant="danger">
                            <div>
                                <Title size="xs">Error</Title>
                                <div>
                                    {/* eslint-disable-next-line max-len */}
                                    Failed to verify connection. Are you sure you have the correct credentials and the database is up and running?
                                </div>
                            </div>
                        </Callout>
                    )}
                </form>
                <Navigation
                    prev={abort}
                    prevText="Back"
                    next={handleSubmit}
                    nextText="Verify connection"
                    loading={loading}
                    isDisabled={!isValidData(credentials, formData)}
                />
            </div>
        </>
    );
}

ConfigureGcpBackend.propTypes = {
    onSave: PropTypes.func,
    abort: PropTypes.func
};
