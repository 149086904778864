import React, {useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useGetTableStats} from "src/api/tableData/useGetTableStats.js";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {useAddUniqueConstraint} from "src/api/tables/constraints/useAddUniqueConstraint.js";
import {useRemoveUniqueConstraint} from "src/api/tables/constraints/useRemoveUniqueConstraint.js";
import {Button} from "src/primitives/button.jsx";


export function EditColumnConstraint({tableId, columnName}) {
    const {data: tableStats} = useGetTableStats(tableId);
    const {table} = useGetTable(tableId);
    const [addUniqueConstraint] = useAddUniqueConstraint(tableId);
    const [removeUniqueConstraint] = useRemoveUniqueConstraint(tableId);


    const hasUniqueValues = useMemo(() => {
        if (!tableStats || !columnName) {
            return null;
        }

        const columnStats = tableStats[columnName];

        if (!columnStats) {
            return null;
        }

        const {distinct, values} = columnStats;
        return values > 0 && distinct === values;
    }, [tableStats, columnName]);

    const hasUniqueConstraint = useMemo(() => {
        if (!table || !columnName) {
            return null;
        }
        const {uniqueConstraints} = table;
        if (!uniqueConstraints || !Array.isArray(uniqueConstraints)) {
            return false;
        }
        const constraints = new Set(uniqueConstraints);
        return constraints.has(columnName);
    }, [table, columnName]);

    if (!hasUniqueValues) {
        return (
            <Wrapper
                className="opacity-30 hover:opacity-50 transition-opacity cursor-not-allowed"
            >
                Not unique
            </Wrapper>
        );
    }

    if (hasUniqueConstraint) {
        return (
            <Wrapper>
                <Button
                    size="xs"
                    theme="outlineBlack"
                    onClick={() => removeUniqueConstraint(columnName)}
                >
                    <span>Remove constraint</span>
                </Button>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Button
                size="xs"
                theme="outlineBlack"
                onClick={() => addUniqueConstraint(columnName)}
            >
                <span>Add constraint</span>
            </Button>
        </Wrapper>
    );
}

EditColumnConstraint.propTypes = {
    tableId: PropTypes.string.isRequired,
    columnName: PropTypes.string
};

const Wrapper = styled.div`
`;
