import React from "react";
import {useUser} from "@clerk/clerk-react";
import {Navigate} from "react-router-dom";
import {LINKS} from "src/links.js";
import {Ambient} from "src/pages/Web/components/Ambient/index.jsx";
import {StaticGlowLineEffect} from "src/pages/CreateWorkspace/StaticGlow/index.jsx";
import styled from "styled-components";
import {HeaderWrapper} from "src/pages/Web/index.jsx";
import {CreateWorkspaceForm} from "./components/CreateWorkspaceForm/index.jsx";


export function CreateWorkspacePage() {
    const {user, isLoaded} = useUser();

    if (!isLoaded) {
        return null;
    }

    if (isLoaded && !user) {
        return <Navigate to={LINKS.WEB}/>;
    }

    return (
        <Wrapper className="full">
            <HeaderWrapper/>
            <Ambient/>
            <StaticGlowLineEffect/>
            <div className="absolute inset-0 flex-center">
                <CreateWorkspaceForm userId={user.id}/>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  background: var(--bg-dark-primary);
`;
