import React from "react";

export function generateMockLayout(n = 20) {
    const values = [...Array(n).keys()];

    return values.map((i) => {
        const y = Math.ceil(Math.random() * 4) + 1;

        return {
            x: (i * 2) % 12,
            y: Math.floor(i / 6) * y,
            w: 2,
            h: y,
            i: i.toString()
        };
    });
}

export function generateMockItems(n = 20) {
    const values = [...Array(n).keys()];

    return values.map((i) => (
        <div key={i}>
            <span className="text">{i}</span>
        </div>
    ));
}
