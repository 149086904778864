import React from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {CHART_COLORS} from "src/charts/colors.js";
import {getDefaultTextStyle} from "src/charts/options.js";
import {formatPercent} from "src/utils/formatting.js";


function getOption(data, title, totalValue) {
    const categories = data.map(({name}) => name);
    const values = data.map(({value}) => value);

    return {
        textStyle: getDefaultTextStyle(),
        tooltip: {
            trigger: "item"
        },
        grid: {
            containLabel: true,
            top: "5%",
            left: "10%",
            width: "80%",
            bottom: "5%"
        },
        xAxis: {
            type: "value"
        },
        yAxis: {
            type: "category",
            data: categories
        },
        series: [{
            name: title,
            type: "bar",
            data: values,
            color: CHART_COLORS.gray,
            label: {
                show: true,
                position: "right",
                formatter: ({value}) => {
                    // return `${value} (${formatPercent(value / totalValue, 1)})`;
                    return formatPercent(value / totalValue, 1);
                }
            }
        }]
    };
}


export function HorizontalBarChart({data, title, maxItems, height}) {
    /**
     * https://echarts.apache.org/examples/en/editor.html?c=pie-simple
     */
    const totalValue = data.reduce((acc, item) => acc + item.value, 0);

    const chartData = [...data]; // Copy
    chartData.sort((a, b) => b.value - a.value);

    const slicedData = chartData.slice(0, maxItems);
    slicedData.reverse(); // Reverse when displaying

    const option = getOption(slicedData, title, totalValue);

    return (
        <ChartWrapper option={option} height={height}/>
    );
}

HorizontalBarChart.propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })),
    maxItems: PropTypes.number,
    height: PropTypes.number
};

HorizontalBarChart.defaultProps = {
    // Example
    data: [
        {value: 1048, name: "Search Engine"},
        {value: 735, name: "Direct"},
        {value: 580, name: "Email"},
        {value: 484, name: "Union Ads"},
        {value: 300, name: "Video Ads"}
    ],
    maxItems: 10
};
