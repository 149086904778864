import React from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {CHART_COLORS} from "src/charts/colors.js";
import {getAxisLabelStyle, getDefaultTextStyle} from "src/charts/options.js";
import {formatPercent} from "src/utils/formatting.js";


function getOption(data, title, totalValue) {
    const categories = data.map(({name}) => name);
    // const values = data.map(({value}) => value);

    return {
        textStyle: getDefaultTextStyle(),
        colors: CHART_COLORS,
        tooltip: {
            trigger: "item"
        },
        grid: {
            containLabel: true,
            top: "5%",
            left: "2%",
            width: "96%",
            bottom: "5%"
        },
        xAxis: {
            type: "category",
            data: categories,
            axisLabel: getAxisLabelStyle()
        },
        yAxis: {
            type: "value",
            splitLine: {
                show: true
            },
            axisLabel: getAxisLabelStyle()
        },
        series: [{
            name: title,
            type: "bar",
            data,
            color: CHART_COLORS.gray,
            label: {
                show: true,
                position: "top",
                formatter: ({value}) => {
                    // return `${value} (${formatPercent(value / totalValue, 1)})`;
                    return formatPercent(value / totalValue, 1);
                }
            }
        }]
    };
}


export function BarChart({data, title, height, sortData}) {
    /**
     * https://echarts.apache.org/examples/en/editor.html?c=pie-simple
     */
    const totalValue = data.reduce((acc, item) => acc + item.value, 0);

    const chartData = data.slice(0); // Copy

    if (sortData) {
        chartData.sort((a, b) => b.value - a.value);
        chartData.reverse(); // Reverse when displaying
    }

    const option = getOption(chartData, title, totalValue);

    return (
        <ChartWrapper
            option={option}
            height={height}
        />
    );
}

BarChart.propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })),
    height: PropTypes.number,
    sortData: PropTypes.bool
};

BarChart.defaultProps = {
    // Example
    data: [
        {value: 1048, name: "Search Engine"},
        {value: 735, name: "Direct"},
        {value: 580, name: "Email"},
        {value: 484, name: "Union Ads"},
        {value: 300, name: "Video Ads"}
    ]
};
