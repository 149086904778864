import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {FiRefreshCw, FiSave} from "react-icons/fi";
import {TextInput} from "src/primitives/controls/TextInput.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {overrideSystemHandling} from "src/utils/system.js";
import {useBooleanState} from "src/utils/hooks/useBooleanState.jsx";
import {isNullOrUndefined} from "src/utils/misc.js";


export function TextInputForm({value, onSubmit, loading}) {
    const [tempValue, setTempValue] = useState(value || "");
    const [needsSaving, setTrue, setFalse] = useBooleanState(false);

    const handleChange = (e) => {
        const newValue = e.target.value;

        setTempValue(newValue);
        if (newValue !== value) {
            setTrue();
        } else {
            setFalse();
        }
    };

    const handleSave = useCallback((val) => {
        onSubmit(typeof val === "string" ? val : tempValue);
    }, [onSubmit, tempValue]);

    const handleClear = useCallback(() => {
        setTempValue("");
        handleSave("");
        setFalse();
    }, [setTempValue, handleSave, setFalse]);

    const handleKeyUp = useCallback((e) => {
        if (e.key === "Enter") {
            overrideSystemHandling(e);
            handleSave();
        }
    }, [handleSave]);

    useEffect(() => {
        if (!isNullOrUndefined(value)) {
            setTempValue(value || "");
            setFalse();
        }
    }, [value, setTempValue, setFalse]);

    const isSuccess = !!(tempValue && (tempValue === value));
    const showIcon = loading || needsSaving;

    return (
        <div className="flex items-center gap-2">
            <TextInput
                value={tempValue}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                isSuccess={isSuccess}
                highlight={needsSaving}
                onClear={handleClear}
            />
            <div className={loading ? "animate-spin" : ""}>
                <IconWrapper
                    icon={loading ? <FiRefreshCw/> : <FiSave/>}
                    size="small"
                    onMouseDown={handleSave}
                    style={{opacity: showIcon ? 1 : 0}}
                />
            </div>
        </div>
    );
}

TextInputForm.propTypes = {
    value: PropTypes.string,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool
};
