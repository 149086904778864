import React from "react";
import PropTypes from "prop-types";
import {CategoryDistribution} from "src/charts/CategoryDistribution/index.jsx";
import {formatBooleanValues} from "src/charts/CategoryDistribution/utils.js";


export function BooleanDistribution({values, ...props}) {
    const booleanValues = formatBooleanValues(values);

    return (
        <CategoryDistribution
            values={booleanValues}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

BooleanDistribution.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({
        p: PropTypes.number,
        name: PropTypes.string,
        value: PropTypes.number
    }))
};
