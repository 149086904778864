import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {overrideSystemHandling} from "src/utils/system.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiCheck, FiEdit, FiXSquare} from "react-icons/fi";


export function TextEditable({text, onSubmit, formatValue, showActionIcons}) {
    const ref = useRef();
    const [tempText, setTempText] = useState(text);
    const [isEditable, setIsEditable] = useState(false);
    const [textRect, setTextRect] = useState(null);

    const resetState = () => {
        setTempText(text);
        setIsEditable(false);
    };

    const handleKeyUp = (e) => {
        if (e.key === "Escape") {
            overrideSystemHandling(e);
            resetState();
        }
    };

    const activateEditMode = () => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            setTextRect(rect);
        }
        setIsEditable(true);
    };

    const handleChange = (e) => {
        let {value} = e.target;
        if (formatValue) {
            value = formatValue(value);
        }
        setTempText(value);
    };

    const handleBlur = () => {
        // TODO: Save or cancel on blur??
        resetState();
    };

    const handleSubmit = (e) => {
        overrideSystemHandling(e);
        if (!tempText) {
            // Don't allow empty submits
            resetState();
            return;
        }
        const trimmedText = tempText.trim();
        if (trimmedText !== text) {
            // Only save if things have changed!
            onSubmit(trimmedText);
        } else if (trimmedText !== tempText) {
            // if only white space have changed, revert to trimmed text
            setTempText(trimmedText);
        }
        setIsEditable(false);
    };

    const inputStyle = {
        width: textRect?.width && textRect.width + 24
    };

    useEffect(() => {
        // Update state if receiving new text
        setTempText(text);
    }, [text]);

    return (
        <>
            <div style={{marginLeft: "-0.5rem"}}>
                {!isEditable && (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                        className="flex items-center gap-3 group cursor-pointer hover:bg-black/5 rounded-md px-2 mr-6"
                        ref={ref}
                        onClick={activateEditMode}
                    >
                        <span>
                            {tempText}
                        </span>
                        <span className="invisible group-hover:visible">
                            <IconWrapper
                                icon={<FiEdit/>}
                                size="xxs"
                            />
                        </span>
                    </div>
                )}
                {isEditable && (
                    <form onSubmit={handleSubmit} className="flex gap-2 items-center">
                        <input
                            style={inputStyle}
                            className="border-none bg-white/50 pl-2 pr-4 rounded-md min-w-44 max-w-96"
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus={true}
                            value={tempText}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyUp={handleKeyUp}
                        />
                        {showActionIcons && (
                            <div className="flex text-neutral-600">
                                <IconWrapper
                                    icon={<FiXSquare/>}
                                    onClick={resetState}
                                    size="xs"
                                />
                                <IconWrapper
                                    icon={<FiCheck/>}
                                    size="xs"
                                    onMouseDown={handleSubmit}
                                    clickable={true}
                                />
                            </div>
                        )}
                    </form>
                )}
            </div>
        </>
    );
}

TextEditable.propTypes = {
    text: PropTypes.string,
    onSubmit: PropTypes.func,
    formatValue: PropTypes.func,
    showActionIcons: PropTypes.bool
};

TextEditable.defaultProps = {
    showActionIcons: true
};
