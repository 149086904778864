import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {MODEL_RESULT_FIELDS} from "./fragments.js";


const GET_MODEL_QUERY = gql`
    ${MODEL_RESULT_FIELDS}
    query GetModel ($modelId: String!) {
        model (modelId: $modelId) {
            id
            model
            target
            features {
                name
                transformation
            }
            result {
                ...ModelResultFields
            }
        }
    }
`;

export function useGetModel(modelId) {
    const variables = {modelId};
    const {data, loading, error, refetch} = useQuery(GET_MODEL_QUERY, {variables});

    const model = useMemo(() => {
        return data?.model;
    }, [data]);

    return {
        model, loading, error, refetch
    };
}
