import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FiPlus} from "react-icons/fi";
import {PageContent} from "src/primitives/layout/index.jsx";
import {ContentList} from "src/pages/Home/components/ContentList/index.jsx";
import {IconButton} from "src/primitives/button.jsx";
import {AddItemMenu} from "src/pages/Home/components/AddItemMenu/index.jsx";
import {FolderPageTitle} from "./components/FolderPageTitle/index.jsx";


export function FolderContent({folderId, workspaceId}) {
    return (
        <PageContent
            size="lg"
            title={(
                <FolderPageTitle
                    folderId={folderId}
                    workspaceId={workspaceId}
                />
            )}
            subTitle={(
                <AddItemMenu workspaceId={workspaceId} folderId={folderId} goToCreatedFolder={false}>
                    <IconButton theme="secondary" icon={<FiPlus/>} text="Add"/>
                </AddItemMenu>
            )}
        >
            <Wrapper>
                <ContentList
                    workspaceId={workspaceId}
                    folderId={folderId}
                />
            </Wrapper>
        </PageContent>
    );
}

FolderContent.propTypes = {
    workspaceId: PropTypes.string,
    folderId: PropTypes.string
};

const Wrapper = styled.div`
`;
