import React, {useCallback, useContext, useRef} from "react";
import styled from "styled-components";
import {NewFileContext, SetNewFileContext} from "src/pages/Home/contexts/file_context.jsx";
import {useGoToUploadPage} from "src/utils/useGetLink.js";
import {generateId} from "src/utils/id.js";
import {TextInput} from "src/primitives/controls/TextInput.jsx";
import {Button} from "src/primitives/button.jsx";


export function ChooseFile() {
    const divId = generateId();
    const inputRef = useRef();
    const goToUploadPage = useGoToUploadPage();
    const setNewFile = useContext(SetNewFileContext);
    const newFile = useContext(NewFileContext);

    const clearSelectedFile = useCallback(() => {
        if (setNewFile) {
            setNewFile(null);
        }
    }, [setNewFile]);

    const handleFileInputChange = useCallback((event) => {
        const file = event.target.files[0];
        if (file && setNewFile) {
            setNewFile(file);
            goToUploadPage();
        }
    }, [setNewFile, goToUploadPage]);

    const handleClick = useCallback(() => {
        if (!newFile) {
            if (inputRef.current) {
                // Trigger open file selector
                inputRef.current.click();
            }
        } else {
            clearSelectedFile();
        }
    }, [clearSelectedFile, newFile]);

    return (
        <div className="w-full relative flex gap-2 items-center">
            <Input
                id={divId}
                ref={inputRef}
                type="file"
                required={true}
                onChange={handleFileInputChange}
                accept="text/csv"
            />
            <label htmlFor={divId}>
                <Button
                    theme="secondary"
                    style={{width: "10rem"}}
                    onClick={handleClick}
                >
                    {newFile ? "Clear" : "Select file"}
                </Button>
            </label>
            <TextInput
                placeholder=""
                disabled={true}
                isSuccess={true}
                value={newFile?.name || ""}
            />
        </div>
    );
}

const Input = styled.input`
  left: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
`;
