import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const GET_WORKSPACE_QUERY = gql`
query GetWorkspace ($id: String) {
  workspace (workspaceId: $id) {
    id
    createdAt
    name
    usage {
      totalTables
      totalBytes
    }
  }
}
`;

export function useGetWorkspace(workspaceId) {
    const options = {variables: {id: workspaceId}, skip: !workspaceId};
    const {data, loading, error, refetch} = useQuery(GET_WORKSPACE_QUERY, options);

    const workspace = useMemo(() => {
        return data?.workspace;
    }, [data]);

    return {
        workspace, loading, error, refetch
    };
}
