import React from "react";
import {PageContent, PageLayout} from "src/primitives/layout/index.jsx";
import {ContentGrid} from "src/pages/Home/components/ContentList/ContentGrid.jsx";
import {useGetSharedResources} from "src/api/sharing/useGetSharedResources.js";
import {EaseIn} from "src/primitives/EaseIn.jsx";


export function SharedItemsPage() {
    const {folders: sharedFolders, tables: sharedTables, loading} = useGetSharedResources();

    return (
        <PageLayout
            title="Shared items"
            loading={loading}
        >
            <PageContent
                size="lg"
                title="Shared items"

            >
                <EaseIn loading={loading}>
                    <ContentGrid
                        tables={sharedTables}
                        folders={sharedFolders}
                    />
                </EaseIn>

            </PageContent>
        </PageLayout>
    );
}

SharedItemsPage.propTypes = {
};
