import React from "react";
import {Button} from "src/primitives/button.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiCode} from "react-icons/fi";
import {useCodeHintsState} from "src/pages/Home/components/CodeHints/states.jsx";


export function ToggleCodeHintsButton() {
    const [isOpen, toggle] = useCodeHintsState((state) => [state.isOpen, state.toggle]);

    return (
        <Button onClick={toggle} theme="secondary" size="xs">
            <div className="flex items-center gap-2" style={{marginLeft: -4}}>
                <IconWrapper icon={<FiCode/>} size="xxs"/>
                <span>
                    {isOpen ? "Hide code examples" : "Show code examples"}
                </span>
            </div>
        </Button>
    );
}
