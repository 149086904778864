import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";


const titleClasses = cva({
    base: "font-bold",
    variants: {
        size: {
            xxs: "text-xs",
            xs: "text-sm",
            small: "text-base mt-1 mb-2",
            medium: "text-lg mt-2 mb-4",
            large: "text-2xl mt-4 mb-6"
        },
        mode: {
            uppercase: "uppercase"
        }
    },
    defaultVariants: {
        size: "medium"
    }
});


export function Title({children, size, style, mode, ...props}) {
    return (
        <div
            className={titleClasses({size, mode})}
            style={style}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            {children}
        </div>
    );
}
Title.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
    size: PropTypes.oneOf([
        "xxs",
        "xs",
        "small",
        "medium",
        "large"
    ]),
    mode: PropTypes.string
};
