import React, {useCallback, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {arrayToMap} from "src/utils/misc.js";
import {TableHeader} from "src/components/SimpleTable/TableHeader.jsx";
import {TableFooter} from "src/components/SimpleTable/TableFooter.jsx";
import {smartSorting} from "src/utils/sorting.js";
import {Table, Tbody, Td, Tr} from "./components.jsx";
import {nextDirection} from "./utils.jsx";


export function SimpleTable({data, headers, footers, columns}) {
    const [sorting, setSorting] = useState(null);

    const handleSort = useCallback((key) => {
        setSorting((prevValue) => {
            if (prevValue?.key === key) {
                return {key, direction: nextDirection(prevValue.direction)};
            }
            return {key, direction: "asc"};
        });
    }, [setSorting]);

    const getSortStatus = (key) => {
        if (key === sorting?.key) {
            return sorting.direction;
        }
        return null;
    };

    if (columns) {
        // eslint-disable-next-line no-param-reassign
        headers = columns.map(({header}) => header);
        // eslint-disable-next-line no-param-reassign
        footers = columns.map(({footer}) => footer);
    } else {
        // eslint-disable-next-line no-param-reassign
        columns = Object.keys(data[0]).map((key) => ({key}));
    }

    const keys = columns.map(({key}) => key);
    const columnMap = arrayToMap(columns, "key");
    const formatters = useMemo(() => {
        return columns.filter(({key, render}) => key && render).reduce((acc, item) => {
            return Object.assign({}, acc, {[item.key]: item.render});
        }, {});
    }, [columns]);

    const hasHeader = useMemo(() => {
        if (!Array.isArray(headers)) {
            return false;
        }
        const existingHeaders = headers.filter((value) => !!value);
        return existingHeaders.length > 0;
    }, [headers]);

    const hasFooter = useMemo(() => {
        if (!Array.isArray(footers)) {
            return false;
        }
        const existingFooters = footers.filter((value) => !!value);
        return existingFooters.length > 0;
    }, [footers]);

    const getCellStyle = useCallback((key, value) => {
        const columnObject = columnMap[key];
        if (columnObject.getStyle) {
            return columnObject.getStyle(value);
        }

        return null;
    }, [columnMap]);

    const getValue = useCallback((key, row) => {
        const columnObject = columnMap[key];
        if (columnObject.getValue) {
            return columnObject.getValue(row);
        }
        return row[key];
    }, [columnMap]);

    const extendedData = useMemo(() => {
        return data.map((row) => {
            const extendedFields = columns.reduce((acc, column) => {
                if (column.getValue) {
                    return Object.assign(acc, {[column.key]: column.getValue(row)});
                }
                return acc;
            }, {});
            return {...row, ...extendedFields};
        });
    }, [data, columns]);

    const sortedData = useMemo(() => {
        if (sorting && sorting.direction) {
            return smartSorting(extendedData, sorting.key, sorting.direction);
        }
        // default
        return smartSorting(extendedData, columns[0].key, "asc");
    }, [extendedData, sorting, columns]);

    return (
        <Wrapper>
            <TableContainer>
                <Table>
                    {hasHeader && (
                        <TableHeader
                            keys={keys}
                            headers={headers}
                            handleSort={handleSort}
                            getSortStatus={getSortStatus}
                        />
                    )}

                    <Tbody>
                        {sortedData.map((row, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Tr key={`row-${i}`}>
                                {keys.map((key) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Td key={`row-${i}-${key}`} style={getCellStyle(key, getValue(key, row))}>
                                        {formatters[key]
                                            ? formatters[key](getValue(key, row), row)
                                            : getValue(key, row)}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>

                    {hasFooter && (
                        <TableFooter keys={keys} footers={footers}/>
                    )}
                </Table>
            </TableContainer>
        </Wrapper>
    );
}

SimpleTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string),
    footers: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        header: PropTypes.node,
        render: PropTypes.func,
        footer: PropTypes.node,
        getStyle: PropTypes.func
    }))
};

SimpleTable.defaultProps = {
};


const Wrapper = styled.div`
  margin: 10px 0;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const TableContainer = styled.div`
  display: block;
  white-space: nowrap;
  max-width: 100%;
`;
