import React from "react";
import PropTypes from "prop-types";
import {useUpdateColumnTarget} from "src/api/tableColumns/useUpdateColumnTarget.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {Title} from "src/primitives/title.jsx";
import {Button} from "src/primitives/button.jsx";


export function SelectFeatureColumn({tableId, columns}) {
    const [update, {loading}] = useUpdateColumnTarget(tableId);

    const setColumnAsFeatureColumn = (colName) => {
        update(colName, true);
    };

    if (loading) {
        return (
            <Spinner/>
        );
    }

    return (
        <div className="my-4 flex flex-col gap-2">
            <Title size="xs">
                Select target column to show aggregations:
            </Title>

            {columns.map((column) => (
                <Button
                    size="xs"
                    theme="outlineBlack"
                    key={column.name}
                    onClick={setColumnAsFeatureColumn.bind(null, column.name)}
                >
                    {column.name}
                </Button>
            ))}
        </div>
    );
}

SelectFeatureColumn.propTypes = {
    tableId: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
    }))
};
