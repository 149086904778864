import styled from "styled-components";

/**
 * Do we need this class at all?
 * @type {IStyledComponent<"web", Substitute<import("react").DetailedHTMLProps<import("react").HTMLAttributes<HTMLDivElement>, HTMLDivElement>, BaseObject>> & BaseObject & {}}
 */
export const CellBaseClass = styled.div`
  border-bottom: 1px solid;
  border-right: 1px solid;

  border-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 4px 6px;

  &:focus {
    // outline: 2px solid rgb(59, 134, 70);
    outline: #4d9e3c auto 1px;
  }
`;
