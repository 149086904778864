import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useGetTablePreview} from "src/api/tableData/useGetTablePreview.js";
import {useGetTableStats} from "src/api/tableData/useGetTableStats.js";
import {SelectInput, TextInput} from "src/primitives/controls/index.jsx";
import {InputRange} from "src/primitives/controls/InputRange.jsx";
import {useGetColumns} from "src/api/tableColumns/useGetColumns.js";
import {arrayToMap} from "src/utils/misc.js";
import {isColumnCategorical, isColumnNumeric} from "src/utils/tableData.js";


const FeatureWrapper = ({children}) => (
    <div className="border-b border-b-black/5 pb-2 mb-2 last:border-b-0 last:mb-0">
        {children}
    </div>
);

FeatureWrapper.propTypes = {
    children: PropTypes.node.isRequired
};


export function FeaturesForm({tableId, features, onChange}) {
    const [featureState, setFeatureState] = React.useState({});
    const {data: tablePreview} = useGetTablePreview(tableId);
    const {data: tableStats} = useGetTableStats(tableId);
    const {columns} = useGetColumns(tableId);
    const columnsMap = arrayToMap(columns, "name");

    const updateData = (key, value) => {
        setFeatureState((prevState) => {
            return Object.assign({}, prevState, {[key]: value});
        });
    };

    useEffect(() => {
        if (onChange) {
            onChange(featureState);
        }
    }, [featureState, onChange]);

    if (!columns) {
        return null;
    }

    return (
        <div className="max-w-112">
            {features.map(({name, transformation}) => {
                const label = (
                    <div className="flex items-center justify-between">
                        <span>{name}</span>
                        <span className="text-xs monospace text-neutral-600">{transformation}</span>
                    </div>
                );

                const column = columnsMap[name];
                if (!column) {
                    return (
                        <FeatureWrapper key={name}>
                            <div className="text-red-500">
                                {`Column ${name} not found. It might have been deleted since the model was created.`}
                            </div>
                        </FeatureWrapper>
                    );
                }

                if (isColumnNumeric(column)) {
                    const columnStats = tableStats[column.name];

                    return (
                        <FeatureWrapper key={name}>
                            <InputRange
                                label={label}
                                onChange={(value) => updateData(name, value)}
                                value={featureState[name] || Math.round(columnStats.avg)}
                                minValue={columnStats.min}
                                maxValue={columnStats.max}
                            />
                        </FeatureWrapper>
                    );
                }

                if (isColumnCategorical(column)) {
                    const options = tablePreview[column.name].map((item) => ({
                        name: item.name || "null",
                        value: item.name
                    }));
                    const selectedOption = options.find((item) => item.value === featureState[name]);

                    return (
                        <FeatureWrapper key={name}>
                            <SelectInput
                                label={label}
                                options={options}
                                selectedOption={selectedOption}
                                onChange={(option) => updateData(name, option?.value)}
                                getValue={(item) => item.value}
                            />
                        </FeatureWrapper>
                    );
                }

                return (
                    <FeatureWrapper key={name}>
                        <TextInput
                            label={label}
                            value={featureState[name] || ""}
                            onChange={(e) => updateData(name, e.target.value)}
                        />
                    </FeatureWrapper>
                );
            })}
        </div>
    );
}

FeaturesForm.propTypes = {
    tableId: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        transformation: PropTypes.string
    })),
    onChange: PropTypes.func
};
