import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/ext-language_tools";
// eslint-disable-next-line no-unused-vars
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-github";
import {addOrReplaceAutoCompleter, getAutoCompleter} from "src/components/SqlEditor/autocomplete.js";
import "src/components/SqlEditor/editor.css";
import {padString} from "src/components/SqlEditor/utils.js";


function highlightTables(editor, tableNames) {
    // Obs! Not working when the name have dots. The editor splits that up in multiple divs.
    // const elements = window.document.getElementsByClassName("ace_identifier");
    const elements = [...editor.container.getElementsByClassName("ace_identifier")];
    if (!elements) {
        return;
    }

    elements.forEach((element) => {
        const elementContent = element.textContent;
        console.log(elementContent);
        if (tableNames.indexOf(padString(elementContent)) >= 0) {
            element.classList.add("id_table");
            // eslint-disable-next-line no-param-reassign
            element.style.color = "green";
        }
    });
}


export function SqlEditor({submit, defaultText, tables}) {
    const editorRef = useRef();
    const [value, setValue] = useState(defaultText || "SELECT *\nFROM table;\n");
    const tableNames = useMemo(() => {
        return tables.map((table) => padString(table.name));
    }, [tables]);

    const handleSubmit = useCallback((editor) => {
        let sql = editor.getValue().trim();
        tables.forEach((table) => {
            sql = sql.replaceAll(padString(table.name), table.id);
        });
        if (submit && sql) {
            submit(sql);
        }
    }, [submit, tables]);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const onLoad = (editor) => {
        editorRef.current = editor;
        window.e = editor;
        window.setTimeout(() => {
            highlightTables(editor, tableNames);
        }, 100);
    };

    useEffect(() => {
        if (!editorRef.current) {
            return;
        }
        if (tableNames) {
            const tableAutoCompleter = getAutoCompleter(tableNames, "table", "tables");
            // langTools.addCompleter(tableAutoCompleter)
            addOrReplaceAutoCompleter(editorRef.current, tableAutoCompleter);
        }
    }, [tableNames]);

    useEffect(() => {
        if (!editorRef.current) {
            return;
        }
        // Trigger for either new 'value' or new tables
        window.setTimeout(() => {
            highlightTables(editorRef.current, tableNames);
        }, 100);
    }, [value, tableNames]);

    return (
        <div>
            <AceEditor
                placeholder="Write something!"
                mode="sql"
                theme="github"
                name="sql_editor"
                width="100%"
                height="120px"
                onLoad={onLoad}
                onChange={handleChange}
                fontSize={14}
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={true}
                value={value}
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={true}
                setOptions={{
                    showLineNumbers: true,
                    tabSize: 2
                }}
                commands={[{
                    name: "submit",
                    bindKey: {win: "Ctrl-Enter", mac: "Command-Enter"},
                    exec: handleSubmit
                }]}
            />
        </div>
    );
}

SqlEditor.propTypes = {
    submit: PropTypes.func,
    defaultText: PropTypes.string,
    tables: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    }))
};
