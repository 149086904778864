import React from "react";
import styled from "styled-components";
import {TextHighlight} from "src/pages/Web/components/TextHighlight.jsx";
import {Features} from "src/pages/Web/pages/Start/components/Features/index.jsx";
import {Ambient} from "src/pages/Web/components/Ambient/index.jsx";
import {FAQ} from "src/pages/Web/pages/Start/components/FAQ/index.jsx";
import {GalaxyWrapper} from "src/pages/Web/components/Galaxy/index.jsx";
import {About} from "src/pages/Web/sections/About/index.jsx";
import {StartIntro} from "src/pages/Web/pages/Start/components/Intro/index.jsx";


export function StartPage() {
    return (
        <>
            <Ambient/>
            <GalaxyWrapper/>

            <Wrapper>
                <div className="max-w-96 text-center text-2xl mx-auto my-6 lg:my-8 lg:mt-10 appear-fast">
                    <TextHighlight theme="green">rho.store</TextHighlight>
                </div>

                <div className="appear">
                    <StartIntro/>
                </div>

                <div className="appear-slow">
                    <div className="max-w-96 text-center text-2xl mx-auto mt-8 lg:my-12">
                        <TextHighlight theme="white">features</TextHighlight>
                    </div>

                    <Features/>
                </div>

                <div className="my-10">
                    <About/>
                </div>

                <div className="my-10 w-192 max-w-full mx-auto px-8">
                    <FAQ/>
                </div>

            </Wrapper>
        </>
    );
}


const Wrapper = styled.div`
`;

