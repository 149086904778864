import React from "react";
import PropTypes from "prop-types";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {Title} from "src/primitives/title.jsx";
import {ListOptions} from "src/primitives/controls/ListOptions.jsx";


export function SelectMergeIndex({tableId, selectedColumn, onChange}) {
    const {table, loading} = useGetTable(tableId);
    const uniqueConstraints = table?.uniqueConstraints || [];

    const options = uniqueConstraints.map((columnName) => {
        return {
            key: columnName,
            label: <span>{columnName}</span>
        };
    });

    const isSelected = (key) => key === selectedColumn;

    return (
        <div>
            <div className="mb-2">
                <Title size="xs">
                    Select column to merge on
                </Title>
            </div>
            {!tableId && (
                <div>
                    Select table
                </div>
            )}
            {(tableId && !loading && uniqueConstraints.length === 0) && (
                <div>
                    <p>
                        No unique constraints for table.
                    </p>
                    <p>To be able to merge two tables, it is required to have an unique column to merge on.</p>
                </div>
            )}
            {(tableId && !loading && uniqueConstraints.length > 0) && (
                <div>
                    <ListOptions
                        options={options}
                        onSelect={onChange}
                        isSelected={isSelected}
                    />
                </div>
            )}
        </div>
    );
}

SelectMergeIndex.propTypes = {
    tableId: PropTypes.string,
    selectedColumn: PropTypes.string,
    onChange: PropTypes.func
};
