import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const GET_CATEGORICAL_RELATIONS_QUERY = gql`
  query GetCategoricalRelations($id: String!) {
    table (tableId: $id) {
      id
      categoricalRelations
    }
  }
`;


export function useGetCategoricalRelations(tableId) {
    const {
        data, loading, error, refetch
    } = useQuery(GET_CATEGORICAL_RELATIONS_QUERY, {variables: {id: tableId}});

    const result = useMemo(() => {
        if (!data?.table?.categoricalRelations) {
            return {};
        }
        const cr = data.table.categoricalRelations;
        window.cr = cr;
        return cr;
    }, [data]);

    return {
        data: result, loading, error, refetch
    };
}
