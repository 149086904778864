import React from "react";
import PropTypes from "prop-types";
import {formatPrettyString} from "src/utils/formatting.js";
import {Circle} from "src/primitives/circle.jsx";
import {Card} from "src/primitives/Card.jsx";
import {OVERALL_STRATEGY} from "./strategies.js";


export function OverallStrategy({strategy, onChange}) {
    const keys = Object.keys(OVERALL_STRATEGY);

    return (
        <div className="mb-4">
            <div className="flex gap-4">
                {keys.map((key) => (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <Card
                        key={key}
                        state={OVERALL_STRATEGY[key] === strategy ? "active" : null}
                        onClick={onChange.bind(null, OVERALL_STRATEGY[key])}
                    >
                        <div className="full flex items-center gap-2">
                            <Circle
                                theme="black"
                                size="medium"
                                mode={OVERALL_STRATEGY[key] === strategy ? null : "outline"}
                            />
                            <span className="text-sm">
                                {formatPrettyString(OVERALL_STRATEGY[key])}
                            </span>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
}

OverallStrategy.propTypes = {
    strategy: PropTypes.oneOf(Object.values(OVERALL_STRATEGY)),
    onChange: PropTypes.func.isRequired
};

OverallStrategy.defaultProps = {
    strategy: OVERALL_STRATEGY.CREATE_NEW_TABLE
};
