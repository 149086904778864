import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Table} from "src/components/Table/index.jsx";


function formatColumnTypes(columns) {
    if (!Array.isArray(columns)) {
        return {};
    }
    return columns.reduce((acc, item) => Object.assign(acc, {[item.name]: item.dataType}), {});
}

export function QueryResult({result, columns, statsMap, actions, onEdit}) {
    if (!result) {
        // Empty state
        return (
            <Wrapper/>
        );
    }

    if (result && result.rows.length === 0) {
        // table exist but no rows
        return (
            <Wrapper>
                <p>No rows!</p>
            </Wrapper>
        );
    }

    return (
        <Table
            rows={result.rows}
            headers={result.columns}
            columnTypes={formatColumnTypes(columns)}
            statsMap={statsMap}
            actions={actions}
            onEdit={onEdit}
        />
    );
}

QueryResult.propTypes = {
    result: PropTypes.shape({
        rows: PropTypes.array,
        columns: PropTypes.array
    }),
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string
    })),
    statsMap: PropTypes.object,
    actions: PropTypes.object,
    onEdit: PropTypes.func
};

const Wrapper = styled.div`
`;
