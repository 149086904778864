import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

export function LinkList({links}) {
    return (
        <div className="text-sm">
            <ul>
                {links.map((link) => (
                    <li key={link.url} className="my-3">
                        <Link
                            to={link.url}
                            className="text-white/70 hover:text-white/90"
                            // default to _blank for external links
                            target={link.url.startsWith("http") ? "_blank" : "_self"}
                        >
                            {link.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

LinkList.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.node
    }))
};
