import {useCallback, useMemo} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
  mutation DeleteChart($chartId: String!) {
    deleteChart (chartId: $chartId) {
      ok
      errorCode
      chartId
    }
  }
`;


function removeChartFromCache(cache, response) {
    const {ok, chartId} = response.data.deleteChart;
    if (!ok) {
        return;
    }
    const model = {
        id: chartId,
        __typename: "Chart"
    };
    const normalizedId = cache.identify(model);
    cache.evict({id: normalizedId});
    cache.gc();
}

export function useDeleteChart() {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((chartId) => {
        return mutation({
            variables: {chartId},
            update(cache, response) {
                removeChartFromCache(cache, response);
            }
        }).then((response) => response.data.deleteChart);
    }, [mutation]);

    const result = useMemo(() => {
        return data?.deleteChart;
    }, [data]);

    return [doMutate, {loading, error, result}];
}
