import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {HistogramMulti} from "src/charts/HistogramMulti.jsx";
import {Title} from "src/primitives/title.jsx";


export function Distributions({targetColumn}) {
    return (
        <Wrapper>
            <Title>
                Distributions
            </Title>
            {targetColumn}
            <HistogramMulti/>
        </Wrapper>
    );
}
Distributions.propTypes = {
    targetColumn: PropTypes.string
};

const Wrapper = styled.div`
`;
