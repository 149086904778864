import {getRange} from "src/utils/misc.js";

export class DataHelper {
    constructor(tableRef) {
        this.tableRef = tableRef;
    }

    getHotInstance = () => this.tableRef.current.hotInstance;

    getRowId = (rowIndex) => {
        const hotInstance = this.getHotInstance();
        const row = hotInstance.getDataAtRow(rowIndex);
        return row[0];
    };

    getColumnName = (colIndex) => {
        const hotInstance = this.getHotInstance();
        return hotInstance.getColHeader(colIndex);
    };

    getColumnNames = (colStartIndex, colEndIndex) => {
        const hotInstance = this.getHotInstance();
        // const start = Math.min(colStartIndex, colEndIndex);
        // const end = Math.max(colStartIndex, colEndIndex);
        const step = colStartIndex <= colEndIndex ? 1 : -1;
        const range = getRange(colStartIndex, colEndIndex, step);
        return range.map((index) => hotInstance.getColHeader(index));
    };

    formatChangeArray = (changeArray) => {
        const colIndex = changeArray[1];
        const rowIndex = changeArray[0];
        return {
            rowIndex,
            colIndex,
            rowId: this.getRowId(rowIndex),
            colName: this.getColumnName(colIndex),
            from: changeArray[2],
            to: changeArray[3]
        };
    };
}
