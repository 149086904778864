function validateGcpServiceAccountData(data) {
    if (!data) {
        throw new Error("GCP service account credentials not provided");
    }
    if (data.type !== "service_account") {
        throw new Error("GCP service account credentials not provided");
    }
    if (!data.project_id) {
        throw new Error("GCP project id not provided");
    }
    if (!data.private_key_id) {
        throw new Error("GCP private key id not provided");
    }
    if (!data.private_key) {
        throw new Error("GCP private key not provided");
    }
    if (!data.client_email) {
        throw new Error("GCP client email not provided");
    }
    if (!data.client_id) {
        throw new Error("GCP client id not provided");
    }
    if (!data.auth_uri) {
        throw new Error("GCP auth uri not provided");
    }
    if (!data.token_uri) {
        throw new Error("GCP token uri not provided");
    }
    if (!data.auth_provider_x509_cert_url) {
        throw new Error("GCP auth provider x509 cert url not provided");
    }
    if (!data.client_x509_cert_url) {
        throw new Error("GCP client x509 cert url not provided");
    }
    if (!data.universe_domain) {
        throw new Error("GCP universe domain not provided");
    }
}
export function isValidGcpServiceAccountData(data) {
    try {
        validateGcpServiceAccountData(data);
        return true;
    } catch (error) {
        return false;
    }
}


function validateInstanceConnectionName(connectionName) {
    // Regular expression to match the GCP instance connection name format
    const regex = /^[a-z][-a-z0-9]*[a-z0-9]:[-a-z0-9]+:[a-z][-a-z0-9]*[a-z0-9]$/;

    if (!connectionName || typeof connectionName !== "string") {
        throw new Error("Instance connection name must be a non-empty string");
    }

    if (!regex.test(connectionName)) {
        throw new Error("Invalid instance connection name format. Expected format: project-id:region:instance-name");
    }

    const parts = connectionName.split(":");
    if (parts.length !== 3) {
        throw new Error("Instance connection name must have exactly three parts separated by colons");
    }

    const [projectId, region, instanceName] = parts;

    if (projectId.length < 6 || projectId.length > 30) {
        throw new Error("Project ID must be between 6 and 30 characters long");
    }

    if (region.length < 2 || region.length > 32) {
        throw new Error("Region must be between 2 and 32 characters long");
    }

    if (instanceName.length < 1 || instanceName.length > 63) {
        throw new Error("Instance name must be between 1 and 63 characters long");
    }

    // If we've made it this far, the connection name is valid
    return true;
}

// Wrapper function to return boolean instead of throwing errors
export function isValidInstanceConnectionName(connectionName) {
    try {
        validateInstanceConnectionName(connectionName);
        return true;
    } catch (error) {
        return false;
    }
}
