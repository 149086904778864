import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Dialog, Transition} from "@headlessui/react";
import {FiX} from "react-icons/fi";
import {cva} from "cva";
import {Title} from "src/primitives/title.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {ModalBackdrop} from "./ModalBackdrop.jsx";


const dialogWrapperClasses = cva({
    base: "flex h-full min-h-full justify-center p-4 relative",
    variants: {
        verticalAlign: {
            top: "items-start pt-[10vh]",
            center: "items-center",
            bottom: "items-end pb-[10vh]"
        }
    },
    defaultVariants: {
        verticalAlign: "center"
    }
});


const dialogPanelClasses = cva({
    // eslint-disable-next-line max-len
    base: "w-full max-h-full flex overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transform transition-all",
    variants: {
        size: {
            sm: "max-w-sm",
            md: "max-w-md",
            lg: "max-w-lg",
            xl: "max-w-xl",
            "2xl": "max-w-2xl"
        }
    },
    defaultVariants: {
        size: "md"
    }
});


export function DialogModal({close, title, children, footer, isOpen, size, verticalAlign}) {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <ModalBackdrop/>

                <div className="fixed inset-0 overflow-hidden">
                    <div className={dialogWrapperClasses({verticalAlign})}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className={dialogPanelClasses({size})}>
                                <div className="flex-1 flex flex-col">
                                    <div className="px-4 py-3 border-b border-neutral-200">
                                        <div className="w-full flex items-center justify-between">
                                            <Title size="small" style={{margin: 0}}>
                                                {title}
                                            </Title>
                                            <IconWrapper
                                                onClick={close}
                                                size="xs"
                                                icon={<FiX/>}
                                            />
                                        </div>
                                    </div>

                                    <div className="py-4 px-4 text-sm overflow-y-auto">
                                        {children}
                                    </div>

                                    {footer && (
                                        <div className="bg-neutral-100 px-4 py-3 mt-4">
                                            {footer}
                                        </div>
                                    )}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

DialogModal.propTypes = {
    close: PropTypes.func,
    title: PropTypes.node,
    children: PropTypes.node,
    footer: PropTypes.node,
    isOpen: PropTypes.bool,
    size: PropTypes.oneOf(["sm", "md", "lg", "xl", "2xl"]),
    verticalAlign: PropTypes.oneOf(["top", "center", "bottom"])
};

DialogModal.defaultProps = {
    isOpen: true
};
