import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";


export const LoadingBar = ({completedState}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = window.setInterval(() => {
            const step = 5 * Math.random();
            setProgress((prev) => Math.min(prev + step, 120));
        }, 250); // Increase progress every 250ms

        return () => window.clearInterval(interval);
    }, []);

    if (progress >= 120 && completedState) {
        return (
            <div className="fade-in">
                {completedState}
            </div>
        );
    }

    return (
        <div
            className="w-full h-2 bg-lime-200/10 rounded-sm overflow-hidden outline outline-1 outline-lime-600/80"
        >
            <div
                className="h-full bg-lime-500 transition-all duration-300 ease-in-out"
                style={{width: `${progress}%`}}
            />
        </div>
    );
};

LoadingBar.propTypes = {
    completedState: PropTypes.node
};
