import React from "react";
import PropTypes from "prop-types";
import {useGetFolder} from "src/api/folders/useGetFolder.js";
import {ShareResource} from "./index.jsx";
import {ResourceType} from "./constants.js";


export function ShareFolder({folderId}) {
    const {folder} = useGetFolder(folderId);

    return (
        <ShareResource
            resourceId={folderId}
            resourceType={ResourceType.FOLDER}
            data={folder?.sharedResources}
        />
    );
}

ShareFolder.propTypes = {
    folderId: PropTypes.string.isRequired
};
