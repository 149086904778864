import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Button} from "src/primitives/button.jsx";
import {TextInput} from "src/primitives/controls";
import {useShowToast} from "src/components/toasts/useShowToast.js";


export function CopyLink({link}) {
    const {showSuccess} = useShowToast();
    const [copied, setIsCopied] = useState(false);

    const onLinkCopied = useCallback(() => {
        setIsCopied(true);
        showSuccess({message: "Link copied!"});
        window.setTimeout(() => {
            setIsCopied(false);
        }, 2500);
    }, [showSuccess]);

    return (
        <div className="flex items-center gap-2">
            <TextInput
                size="small"
                defaultValue={link}
                isDisabled={true}
                selectOnClick={true}
            />

            <CopyToClipboard
                text={link}
                onCopy={onLinkCopied}
            >
                <Button
                    size="xs"
                    theme="outlineBlack"
                    style={{width: 80}}
                >
                    {copied ? "Copied!" : "Copy"}
                </Button>
            </CopyToClipboard>
        </div>
    );
}

CopyLink.propTypes = {
    link: PropTypes.string
};
