import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Title} from "src/primitives/title.jsx";


export function ColumnSample({columnName, values}) {
    const getValueName = (value) => {
        return typeof value === "object" ? value?.name : value;
    };
    const displayValues = values.filter((item) => !!item).slice(0, 5);

    return (
        <Wrapper className="flex flex-col w-full ml-4">
            <Title size="xs">
                Sample
            </Title>
            <div className="monospace text-xs">
                {displayValues.map((item) => (
                    <div
                        key={`${columnName}-${getValueName(item)}`}
                        className="mt-1"
                    >
                        {getValueName(item)}
                    </div>
                ))}
            </div>
        </Wrapper>
    );
}

ColumnSample.propTypes = {
    columnName: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.oneOfType([
        // could be strings, numbers or objects
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({name: PropTypes.string})
    ]))
};

const Wrapper = styled.div`
`;
