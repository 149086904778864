import React, {useState} from "react";
import PropTypes from "prop-types";
import {useCreateFolder} from "src/api/folders/useCreateFolder.js";
import {TextInput} from "src/primitives/controls/TextInput.jsx";
import {Button} from "src/primitives/button.jsx";


export function NewFolderForm({workspaceId, parentId, onSuccess}) {
    const [name, setName] = useState("");
    const [createFolder, {loading}] = useCreateFolder(workspaceId);

    const handleChange = (e) => {
        setName(e.target.value);
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (!name) {
            return;
        }
        const data = {name: name.trim(), parentFolderId: parentId};
        createFolder(data).then((result) => {
            onSuccess(result);
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <form className="flex items-center gap-2">
            <div className="flex-grow">
                <TextInput
                    placeholder="New folder name"
                    autoFocus={true}
                    value={name}
                    onChange={handleChange}
                />
            </div>
            <Button
                theme="secondary"
                onClick={handleSubmit}
                isLoading={loading}
                isDisabled={!name}
            >
                Save
            </Button>
        </form>
    );
}

NewFolderForm.propTypes = {
    workspaceId: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    onSuccess: PropTypes.func
};
