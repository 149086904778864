
export const LINKS = {
    WEB: "/",
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    SIGN_OUT: "/sign-out",
    START: "/start",
    // Public features!
    PUBLIC: "/public",
    publicTable: (tableId = ":tableId") => `/public/${tableId}`,
    publicTableProfiling: (tableId = ":tableId") => `/public/${tableId}/profiling`,
    // Admin
    ADMIN: "/admin",
    ADMIN_USERS: "/admin/users",
    ADMIN_WORKSPACES: "/admin/workspaces",
    //  Create new workspace
    CREATE_WORKSPACE: "/init",
    // The application!
    HOME: "/app",
    CONTACT: "/app/contact",
    SHARED: "/app/shared",
    WORKSPACE: "/app/workspace",
    WORKSPACE_SETTINGS: "/app/workspace/settings",
    WORKSPACE_MEMBERS: "/app/workspace/members",
    WORKSPACES: "/app/workspaces",
    UPLOAD: "/app/upload",
    SETTINGS: "/app/settings",
    ACCOUNT: "/app/account",
    ONBOARDING: "/app/onboarding",
    // avoid trailing '/'
    tablePage: (tableId, suffix = "") => suffix ? `/app/tables/${tableId}/${suffix}` : `/app/tables/${tableId}`,
    tableProfiling: (tableId) => `/app/tables/${tableId}/profiling`,
    tablePredict: (tableId) => `/app/tables/${tableId}/predict`,
    tableExplore: (tableId) => `/app/tables/${tableId}/explore`,
    tableChat: (tableId) => `/app/tables/${tableId}/chat`,
    tableSettings: (tableId) => `/app/tables/${tableId}/settings`,
    // avoid trailing '/'
    folderPage: (folderId, suffix = "") => suffix ? `/app/folders/${folderId}/${suffix}` : `/app/folders/${folderId}`,
    folderSettings: (folderId) => `/app/folders/${folderId}/settings`,
    // Dashboard and charts
    dashboard: (tableId) => `/app/tables/${tableId}/dashboard`,
    editChart: (tableId, chartId) => `/app/tables/${tableId}/dashboard/charts/${chartId}`
};

export function getRelativePath(url) {
    /**
     * Converts "/app/tables/abc" to "tables/abc"
     */
    return url.split("/").slice(2).join("/");
}

export const EXTERNAL_LINKS = {
    RHO_DOCS: "https://docs.rho.store",
    RHO_STATUS_PAGE: "https://rho-store.openstatus.dev"
};
