import React from "react";
import PropTypes from "prop-types";


export function ColumnValues({values}) {
    return (
        <div className="my-2 text-xs monospace">
            {values.map((value, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="mb-1">
                    {value}
                </div>
            ))}
        </div>
    );
}
ColumnValues.propTypes = {
    values: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string, PropTypes.number
    ]))
};
