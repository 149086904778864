import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {PageContent} from "src/primitives/layout/PageContent.jsx";
import {TimeSeries} from "src/pages/Home/pages/TablePage/pages/ExplorePage/components/TimeSeries/index.jsx";
import {Distributions} from "src/pages/Home/pages/TablePage/pages/ExplorePage/components/Distributions/index.jsx";


export function TableExplorePage({table}) {
    return (
        <PageContent title={`Explore '${table?.name}'`}>
            <Wrapper className="mb-4">
                <TimeSeries
                    tableId={table?.id}
                    tableColumns={table?.columns}
                />
            </Wrapper>
            <Wrapper>
                <Distributions />
            </Wrapper>
        </PageContent>
    );
}

TableExplorePage.propTypes = {
    table: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        columns: PropTypes.array
    })
};

const Wrapper = styled.div`
`;
