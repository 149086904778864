import {useCallback, useMemo, useState} from "react";
import {useUploadToStorage} from "src/api/uploads/useUploadToStorage.js";
import {useGetUploadUrl} from "src/api/uploads/useGetUploadUrl.js";
import {useProcessFile} from "src/api/uploads/useProcessFile.js";
import {useCreateTable} from "src/api/tables/useCreateTable.js";
import {NEW_FILE_STRATEGY} from "src/pages/Home/pages/UploadFilePage/components/options/strategies.js";


export function useUploadFile(workspaceId) {
    const [stepState, setStepState] = useState({loading: null, completed: null});
    const [uploadError, setUploadError] = useState(null);
    const [createTable, createTableState] = useCreateTable(workspaceId);
    const [getUploadUrl, uploadUrlState] = useGetUploadUrl(workspaceId);
    const [uploadToStorage, uploadStorageState] = useUploadToStorage();
    const [processFile, processFileState] = useProcessFile(workspaceId);

    const startUpload = useCallback(async (file, options) => {
        let {tableId} = options;
        const {folderId, name, strategy, mergeOptions} = options;

        try {
            // create new table
            if (strategy === NEW_FILE_STRATEGY.NEW_TABLE) {
                const createTableResponse = await createTable({name, folderId});
                if (!createTableResponse.ok) {
                    throw new Error(createTableResponse.errorCode);
                }
                tableId = createTableResponse.table.id;
            }

            setStepState({loading: 1, completed: null});
            const uploadResponse = await getUploadUrl(file.name);
            const {ok, errorCode, fileId, url} = uploadResponse;
            if (!ok) {
                throw new Error(errorCode);
            }
            setStepState({loading: 2, completed: 1});
            // raises exception if error
            await uploadToStorage({file, url});
            setStepState({loading: 3, completed: 2});
            const processFileOptions = {fileId, tableId, strategy, mergeOptions};
            const processFileResponse = await processFile(processFileOptions);
            if (!processFileResponse.ok) {
                throw new Error(processFileResponse.errorCode);
            }
            setStepState({loading: null, completed: 3});
        } catch (error) {
            console.log(error);
            setUploadError(error);
            setStepState({loading: null, completed: null});
        }
    }, [setStepState, setUploadError, uploadToStorage, getUploadUrl, processFile, createTable]);

    const isSomethingLoading = useMemo(() => {
        // eslint-disable-next-line max-len
        return uploadUrlState.loading || uploadStorageState.loading || processFileState.loading || createTableState.loading;
    }, [uploadUrlState, uploadStorageState, processFileState, createTableState]);

    const createdTable = useMemo(() => {
        return processFileState?.result?.table;
    }, [processFileState]);

    return [startUpload, {steps: stepState, error: uploadError, isLoading: isSomethingLoading, table: createdTable}];
}
