import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {useRemoveColumn} from "src/api/tableColumns/useRemoveColumn.js";
import {Button} from "src/primitives/button.jsx";
import {overrideSystemHandling} from "src/utils/system.js";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {DialogModal} from "src/primitives/modals/Dialog.jsx";
import {Spinner} from "src/primitives/spinner.jsx";


export function RemoveColumnDialog({tableId, columnName, close}) {
    const {showError} = useShowToast();
    const [removeColumn, {loading}] = useRemoveColumn(tableId);

    const handleRemoveColumn = useCallback((e) => {
        overrideSystemHandling(e);
        removeColumn(columnName).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
            close();
        }).catch((error) => {
            console.error(error);
            showError();
        });
    }, [removeColumn, columnName, showError, close]);

    return (
        <DialogModal
            close={close}
            title="Are you sure?"
            footer={(
                <div className="flex justify-between">
                    <div>
                        {loading && (
                            <Spinner size="md" theme="gray"/>
                        )}
                    </div>
                    <div className="flex gap-2 justify-end">
                        <Button
                            size="xs"
                            theme="secondary"
                            onClick={close}
                            isDisabled={loading}
                        >
                            No - abort
                        </Button>
                        <Button
                            size="xs"
                            theme="danger"
                            onClick={handleRemoveColumn}
                            isDisabled={loading}
                        >
                            OK - Delete
                        </Button>
                    </div>
                </div>
            )}
        >
            <div>
                You are about to permanently remove the column <strong>{columnName}</strong>.
            </div>
        </DialogModal>
    );
}

RemoveColumnDialog.propTypes = {
    tableId: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
};
