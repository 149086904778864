import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {API_KEY_FIELDS} from "src/api/fragments.js";


const MUTATION = gql`
${API_KEY_FIELDS}
mutation CreateApiKey($workspaceId: String!) {
  createApiKey(workspaceId: $workspaceId) {
    ok
    errorCode
    apiKey {
      ... ApiKeyFields
    }
  }
}
`;

function updateCache(cache, response) {
    const {ok, apiKey} = response.data.createApiKey;
    if (!ok) {
        return;
    }
    const user = {
        id: apiKey.subjectId,
        __typename: "User"
    };

    const newRef = cache.writeFragment({
        data: apiKey,
        fragment: API_KEY_FIELDS
    });
    cache.modify({
        id: cache.identify(user),
        fields: {
            personalApiKeys(existingCommentRefs) {
                return [newRef, ...existingCommentRefs];
            }
        }
    });
}

export function useCreateApiKey(workspaceId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((wid) => {
        return mutation({
            variables: {workspaceId: workspaceId || wid},
            update(cache, response) {
                updateCache(cache, response);
            }
        }).then((response) => {
            return response.data.createApiKey;
        });
    }, [mutation, workspaceId]);

    return [doMutate, {loading, error}];
}
