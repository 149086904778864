import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {PersistentStorage} from "src/utils/localstorage.js";
import {SidebarContext} from "src/primitives/layout/sidebar_context.jsx";


export function Resizeable({id, sidebar, children, minWidth, maxWidth, secondary}) {
    const DEFAULT_SIDEBAR_WIDTH = 320;
    const {isOpen} = useContext(SidebarContext);
    const persistentStorage = useMemo(() => {
        return new PersistentStorage(id);
    }, [id]);
    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(persistentStorage.getInt(DEFAULT_SIDEBAR_WIDTH));

    const startResizing = useCallback(() => {
        setIsResizing(true);
    }, []);

    const stopResizing = useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = useCallback((mouseMoveEvent) => {
        if (isResizing) {
            const sidebarElement = sidebarRef.current.getBoundingClientRect();
            const newWidth = mouseMoveEvent.clientX - sidebarElement.left;
            setSidebarWidth(Math.min(Math.max(newWidth, minWidth), maxWidth));
        }
    }, [isResizing, minWidth, maxWidth]);

    useEffect(() => {
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);

        return () => {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResizing);
        };
    }, [resize, stopResizing]);

    useEffect(() => {
        persistentStorage.storeInt(sidebarWidth);
    }, [sidebarWidth, persistentStorage]);

    return (
        <Container>
            {isOpen && (
                <Sidebar
                    ref={sidebarRef}
                    style={{width: sidebarWidth}}
                    // onMouseDown={(e) => e.preventDefault()}
                    $minWidth={minWidth}
                    $maxWidth={maxWidth}
                    $isHidden={!isOpen}
                >
                    <SidebarContent>
                        {sidebar}
                    </SidebarContent>
                    <SidebarResizer
                        onMouseDown={startResizing}
                        $isActive={isResizing}
                    />
                </Sidebar>
            )}
            <Main>
                {children}
            </Main>
            <Secondary id="__secondary">
                {secondary}
            </Secondary>
        </Container>
    );
}

Resizeable.propTypes = {
    id: PropTypes.string,
    sidebar: PropTypes.element,
    children: PropTypes.element,
    secondary: PropTypes.element,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number
};

Resizeable.defaultProps = {
    id: "resizeable",
    minWidth: 250,
    maxWidth: 400
};

const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const Sidebar = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  min-width: ${(props) => props.$minWidth}px;
  max-width: ${(props) => props.$maxWidth}px;
  // Set border on child component!
  // border-right: 1px solid #bdbdbd;
  flex-direction: row;
  ${(props) => props.$isHidden ? `
    display: none;
  ` : ""};
`;


Sidebar.propTypes = {
    $isHidden: PropTypes.bool,
    $minWidth: PropTypes.number,
    $maxWidth: PropTypes.number
};


const SidebarContent = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
`;

const SidebarResizer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 4px;
  justify-self: flex-end;
  cursor: col-resize;
  resize: horizontal;
  width: 4px;
  z-index: 1000;
  transition: opacity 0.4s;
  opacity: 0;
  background: rgb(166, 166, 166);
  &:hover {
    opacity: 1;
  }
  ${(props) => props.$isActive ? `
    opacity: 1;
  ` : ""};
`;

SidebarResizer.propTypes = {
    $isActive: PropTypes.bool
};


const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  overflow: hidden;
`;

const Secondary = styled.div``;
