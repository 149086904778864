import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {cva} from "cva";
import {FiX} from "react-icons/fi";
import {Label} from "src/primitives/controls/Label.jsx";

const inputWrapperClasses = cva({
    // eslint-disable-next-line max-len
    base: "relative flex flex-1 rounded-md shadow-sm ring-1 ring-inset ring-neutral-300 focus-within:ring-1 focus-within:ring-inset focus-within:ring-blue-800 sm:max-w-md",
    variants: {
        state: {
            disabled: "bg-neutral-100",
            success: "!ring-green-700",
            error: "!ring-red-700",
            highlight: "!ring-amber-500"
        }
    }
});

const inputClasses = cva({
    // eslint-disable-next-line max-len
    base: "block flex-1 border-0 bg-transparent py-1.5 pl-2 text-neutral-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-0 focus:outline-0",
    variants: {
        state: {
            disabled: "text-neutral-500 cursor-not-allowed"
        },
        size: {
            small: "!text-sm !py-1",
            medium: ""
        },
        mode: {
            clickable: "hover:bg-neutral-50/50 hover:text-neutral-700 cursor-pointer"
        }
    },
    defaultVariants: {
        size: "medium"
    }
});

export function TextInput({
    id,
    label,
    type,
    placeholder,
    isSuccess,
    isDisabled,
    highlight,
    hasError,
    onClear,
    onChange,
    size,
    selectOnClick,
    ...props
}) {
    let state = "";
    // eslint-disable-next-line react/prop-types
    if (isDisabled || props.disabled) {
        state = "disabled";
    } else if (isSuccess) {
        state = "success";
    } else if (hasError) {
        state = "error";
    } else if (highlight) {
        state = "highlight";
    }

    let mode = "";
    if (selectOnClick) {
        mode = "clickable";
    }

    const onClick = useCallback((e) => {
        if (selectOnClick) {
            e.target.select();
        }
    }, [selectOnClick]);

    const wrapperStyle = {};
    if (onClear) {
        // make room for clear-icon
        wrapperStyle.paddingRight = 36;
    }

    return (
        <div className="flex flex-col flex-1">
            {label && (
                <div>
                    <Label label={label} inputId={id}/>
                </div>
            )}

            <div className="flex flex-1 gap-2">
                <div className={inputWrapperClasses({state})} style={wrapperStyle}>
                    <input
                        id={id}
                        type={type}
                        name={id}
                        placeholder={placeholder}
                        autoComplete="qwerty"
                        className={inputClasses({state, size, mode})}
                        onClick={onClick}
                        onChange={onChange}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                    />
                    {(!!props.value && onClear) && (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,max-len
                        <span
                            onClick={onClear}
                            className="absolute inset-y-0 right-2 flex items-center cursor-pointer"
                        >
                            <FiX
                                className="h-6 w-6 p-1 text-gray-500 hover:bg-black/10 rounded-full"
                                aria-hidden="true"
                            />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

TextInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.node,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    autoFocus: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isSuccess: PropTypes.bool,
    highlight: PropTypes.bool,
    hasError: PropTypes.bool,
    size: PropTypes.string,
    selectOnClick: PropTypes.bool
};

TextInput.defaultProps = {
    type: "text"
};
