import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const GET_DUPLICATED_ROWS_QUERY = gql`
query GetDuplicatedRows($id: String!) {
  table (tableId: $id) {
    id
    duplicatedRows
  }
}
`;

export function useGetDuplicatedRows(tableId) {
    const options = {variables: {id: tableId}};
    const {data, loading, error, refetch} = useQuery(GET_DUPLICATED_ROWS_QUERY, options);

    const rows = useMemo(() => {
        return data?.table?.duplicatedRows || [];
    }, [data]);

    return {
        data: rows, loading, error, refetch
    };
}
