import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {useChartOptions} from "src/pages/Home/pages/TablePage/pages/TableDashboardPage/useChartOptions.jsx";
import {DashboardPageLayout} from "../../components/DashboardPageLayout/index.jsx";
import {BasicLayout} from "./GridLayout.jsx";


export function TableDashboard({tableId}) {
    const chartId = "cgeldabmgqzx";
    const {options} = useChartOptions(chartId, tableId);

    const rowHeight = 80;
    const layout = [{
        x: 0, y: 0, w: 2, h: 5, i: chartId
    }, {
        x: 2, y: 0, w: 2, h: 4, i: "mock-1"
    }, {
        x: 4, y: 0, w: 2, h: 3, i: "mock-2"
    }];

    const handleNewLayout = useCallback((newLayout) => {
        console.log("New layout: ");
        console.table(newLayout);
    }, []);

    return (
        <DashboardPageLayout tableId={tableId}>
            {options && (
                <div className="p-4">
                    <BasicLayout
                        isEditable={false}
                        rowHeight={rowHeight}
                        items={[(
                            <div key={chartId}>
                                <ChartWrapper
                                    option={options}
                                    style={{height: "100%"}}
                                />
                            </div>
                        ), (
                            <div key="mock-1">
                                <span className="text">mock 1</span>
                            </div>
                        ), (
                            <div key="mock-2">
                                <span className="text">mock 2</span>
                            </div>
                        )]}
                        layout={layout}
                        onLayoutChange={handleNewLayout}
                    />
                </div>
            )}

            <div className="w-full h-1 bg-black/50 my-4"/>

            <div className="p-4">
                <BasicLayout/>
            </div>
        </DashboardPageLayout>
    );
}

TableDashboard.propTypes = {
    tableId: PropTypes.string
};
