import React from "react";
import PropTypes from "prop-types";
import {PageContent, PageLayout} from "src/primitives/layout/index.jsx";
import {OnboardingChecklist} from "src/pages/Home/pages/GettingStarted/components/OnboardingChecklist/index.jsx";


export function GettingStartedPage({workspaceId}) {
    return (
        <PageLayout
            title="Getting Started"
            loading={false}
        >
            <PageContent
                title="Getting started"
                size="auto"
            >
                <OnboardingChecklist
                    workspaceId={workspaceId}
                />
            </PageContent>
        </PageLayout>
    );
}

GettingStartedPage.propTypes = {
    workspaceId: PropTypes.string.isRequired
};
