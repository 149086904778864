import React from "react";
import {SignIn} from "@clerk/clerk-react";
import {LINKS} from "src/links.js";
import {CenteredContent, FlexLayout, SplitPage} from "src/primitives/layout";
import {RhoHeader} from "src/pages/components/RhoHeader/index.jsx";


export function SignInPage() {
    return (
        <SplitPage>
            <FlexLayout
                header={<RhoHeader/>}
            >
                <CenteredContent>
                    <SignIn
                        path={LINKS.SIGN_IN}
                        afterSignInUrl={LINKS.HOME}
                        signUpUrl={LINKS.SIGN_UP}
                        afterSignUpUrl={LINKS.START}
                    />
                </CenteredContent>
            </FlexLayout>
        </SplitPage>
    );
}
