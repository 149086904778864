import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";


const QUERY = gql`
query GetUsers {
  users {
    id
    createdAt
    email
    firstName
    lastName
    events {
      createdAt
      name
      userId
    }
  }
}
`;


export function useGetUsers() {
    const {data, loading, error, refetch} = useQuery(QUERY);

    const users = useMemo(() => {
        return Array.isArray(data?.users) ? data.users : [];
    }, [data]);

    return {
        users, loading, error, refetch
    };
}
