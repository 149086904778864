import styled from "styled-components";

export const Table = styled.table`
  font-variant-numeric: lining-nums tabular-nums;
  border-collapse: collapse;
  font-size: 13px;
  width: 100%;
`;

export const Tbody = styled.tbody``;
export const Tfoot = styled.tfoot``;
export const Th = styled.th`
  text-align: left;
`;

export const Thead = styled.thead`
  padding: 8px 0;
  border-bottom: 1px solid #d3d3d3;
`;

export const Tr = styled.tr``;

export const Td = styled.td`
  text-align: start;
  padding: 0.5rem 0.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;
