import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

const GET_TABLE_INFO_QUERY = gql`
query GetTableInfo($id: String!) {
  table (tableId: $id) {
    id
    name
    createdAt
    createdByUser {
      id
      email
      firstName
      lastName
    }
  }
}
`;


export function useGetTableInfo(tableId) {
    const options = useMemo(() => {
        return {variables: {id: tableId}};
    }, [tableId]);

    const {data, loading, error, refetch} = useQuery(GET_TABLE_INFO_QUERY, options);

    return {
        table: data?.table, loading, error, refetch
    };
}
