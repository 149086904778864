import React, {useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {ChangeDataType} from "./components/ChangeDataType/index.jsx";
import {EditColumnConstraint} from "./components/EditColumnConstraint";
import {RemoveColumnCell} from "./components/RemoveColumn";
import {ChangeVariableType} from "./components/ChangeVariableType";
import {EditColumnName} from "./components/EditColumnName";
import {ToggleColumnTarget} from "./components/ToggleColumnTarget";
import {ToggleColumnVisibility} from "./components/ToggleColumnVisibility";
import {ToggleColumnFeature} from "./components/ToggleColumnFeature";


function getColumnSettingsColumns(tableId) {
    return [{
        key: "position",
        header: <div className="pl-2">#</div>,
        style: {width: "36px"},
        render: (value) => (
            <div className="pl-2">
                {value}
            </div>
        )
    }, {
        key: "name",
        header: "Name",
        render: (value) => (
            <div className="flex">
                <EditColumnName
                    tableId={tableId}
                    name={value}
                />
            </div>
        )
    }, {
        key: "dataType",
        header: "Data type",
        render: (value, row) => (
            <ChangeDataType
                tableId={tableId}
                column={row}
            />
        )
    }, {
        key: "variableType",
        header: "Variable type",
        render: (_, row) => (
            <div>
                <ChangeVariableType
                    tableId={tableId}
                    column={row}
                />
            </div>
        )
    }, {
        key: "visible",
        header: "Visibility",
        render: (_, row) => (
            <ToggleColumnVisibility
                tableId={tableId}
                column={row}
            />
        )
    }, {
        key: "target",
        header: "Is target",
        render: (_, row) => (
            <ToggleColumnTarget
                tableId={tableId}
                column={row}
            />
        )
    }, {
        key: "feature",
        header: "Is feature",
        render: (_, row) => (
            <ToggleColumnFeature
                tableId={tableId}
                column={row}
            />
        )
    }, {
        key: "unique_constraint",
        header: "Unique constraint",
        render: (_, row) => (
            <EditColumnConstraint
                tableId={tableId}
                columnName={row.name}
            />
        )
    }, {
        key: "delete",
        header: "Delete column",
        render: (_, row) => (
            <div className="flex justify-center w-10">
                <RemoveColumnCell
                    tableId={tableId}
                    columnName={row.name}
                />
            </div>
        )
    }];
}


export function ColumnSettings({tableId, columns}) {
    const cols = useMemo(() => {
        return getColumnSettingsColumns(tableId);
    }, [tableId]);

    return (
        <Wrapper>
            <SimpleTable
                data={columns}
                columns={cols}
            />
        </Wrapper>
    );
}

ColumnSettings.propTypes = {
    tableId: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        position: PropTypes.number,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    }))
};

const Wrapper = styled.div`
`;
