import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
  mutation UpdateTable($tableId: String!, $data: TableUpdate!) {
    updateTable (tableId: $tableId, data: $data) {
      ok
      errorCode
      table {
        id
        name
      }
    }
  }
`;


export function useUpdateTable(tableId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((data) => {
        const variables = {tableId, data};
        const expectedResult = {
            ok: true,
            errorCode: null,
            table: {
                __typename: "Table",
                id: tableId,
                ...data
            }
        };
        const optimisticResponse = {updateTable: expectedResult};
        const options = {variables, optimisticResponse};

        return mutation(options).then((response) => {
            return response.data.updateTable;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
