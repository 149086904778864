// eslint-disable-next-line import/no-extraneous-dependencies
import Pusher from "pusher-js";
import {useEffect, useState} from "react";
import {config} from "src/config.js";

const pusher = new Pusher(config.PUSHER_APP_KEY, {
    cluster: "eu",
    encrypted: true,
    forceTLS: true
});

// Pusher.logToConsole = true;


export function useRealtimeUpdates() {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const channelName = "my-channel";
        const eventName = "my-event";
        const channel = pusher.subscribe(channelName);


        channel.bind(eventName, (data) => {
            console.log("new event: ", data);
            setEvents((existingEvents) => [data, ...existingEvents]);
        });

        return () => {
            // pusher.unsubscribe(channelName);
            channel.unbind(eventName);
            channel.unsubscribe();
            channel.disconnect();
        };
    }, []);

    return events;
}
