import {createColumnHelper} from "@tanstack/react-table";

export function formatColumns(columns) {
    const columnHelper = createColumnHelper();

    return columns.map((item) => {
        return columnHelper.accessor(item.id, {
            header: (info) => {
                return item.header || info.column.id;
            },
            cell: (info) => {
                const cellValue = info.getValue();

                if (item.render) {
                    return item.render(cellValue, info.row.original);
                }

                return cellValue;
            },
            footer: () => {
                return item.footer || null;
            }
        });
    });
}
