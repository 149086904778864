import React from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiArrowRight} from "react-icons/fi";
import {LineDivider} from "./components/divider.jsx";


export function Navigation({
    prev,
    prevText,
    next,
    nextText,
    nextIcon,
    loading,
    isDisabled,
    isPrevDisabled
}) {
    return (
        <div>
            <LineDivider/>

            <div className="flex items-center justify-between">
                <div>
                    {prev && (
                        <Button
                            onClick={prev}
                            theme="secondary"
                            isDisabled={loading || isPrevDisabled}
                        >
                            {prevText}
                        </Button>
                    )}
                </div>
                <div>
                    <Button
                        isLoading={loading}
                        isDisabled={isDisabled}
                        onClick={next}
                    >
                        <div className="flex gap-2 items-center" style={{marginRight: nextIcon ? -14 : 0}}>
                            <span>{nextText}</span>
                            {nextIcon && (
                                <IconWrapper icon={nextIcon} size="xs"/>
                            )}
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
}

Navigation.propTypes = {
    prev: PropTypes.func,
    next: PropTypes.func,
    prevText: PropTypes.node,
    nextText: PropTypes.node,
    nextIcon: PropTypes.node,
    loading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isPrevDisabled: PropTypes.bool
};

Navigation.defaultProps = {
    prevText: "Go back",
    nextText: "Continue",
    nextIcon: <FiArrowRight/>
};
