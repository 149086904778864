/* eslint-disable max-len */
import React from "react";
import {MarkdownContent} from "src/primitives/Markdown/index.jsx";
import {PageWrapper} from "src/pages/Web/components/layouts.jsx";

const content = `
# How We Fit Data to Theoretical Distributions

Fitting datasets to theoretical distributions is a powerful way to uncover underlying patterns and characteristics within your data. By matching your data to well-known distributions, you can gain insights into its behavior, make predictions, and better understand the variability and trends. Our SaaS tool simplifies this process by automatically fitting your data to the most appropriate distribution, allowing you to focus on analysis and decision-making.

## Supported Distributions

Our tool supports six key distributions:
1. Normal distribution (gaussian)
2. Uniform distribution
3. Log-normal distribution
4. Exponential distribution
5. Gamma distribution
6. Pareto distribution


### Normal Distribution
The normal distribution, often referred to as the bell curve, is a symmetric distribution where most of the data points cluster around the mean, with the probability of extreme values tapering off equally in both directions.

- **Parameters:**
  - **Mean (μ):** The central value where the peak of the distribution occurs.
  - **Standard Deviation (σ):** Measures the spread of the data around the mean.

- **Use Cases:**
  - Modeling natural phenomena like heights, weights, and IQ scores.
  - Analyzing errors or deviations in manufacturing processes.
  - Financial modeling, such as returns on assets.

### 2. Uniform Distribution
The uniform distribution represents a situation where all outcomes within a certain range are equally likely. It is characterized by a constant probability over a given interval.

- **Parameters:**
  - **Minimum (a):** The lower bound of the distribution.
  - **Maximum (b):** The upper bound of the distribution.

- **Use Cases:**
  - Random number generation.
  - Simulations where all outcomes are equally likely.
  - Modeling scenarios where each value within a range is equally probable.

### 3. Log-Normal Distribution
The log-normal distribution is a skewed distribution where the logarithm of the variable is normally distributed. This distribution is suitable for data that cannot go below zero and tends to have a long right tail.

- **Parameters:**
  - **Shape (σ):** Describes the spread of the distribution.
  - **Location (loc):** The shift of the distribution along the x-axis.
  - **Scale (exp(μ)):** Determines the distribution’s central tendency after taking the log.

- **Use Cases:**
  - Modeling financial data like stock prices and income distributions.
  - Analyzing biological data, such as sizes of living organisms.
  - Time-to-failure analysis in reliability engineering.

### 4. Exponential Distribution
The exponential distribution is used to model the time between events in a Poisson process, where events occur continuously and independently at a constant average rate. It is characterized by a rapid decay in probability as time increases.

- **Parameters:**
  - **Rate (λ):** The inverse of the mean, representing the rate at which events occur.

- **Use Cases:**
  - Modeling the time between arrivals in a queue (e.g., customers in a store).
  - Analyzing lifetimes of products and systems (e.g., light bulbs).
  - Financial modeling for interest rates or loan defaults.

### 5. Gamma Distribution
The Gamma distribution is versatile and used to model positive continuous data that is skewed to the right. It generalizes the exponential distribution and can handle varying shapes depending on its parameters.

- **Parameters:**
  - **Shape (k):** Controls the distribution’s shape.
  - **Location (loc):** Shifts the distribution along the x-axis.
  - **Scale (θ):** Stretches the distribution horizontally.

- **Use Cases:**
  - Modeling waiting times or the time until a certain number of events occur.
  - Reliability analysis and queuing models.
  - Analyzing rainfall amounts and insurance claims.

### 6. Pareto Distribution
The Pareto distribution is a heavy-tailed distribution often used to model the distribution of wealth, where a small percentage of the population holds most of the wealth. It is characterized by a long tail that represents the rare occurrence of very large values.

- **Parameters:**
  - **Shape (α):** Determines the heaviness of the tail.
  - **Location (loc):** Shifts the distribution along the x-axis.
  - **Scale (x_m):** Represents the minimum value from which the distribution starts.

- **Use Cases:**
  - Modeling income and wealth distributions.
  - Analyzing natural phenomena like city sizes and earthquake magnitudes.
  - Assessing risks and losses in finance and insurance.

## Determine the Best Distribution

To ensure the best fit for your data, we use the Kolmogorov-Smirnov (KS) test, a non-parametric test that compares the empirical distribution of your data with the theoretical distribution. The test provides a p-value, which indicates the probability that the observed data could come from the fitted distribution.

- **P-Value:** The p-value helps you understand the goodness of fit:
  - A high p-value (closer to 1) suggests that the distribution is a good fit for the data.
  - A low p-value (closer to 0) suggests that the distribution may not be a good fit.

Our tool ranks the distributions based on their p-values, allowing you to identify which distribution best matches your data.

## Summary

Fitting your data to theoretical distributions is a crucial step in data analysis, enabling you to understand the underlying patterns, make predictions, and derive meaningful insights. Our SaaS tool supports six key distributions—Normal, Uniform, Log-Normal, Exponential, Gamma, and Pareto—providing you with a comprehensive analysis of your data. By leveraging the KS test and ranking distributions based on p-values, we help you make informed, data-driven decisions with confidence.

`;

export function FitDistributionPage() {
    return (
        <PageWrapper>
            <MarkdownContent content={content}/>
        </PageWrapper>
    );
}

FitDistributionPage.propTypes = {
};
