import React from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";
import {ShareTable} from "src/pages/Home/components/ShareResource/components/ShareTable/index.jsx";
import {formatTitleCase} from "src/utils/formatting.js";
import {TablePublicLink} from "src/pages/Home/components/ShareResource/components/TablePublicLink/index.jsx";
import {
    TogglePublicLink
} from "src/pages/Home/components/ShareResource/components/TablePublicLink/TogglePublicLink.jsx";
import {SharedUsersList} from "./components/SharedUsersList";
import {ShareFolder} from "./components/ShareFolder";


export function ManageSharing({resourceId, resourceType, data}) {
    return (
        <div className="py-2 px-1">
            <div className="mb-4">
                <Title size="small">
                    <span>Share {formatTitleCase(resourceType)}</span>
                </Title>
                {resourceType === "FOLDER" ? (
                    <ShareFolder
                        folderId={resourceId}
                    />
                ) : (
                    <ShareTable tableId={resourceId}/>
                )}
            </div>

            {data.length > 0 && (
                <div>
                    <Title size="xs">
                        People with access
                    </Title>
                    <SharedUsersList
                        items={data}
                    />
                </div>
            )}

            {resourceType === "TABLE" && (
                <div className="mt-4 mb-2">
                    <Title size="xs">
                        <span>Public link</span>
                    </Title>
                    <div className="my-2">
                        <TogglePublicLink tableId={resourceId}/>
                    </div>
                    <TablePublicLink tableId={resourceId}/>
                </div>
            )}
        </div>
    );
}

ManageSharing.propTypes = {
    resourceId: PropTypes.string.isRequired,
    resourceType: PropTypes.oneOf(["FOLDER", "TABLE"]),
    data: PropTypes.array
};

ManageSharing.defaultProps = {
    resourceType: "FOLDER",
    data: []
};
