

export const LIME = [
    "#f7fee7",
    "#d9f99d",
    "#bef264",
    "#a3e635",
    "#84cc16"
];

export const RED = [
    "#fef2f2",
    "#fecaca",
    "#fca5a5",
    "#f87171",
    "#ef4444"
];


export const ORANGE = [
    "#fffbeb",
    "#fef3c7",
    "#fde68a",
    "#fbbf24",
    "#f59e0b"
];

export const BLUE = [
    "#eff6ff",
    "#bfdbfe",
    "#93c5fd",
    "#60a5fa",
    "#3b82f6"
];

export const GRAY = [
    "#ffffff",
    "#f5f5f5",
    "#d4d4d4",
    "#a3a3a3",
    "#737373"
];

export const ORANGE_GREEN = [
    "#F97316",
    "#FED7AA",
    "#FFFFFF",
    "#D9F99D",
    "#84CC16"
];

export const ColorSchemeCodes = {
    LIME: "LIME",
    RED: "RED",
    ORANGE: "ORANGE",
    BLUE: "BLUE",
    GRAY: "GRAY",
    ORANGE_GREEN: "ORANGE_GREEN"
};

export const ColorSchemeColors = {
    [ColorSchemeCodes.LIME]: LIME,
    [ColorSchemeCodes.RED]: RED,
    [ColorSchemeCodes.ORANGE]: ORANGE,
    [ColorSchemeCodes.BLUE]: BLUE,
    [ColorSchemeCodes.GRAY]: GRAY,
    [ColorSchemeCodes.ORANGE_GREEN]: ORANGE_GREEN
};


export const getColorSchemeColors = (value) => {
    return ColorSchemeColors[value];
};

export const isValidColorScheme = (value) => {
    return value && Object.values(ColorSchemeCodes).includes(value);
};

export const getDefaultColorScheme = () => {
    return ColorSchemeCodes.LIME;
};

export const COLOR_SCHEMES = Object.values(ColorSchemeCodes).map((key) => ({
    value: key,
    colors: ColorSchemeColors[key]
}));
