import React from "react";
import styled from "styled-components";
import {Spinner} from "@chakra-ui/react";
import "./style.css";


export function FullScreenLoading() {
    return (
        <Wrapper>
            <Spinner/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // filter: blur(1px);
  animation: fadeIn 1.5s;
`;
