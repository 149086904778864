import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {MODEL_RESULT_FIELDS} from "./fragments.js";


const MUTATION = gql`
${MODEL_RESULT_FIELDS}
mutation TrainModel($tableId: String!, $settings: TrainModelInput!) {
    trainModel(tableId: $tableId, settings: $settings) {
        ok
        errorCode
        result {
            ... ModelResultFields
        }
    }
}
`;


export function useTrainModel(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((settings) => {
        return mutation({
            variables: {tableId, settings}
        }).then((response) => {
            return response.data.trainModel;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error, result: data?.trainModel?.result}];
}
