import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {CORE_FOLDER_FIELDS, SHARED_RESOURCE_FIELDS, CORE_TABLE_FIELDS} from "../fragments.js";

export const GET_SHARED_RESOURCES = gql`
${CORE_TABLE_FIELDS}
${CORE_FOLDER_FIELDS}
${SHARED_RESOURCE_FIELDS}
query GetSharedResources {
  sharedResources {
    tables {
      ... CoreTableFields
    }
    folders {
      ... CoreFolderFields
      shareInfo {
        ...SharedResourceFields
      }
    }
  }
}
`;


export function useGetSharedResources() {
    const {data, loading, error, refetch} = useQuery(GET_SHARED_RESOURCES);

    const tables = useMemo(() => {
        return data?.sharedResources?.tables || [];
    }, [data]);

    const folders = useMemo(() => {
        return data?.sharedResources?.folders || [];
    }, [data]);

    return {
        tables, folders, loading, error, refetch
    };
}
