import {smartFormat} from "src/utils/formatting.js";


export function renderTooltip(params) {
    const [xKey, ...yKeys] = params.dimensionNames;
    const xValue = params.value[xKey];
    // const yValues = yKeys.map((yKey) => params.value[yKey]);

    const yBullets = yKeys.map((yKey) => `
                <li class="flex justify-between gap-4">
                    <span>${yKey}:</span>
                    <span class="font-bold">${smartFormat(params.value[yKey])}</span>
                </li>
    `.trim());

    return `
    <div class="flex items-center gap-2">
        <div class="w-full">
            <ul class="w-full">
                <li class="flex justify-between gap-4">
                    <span>${xKey}: </span>
                    <span class="font-bold">${smartFormat(xValue)}</span>
                </li>
                ${yBullets.join("")}
            </ul>
        </div>
    </div>
    `.trim();
}
