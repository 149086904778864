import React from "react";
import PropTypes from "prop-types";
import {formatByColumn, formatNumber, formatPercent} from "src/utils/formatting.js";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {ColumnDataType} from "src/pages/Home/pages/TablePage/components/ColumnDataType.jsx";
import {CHART_COLORS} from "src/charts/colors.js";
import {BestDistributionFitCell} from "./BestDistributionFitCell.jsx";
import {
    ColumnDistribution
// eslint-disable-next-line max-len
} from "../../../../../../components/ColumnDetailsSidebar/components/ColumnDetails/components/ColumnDistribution/index.jsx";


export function ColumnsOverviewTable({
    columns,
    selectColumn,
    tableStats,
    distributions,
    fittedDistributions
}) {
    const data = columns.map((column) => {
        return {
            name: column.name,
            dataType: column.dataType,
            variableType: column.variableType,
            min: tableStats[column.name].min,
            max: tableStats[column.name].max,
            avg: tableStats[column.name].avg,
            sd: tableStats[column.name].sd,
            dist: distributions[column.name],
            fitDist: fittedDistributions[column.name]
        };
    });

    const cols = [{
        key: "dataType",
        header: " ",
        style: {width: "28px"},
        render: (v, row) => (
            <ColumnDataType column={row} onlyIcon={true}/>
        )
    }, {
        key: "name",
        header: "Name",
        render: (v) => (
            <div className="flex shrink">
                <button
                    className="hover:underline hover:text-neutral-800"
                    type="button"
                    onClick={selectColumn.bind(null, v)}
                >
                    {v}
                </button>
            </div>
        )
    }, {
        key: "values",
        header: "Values",
        render: (_, row) => (
            <div className="flex gap-4 mr-2">
                <span>
                    {formatNumber(tableStats[row.name].values, 0)}
                </span>
                <span className="text-neutral-600">
                    ({formatPercent(tableStats[row.name].values / tableStats[row.name].rows, 2)})
                </span>
            </div>
        )
    }, {
        key: "min",
        header: "Min",
        render: (v, row) => formatByColumn(v, row)
    }, {
        key: "max",
        header: "Max",
        render: (v, row) => formatByColumn(v, row)
    }, {
        key: "avg",
        header: "Average",
        render: (v) => formatNumber(v, 2)
    }, {
        key: "sd",
        header: "Standard deviation",
        render: (v) => formatNumber(v, 2)
    }, {
        key: "dist",
        header: "Distribution",
        render: (v) => (
            <div className="w-40">
                <ColumnDistribution
                    distribution={v}
                    height={60}
                    showXLabel={false}
                    showTooltip={false}
                    colors={[CHART_COLORS.lime]}
                />
            </div>
        )
    }, {
        key: "fitDist",
        header: " ",
        render: (v) => <BestDistributionFitCell fittedDistributions={v}/>
    }];

    return (
        <SimpleTable
            data={data}
            columns={cols}
        />
    );
}

ColumnsOverviewTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    })),
    selectColumn: PropTypes.func,
    tableStats: PropTypes.object,
    distributions: PropTypes.object,
    fittedDistributions: PropTypes.object
};
