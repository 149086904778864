
function maybeParseJSON(value) {
    return typeof value === "string" ? JSON.parse(value) : value;
}


export function formatQueryResult(result) {
    return {
        id: result.id,
        time: result.time,
        rows: maybeParseJSON(result.rows),
        columns: maybeParseJSON(result.columns),
        totalRows: result.totalRows
    };
}
