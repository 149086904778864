import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
  mutation UpdateData($tableId: String!, $updates: [CellUpdate!]!) {
    updateData (tableId: $tableId, updates: $updates) {
      ok
      errorCode
    }
  }
`;

function formatUpdate(update) {
    return {
        rowId: update.rowId,
        colName: update.colName,
        value: update.to ? String(update.to) : null
    };
}

export function useUpdateData(tableId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((data) => {
        const variables = {tableId, updates: data.map(formatUpdate)};
        return mutation({variables});
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
