import {useCallback, useMemo, useState} from "react";
import {useCreateDemoDataForWorkspace} from "src/api/workspaces/useCreateDemoDataForWorkspace.js";
import {useCreateApiKey} from "src/api/apiKeys/useCreateApiKey.js";
import {useCreateWorkspace} from "src/api/workspaces/useCreateWorkspace.js";


export function useConfigureWorkspace() {
    const [createWorkspace, createWorkspaceState] = useCreateWorkspace();
    const [createApiKey, createApiKeyState] = useCreateApiKey();
    const [createDemoData, createDemoDataState] = useCreateDemoDataForWorkspace();
    const [error, setError] = useState(null);
    const [workspaceId, setWorkspaceId] = useState(null);

    const configureWorkspace = useCallback(async (workspaceData) => {
        try {
            // create new workspace
            const createWorkspaceResponse = await createWorkspace(workspaceData);
            if (!createWorkspaceResponse.ok) {
                throw new Error(createWorkspaceResponse.errorCode);
            }
            const {workspace} = createWorkspaceResponse;
            const createApiKeyResponse = await createApiKey(workspace.id);
            if (!createApiKeyResponse.ok) {
                throw new Error(createApiKeyResponse.errorCode);
            }

            const createDemoDataResponse = await createDemoData(workspace.id);
            if (!createDemoDataResponse.ok) {
                throw new Error(createDemoDataResponse.errorCode);
            }
            setWorkspaceId(workspace.id);
        } catch (e) {
            console.log(e);
            setError(e);
        }
    }, [createWorkspace, createApiKey, createDemoData, setError, setWorkspaceId]);

    const isSomethingLoading = useMemo(() => {
        return createWorkspaceState.loading || createApiKeyState.loading || createDemoDataState.loading;
    }, [createWorkspaceState, createApiKeyState, createDemoDataState]);

    const loadingText = useMemo(() => {
        if (createWorkspaceState.loading) {
            return "Setting up workspace...";
        }
        if (createApiKeyState.loading) {
            return "Configure API credentials...";
        }
        if (createDemoDataState.loading) {
            return "Creating demo data...";
        }
        return null;
    }, [createWorkspaceState, createApiKeyState, createDemoDataState]);

    return [
        configureWorkspace,
        {loading: isSomethingLoading, text: loadingText, workspaceId, error}
    ];
}
