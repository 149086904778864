import {isNullOrUndefined} from "src/utils/misc.js";

export function getBgColorForCorrelation(correlation) {
    if (isNullOrUndefined(correlation) || correlation === 0) {
        return "bg-transparent";
    }
    if (correlation > 0.75) {
        return "bg-orange-600";
    }
    if (correlation > 0.25) {
        return "bg-orange-500";
    }
    if (correlation > 0) {
        return "bg-orange-300";
    }
    if (correlation < -0.75) {
        return "bg-blue-600";
    }
    if (correlation < -0.25) {
        return "bg-blue-500";
    }
    if (correlation < 0) {
        return "bg-blue-300";
    }
    return "bg-transparent";
}
