import {useEffect} from "react";
import PropTypes from "prop-types";
import {clearHighlightedText, highlightText} from "src/components/TableGrid/highlight.jsx";
import {useLatestOptions} from "./table_data_context.jsx";


export function TableUpdates({data}) {
    const {q} = useLatestOptions();

    useEffect(() => {
        if (!Array.isArray(data) || data.length === 0) {
            return;
        }

        if (q) {
            window.setTimeout(() => {
                highlightText(q);
            }, 25);
        } else {
            window.setTimeout(() => {
                clearHighlightedText();
            }, 25);
        }
    }, [data, q]);

    return null;
}

TableUpdates.propTypes = {
    data: PropTypes.array
};
