import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const GET_TEXT_ANALYSIS_QUERY = gql`
    query GetTextAnalysis($id: String!) {
        table (tableId: $id) {
            id
            textAnalysis
        }
    }
`;


export function useGetTextAnalysis(tableId) {
    const {
        data, loading, error, refetch
    } = useQuery(GET_TEXT_ANALYSIS_QUERY, {variables: {id: tableId}});

    const textAnalysis = useMemo(() => {
        if (!data?.table?.textAnalysis) {
            return {};
        }
        const ta = data.table.textAnalysis;
        window.ta = ta;
        return ta;
    }, [data]);

    return {
        data: textAnalysis, loading, error, refetch
    };
}
