import {useEffect} from "react";
import {useUser} from "@clerk/clerk-react";
import {userDataFromClerkUser, useUpdateUser} from "src/api/users/useUpdateUser.js";


export function UpdateUser() {
    const {user: clerkUser} = useUser();
    const [updateUser] = useUpdateUser();

    useEffect(() => {
        if (!clerkUser) {
            return;
        }
        const userId = clerkUser.id;
        if (window.sessionStorage.getItem(userId)) {
            // already updated this session
            console.debug("Already updated this session");
            return;
        }
        const userData = userDataFromClerkUser(clerkUser);
        updateUser(userId, userData).then(({ok, errorCode}) => {
            if (ok) {
                window.sessionStorage.setItem(userId, "true");
            } else {
                throw new Error(errorCode);
            }
        }).catch((error) => {
            console.error(error);
            window.sessionStorage.removeItem(userId);
        });
    }, [clerkUser, updateUser]);

    return null;
}
