import React from "react";
import PropTypes from "prop-types";
import {useGetDuplicatedRows} from "src/api/tableData/useGetDuplicatedRows.js";
import {Callout} from "src/primitives/Callout/index.jsx";


export function DuplicatesOverview({tableId}) {
    const {data, loading} = useGetDuplicatedRows(tableId);

    if (loading) {
        return null;
    }

    if (data.length === 0) {
        return null;
    }

    return (
        <Callout variant="danger">
            {data.length} duplicated rows
        </Callout>
    );
}

DuplicatesOverview.propTypes = {
    tableId: PropTypes.string.isRequired
};
