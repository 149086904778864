import React from "react";
import PropTypes from "prop-types";
import {TextEditable} from "src/primitives/controls";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {useUpdateWorkspace} from "src/api/workspaces/useUpdateWorkspace.js";


export function EditWorkspaceName({workspaceId, name}) {
    const {showLoading, showSuccess, showError} = useShowToast();
    const [updateWorkspace] = useUpdateWorkspace(workspaceId);

    const handleSubmit = (newValue) => {
        showLoading();
        updateWorkspace({name: newValue}).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
            showSuccess();
        }).catch((error) => {
            console.warn(error);
            showError();
        });
    };

    return (
        <TextEditable
            text={name}
            onSubmit={handleSubmit}
        />
    );
}

EditWorkspaceName.propTypes = {
    workspaceId: PropTypes.string.isRequired,
    name: PropTypes.string
};
