import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {SHARED_RESOURCE_FIELDS} from "src/api/fragments.js";


const MUTATION = gql`
${SHARED_RESOURCE_FIELDS}
mutation ShareFolder ($data: ShareResourceInput!) {
  shareFolder (data: $data) {
    ok
    errorCode
    result {
      ...SharedResourceFields
    }
  }
}
`;


function updateCache(cache, response, folderId) {
    const folder = {
        id: folderId,
        __typename: "Folder"
    };

    const {ok, result} = response.data.shareFolder;
    if (!ok) {
        return;
    }

    const newRef = cache.writeFragment({
        data: result,
        fragment: SHARED_RESOURCE_FIELDS
    });
    cache.modify({
        id: cache.identify(folder),
        fields: {
            sharedResources(existingCommentRefs) {
                return [...existingCommentRefs, newRef];
            }
        }
    });
}


export function useShareFolder(folderId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(({email}) => {
        const variables = {data: {resourceId: folderId, email}};

        return mutation({
            variables,
            update(cache, response) {
                updateCache(cache, response, folderId);
            }
        }).then((response) => response.data?.shareFolder);
    }, [mutation, folderId]);

    return [doMutate, {loading, error}];
}
