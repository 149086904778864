import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {flexRender} from "@tanstack/react-table";
import {FiArrowDown, FiArrowUp} from "react-icons/fi";
import {IconButton} from "@chakra-ui/react";


export function ColumnHeader({header}) {
    if (header.isPlaceholder) {
        return null;
    }

    const className = header.column.getCanSort() ? "cursor-pointer select-none" : "";
    const onClick = header.column.getToggleSortingHandler();
    const sortingState = header.column.getIsSorted();

    let icon = null;
    if (sortingState === "asc") {
        icon = <FiArrowUp/>;
    }
    if (sortingState === "desc") {
        icon = <FiArrowDown/>;
    }

    return (
        <Wrapper
            className={className}
            onClick={onClick}
            $clickable={!!onClick}
        >
            <div className="text-sm text-neutral-600 font-semibold">
                {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                )}
            </div>
            {icon ? (
                <IconButton
                    icon={icon}
                    aria-label="sort-order"
                    size="sm"
                    isRound={true}
                    // variant={icon ? "solid" : "ghost"}
                />
            ) : <Space $width={32}/>}
        </Wrapper>
    );
}

ColumnHeader.propTypes = {
    header: PropTypes.shape({
        isPlaceholder: PropTypes.bool,
        column: PropTypes.object,
        getContext: PropTypes.func
    })
};

const Wrapper = styled.div`
  height: var(--chakra-sizes-6);
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.$clickable ? `
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
  ` : ""};  
`;


const Space = styled.div`
  width: ${(props) => props.$width}px;
`;
