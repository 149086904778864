import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useDeleteTable} from "src/api/tables/useDeleteTable.js";
import {Button} from "src/primitives/button.jsx";


export function DeleteTable({tableId}) {
    const [isDeleted, setIsDeleted] = useState(false);
    const [deleteTable, {loading}] = useDeleteTable({onSuccess: () => setIsDeleted(true)});

    const doDelete = () => {
        deleteTable(tableId);
    };

    return (
        <Wrapper className="flex items-center gap-4">
            <Button
                isLoading={loading}
                onClick={doDelete}
                theme="danger"
                isDisabled={isDeleted}
            >
                Delete table
            </Button>
            {isDeleted && (
                <span>Table deleted, refresh page</span>
            )}
        </Wrapper>
    );
}

DeleteTable.propTypes = {
    tableId: PropTypes.string
};

const Wrapper = styled.div`
`;
