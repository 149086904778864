import React from "react";
import {useMakeContact} from "src/api/users/useMakeContact.js";
import {Textarea} from "@headlessui/react";
import {Button} from "src/primitives/button.jsx";
import {Label} from "src/primitives/controls/Label.jsx";


export function ContactForm() {
    const [makeContact, {loading}] = useMakeContact();
    const [message, setMessage] = React.useState("");
    const [sentMessageId, setSentMessageId] = React.useState(null);
    const [error, setError] = React.useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (message) {
            makeContact(message).then((result) => {
                if (result.ok) {
                    setSentMessageId(result.messageId);
                } else {
                    throw new Error(result.errorCode);
                }
            }).catch((e) => {
                console.error(e);
                setError(e);
            });
        }
    };

    if (sentMessageId) {
        return (
            <div className="flex flex-col gap-2">
                <div>
                    <Label>Message sent</Label>
                    <p>Your message has been sent. We will get back to you soon.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <form
                onSubmit={handleSubmit}
            >
                <div className="flex flex-col gap-2">
                    <div>
                        <Label>Message</Label>
                        <Textarea
                            onChange={(event) => setMessage(event.target.value)}
                            value={message}
                            placeholder="A message"
                            className="w-full h-full border border-neutral-400 rounded-md p-2 max-w-112"
                        />
                    </div>
                    <div>
                        <Button
                            type="submit"
                            loading={loading}
                            isLoading={loading}
                            isDisabled={!message}
                        >
                            Send
                        </Button>
                    </div>
                    {error && (
                        <div className="text-red-500">
                            An error occurred.
                            {/* eslint-disable-next-line max-len */}
                            Please contact us at <a href="mailto:contact@rho.store">contact@rho.store</a>, or try again later.
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
}
