import React from "react";
import PropTypes from "prop-types";
import {Overlay} from "src/primitives/Overlay/index.jsx";
import {COLOR_SCHEMES, getColorSchemeColors, getDefaultColorScheme, isValidColorScheme} from "../../color_schemes.js";
import {ColorSchemePreview} from "./ColorSchemePreview.jsx";


function ColorSchemeOptions({onSelect, selectedColorScheme}) {
    return (
        <div className="flex flex-col gap-2">
            {COLOR_SCHEMES.map(({colors, value}) => (
                <button
                    type="button"
                    key={value}
                    className=""
                    onClick={() => onSelect(value)}
                >
                    <div className="w-48">
                        <ColorSchemePreview
                            colors={colors}
                            isSelected={selectedColorScheme === value}
                        />
                    </div>
                </button>
            ))}
        </div>
    );
}

ColorSchemeOptions.propTypes = {
    onSelect: PropTypes.func.isRequired,
    selectedColorScheme: PropTypes.string
};

export function SelectColorSchema({onSelect, selectedColorScheme}) {
    // eslint-disable-next-line max-len
    const colors = isValidColorScheme(selectedColorScheme) ? getColorSchemeColors(selectedColorScheme) : getColorSchemeColors(getDefaultColorScheme());

    return (
        <div className="w-48">
            <Overlay
                position="left"
                content={(
                    <div>
                        <ColorSchemeOptions
                            onSelect={onSelect}
                            selectedColorScheme={selectedColorScheme}
                        />
                    </div>
                )}
            >
                <>
                    {colors ? (
                        <ColorSchemePreview colors={colors}/>
                    ) : ( // given schema does not exist
                        <button
                            type="button"
                            className="w-48 h-8 rounded-md border border-black/10 hover:border-black/20"
                        >
                            Select color scheme
                        </button>
                    )}
                </>
            </Overlay>
        </div>
    );
}

SelectColorSchema.propTypes = {
    onSelect: PropTypes.func.isRequired,
    selectedColorScheme: PropTypes.string
};
