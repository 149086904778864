import React from "react";
import PropTypes from "prop-types";
import {SidebarDrawer} from "src/primitives/drawer/index.jsx";
import {CopyCode} from "src/primitives/Terminal/CopyCode.jsx";
import {Title} from "src/primitives/title.jsx";


const Divider = () => (
    <div className="my-6 border-white"/>
);


export function ConfigureGcpInstructions({show, hide}) {
    const variables = `
export PROJECT_ID=your-project-id
export SERVICE_ACCOUNT_NAME=your-service-account-name
    `.trim();

    const code = `
gcloud iam service-accounts create $SERVICE_ACCOUNT_NAME \\
--project=$PROJECT_ID \\
--description="Service account for Cloud SQL access" \\
--display-name="Rho store cloud sql access"


gcloud projects add-iam-policy-binding $PROJECT_ID \\
--member="serviceAccount:$SERVICE_ACCOUNT_NAME@$PROJECT_ID.iam.gserviceaccount.com" \\
--role="roles/cloudsql.client"

gcloud iam service-accounts keys create key.json \\
--iam-account=$SERVICE_ACCOUNT_NAME@$PROJECT_ID.iam.gserviceaccount.com
    `.trim();

    const createDatabase = `
gcloud sql databases create DATABASE_NAME \\
--instance=INSTANCE_NAME
    `.trim();

    const createUser = `
gcloud sql users create USERNAME \\
    --instance=INSTANCE_NAME \\
    --password=PASSWORD
`.trim();

    const connectToDatabase = "gcloud sql connect INSTANCE_NAME --user=postgres";

    const databaseUserAccess = `
-- Switch to the new database
\\c DATABASE_NAME;

GRANT ALL PRIVILEGES ON DATABASE DATABASE_NAME TO USERNAME;
    `.trim();

    return (
        <SidebarDrawer
            isOpen={show}
            close={hide}
            size={560}
            enableOverlay={false}
            title="Configure GCP backend"
        >
            <div>
                Obs! This guide assumes you have a Postgres database instance running on Google Cloud.
                If you do not have one, you can create one from the GCP console.
                <a
                    className="text-blue-500 underline ml-1"
                    href="https://cloud.google.com/sql/docs/postgres/create-instance"
                    target="_blank"
                    rel="noreferrer"
                >
                    See this guide.
                </a>
            </div>
            <Divider/>
            <div>
                <Title>Step 1: Service account credentials</Title>
            </div>
            <div>
                We will create and configure a new service account from the terminal.
                First, define the following variables:
            </div>
            <div className="bg-white my-4">
                <CopyCode code={variables} language="bash" theme="github"/>
            </div>
            <div>
                {/* eslint-disable-next-line max-len */}
                Then, we create a new service account, give it the role &quot;cloudsql.client&quot; and download the key file:
            </div>
            <div className="bg-white my-4">
                <CopyCode code={code} language="bash" theme="github"/>
            </div>
            <div>
                Upload the key file in the form.
            </div>

            <Divider/>

            <div>
                <Title>Step 2: Database user</Title>
            </div>
            <div>
                Run the following command to create a new database on your Cloud SQL instance:
            </div>
            <div className="bg-white my-4">
                <CopyCode code={createDatabase} language="bash" theme="github"/>
            </div>
            <div>
                Then create new user:
            </div>
            <div className="bg-white my-4">
                <CopyCode code={createUser} language="bash" theme="github"/>
            </div>
            <div>
                No we need to connect to the database:
            </div>
            <div className="bg-white my-4 p-4">
                <CopyCode code={connectToDatabase} language="bash" theme="github"/>
            </div>
            <div>
                Give full permission to the user for the created database:
            </div>
            <div className="bg-white my-4 p-4">
                <CopyCode code={databaseUserAccess} language="bash" theme="github"/>
            </div>
        </SidebarDrawer>
    );
}

ConfigureGcpInstructions.propTypes = {
    show: PropTypes.bool,
    hide: PropTypes.func
};
