import React, {createContext, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";


export const LatestFolderContext = createContext(null);


export function LatestFolderContextProvider({children}) {
    const {folderId} = useParams();
    const [latestFolderId, setLatestFolderId] = useState(folderId);

    useEffect(() => {
        if (folderId) {
            setLatestFolderId(folderId);
        }
    }, [folderId]);

    return (
        <LatestFolderContext.Provider value={latestFolderId}>
            {children}
        </LatestFolderContext.Provider>
    );
}

LatestFolderContextProvider.propTypes = {
    children: PropTypes.element
};

export function useLatestFolderId() {
    return useContext(LatestFolderContext);
}
