import React from "react";
import PropTypes from "prop-types";
import {LoadingBar} from "src/pages/StartPage/components/InitAccount/LoadingBar.jsx";
import {Navigation} from "src/pages/CreateWorkspace/components/CreateWorkspaceForm/Navigation.jsx";
import {LineDivider} from "src/pages/CreateWorkspace/components/CreateWorkspaceForm/components/divider.jsx";
import {Title} from "src/primitives/title.jsx";
import {Label} from "src/primitives/controls/Label.jsx";
import {
    BackendDescription
} from "src/pages/CreateWorkspace/components/CreateWorkspaceForm/components/WorkspaceBackend/BackedDescription.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {BackendLogo} from "../WorkspaceBackend/BackendLogo.jsx";
import {useConfigureWorkspace} from "./useConfigureWorkspace.jsx";
import {GoToWorkspaceButton} from "./GoToWorkspaceButton.jsx";


export function CreateWorkspace({name, backend, backendCredentials, goBack}) {
    const [configureWorkspace, {loading, text, workspaceId, error}] = useConfigureWorkspace();

    const handleSubmit = () => {
        const data = {
            name,
            backend,
            backendCredentials
        };
        configureWorkspace(data);
    };

    return (
        <div className="flex-1 flex flex-col justify-between">

            {(!workspaceId && !loading) && (
                <div className="flex-1 flex flex-col justify-center">
                    <div className="mb-4">
                        <Label label="Workspace name:"/>
                        <div>
                            {name}
                        </div>
                    </div>
                    <div className="mb-4">
                        <Label label="Backend:"/>
                        <div className="flex items-center gap-2">
                            <BackendLogo backend={backend} size={44}/>
                            <BackendDescription backend={backend}/>
                        </div>
                    </div>
                </div>
            )}
            {loading && (
                <div className="flex flex-1 flex-col justify-center">
                    <div>
                        <div className="h-4 flex items-center gap-3">
                            <Spinner size="sm"/>
                            <div className="text-sm monospace">
                                {text || "Loading..."}
                            </div>
                        </div>
                        <div className="my-4">
                            <LoadingBar/>
                        </div>
                    </div>
                </div>
            )}
            {error && (
                <div>
                    <div>Error</div>
                    <div>Failed to create workspace</div>
                </div>
            )}
            {workspaceId && (
                <div className="flex-1 flex items-center justify-center">
                    <Title size="large">
                        Your workspace is ready!
                    </Title>
                </div>
            )}

            {workspaceId && (
                <div className="fade-in">
                    <LineDivider/>
                    <div className="flex items-center justify-center">
                        <GoToWorkspaceButton workspaceId={workspaceId}/>
                    </div>
                </div>
            )}
            {!workspaceId && (
                <Navigation
                    prev={goBack}
                    prevText="Back"
                    next={handleSubmit}
                    nextText="Create workspace"
                    nextIcon={null}
                    loading={loading}
                    isDisabled={!!workspaceId}
                    isPrevDisabled={!!workspaceId}
                />
            )}
        </div>
    );
}


CreateWorkspace.propTypes = {
    name: PropTypes.string.isRequired,
    backend: PropTypes.string,
    backendCredentials: PropTypes.object,
    goBack: PropTypes.func
};
