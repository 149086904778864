import React from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {abbreviateNumber, formatNumber} from "src/utils/formatting.js";
import {CHART_COLORS} from "src/charts/colors.js";
import {getAxisLabelStyle, getAxisTitleStyle} from "src/charts/options.js";


function getAxisGap(maxValue) {
    /**
     * Make name-gap dynamic based on width of y-label
     * For example, large numbers (10,000,000) requires large gap than small numbers (10)
     */
    if (!maxValue) {
        // default
        return 32;
    }
    if (maxValue.toString().length < 6) {
        return 40;
    }
    if (maxValue.toString().length < 9) {
        return 80;
    }
    if (maxValue.toString().length < 12) {
        return 100;
    }
    return 120;
}


function getOption(data, {xName, yName, xMin, xMax, yMin, yMax}) {
    return {
        animation: false,
        grid: {
            left: "12%",
            right: "10%",
            top: "10%",
            bottom: "12%",
            containLabel: true
        },
        tooltip: {
            trigger: "item"
        },
        xAxis: {
            min: xMin,
            max: xMax,
            name: xName,
            nameLocation: "middle",
            nameGap: 32,
            nameTextStyle: getAxisTitleStyle(),
            axisLabel: {
                formatter: (value) => {
                    return abbreviateNumber(value);
                },
                ...getAxisLabelStyle()
            }
        },
        yAxis: {
            min: yMin,
            max: yMax,
            name: yName,
            nameLocation: "middle",
            nameGap: getAxisGap(yMax),
            nameTextStyle: getAxisTitleStyle(),
            axisLabel: {
                formatter: (value) => {
                    return abbreviateNumber(value);
                },
                ...getAxisLabelStyle()
            }
        },
        dataset: [
            {
                source: data
            },
            {
                transform: {
                    type: "ecStat:regression",
                    config: {method: "exponential"}
                    // 'linear' by default.
                    // config: { method: 'linear', formulaOn: 'end'}
                }
            }
        ],
        series: [{
            type: "scatter",
            large: false,
            symbolSize: 5,
            dimensions: [xName, yName],
            itemStyle: {
                color: CHART_COLORS.orange
            },
            tooltip: {
                formatter(params) {
                    const [xValue, yValue] = params.value;
                    const [xLabel, yLabel] = params.dimensionNames;

                    return `
                        <div class="flex items-center gap-2">
                            <div>
                            ${params.marker}
                            </div>
                            <div class="w-full">
                            <ul class="w-full">
                                <li class="flex justify-between gap-4">
                                    <span>${xLabel}: </span>
                                    <span class="font-bold">${formatNumber(xValue, 1)}</span>
                                </li>
                                <li class="flex justify-between gap-4">
                                    <span>${yLabel}: </span>
                                    <span class="font-bold">${formatNumber(yValue, 1)}</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        `.trim();
                }
            }
        }, {
            name: "Linear regression",
            type: "line",
            datasetIndex: 1,
            symbolSize: 0.1,
            symbol: "circle",
            label: {
                show: false,
                fontSize: 14
            },
            labelLayout: {dx: -20},
            encode: {label: 2, tooltip: 1},
            lineStyle: {
                color: CHART_COLORS.gray,
                opacity: 0.75,
                width: 4
            }
        }]
    };
}


export function ScatterPlot({data, height, xName, yName, xMin, xMax, yMin, yMax}) {
    const option = getOption(data, {xName, yName, xMin, xMax, yMin, yMax});

    return (
        <ChartWrapper option={option} height={height} className="font-bold"/>
    );
}

ScatterPlot.propTypes = {
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    height: PropTypes.number,
    xName: PropTypes.string,
    yName: PropTypes.string,
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    yMin: PropTypes.number,
    yMax: PropTypes.number
};

ScatterPlot.defaultProps = {
    // Example
    data: [[1, 2], [3, 4], [5, 6], [7, 8], [9, 10]],
    xName: "X",
    yName: "Y"
};
