import React, {useContext} from "react";
import PropTypes from "prop-types";
import {sortByDate} from "src/utils/sorting.js";
import {AuthUserContext} from "src/pages/contexts/user_context.jsx";
import {UserRow} from "./components/UserRow.jsx";


export function SharedUsersList({items}) {
    const sortedItems = sortByDate(items, "sharedAt");
    const authUser = useContext(AuthUserContext);

    return (
        <div className="max-h-96">
            {sortedItems.map((item) => (
                <div key={item.id}>
                    <UserRow
                        id={item.id}
                        authUser={authUser}
                        status={item.status}
                        email={item.sharedWithUserEmail}
                        user={item.sharedWithUser}
                        sharedAt={item.sharedAt}
                    />
                </div>
            ))}
        </div>
    );
}

SharedUsersList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        sharedAt: PropTypes.string,
        status: PropTypes.string,
        sharedWithUser: PropTypes.object,
        sharedWithUserEmail: PropTypes.string
    }))
};
