import React from "react";
import PropTypes from "prop-types";
import {useGetTableStats} from "src/api/tableData/useGetTableStats.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {usePivotData} from "./usePivotData.jsx";
import {PivotTable} from "../PivotTable/index.jsx";


export function ColumnPivot({tableId, columns, targets}) {
    const {data: tableStats, loading: loadingTableStats} = useGetTableStats(tableId);
    const {data: pivotData, loading} = usePivotData(tableId, columns, targets);

    if (loading || loadingTableStats) {
        return (
            <div className="flex items-center justify-center min-h-48">
                <Spinner/>
            </div>
        );
    }

    return (
        <div>
            <PivotTable
                data={pivotData}
                columns={columns}
                targets={targets}
                tableStats={tableStats}
            />
        </div>
    );
}

ColumnPivot.propTypes = {
    tableId: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.string),
    targets: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        variableType: PropTypes.string,
        dataType: PropTypes.string
    }))
};
