import React, {useContext, useRef, useState} from "react";
import PropTypes from "prop-types";
import {isValidEmail} from "src/utils/validators.js";
import {TextForm} from "src/primitives/controls/forms/TextForm.jsx";
import {useShareTable} from "src/api/sharing/useShareTable.js";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {AuthUserContext} from "src/pages/contexts/user_context.jsx";


export function ShareTable({tableId}) {
    const createdCounter = useRef(0);
    const [shareTable, {loading}] = useShareTable(tableId);
    const {showLoading, showSuccess, showError} = useShowToast();
    const [hasError, setHasError] = useState(false);
    const authUser = useContext(AuthUserContext);

    const onSubmit = (email) => {
        if (email === authUser.primaryEmailAddress.emailAddress) {
            const message = "Cannot invite self, try another email!";
            showError({message});
            setHasError(true);
            return;
        }

        setHasError(false);
        showLoading();
        shareTable({email}).then((result) => {
            const {ok, errorCode} = result;
            if (!ok) {
                throw new Error(errorCode);
            }
            showSuccess();
            createdCounter.current += 1;
        }).catch((error) => {
            if (error.message === "RESOURCE_ALREADY_SHARED") {
                showError({message: `Resource is already shared with user ${email}`});
            } else {
                showError();
            }
            setHasError(true);
        });
    };

    return (
        <TextForm
            key={`share-table-${createdCounter.current}`}
            loading={loading}
            buttonText="Invite"
            validator={isValidEmail}
            onSubmit={onSubmit}
            inputType="email"
            hasError={hasError}
        />
    );
}

ShareTable.propTypes = {
    tableId: PropTypes.string.isRequired
};
