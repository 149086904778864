import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import hljs from "highlight.js/lib/core";
import python from "highlight.js/lib/languages/python";
import bash from "highlight.js/lib/languages/bash";
import sql from "highlight.js/lib/languages/sql";
import javascript from "highlight.js/lib/languages/javascript";
// import "highlight.js/styles/github.min.css";
// import "highlight.js/styles/obsidian.min.css";
import "./obsidian.css";
import "./github.css";


hljs.registerLanguage("python", python);
hljs.registerLanguage("sql", sql);
hljs.registerLanguage("bash", bash);
hljs.registerLanguage("javascript", javascript);

export const SUPPORTED_THEMES = ["obsidian", "github"];
export const SUPPORTED_LANGUAGES = ["python", "sql", "bash", "javascript"];

export function Code({code, language, theme, showLineNumbers}) {
    const ref = useRef();

    const lines = code.split("\n");

    useEffect(() => {
        if (ref.current) {
            // reset innerHTML to content string, and remove 'highlighted'-flag
            ref.current.innerHTML = code;
            delete ref.current.dataset.highlighted;
        }
        // this manipulates innerHTML
        hljs.highlightElement(ref.current);
    }, [code, language, theme]);

    return (
        <Wrapper>
            {showLineNumbers && (
                <div className="mr-4 text-right text-zinc-500 select-none">
                    {lines.map((line, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p key={`line-${index}`}>{index + 1}</p>
                    ))}
                </div>
            )}
            <pre className={theme}>
                <code
                    ref={ref}
                    className={`language-${language}`}
                >
                    {code}
                </code>
            </pre>
        </Wrapper>
    );
}

Code.propTypes = {
    code: PropTypes.string,
    language: PropTypes.oneOf(SUPPORTED_LANGUAGES),
    theme: PropTypes.oneOf(SUPPORTED_THEMES),
    showLineNumbers: PropTypes.bool
};

Code.defaultProps = {
    code: "\nhello = 'world' \n",
    language: "python",
    theme: "obsidian",
    showLineNumbers: true
};

const Wrapper = styled.div`
  padding: 1rem;  
  display: flex;
  font-family: monospace;
  font-size: .875rem;
  letter-spacing: .01em;
  line-height: 1.25rem;
  min-width: 0;
  height: 100%;
  overflow: auto;
  & code {
    padding: 0 !important;
    background: transparent;
    display: block;
    overflow-x: auto;
  }
`;

export const CodeWrapper = styled.div`
  padding: 1rem;
  background: #222223;
`;
