import React from "react";
import PropTypes from "prop-types";


export function PageContainer({children}) {
    return (
        <div className="px-8 py-6 bg-gray-50 w-full h-full">
            {children}
        </div>
    );
}

PageContainer.propTypes = {
    children: PropTypes.node
};
