import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {cva} from "cva";

// import {overrideSystemHandling} from "src/utils/system.js";


const labelStyle = cva({
    base: "flex-1 py-2 px-2 mx-[-8px] flex items-center rounded-md transition-all",
    variants: {
        mode: {
            dynamic: "cursor-pointer hover:bg-neutral-500/5",
            controlled: ""
        }
    },
    defaultVariants: {
        mode: "dynamic"
    }
});


export function Checkbox({name, isChecked, text, onChange}) {
    const mode = onChange ? "dynamic" : "controlled";

    return (
        <label
            htmlFor={name}
            // eslint-disable-next-line max-len
            className={labelStyle({mode})}
        >
            <CheckboxInput
                name={name}
                isChecked={isChecked}
                onChange={onChange}
            />
            {text && (
                <div
                    className="ml-2 flex-1 text-neutral-800 hover:text-neutral-950"
                >
                    {text}
                </div>
            )}
        </label>
    );
}

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    text: PropTypes.node,
    onChange: PropTypes.func
};

export const CheckboxInput = ({name, isChecked, onChange}) => {
    const handleChange = useCallback((e) => {
        // overrideSystemHandling(e);

        if (onChange) {
            onChange(name, e.target.checked);
        }
    }, [onChange, name]);

    return (
        <div className="relative h-5 w-5">
            <input
                id={name}
                type="checkbox"
                // eslint-disable-next-line max-len
                className={`before:content[''] peer relative h-5 w-5 appearance-none rounded-md border border-neutral-500 checked:border-gray-900 checked:bg-gray-900 ${onChange ? "cursor-pointer" : ""}`}
                checked={isChecked}
                onChange={handleChange}
            />
            <span
                // eslint-disable-next-line max-len
                className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
            >
                <CheckboxIcon/>
            </span>
        </div>
    );
};

CheckboxInput.propTypes = {
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    onChange: PropTypes.func
};

const CheckboxIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-3.5 w-3.5"
        viewBox="0 0 20 20"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1"
    >
        <path
            fillRule="evenodd"
            // eslint-disable-next-line max-len
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
        />
    </svg>
);
