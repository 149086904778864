

export const OVERALL_STRATEGY = {
    CREATE_NEW_TABLE: "CREATE_NEW_TABLE",
    USE_EXISTING_TABLE: "USE_EXISTING_TABLE"
};

export const NEW_FILE_STRATEGY = {
    NEW_TABLE: "NEW_TABLE",
    NEW_VERSION: "NEW_VERSION",
    APPEND: "APPEND",
    REPLACE: "REPLACE",
    MERGE: "MERGE"
};


export function getStrategy(overallStrategy, fileStrategy) {
    if (overallStrategy === OVERALL_STRATEGY.CREATE_NEW_TABLE) {
        return NEW_FILE_STRATEGY.NEW_TABLE;
    }

    if (fileStrategy === NEW_FILE_STRATEGY.NEW_TABLE) {
        // invalid
        return null;
    }

    return fileStrategy;
}
