import React from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";
import {cva} from "cva";


const wrapperClasses = cva({
    base: "flex-1 py-2 px-4 overflow-auto"
});

const contentClasses = cva({
    base: "",
    variants: {
        size: {
            auto: "w-auto min-w-page-xs",
            md: "max-w-page-md min-w-page-xs",
            lg: "max-w-page-lg min-w-page-sm",
            xl: "max-w-page-xl min-w-page-sm"
        },
        align: {
            center: "mx-auto"
        }
    },
    defaultVariants: {
        size: "auto"
    }
});

export function PageContent({title, subTitle, children, size, align}) {
    return (
        <div className={wrapperClasses()}>
            <div className={contentClasses({size, align})}>
                <div className="mt-4 mb-6 flex items-center justify-between">
                    <Title size="medium" style={{margin: 0}}>
                        {title}
                    </Title>
                    <div>
                        {subTitle}
                    </div>
                </div>

                <div>
                    {children}
                </div>
            </div>
        </div>
    );
}

PageContent.propTypes = {
    title: PropTypes.node,
    subTitle: PropTypes.node,
    children: PropTypes.node,
    size: PropTypes.oneOf(["auto", "md", "lg", "xl"]),
    align: PropTypes.oneOf(["center"])
};
