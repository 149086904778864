import React from "react";
import PropTypes from "prop-types";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {useTrainModel} from "src/api/dataModels/useTrainModel.js";
import {Button} from "src/primitives/button.jsx";
import {PageContent} from "src/primitives/layout/index.jsx";
import {Title} from "src/primitives/title.jsx";
import {Badge} from "src/primitives/badge.jsx";
import {SelectInput} from "src/primitives/controls/index.jsx";
import {formatPrettyString} from "src/utils/formatting.js";
import {ModelEvaluation} from "./components/Evaluation/index.jsx";
import {FeaturesOverview} from "./components/FeaturesOverview/index.jsx";
import {ModelResult} from "./components/ModelResult/index.jsx";
import {ModelPreview} from "./components/ModelPreview/index.jsx";
import {sortFeaturesByVariables} from "./utils.js";


const RegressionModel = {
    LINEAR: "linear",
    DECISION_TREE: "decision_tree",
    RANDOM_FOREST: "random_forest"
};

const modelOptions = Object.keys(RegressionModel).map((key) => ({
    key,
    value: RegressionModel[key],
    name: formatPrettyString(key)
}));


export function TablePredictPage({tableId}) {
    const [model, setModel] = React.useState(RegressionModel.LINEAR);
    const [doTrain, {loading, result}] = useTrainModel(tableId);
    const {table} = useGetTable(tableId);
    const columns = table?.columns || [];

    const target = columns.find((item) => item.isTarget);
    const features = columns.filter((item) => item.isFeature);
    const sortedFeatures = sortFeaturesByVariables(features, columns);

    const handleTrainModel = () => {
        if (!target || !model) {
            return;
        }
        const settings = {
            model,
            targetColumn: target.name
        };
        doTrain(settings);
    };

    return (
        <PageContent title="Predict" size="xl">
            <div>
                <div className="mb-6">
                    <Title size="small">
                        Target column
                    </Title>
                    <div>
                        {target ? (
                            <Badge theme="blue">
                                {target.name}
                            </Badge>
                        ) : (
                            <span>
                                No target column selected
                            </span>
                        )}
                    </div>
                </div>
                <div className="mb-6">
                    <Title size="small">
                        Features
                    </Title>
                    <ul>
                        {sortedFeatures.map((column) => (
                            <li
                                key={column.name}
                                className="flex items-center gap-2 mb-2"
                            >
                                <Badge theme="blue">
                                    {column.name}
                                </Badge>
                                <Badge>
                                    {column.variableType}
                                </Badge>
                            </li>
                        ))}
                    </ul>
                </div>

                {(features && target) && (
                    <div className="mb-6">
                        <FeaturesOverview tableId={tableId} features={sortedFeatures} target={target}/>
                    </div>
                )}

                <div className="mb-4">
                    <div className="flex flex-col gap-4 max-w-80">
                        <div>
                            <SelectInput
                                label="Select regression model"
                                onChange={(option) => setModel(option?.value)}
                                options={modelOptions}
                                selectedOption={modelOptions.find((item) => item.value === model)}
                            />
                        </div>
                        <div>
                            <Button
                                onClick={handleTrainModel}
                                isLoading={loading}
                                isDisabled={!target || !model}
                            >
                                Train model
                            </Button>
                        </div>
                    </div>

                </div>

                {result && (
                    <div className="mb-4">
                        <Title size="small">
                            Result:
                        </Title>
                        <ModelResult result={result}/>
                    </div>
                )}

                <>
                    <hr/>
                    <div className="my-4">
                        <ModelPreview tableId={tableId} modelId={tableId}/>
                    </div>
                </>

                {result && (
                    <>
                        <hr/>
                        <div className="h-4"/>
                        <div className="mb-4">
                            <ModelEvaluation tableId={tableId}/>
                        </div>
                    </>
                )}
            </div>
        </PageContent>
    );
}

TablePredictPage.propTypes = {
    tableId: PropTypes.string.isRequired
};
