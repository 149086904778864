import React from "react";
import PropTypes from "prop-types";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {getIconForColumn} from "src/components/TableGrid/Header/utils.jsx";


export const ColumnOption = ({column}) => (
    <div className="flex items-center gap-1">
        <IconWrapper
            icon={getIconForColumn(column)}
            size="xs"
        />
        <div>
            {column.name}
        </div>
    </div>
);

ColumnOption.propTypes = {
    column: PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string
    })
};
