import React from "react";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiFilePlus} from "react-icons/fi";
import {LINKS} from "src/links.js";
import {useGetLink} from "src/utils/useGetLink.js";
import {NavLink} from "react-router-dom";
import {buttonClasses} from "./styles.jsx";

export function NewFileButton() {
    const getLink = useGetLink();

    return (
        <div className="mb-1 text-sm">
            <NavLink to={getLink(LINKS.UPLOAD)}>
                {({isActive}) => (
                    <div className={buttonClasses({state: isActive ? "active" : ""})}>
                        <IconWrapper
                            icon={<FiFilePlus />}
                            size="xs"
                            style={{marginRight: 4}}
                        />
                        <div>
                            New file
                        </div>
                    </div>
                )}
            </NavLink>
        </div>
    );
}
NewFileButton.propTypes = {
};
