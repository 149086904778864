import React from "react";
import PropTypes from "prop-types";
import {SortIcon} from "src/components/SimpleTable/SortIcon.jsx";
import {Thead, Tr, Th} from "./components.jsx";


export function TableHeader({keys, headers, handleSort, getSortStatus}) {
    return (
        <Thead>
            <Tr>
                {headers.map((header, index) => (
                    <Th
                        key={`header-${keys[index]}`}
                        onClick={handleSort.bind(null, keys[index])}
                    >
                        <div
                            className="cursor-pointer hover:underline flex items-center justify-between"
                        >
                            <div>
                                {header}
                            </div>
                            <div className="mx-1">
                                <SortIcon
                                    direction={getSortStatus(keys[index])}
                                />
                            </div>
                        </div>
                    </Th>
                ))}
            </Tr>
        </Thead>
    );
}

TableHeader.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.node),
    keys: PropTypes.arrayOf(PropTypes.string),
    handleSort: PropTypes.func,
    getSortStatus: PropTypes.func
};
