import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiExternalLink} from "react-icons/fi";


export function TextLink({to, children, target, ...props}) {
    const isExternal = to.startsWith("http");

    if (!target) {
        // eslint-disable-next-line no-param-reassign
        target = isExternal ? "_blank" : "_self";
    }

    return (
        <Link
            to={to}
            /* eslint-disable-next-line max-len */
            className="text-blue-600 hover:text-blue-800 transition-colors inline-flex items-center focus-visible:outline-0"
            target={target}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
        >
            <span>
                {children}
            </span>
            {isExternal && (
                <IconWrapper
                    size="xs"
                    icon={<FiExternalLink/>}
                    style={{margin: 0}}
                />
            )}
        </Link>
    );
}

TextLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    target: PropTypes.string
};
