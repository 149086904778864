import React from "react";
import PropTypes from "prop-types";
import {formatTimestamp} from "src/utils/formatting.js";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {ApiKeyCell} from "./ApiKeyCell.jsx";
import {RemoveApiKeyCell} from "./RemoveApiKeyCell.jsx";


export function ApiKeysTable({keys}) {
    const columns = [{
        key: "issuedAt",
        header: "Created at",
        render: (value) => (
            // date is timestamp
            <div>{formatTimestamp(new Date(value * 1000))}</div>
        )
    }, {
        key: "value",
        header: "Key",
        render: (value) => (
            <div className="w-72">
                <ApiKeyCell value={value}/>
            </div>
        )
    }, {
        key: "delete",
        header: " ",
        render: (value, row) => {
            return (
                <div>
                    <RemoveApiKeyCell
                        keyId={row.id}
                    />
                </div>
            );
        }
    }];

    return (
        <div>
            <SimpleTable data={keys} columns={columns}/>
        </div>
    );
}
ApiKeysTable.propTypes = {
    keys: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
        issuedAt: PropTypes.number
    }))
};
