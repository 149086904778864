import React, {useState} from "react";
import PropTypes from "prop-types";
import {overrideSystemHandling} from "src/utils/system.js";
import {
    useLatestOptions,
    useLoadWithOptions
} from "src/pages/Home/pages/TablePage/pages/TableGridPage/table_data_context.jsx";
import {TextInput} from "src/primitives/controls/index.jsx";
import {useIndexTable} from "src/api/tables/useIndexTable.js";
import {Button} from "src/primitives/button.jsx";


export function SearchTableInput({table}) {
    const [indexTable, {loading}] = useIndexTable(table.id);
    const latestOptions = useLatestOptions();
    const {handleSearch} = useLoadWithOptions();
    const [localSearchValue, setLocalSearchValue] = useState("");

    const handleSearchSubmit = (e) => {
        overrideSystemHandling(e);

        const newSearchValue = localSearchValue.trim();
        if (newSearchValue !== latestOptions.q) {
            handleSearch(newSearchValue);
        }

        // If searching with trailing spaces -> trim
        if (newSearchValue !== localSearchValue) {
            window.setTimeout(() => {
                setLocalSearchValue(newSearchValue);
            }, 10);
        }
    };

    const handleClearSearch = () => {
        setLocalSearchValue("");
        window.setTimeout(() => {
            handleSearch("");
        }, 10);
    };

    const handleSearchChange = (e) => {
        const {value} = e.target;
        if (value) {
            setLocalSearchValue(value);
        } else if (!value) {
            // search is cleared. Most likely, backspace to empty string
            handleClearSearch();
        }
    };

    if (!table.searchIndex) {
        return (
            <div>
                <Button
                    size="xs"
                    theme="orange"
                    onClick={() => indexTable()}
                    isLoading={loading}
                >
                    Enable search
                </Button>
            </div>
        );
    }

    return (
        <form onSubmit={handleSearchSubmit} className="w-80">
            <TextInput
                placeholder="Search table"
                value={localSearchValue}
                onChange={handleSearchChange}
                onClear={handleClearSearch}
                highlight={(!!localSearchValue && localSearchValue !== latestOptions.q)}
                isSuccess={(!!localSearchValue && localSearchValue === latestOptions.q)}
            />
        </form>
    );
}
SearchTableInput.propTypes = {
    table: PropTypes.shape({
        id: PropTypes.string,
        searchIndex: PropTypes.arrayOf(PropTypes.string)
    })
};
