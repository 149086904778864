import React from "react";
import PropTypes from "prop-types";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {getIconForColumn} from "src/components/TableGrid/Header/utils.jsx";
import {formatTitleCase} from "src/utils/formatting.js";


export function ColumnDataType({column, onlyIcon, children}) {
    const icon = getIconForColumn(column);

    if (onlyIcon) {
        return (
            <IconWrapper
                icon={icon}
                size="xs"
                tooltip={formatTitleCase(column.dataType)}
            />
        );
    }

    return (
        <div className="flex items-center gap-1 ml-[-4px]">
            <IconWrapper
                icon={icon}
                size="xs"
            />
            {children || (
                <span>{formatTitleCase(column.dataType)}</span>
            )}
        </div>
    );
}

ColumnDataType.propTypes = {
    column: PropTypes.shape({
        dataType: PropTypes.string.isRequired
    }).isRequired,
    onlyIcon: PropTypes.bool,
    children: PropTypes.node
};
