import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation Predict($tableId: String!, $data: JSON!) {
    predict(tableId: $tableId, data: $data) {
        ok
        errorCode
        result
    }
}
`;


export function usePredict(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((inputData) => {
        return mutation({
            variables: {tableId, data: inputData}
        }).then((response) => {
            return response.data.predict;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error, result: data?.predict?.result}];
}
