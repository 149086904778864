import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Spinner} from "src/primitives/spinner.jsx";
import {CenteredContent} from "src/primitives/layout/index.jsx";

export function EaseIn({children, loading, loadingHeight}) {
    const style = {};
    if (loading) {
        style.height = loadingHeight;
    }

    return (
        <Wrapper style={style}>
            {loading && (
                <CenteredContent>
                    <LoadingWrapper>
                        <Spinner/>
                    </LoadingWrapper>
                </CenteredContent>
            ) }

            {!loading && (
                <ContentWrapper>
                    {children}
                </ContentWrapper>
            )}
        </Wrapper>
    );
}

EaseIn.propTypes = {
    children: PropTypes.any,
    loading: PropTypes.bool,
    loadingHeight: PropTypes.number
};

EaseIn.defaultProps = {
    loadingHeight: 200
};

const Wrapper = styled.div`
  width: 100%;
`;

const LoadingWrapper = styled.div`
  animation: appear 1s;
`;

const ContentWrapper = styled.div`
  animation: fadeIn 0.4s;
`;
