import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import {ChartWrapper} from "src/charts/ChartWrapper.jsx";
import {getAxisLabelStyle, getDefaultTextStyle} from "src/charts/options.js";
import {formatPercent} from "src/utils/formatting.js";


const ColorSchemas = {
    // https://www.learnui.design/tools/data-color-picker.html#single
    blue: [
        "#276c98",
        "#5791af",
        "#88b6c6",
        "#bddae0",
        "#f4ffff"
    ].reverse(),
    mixed: [
        // From tailwind: https://tailwindcss.com/docs/customizing-colors
        // "#1d4ed8",
        "#2563eb",
        "#3b82f6",
        "#60a5fa",
        "#93c5fd",
        "#f5f5f5",
        "#fdba74",
        "#fb923c",
        "#f97316",
        "#ea580c"
    ]
};

function getOption(xValues, yValues, matrix, {title, min, max, showControl}) {
    return {
        animation: false,
        textStyle: getDefaultTextStyle(),
        tooltip: {
            show: true,
            position: "top",
            formatter(params) {
                const [xIndex, yIndex, rho] = params.value;
                const xName = xValues[xIndex];
                const yName = yValues[yIndex];

                return `
                <div class="flex items-center gap-1">
                    <div>
                    ${params.marker}
                    </div>
                    <div>
                    CORR(<span class="italic">${xName}</span>, <span class="italic">${yName}</span>): 
                    <span class="font-bold">${formatPercent(rho / 100.0)}</span>
                    </div>
                </div>
                `.trim();
            }
        },
        grid: {
            height: showControl ? "50%" : "90%",
            top: "5%",
            left: "10%",
            right: "10%",
            containLabel: true
        },
        xAxis: {
            type: "category",
            data: xValues,
            axisLabel: {
                // could optimize this to be more dynamic
                rotate: xValues.length >= 10 ? 45 : 30,
                ...getAxisLabelStyle()
            },
            splitArea: {
                show: true
            },
            triggerEvent: false
        },
        yAxis: {
            type: "category",
            data: yValues,
            splitArea: {
                show: false
            },
            triggerEvent: false,
            axisLabel: getAxisLabelStyle()
        },
        visualMap: {
            show: !!showControl,
            min,
            max,
            calculable: true,
            orient: "horizontal",
            left: "center",
            bottom: "0",
            inRange: {
                color: ColorSchemas.mixed
            }
            // inRange: {
            //     color: ['red', 'white', 'green'],
            //     symbolSize: [100, 100]
            // },
        },
        series: [
            {
                name: title,
                type: "heatmap",
                data: matrix,
                triggerEvent: true,
                label: {
                    show: true,
                    formatter: (params) => {
                        // eslint-disable-next-line no-unused-vars
                        const [_x, _y, rho] = params.value;
                        return Math.round(rho);
                    }
                },
                visualMap: false,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 5,
                        shadowColor: "rgba(0, 0, 0, 0.20)"
                    }
                }
            }
        ]
    };
}


export function Heatmap({xValues, yValues, title, matrix, minValue, maxValue, onClick}) {
    /**
     * https://echarts.apache.org/examples/en/editor.html?c=heatmap-cartesian
     * Format of matrix: https://echarts.apache.org/en/option.html#series-heatmap.data
     */

    const values = matrix.map((row) => row[2]).filter((value) => Number.isFinite(value));

    // if min/max is not specified, derive from series values.
    if (!maxValue) {
        // eslint-disable-next-line no-param-reassign
        maxValue = Math.max(...values);
    }
    if (!minValue) {
        // eslint-disable-next-line no-param-reassign
        minValue = Math.min(...values);
    }

    const config = {title, min: minValue, max: maxValue};
    const option = getOption(
        xValues,
        yValues,
        matrix,
        config
    );

    const minHeight = 400;
    const height = Math.max(minHeight, 80 * yValues.length);

    const handleClick = useCallback((params) => {
        if (params.componentType !== "series") {
            return;
        }

        const [x, y, v] = params.data;
        const xValue = xValues[x];
        const yValue = yValues[y];
        if (onClick) {
            onClick({x: xValue, y: yValue, z: v});
        } else {
            console.log(xValues[x], ":", yValues[y], " -> ", v);
        }
    }, [xValues, yValues, onClick]);

    const events = useMemo(() => {
        return {
            click: handleClick
        };
    }, [handleClick]);

    if (!minValue || !maxValue) {
        return null;
    }

    if (!xValues.length || !yValues.length) {
        return null;
    }

    return (
        <ChartWrapper option={option} height={height} events={events}/>
    );
}

Heatmap.propTypes = {
    title: PropTypes.string,
    xValues: PropTypes.arrayOf(PropTypes.string),
    yValues: PropTypes.arrayOf(PropTypes.string),
    matrix: PropTypes.arrayOf(PropTypes.array),
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    onClick: PropTypes.func
};

Heatmap.defaultProps = {
    title: "A Heatmap",
    xValues: ["x1", "x2", "x3"],
    yValues: ["y1", "y2"],
    // Format: [[x, y, v], [x, y, v], ...]
    matrix: [[0, 0, 10], [0, 1, 20], [1, 0, 50], [1, 1, 100], [2, 0, 100], [2, 1, 200]]
};
