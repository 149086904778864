import React from "react";
import PropTypes from "prop-types";
import {
    useLatestOptions,
    useLoadWithOptions
} from "src/pages/Home/pages/TablePage/pages/TableGridPage/table_data_context.jsx";
import {safeArrayToMap} from "src/utils/misc.js";
import {getDirection} from "src/utils/tableData.js";
import {TableGridHeaderCell} from "./TableGridHeaderCell.jsx";


export function TableGridHeader({
    columns,
    columnMap,
    tableStats,
    tablePreview,
    updateColumnWidth
}) {
    const {handleSort} = useLoadWithOptions();
    const {sort, filters} = useLatestOptions();
    const filterOptions = safeArrayToMap(filters, "name");
    const sortOptions = safeArrayToMap(sort, "name");

    return (
        <>
            {columns.map((column) => (
                <TableGridHeaderCell
                    key={column}
                    name={column}
                    stats={tableStats[column]}
                    columnInfo={columnMap[column]}
                    columnValues={tablePreview[column]}
                    sort={handleSort}
                    sortedDirection={getDirection(sortOptions[column])}
                    activeFilter={filterOptions[column]}
                    updateColumnWidth={updateColumnWidth}
                />
            ))}
        </>
    );
}

TableGridHeader.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string),
    tableStats: PropTypes.object,
    columnMap: PropTypes.object,
    tablePreview: PropTypes.object,
    updateColumnWidth: PropTypes.func
};
