import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_TABLE_STATS_QUERY} from "src/api/tableData/useGetTableStats.js";
import {GET_CORRELATION_MATRIX_QUERY} from "src/api/tableData/useGetCorrelationMatrix.js";
import {GET_TABLE_PREVIEW} from "src/api/tableData/useGetTablePreview.js";
import {GET_COLUMN_DISTRIBUTIONS_QUERY} from "src/api/tableData/useGetColumnDistributions.js";
import {GET_FITTED_DISTRIBUTIONS} from "src/api/tableData/useGetFittedDistributions.js";


const MUTATION = gql`
    mutation ConvertPercentageColumn($tableId: String!, $columnName: String!) {
        convertPercentageColumn(tableId: $tableId, columnName: $columnName) {
            ok
            errorCode
            column {
                id
                name
                dataType
                variableType
                semanticType
            }
        }
    }
`;

export function useConvertPercentageColumn(tableId, {onCompleted} = {}) {
    const [mutation, {loading, error}] = useMutation(MUTATION, {onCompleted});

    const doMutate = useCallback((columnName) => {
        const refetchQueries = [{
            query: GET_TABLE_STATS_QUERY,
            variables: {id: tableId}
        }, {
            query: GET_TABLE_PREVIEW,
            variables: {id: tableId}
        }, {
            query: GET_CORRELATION_MATRIX_QUERY,
            variables: {id: tableId}
        }, {
            query: GET_COLUMN_DISTRIBUTIONS_QUERY,
            variables: {id: tableId}
        }, {
            query: GET_FITTED_DISTRIBUTIONS,
            variables: {id: tableId}
        }];
        const variables = {tableId, columnName};
        return mutation({variables, refetchQueries}).then((response) => {
            return response.data.convertPercentageColumn;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
