import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_PENDING_INVITATIONS} from "src/api/workspaceMembers/useGetPendingInvitations.js";
import {GET_WORKSPACES_QUERY} from "src/api/workspaces/useGetWorkspaces.js";


const MUTATION = gql`
  mutation AcceptInvitation($workspaceId: String!) {
    acceptInvitation (workspaceId: $workspaceId) {
      ok
      errorCode
      member {
        id
        acceptedAt
        userId
        status
      }
    }
  }
`;


export function useAcceptInvitation() {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const mutate = useCallback(({workspaceId}) => {
        return mutation({
            variables: {workspaceId},
            refetchQueries: [{
                query: GET_PENDING_INVITATIONS
            }, {
                query: GET_WORKSPACES_QUERY
            }]
        }).then((response) => {
            const {
                ok,
                errorCode,
                member
            } = response.data.acceptInvitation;
            if (ok) {
                return member;
            }
            throw new Error(errorCode);
        });
    }, [mutation]);

    return [mutate, {loading, error, data}];
}
