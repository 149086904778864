import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {LineChart} from "src/charts/LineChart.jsx";


export function TimeSeriesChart({data, title}) {
    const firstRelevantIndex = data.findIndex(({total}) => total >= 3);
    const relevantData = data.slice(firstRelevantIndex);
    const chartData = relevantData.map((row) => {
        return {
            value: Number(row.p50),
            date: row.period,
            l: Number(row.p25),
            u: Number(row.p75)
        };
    });

    return (
        <Wrapper>
            <LineChart
                title={title}
                data={chartData}
            />

        </Wrapper>
    );
}
TimeSeriesChart.propTypes = {
    data: PropTypes.array,
    title: PropTypes.node
};

const Wrapper = styled.div`
`;
