import React from "react";
import PropTypes from "prop-types";


export const PageWrapper = ({children}) => (
    <div className="w-192 max-w-full px-4 mx-auto mt-12 mb-24">
        {children}
    </div>
);

PageWrapper.propTypes = {
    children: PropTypes.node
};
