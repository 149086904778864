import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation DeleteApiKey ($keyId: String!) {
  deleteApiKey (keyId: $keyId) {
    ok
    errorCode
    keyId
  }
}
`;


function updateCache(cache, response) {
    const {ok, keyId} = response.data.deleteApiKey;
    if (!ok) {
        return;
    }
    const model = {
        id: keyId,
        __typename: "ApiKey"
    };
    const normalizedId = cache.identify(model);
    cache.evict({id: normalizedId});
    cache.gc();
}

export function useDeleteApiKey() {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((keyId) => {
        return mutation({
            variables: {keyId},
            update(cache, response) {
                updateCache(cache, response);
            }
        }).then((response) => {
            return response.data.deleteApiKey;
        });
    }, [mutation]);

    return [doMutate, {loading, error}];
}
