import React from "react";
import {FiChevronRight} from "react-icons/fi";
import {Title} from "src/pages/Web/components/Title.jsx";
import {TextBlock} from "src/pages/Web/components/TextBlock.jsx";
import {Terminal} from "src/primitives/Terminal/index.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {Image, ZoomIn} from "src/pages/Web/components/Image.jsx";
import tableEx from "src/pages/Web/assets/screenshots/rho_table_ex.png";
import profilingEx from "src/pages/Web/assets/screenshots/rho_profiling.png";
import sharingEx from "src/pages/Web/assets/screenshots/rho_sharing.png";


const Divider = () => {
    // TODO: Divider or not divider?
    const style = {
        // background: "rgb(255, 255, 255)",
        // eslint-disable-next-line max-len
        background: "linear-gradient(90deg, rgba(255, 255, 255, 0.005) 0%, rgba(255, 255, 255, 0.04) 50%, rgba(255, 255, 255, 0.005) 100%)"
    };
    // old: border-t border-neutral-400/5
    return <div className="my-12 md:my-20 xl:my-28 mx-4 md:mx-8 xl:mx-12 h-[2px]" style={style}/>;
};


export function Features() {
    return (
        <div className="px-4">
            <Title>
                Simplified data storage
            </Title>
            <div className="mt-4 flex-center">
                <div className="flex flex-col">
                    <TextBlock size="medium" align="left">
                        {/* eslint-disable-next-line max-len */}
                        A Python SDK that is built for pandas dataframes - one line of code to either store or retrieve a dataset.
                        Or you can simply drag-and-drop a CSV-file in the web application.
                    </TextBlock>
                    <TextBlock size="medium" align="left">
                        Support for multiple upload & merge strategies, as well as versioning of datasets, makes
                        it flexible for many use cases.
                    </TextBlock>
                </div>
            </div>

            <div className="max-w-5xl mx-auto mt-16 flex items-stretch justify-between">
                <div className="w-[45%]">
                    <Terminal
                        hideTopBar={true}
                        language="python"
                        code={`
# %pip install rho-store

import pandas as pd
from rho_store import RhoClient

client = RhoClient(api_key="$API_KEY")
data = pd.DataFrame(...)

# store DataFrame
table = client.store_df(data)
# visit table in browser
print(table.url)                        
                        `.trim()}
                    />
                </div>
                <div className="flex items-center justify-center">
                    <IconWrapper icon={<FiChevronRight/>} size="xl" />
                </div>
                <div className="w-[45%]">
                    <Image source={tableEx} opacity={0.9}/>
                </div>
            </div>
            <Divider/>

            <div className="flex justify-between max-w-page-lg mx-auto">
                <div className="flex-1">
                    <Title align="left">
                        Data profiling
                    </Title>
                    <div className="mt-4">
                        <TextBlock size="medium" align="left">
                            {/* eslint-disable-next-line max-len */}
                            Uploaded datasets are analyzed instantly, offering quick insights and allowing you to explore your data more thoroughly and efficiently.
                        </TextBlock>
                    </div>
                </div>
                <div className="w-112 h-64">
                    <ZoomIn
                        source={profilingEx}
                        opacity={1}
                        alignX="right"
                        alignY="top"
                    />
                </div>
            </div>
            <Divider/>

            <div className="flex justify-between max-w-page-lg mx-auto">
                <div>

                    <Title align="left">
                        Seamless sharing & collaboration
                    </Title>

                    <div className="mt-4">
                        <TextBlock size="medium" align="left">
                            Collaborate with friends and colleagues with native sharing features.
                            No more emails with outdated CSV files.
                            Work together on the same data.
                        </TextBlock>
                    </div>
                </div>

                <div className="w-112 h-64">
                    <Image
                        source={sharingEx}
                        opacity={0.9}
                        alignX="center"
                        alignY="center"
                    />
                </div>
            </div>
            <Divider/>
        </div>
    );
}
