export function buildSearchFunction(tableRef, rows) {
    const searchCallback = (value) => {
        if (!tableRef.current) {
            // table not loaded
            return;
        }
        const hot = tableRef.current.hotInstance;
        const searchPlugin = hot.getPlugin("search");
        const hiddenRowsPlugin = hot.getPlugin("hiddenRows");

        const hiddenRows = hiddenRowsPlugin.getHiddenRows();

        if (!value && hiddenRows.length === 0) {
            searchPlugin.query(value);
            hot.render();
            return;
        }

        if (!value && hiddenRows.length > 0) {
            // reset
            searchPlugin.query(value);
            hiddenRowsPlugin.showRows(hiddenRows);
            hot.render();
            return;
        }

        const queryResult = searchPlugin.query(value);
        const allRows = new Set(rows.map((item, index) => index));
        const rowsWithMatches = new Set(queryResult.map((item) => item.row));
        const rowsToHide = [...allRows].filter((index) => !rowsWithMatches.has(index));

        hiddenRowsPlugin.showRows(hiddenRows);
        hiddenRowsPlugin.hideRows(rowsToHide);
        hot.render();
    };

    return searchCallback;
}
