import {useCallback, useMemo} from "react";
import {gql, useMutation} from "@apollo/client";
import {CHART_FIELDS} from "src/api/fragments.js";
import {GET_CHARTS_QUERY} from "./useGetCharts.js";

const MUTATION = gql`
${CHART_FIELDS}
mutation CreateChart($data: CreateChartInput!) {
  createChart (data: $data) {
    ok
    errorCode
    chart {
      ... ChartFields
    }
  }
}
`;

export function useCreateChart(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        return mutation({
            variables: {data: {tableId}},
            refetchQueries: [{query: GET_CHARTS_QUERY, variables: {tableId}}]
        }).then((response) => {
            return response.data.createChart;
        });
    }, [mutation, tableId]);

    const result = useMemo(() => {
        return data?.createChart;
    }, [data]);

    return [doMutate, {loading, error, result}];
}
