import {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "src/config.js";


export function pingServer(timeout = 2000, onSuccess, onError) {
    const url = `${config.API_URL}/health/up`;

    return axios({
        url,
        timeout
    }).then((response) => {
        if (onSuccess) {
            onSuccess();
        } else {
            console.log(response);
        }
    }).catch((error) => {
        if (onError) {
            onError(error);
        }
    });
}

const MAX_ATTEMPTS = 15;


export function usePingServer() {
    const timerRef = useRef();
    const [attempts, setAttempts] = useState(0);
    const [success, setHasSuccess] = useState(false);

    const makePing = useCallback(() => {
        const onSuccess = () => {
            setHasSuccess(true);
        };
        pingServer(2000, onSuccess).then(() => {
            setAttempts((prevAttempts) => prevAttempts + 1);
        });
    }, []);

    useEffect(() => {
        if (attempts >= MAX_ATTEMPTS) {
            // max attempts
            window.clearInterval(timerRef.current);
        }
        if (success) {
            window.clearInterval(timerRef.current);
            return;
        }
        if (timerRef.current) {
            return;
        }
        // Start interval
        timerRef.current = window.setInterval(() => {
            makePing();
        }, 5000);
        // make initial request without delay
        makePing();
    }, [makePing, success, attempts]);

    return {serverReady: success, attempts, serverFailed: attempts >= MAX_ATTEMPTS};
}
