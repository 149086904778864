import {useCallback, useMemo} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
mutation AddUniqueConstraint ($tableId: String!, $column: String!) {
  addUniqueConstraint(tableId: $tableId, column: $column) {
    ok
    errorCode
    table {
      id
      uniqueConstraints
    }
  }
}
`;

export function useAddUniqueConstraint(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((column) => {
        return mutation({
            variables: {tableId, column}
        }).then((response) => {
            return response.data.addUniqueConstraint;
        });
    }, [mutation, tableId]);

    const result = useMemo(() => {
        return data?.addUniqueConstraint;
    }, [data]);

    return [doMutate, {loading, error, result}];
}
