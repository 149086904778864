import React from "react";
import PropTypes from "prop-types";
import {FiMaximize2, FiSettings} from "react-icons/fi";
import {useUpdateColumn} from "src/api/tableColumns/useUpdateColumn.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {Dropdown} from "src/primitives/Dropdown/index.jsx";
import {useColumnDetailsState} from "src/pages/Home/pages/TablePage/components/ColumnDetailsSidebar/states.jsx";
import {ChevronToggle} from "src/primitives/ChevronToggle.jsx";
import {ColumnCompleteness} from "./ColumnCompleteness/index.jsx";
import {ColumnHighlights} from "./ColumnHighlights/index.jsx";


export function ColumnMenu({tableId, column, columnStats, showMore, setShowMore}) {
    const enableShowMore = false;

    const [updateColumn] = useUpdateColumn(tableId);
    const selectColumn = useColumnDetailsState((state) => state.select);

    const selectCurrentColumn = () => {
        selectColumn(column.name);
    };

    const hideColumn = () => {
        return updateColumn({name: column.name, isHidden: true});
    };

    const options = [{
        title: "Hide column",
        action: hideColumn
    }, {
        title: "Show more",
        action: selectCurrentColumn
    }];

    return (
        <div className="flex items-center gap-1">
            <ColumnCompleteness
                tableId={tableId}
                columnName={column.name}
                columnStats={columnStats}
            />
            <ColumnHighlights
                column={column}
            />
            <IconWrapper
                size="small"
                onClick={selectCurrentColumn}
                icon={<FiMaximize2/>}
                thickness={1.5}
            />
            <Dropdown
                options={options}
                render={({open}) => (
                    <IconWrapper
                        size="small"
                        clickable={true}
                        icon={<FiSettings/>}
                        thickness={1.5}
                        isActive={open}
                    />
                )}
            />
            {enableShowMore && (
                <ChevronToggle
                    size="small"
                    isOpen={showMore}
                    toggle={setShowMore}
                />
            )}
        </div>
    );
}

ColumnMenu.propTypes = {
    tableId: PropTypes.string.isRequired,
    column: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    }),
    columnStats: PropTypes.object,
    showMore: PropTypes.bool,
    setShowMore: PropTypes.func
};
