import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Skeleton} from "@chakra-ui/react";
import {Title} from "src/primitives/title.jsx";
import {AddItemMenu} from "src/pages/Home/components/AddItemMenu/index.jsx";
import {useGetLink} from "src/utils/useGetLink.js";
import {IconWrapper} from "src/primitives/Icon.jsx";


export function TreeHeader({
    title,
    link,
    showMenu,
    workspaceId,
    icon,
    loading
}) {
    const getLink = useGetLink();

    const TheTitle = () => {
        if (loading) {
            return <Skeleton height="12px" width="100%"/>;
        }

        return (
            <Title size="xxs" mode="uppercase">
                {title}
            </Title>
        );
    };

    return (
        <Wrapper
            className="w-full flex items-center justify-between rounded-md gap-2 text-neutral-200 mx-1 h-8"
            $clickable={!!link}
        >
            {icon && (
                <IconWrapper icon={icon} size="xs"/>
            )}
            {link ? (
                <StyledLink to={getLink(link)}>
                    <TheTitle/>
                </StyledLink>
            ) : (
                <div className="flex-grow h-full flex items-center">
                    <TheTitle/>
                </div>
            )}

            <div className="flex items-center">
                {showMenu && (
                    <AddItemMenu
                        workspaceId={workspaceId}
                    />
                )}
            </div>
        </Wrapper>
    );
}


TreeHeader.propTypes = {
    workspaceId: PropTypes.string,
    title: PropTypes.node,
    link: PropTypes.string,
    showMenu: PropTypes.bool,
    icon: PropTypes.element,
    loading: PropTypes.bool
};

const Wrapper = styled.div`
  ${({$clickable}) => $clickable ? `
      &:hover {
        background-color: rgba(0, 0, 0, 0.25);
      }
  ` : ""};
  & .active {
    color: #fff;
  }
`;

Wrapper.propTypes = {
    $clickable: PropTypes.bool
};

const StyledLink = styled(NavLink)`
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
