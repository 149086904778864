import React from "react";
import PropTypes from "prop-types";
import {LINKS} from "src/links.js";
import {Route, Routes} from "react-router-dom";
import {useGetWorkspace} from "src/api/workspaces/useGetWorkspace.js";
import {PageLayout} from "src/primitives/layout/index.jsx";
import {WorkspaceSettingsPage} from "./pages/WorkspaceSettings";
import {WorkspaceHome} from "./pages/WorkspaceHome";


export function WorkspacePage({workspaceId}) {
    const {workspace, loading} = useGetWorkspace(workspaceId);
    const tabs = [
        {link: LINKS.WORKSPACE, title: "Overview"},
        {link: LINKS.WORKSPACE_SETTINGS, title: "Settings"}
    ];

    return (
        <PageLayout
            title={workspace?.name}
            loading={loading}
            navLinks={tabs}
        >
            <Routes>
                <Route
                    path=""
                    element={(
                        <WorkspaceHome
                            key={workspaceId}
                            workspaceId={workspaceId}
                            workspace={workspace}
                        />
                    )}
                />
                <Route
                    path="/settings"
                    element={(
                        <WorkspaceSettingsPage
                            key={workspaceId}
                            workspaceId={workspaceId}
                        />
                    )}
                />
            </Routes>
        </PageLayout>
    );
}

WorkspacePage.propTypes = {
    workspaceId: PropTypes.string
};
