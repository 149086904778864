import {useMemo} from "react";
import {useGetWorkspaceResources} from "src/api/workspaces/useGetWorkspaceResources.js";
import {useGetSharedResources} from "./sharing/useGetSharedResources.js";


export function useGetAllResources(workspaceId) {
    const {tables, folders, loading: loadingWorkspaceResources} = useGetWorkspaceResources(workspaceId);
    const {folders: sharedFolders, tables: sharedTables, loading: loadingSharedResources} = useGetSharedResources();

    const allTables = useMemo(() => {
        return [...tables, ...sharedTables];
    }, [tables, sharedTables]);

    const allFolders = useMemo(() => {
        return [...folders, ...sharedFolders];
    }, [folders, sharedFolders]);

    const isLoading = useMemo(() => {
        return loadingWorkspaceResources || loadingSharedResources;
    }, [loadingWorkspaceResources, loadingSharedResources]);

    return {
        tables: allTables,
        folders: allFolders,
        loading: isLoading
    };
}
