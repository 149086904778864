import React from "react";
import PropTypes from "prop-types";
import {CHART_TYPES} from "src/components/ChartBuilder/constants.jsx";
import {Label} from "src/primitives/controls/Label.jsx";
import {SelectInput} from "src/primitives/controls/index.jsx";
import {formatPrettyString} from "src/utils/formatting.js";


export function SelectChartType({value, onSelectChartType}) {
    const handleSelectChartType = (item) => {
        onSelectChartType(item.value);
    };

    const chartTypes = Object.keys(CHART_TYPES).map((key) => ({
        value: CHART_TYPES[key],
        name: formatPrettyString(key)
    }));

    const selectedItem = value ? chartTypes.find((item) => item.value === value) : null;

    return (
        <div>
            <Label label="Select a chart type"/>
            <SelectInput
                onChange={handleSelectChartType}
                options={chartTypes}
                selectedOption={selectedItem}
                showClearIcon={false}
                // renderOption={(item) => formatPrettyString(item.key)}
            />
        </div>
    );
}


SelectChartType.propTypes = {
    value: PropTypes.string,
    onSelectChartType: PropTypes.func
};
