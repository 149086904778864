import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";


export const GET_PENDING_INVITATIONS = gql`
  query GetPendingInvitations {
    user {
      id
      pendingInvitations {
        id
        workspace {
          id
          name
        }
        createdAt
        email
        status
        invitedByUser {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;


export function useGetPendingInvitations() {
    const {data, loading, error, refetch} = useQuery(GET_PENDING_INVITATIONS);

    const invitations = useMemo(() => {
        return data?.user?.pendingInvitations || [];
    }, [data]);

    return {invitations, loading, error, refetch};
}
