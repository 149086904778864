import React from "react";
import PropTypes from "prop-types";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {Overlay} from "src/primitives/Overlay/index.jsx";
import {FiColumns} from "react-icons/fi";
import {arrayToMap} from "src/utils/misc.js";
import {Checkbox} from "src/primitives/controls/Checkbox.jsx";
import {Button} from "src/primitives/button.jsx";
import {Title} from "src/primitives/title.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {useAuthenticatedUser} from "src/pages/contexts/user_context.jsx";
import {useUpdateColumnVisibility} from "src/api/tableColumns/useUpdateColumnVisibility.js";


function ToggleColumnsMenu({columns, handleChange}) {
    const columnMap = arrayToMap(columns, "name");

    return (
        <div className="w-80">
            <div className="max-h-96 overflow-y-auto text-sm py-2 px-3">
                {columns.map((column) => (
                    <div key={column.id} className="flex items-center">
                        <Checkbox
                            name={column.name}
                            text={column.name}
                            isChecked={!columnMap[column.name].isHidden}
                            onChange={handleChange}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}


ToggleColumnsMenu.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        isHidden: PropTypes.bool
    })),
    handleChange: PropTypes.func
};


function SelectColumnsHeader({loading}) {
    return (
        <div className="flex items-center justify-between">
            <Title size="xs">
                <span>Select visible columns</span>
            </Title>
            {loading && (
                <Spinner size="sm"/>
            )}
        </div>
    );
}

SelectColumnsHeader.propTypes = {
    loading: PropTypes.bool
};

const overlayContentStyle = {
    padding: 0
};

export function SelectVisibleColumns({tableId, columns}) {
    const authUser = useAuthenticatedUser();
    const [updateColumn, {loading}] = useUpdateColumnVisibility(tableId);

    const handleChange = (name, value) => {
        // Avoid multiple requests
        if (loading) {
            return;
        }
        const isHidden = !value;
        updateColumn(name, isHidden);
    };

    if (!authUser) {
        // TODO: Support toggle columns when not logged in. Store in localstorage?
        return null;
    }

    return (
        <Overlay
            align="right"
            header={<SelectColumnsHeader loading={loading}/>}
            contentStyle={overlayContentStyle}
            content={(
                <ToggleColumnsMenu
                    tableId={tableId}
                    columns={columns}
                    handleChange={handleChange}
                />
            )}
            render={({open}) => (
                <Button
                    size="xs"
                    theme="outlineBlack"
                    isDisabled={open}
                >
                    <div className="flex items-center gap-2" style={{marginLeft: -4}}>
                        <IconWrapper
                            icon={<FiColumns/>}
                            size="xxs"
                        />
                        <span>Columns</span>
                    </div>
                </Button>
            )}
        />
    );
}

SelectVisibleColumns.propTypes = {
    tableId: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        isHidden: PropTypes.bool
    }))
};
