import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Code} from "src/primitives/Terminal/code.jsx";
import {Title} from "src/primitives/title.jsx";
import {useGetUserApiKeys} from "src/api/users/useUserApiKeys.js";
import {config} from "src/config.js";
import {IconCopy} from "src/primitives/IconCopy.jsx";


export function CodeHintExample({tableId, workspaceId}) {
    const {apiKeys} = useGetUserApiKeys(workspaceId);
    const key = apiKeys[0] ? apiKeys[0].value : "$API_KEY";

    const codeExample = `from rho_store import RhoClient

# personal API key
key = "${key}"
rho_client = RhoClient(api_key=key)

# get table as DataFrame
table_id = "${tableId}"
df = rho_client.get_df(table_id)`;

    const csvExample = `
${config.API_URL}/v1/tables/${tableId}/data?key=${key}&format=csv
`;

    const editTableExample = `# given
df = pd.DataFrame(data=[])

# create new table
table = rho_client.store_df(
    df, 
    name="new_table", 
    strategy="NEW_TABLE"
)
print(table.client_url)  # visit in browser

# add more rows to the table
table_id = "${tableId}"
table = rho_client.store_df(
    df, 
    table_id=table_id, 
    strategy="APPEND"
)

# replace the current data
table_id = "${tableId}"
table = rho_client.store_df(
    df, 
    table_id=table_id, 
    strategy="REPLACE"
)
`;
    return (
        <Wrapper>
            <div className="mb-2">
                <Title size="xs">
                    Install python package
                </Title>
            </div>
            <CodeWrapper className="relative rounded mb-4">
                <Code
                    theme="github"
                    showLineNumbers={false}
                    code="pip install rho-store --upgrade --no-cache"
                />
                <div className="absolute z-10 top-0.5 right-0.5">
                    <IconCopy
                        text="pip install rho-store --upgrade"
                    />
                </div>
            </CodeWrapper>

            <div className="mb-2">
                <Title size="xs">
                    Fetch dataframe
                </Title>
            </div>
            <CodeWrapper className="relative rounded mb-4">
                <Code
                    theme="github"
                    showLineNumbers={false}
                    code={codeExample}
                />
                <div className="absolute z-10 top-0.5 right-0.5">
                    <IconCopy text={codeExample.trim()}/>
                </div>
            </CodeWrapper>

            <div className="mb-2">
                <Title size="xs">
                    Get table as CSV
                </Title>
            </div>
            <CodeWrapper className="relative rounded mb-4">
                <Code
                    theme="github"
                    showLineNumbers={false}
                    code={csvExample}
                    language="bash"
                />
                <div className="absolute z-10 top-0.5 right-0.5">
                    <IconCopy text={csvExample.trim()}/>
                </div>
            </CodeWrapper>

            <div className="mb-2">
                <Title size="xs">
                    Edit data in table
                </Title>
            </div>
            <CodeWrapper className="relative rounded mb-4">
                <Code
                    theme="github"
                    showLineNumbers={false}
                    code={editTableExample}
                />
                <div className="absolute z-10 top-0.5 right-0.5">
                    <IconCopy text={editTableExample.trim()}/>
                </div>
            </CodeWrapper>
        </Wrapper>
    );
}


CodeHintExample.propTypes = {
    tableId: PropTypes.string,
    workspaceId: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CodeWrapper = styled.div`
  background-color: #fff;
`;
