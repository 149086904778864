import React from "react";
import PropTypes from "prop-types";
import {isColumnCategorical, isColumnContinuous} from "src/utils/tableData.js";
import {ScatterPlot} from "src/charts/ScatterPlot.jsx";
import {useGetTableStats} from "src/api/tableData/useGetTableStats.js";
import {
    ColumnPivot
// eslint-disable-next-line max-len
} from "src/pages/Home/pages/TablePage/components/ColumnDetailsSidebar/components/ColumnDetails/components/ColumnAggregations/components/ColumnPivot/index.jsx";


export function FeatureVisualization({
    tableId,
    featureColumn,
    targetColumn,
    values
}) {
    const {data: tableStats} = useGetTableStats(tableId);

    const height = 280;

    if (isColumnContinuous(featureColumn) && isColumnContinuous(targetColumn)) {
        const xStats = tableStats && tableStats[featureColumn.name];
        const yStats = tableStats && tableStats[targetColumn.name];
        const xValues = values[featureColumn.name];
        const yValues = values[targetColumn.name];
        const rows = xValues.map((val, index) => {
            return [val, yValues[index]];
        });

        return (
            <ScatterPlot
                height={height}
                data={rows}
                xName={featureColumn.name}
                yName={targetColumn.name}
                xMin={Math.floor(xStats?.p1)}
                xMax={Math.ceil(xStats?.p99)}
                yMin={Math.floor(yStats?.p1)}
                yMax={Math.ceil(yStats?.p99)}
            />
        );
    }

    if (isColumnCategorical(featureColumn) && isColumnContinuous(targetColumn)) {
        return (
            <ColumnPivot
                tableId={tableId}
                columns={[featureColumn.name]}
                targets={[targetColumn]}
            />
        );
    }

    return (
        <div className="flex items-center justify-center">

            {featureColumn.name} vs {targetColumn.name}
        </div>
    );
}

FeatureVisualization.propTypes = {
    tableId: PropTypes.string.isRequired,
    featureColumn: PropTypes.shape({
        name: PropTypes.string,
        variableType: PropTypes.string
    }),
    targetColumn: PropTypes.shape({
        name: PropTypes.string,
        variableType: PropTypes.string
    }),
    values: PropTypes.object
};
