import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {Overlay} from "src/primitives/Overlay/index.jsx";
import {ListOptions} from "src/primitives/controls/ListOptions.jsx";


function ColumnList({columns, isSelected, onSelect}) {
    const options = columns.map(({name}) => {
        return {key: name, label: name};
    });
    return (
        <div className="min-w-52">
            <ListOptions
                options={options}
                isSelected={isSelected}
                onSelect={onSelect}
            />
        </div>
    );
}

ColumnList.propTypes = {
    columns: PropTypes.array,
    isSelected: PropTypes.func,
    onSelect: PropTypes.func
};

export function SelectColumnOverlay({columns, children, selected, onSelect}) {
    const isSelected = useCallback((key) => {
        return key === selected;
    }, [selected]);

    return (
        <Overlay
            content={<ColumnList columns={columns} isSelected={isSelected} onSelect={onSelect}/>}
        >
            {children}
        </Overlay>
    );
}

SelectColumnOverlay.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    children: PropTypes.node,
    selected: PropTypes.string,
    onSelect: PropTypes.func
};
