import React from "react";
import PropTypes from "prop-types";
import {CategoryDistribution} from "src/charts/CategoryDistribution/index.jsx";


export function ColumnCategoricalValues({values}) {
    return (
        <div className="my-2 text-xs monospace">
            <div className="max-h-72 overflow-y-scroll pr-4">
                <CategoryDistribution
                    values={values}
                    maxItems={50}
                />
            </div>
        </div>
    );
}

ColumnCategoricalValues.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
    }))
};
