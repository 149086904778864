import React from "react";
import ReactPlayer from "react-player";
import {MacOsBackground} from "src/primitives/backgrounds/GradientBackground.jsx";


export function RhoDemo() {
    const demoUrl = "https://storage.googleapis.com/rho-public/rho_demo_v3.mp4";
    // rounded-md
    const style = {
        borderRadius: "0.5rem", // rounded-lg
        overflow: "hidden",
        outline: "2px solid rgba(0, 0, 0, 0.1)"
    };

    return (
        <MacOsBackground style={{position: "relative"}}>
            <ReactPlayer
                url={demoUrl}
                loop={true}
                controls={false}
                playing={true}
                muted={true}
                playbackRate={1}
                width="100%"
                height="auto"
                style={style}
            />
        </MacOsBackground>
    );
}

/*
Hack to hide black stripe

const HideMargin = styled.div`
  position: absolute;
  top: 3px;
  right: 1px;
  width: 3px;
  height: calc(100% - 6px);
  background: white;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`;
*/
