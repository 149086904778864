export function convertDataType(dataType) {
    /**
     * Map datatype to supported cell type:
     * https://handsontable.com/docs/react-data-grid/cell-type/
     */
    switch (dataType) {
    case "INTEGER":
        return "numeric";
    case "FLOAT":
        return "numeric";
    case "TEXT":
        return "text";
    case "DATE":
        return "date";
    case "DATETIME":
        return "date";
    default: // fallback
        // eslint-disable-next-line no-console
        console.log("Unknown data type: ", dataType);
        return "text";
    }
}

export function formatColumnData(headers, columnTypes, columnFormats) {
    if (!Array.isArray(headers)) {
        return null;
    }

    return headers.map((title, index) => {
        // Internal ID-column is always integer
        const dataType = title === "_id" ? "numeric" : convertDataType(columnTypes[title]);

        const column = {
            data: index,
            type: dataType,
            readOnly: false,
            title
            // renderer: ""
        };
        if (columnTypes[title] && columnTypes[title].startsWith("float")) {
            column.numericFormat = {
                pattern: "0.00"
            };
        }
        if (column.type === "date") {
            column.dateFormat = "YYYY-MM-DD";
            column.correctFormat = true;
        }

        if (columnFormats[title]) {
            column.renderer = columnFormats[title];
        }

        /*
        if (title === "pe_avg") {
            column["renderer"] = "heatmap";
        }
        if (title === "price") {
            column["renderer"] = "heatmap";
        }
         */

        return column;
    });
}
