import {nanoId} from "src/utils/id.js";
import {AGGREGATION_TYPES} from "src/components/ChartBuilder/data.js";


export function emptySeries({column, name} = {column: "", name: ""}) {
    return {
        id: nanoId(),
        column,
        name,
        yAxis: "left",
        aggregation: AGGREGATION_TYPES.AVG,
        regressionType: null
    };
}
