"use client";

import React from "react";
import PropTypes from "prop-types";
import {ErrorBoundary} from "react-error-boundary";


export function ErrorComponent({children, fallback}) {
    return (
        <ErrorBoundary fallback={fallback}>
            {children}
        </ErrorBoundary>
    );
}

ErrorComponent.propTypes = {
    children: PropTypes.node,
    fallback: PropTypes.node
};

ErrorComponent.defaultProps = {
    fallback: <div>Something went wrong</div>
};
