import React from "react";
import PropTypes from "prop-types";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import {useKeyPress} from "src/utils/useKeyPress.js";
import {DrawerBody, DrawerFooter, DrawerHeader, DrawerWrapper} from "src/primitives/drawer/components.jsx";


export function SidebarDrawer({
    isOpen,
    close,
    title,
    children,
    footer,
    size,
    enableOverlay
}) {
    useKeyPress(close, ["Escape"], {});

    const style = {
        maxWidth: "50%",
        minWidth: "200px"
    };

    return (
        <Drawer
            open={isOpen}
            onClose={close}
            direction="right"
            duration={240}
            size={size}
            enableOverlay={enableOverlay}
            style={style}
        >
            <DrawerWrapper>
                <DrawerHeader
                    title={title}
                    close={close}
                />
                <DrawerBody>
                    {children}
                </DrawerBody>
                <DrawerFooter>
                    {footer}
                </DrawerFooter>
            </DrawerWrapper>
        </Drawer>
    );
}


SidebarDrawer.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    title: PropTypes.node,
    children: PropTypes.node,
    footer: PropTypes.node,
    size: PropTypes.number,
    enableOverlay: PropTypes.bool
};

SidebarDrawer.defaultProps = {
    size: 480,
    enableOverlay: true
};
