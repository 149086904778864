import {useCallback, useMemo} from "react";
import {gql, useMutation} from "@apollo/client";
import {CORE_TABLE_FIELDS} from "src/api/fragments.js";


const MUTATION = gql`
${CORE_TABLE_FIELDS}
mutation ProcessFile ($data: ProcessFileInput!) {
  processFile(data: $data) {
    table {
      ... CoreTableFields      
    }
    ok
    errorCode
  }
}
`;

export function useProcessFile() {
    const [mutation, {error, loading, data}] = useMutation(MUTATION);

    const doMutate = useCallback(({fileId, tableId, strategy, mergeOptions}) => {
        return mutation({
            variables: {data: {fileId, tableId, strategy, mergeOptions}}
        }).then((response) => {
            return response.data.processFile;
        });
    }, [mutation]);

    const result = useMemo(() => {
        return data?.processFile;
    }, [data]);

    return [doMutate, {error, loading, result}];
}
