import React from "react";
import PropTypes from "prop-types";
import {AGGREGATION_TYPES} from "src/components/ChartBuilder/data.js";
import {SmallDropdown} from "src/primitives/Dropdown/SmallDropdown.jsx";
import {formatPrettyString} from "src/utils/formatting.js";


export function SelectAggregation({value, onChange}) {
    const options = Object.keys(AGGREGATION_TYPES).map((key) => {
        return {
            value: key,
            title: formatPrettyString(AGGREGATION_TYPES[key])
        };
    });
    const selectedValue = value ? formatPrettyString(value) : "Select";

    return (
        <SmallDropdown
            options={options}
            selectedValue={selectedValue}
            onSelect={(option) => onChange(option.value)}
        />
    );
}

SelectAggregation.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};
