import React from "react";
import PropTypes from "prop-types";
import {FiRepeat} from "react-icons/fi";
import {useGetValues} from "src/api/tableData/useGetValues.js";
import Drawer from "react-modern-drawer";
import {DrawerBody, DrawerHeader, DrawerWrapper} from "src/primitives/drawer/components.jsx";
import {isNotEmptyArray} from "src/utils/misc.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {ScatterPlot} from "src/charts/ScatterPlot.jsx";
import {CenteredContent} from "src/primitives/layout/index.jsx";
import {useGetCorrelationMatrix} from "src/api/tableData/useGetCorrelationMatrix.js";
import {Title} from "src/primitives/title.jsx";
import {getBgColorForCorrelation} from "src/pages/Home/pages/TablePage/pages/ProfilingPage/utils.js";
import {useSelectedColumns} from "src/pages/Home/pages/TablePage/pages/ProfilingPage/states.jsx";
import {IconButton} from "src/primitives/button.jsx";
import {useKeyPress} from "src/utils/useKeyPress.js";
import {formatPercent} from "src/utils/formatting.js";
import {convertDictsToRows} from "./utils.js";


export function ColumnsSidebar({tableId, tableStats}) {
    const inverse = useSelectedColumns((state) => state.inverse);
    const close = useSelectedColumns((state) => state.clear);
    const columns = useSelectedColumns((state) => state.columns);

    useKeyPress(close, ["Escape"]);

    const {data: correlation} = useGetCorrelationMatrix(tableId);
    const {data, loading} = useGetValues(tableId, columns);
    const keys = Object.keys(data);
    const xName = keys[0];
    const yName = keys[1];
    const rows = convertDictsToRows(data);
    const height = 520;

    const xStats = tableStats[xName];
    const yStats = tableStats[yName];

    const pairCorrelation = (correlation && correlation[xName]) ? correlation[xName][yName] : null;

    return (
        <Drawer
            open={isNotEmptyArray(columns)}
            onClose={close}
            direction="right"
            duration={320}
            size={640}
            enableOverlay={true}
        >
            <DrawerWrapper>
                <DrawerHeader
                    title={(
                        <div className="flex">
                            <span>Compare&nbsp;</span>
                            <span className="italic">{xName}</span>
                            <span>&nbsp;and&nbsp;</span>
                            <span className="italic">{yName}</span>
                        </div>
                    )}
                    close={close}
                />

                <DrawerBody>
                    <div className="flex items-center gap-2">
                        <Title size="xs">
                            Linear correlation:
                        </Title>
                        {/* eslint-disable-next-line max-len */}
                        <div className={`${getBgColorForCorrelation(pairCorrelation)} text-white rounded-md px-2 py-1 text-sm font-bold flex items-center justify-center`}>
                            {formatPercent(pairCorrelation)}
                        </div>
                        <div className="flex-1"/>
                        <div>
                            <IconButton
                                icon={<FiRepeat/>}
                                onClick={inverse}
                                theme="outlineBlack"
                                size="xs"
                                text="Switch X/Y"
                            />
                        </div>
                    </div>

                    {loading && (
                        <CenteredContent style={{height}}>
                            <Spinner/>
                        </CenteredContent>
                    )}
                    {isNotEmptyArray(rows) && (
                        <ScatterPlot
                            height={height}
                            data={rows}
                            xName={xName}
                            yName={yName}
                            xMin={Math.floor(xStats?.p1)}
                            xMax={Math.ceil(xStats?.p99)}
                            yMin={Math.floor(yStats?.p1)}
                            yMax={Math.ceil(yStats?.p99)}
                        />
                    )}
                </DrawerBody>
            </DrawerWrapper>
        </Drawer>
    );
}

ColumnsSidebar.propTypes = {
    tableId: PropTypes.string,
    tableStats: PropTypes.object
};
