import React from "react";
import styled from "styled-components";
import {useGetTable} from "src/api/tables/useGetTable.js";


export function TestPage() {
    const {table} = useGetTable("bzefejrudtbd");

    console.log(table);
    return (
        <Wrapper>
            <h1>Hello world</h1>
        </Wrapper>
    );
}

const Wrapper = styled.div`
`;
