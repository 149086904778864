import React from "react";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import {DrawerBody, DrawerFooter, DrawerHeader, DrawerWrapper} from "src/primitives/drawer/components.jsx";
import {useKeyPress} from "src/utils/useKeyPress.js";
import {EXTERNAL_LINKS} from "src/links.js";
import {Callout} from "src/primitives/Callout/index.jsx";
import {useCodeHintsState} from "./states.jsx";
import {CodeHintExample} from "./components/Example";


export function CodeHintsSidebar({workspaceId}) {
    const [isOpen, close] = useCodeHintsState((state) => [state.isOpen, state.close]);
    const {tableId} = useParams();

    const disableKeyPress = () => {
        //  if not tableId is active, do not act on keypress
        return !tableId;
    };

    useKeyPress(close, ["Escape"], {}, disableKeyPress);

    if (!tableId) {
        return null;
    }

    return (
        <Drawer
            open={isOpen}
            onClose={close}
            direction="right"
            duration={240}
            size={560}
            enableOverlay={true}
        >
            <DrawerWrapper>
                <DrawerHeader
                    title="Code examples"
                    close={close}
                />
                <DrawerBody>
                    <Callout >
                        {/* eslint-disable-next-line max-len */}
                        See full documentation on <a className="underline text-blue-600 hover:text-blue-800" href={EXTERNAL_LINKS.RHO_DOCS} target="_blank" rel="noreferrer">docs.rho.store</a>.
                    </Callout>
                    <CodeHintExample
                        tableId={tableId}
                        workspaceId={workspaceId}
                    />
                </DrawerBody>
                <DrawerFooter/>
            </DrawerWrapper>
        </Drawer>
    );
}

CodeHintsSidebar.propTypes = {
    workspaceId: PropTypes.string
};
