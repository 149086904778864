import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Spinner} from "src/primitives/spinner.jsx";


export function LoadingOverlay({isLoading}) {
    if (!isLoading) {
        return null;
    }

    return (
        <>
            <LoadingBackdrop/>
            <LoadingWrapper>
                <Spinner theme="blue" size="xl"/>
            </LoadingWrapper>
        </>
    );
}

LoadingOverlay.propTypes = {
    isLoading: PropTypes.bool
};

const LoadingBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(2px);
  animation: fadeIn 0.25s;
  z-index: 10;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 320px;
  animation: fadeIn 0.25s;
  z-index: 9;
`;
