import React from "react";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiExternalLink} from "react-icons/fi";
import {EXTERNAL_LINKS} from "src/links.js";
import {buttonClasses} from "./styles.jsx";

export function RhoDocsLink() {
    return (
        <div className="mb-1 text-sm">
            <a
                href={EXTERNAL_LINKS.RHO_DOCS}
                target="_blank"
                rel="noreferrer"
                className={buttonClasses()}
            >
                <IconWrapper
                    icon={<FiExternalLink />}
                    size="xs"
                    style={{marginRight: 4}}
                />
                <span>
                    Documentation
                </span>
            </a>
        </div>
    );
}
