import {useEffect} from "react";
import {overrideSystemHandling} from "src/utils/system.js";

export function useKeyPress(callback, keyCodes, options = {shift: false, metaKey: false}, tempDisable = false) {
    const handler = (event) => {
        if (tempDisable && tempDisable()) {
            return;
        }
        if (options.shift) {
            if (!event.shiftKey) {
                return;
            }
        }
        if (options.metaKey) {
            if (!event.metaKey) {
                return;
            }
        }

        if (keyCodes.includes(event.key)) {
            overrideSystemHandling(event);
            callback();
        }
    };

    const listenerOptions = {capture: true};

    useEffect(() => {
        window.addEventListener("keydown", handler, listenerOptions);
        return () => {
            window.removeEventListener("keydown", handler, listenerOptions);
        };
    });
}
