import styled from "styled-components";


export const Tr = styled.tr``;

export const Td = styled.td`
  text-align: start;
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  padding-top: var(--chakra-space-2);
  padding-bottom: var(--chakra-space-2);
  font-size: var(--chakra-fontSizes-sm);
  line-height: var(--chakra-lineHeights-4);
  border-color: var(--chakra-colors-gray-100);
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;


export const Th = styled.th`
  text-align: start;
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  padding-top: var(--chakra-space-1);
  padding-bottom: var(--chakra-space-1);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--chakra-colors-gray-100);
`;
