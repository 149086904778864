import React from "react";
import PropTypes from "prop-types";
import {formatBooleanValues} from "src/charts/CategoryDistribution/utils.js";
import {BarChart} from "src/charts/BarChart.jsx";


export function ColumnBooleanValues({values}) {
    const booleanValues = formatBooleanValues(values);
    const data = booleanValues.map((item) => {
        return {
            ...item,
            itemStyle: {color: item.color}
        };
    });

    return (
        <div className="my-2">
            <BarChart
                height={200}
                data={data}
            />
        </div>
    );
}

ColumnBooleanValues.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string
    }))
};
