import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Title} from "src/primitives/title.jsx";


export function LargeCard({title, subtitle, children}) {
    return (
        <Wrapper className="p-4 mb-4 rounded-md">
            <div className="flex items-center justify-between border-b border-black/5 mb-6 pb-2">
                <Title size="small" style={{margin: 0}}>
                    {title}
                </Title>
                <div>
                    {subtitle}
                </div>
            </div>
            <div>
                {children}
            </div>
        </Wrapper>
    );
}

LargeCard.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    children: PropTypes.node
};

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.015);
`;
