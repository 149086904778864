import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_TABLE_STATS_QUERY} from "src/api/tableData/useGetTableStats.js";
import {GET_TABLE_PREVIEW} from "src/api/tableData/useGetTablePreview.js";
import {GET_FITTED_DISTRIBUTIONS} from "src/api/tableData/useGetFittedDistributions.js";
import {GET_CORRELATION_MATRIX_QUERY} from "src/api/tableData/useGetCorrelationMatrix.js";
import {GET_COLUMN_DISTRIBUTIONS_QUERY} from "src/api/tableData/useGetColumnDistributions.js";


const MUTATION = gql`
    mutation AnalyseTable($tableId: String!) {
        analyseTable (tableId: $tableId) {
            ok
            errorCode
        }
    }
`;


export function useReAnalyzeTable(tableId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        const variables = {tableId};
        const refetchQueries = [{
            query: GET_TABLE_STATS_QUERY,
            variables: {id: tableId}
        }, {
            query: GET_TABLE_PREVIEW,
            variables: {id: tableId}
        }, {
            query: GET_CORRELATION_MATRIX_QUERY,
            variables: {id: tableId}
        }, {
            query: GET_FITTED_DISTRIBUTIONS,
            variables: {id: tableId}
        }, {
            query: GET_COLUMN_DISTRIBUTIONS_QUERY,
            variables: {id: tableId}
        }];
        return mutation({variables, refetchQueries});
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
