import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";
import {Button} from "src/primitives/button.jsx";
import {useIndexTable} from "src/api/tables/useIndexTable.js";


export function SearchSettings({tableId, searchIndex}) {
    const [indexTable, {loading}] = useIndexTable(tableId);

    const handleClick = useCallback(() => {
        indexTable();
    }, [indexTable]);

    return (
        <>
            {searchIndex ? (
                <div>
                    <Title size="xs">
                        Following columns are indexed for full text search:
                    </Title>
                    <ul className="list-disc ml-4 my-2">
                        {searchIndex.map((name) => (
                            <li key={name}>{name}</li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div>
                    Search index not enabled yet.
                </div>
            )}
            <div className="my-4">
                <Button
                    theme="orange"
                    onClick={handleClick}
                    isLoading={loading}
                    isDisabled={!!searchIndex}
                >
                    Enable search
                </Button>
            </div>
        </>
    );
}

SearchSettings.propTypes = {
    tableId: PropTypes.string.isRequired,
    searchIndex: PropTypes.arrayOf(PropTypes.string)
};
