import React from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";


export function ErrorPage({content}) {
    return (
        <div className="w-full h-full flex items-center justify-center">
            <div className="h-64 min-w-32">
                <Title>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    That's an error
                </Title>
                <div>
                    {content}
                </div>
            </div>
        </div>
    );
}

ErrorPage.propTypes = {
    content: PropTypes.node
};
