import React from "react";
import styled from "styled-components";


export function Ambient() {
    return (
        <Wrapper className="inert z-10">
            <InnerWrapper>
                <LightA/>
                <LightB/>
                <LightC/>
            </InnerWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const InnerWrapper = styled.div`
  position: absolute;
  inset: 0;
  contain: strict;
  isolation: isolate;
`;

const LightCommon = styled.div`
  position: absolute;
  inset: 0;
  top: 0;
  left: 0;
  border-radius: 9999px;
`;

const LightA = styled(LightCommon)`
  height: 1380px;
  transform: translateY(-350px) rotate(-45deg);
  width: 560px;
  /*
  // eslint-disable-next-line max-len
  background: radial-gradient(
          68.54% 68.72% at 55.02% 31.46%, hsla(0, 0%, 85%, .08) 0, hsla(0, 0%, 55%, .02) 50%, hsla(0, 0%, 45%, 0)
          80%);          
   */
  // eslint-disable-next-line max-len
  background: radial-gradient(
          68.54% 68.72% at 55.02% 31.46%,
          hsla(0, 0%, 85%, .06) 0,
          hsla(0, 0%, 55%, .03) 50%,
          hsla(0, 0%, 45%, 0) 80%
  );
`;

const LightB = styled(LightCommon)`
  transform: rotate(-45deg) translate(5%, -50%);
  transform-origin: top left;
  width: 240px;
  background: radial-gradient(
          50% 50% at 50% 50%, hsla(0, 0%, 85%, .06) 0, hsla(0, 0%, 45%, .02)
          80%, transparent 100%);

`;

const LightC = styled(LightCommon)`
  transform: rotate(-45deg) translate(-180%, -70%);
  transform-origin: top left;
  width: 240px;
  height: 1380px;
  background: radial-gradient(
          50% 50% at 50% 50%, hsla(0, 0%, 85%, .04) 0, hsla(0, 0%, 45%, .02) 80%,
          transparent 100%);
`;
