import React, {useState} from "react";
import PropTypes from "prop-types";
import {TextInput} from "src/primitives/controls/index.jsx";


export function ChartOptions({onChange, config}) {
    const [title, setTitle] = useState(config?.titleText);
    const [xAxisLabel, setXAxisLabel] = useState(config?.xAxisLabel);
    const [yAxisLabel, setYAxisLabel] = useState(config?.yAxisLabel);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
        onChange({titleText: event.target.value});
    };

    const handleXAxisLabelChange = (event) => {
        const {value} = event.target;
        setXAxisLabel(value);
        onChange({xAxisLabel: value});
    };

    const handleYAxisLabelChange = (event) => {
        const {value} = event.target;
        setYAxisLabel(value);
        onChange({yAxisLabel: value});
    };

    return (
        <div className="flex flex-col gap-4">
            <TextInput
                label="Chart title"
                onChange={handleTitleChange}
                value={title}
            />

            <TextInput
                label="X-Axis Label"
                value={xAxisLabel}
                onChange={handleXAxisLabelChange}
            />

            <TextInput
                label="Y-Axis Label"
                value={yAxisLabel}
                onChange={handleYAxisLabelChange}
            />
        </div>
    );
}

ChartOptions.propTypes = {
    onChange: PropTypes.func,
    config: PropTypes.shape({
        titleText: PropTypes.string,
        yAxisLabel: PropTypes.string,
        xAxisLabel: PropTypes.string
    })
};
