import {max, mean, min, quantileSeq, std, sum} from "mathjs";
import {groupByUsingMap} from "src/utils/misc.js";
import {isNumber} from "src/utils/validators.js";

// Constants for aggregation types
export const AGGREGATION_TYPES = {
    COUNT: "COUNT",
    SUM: "SUM",
    MIN: "MIN",
    MAX: "MAX",
    AVG: "AVG",
    STD: "STD",
    P5: "P5",
    P25: "P25",
    P50: "P50",
    P75: "P75",
    P95: "P95"
};


export const AGGREGATIONS = {
    [AGGREGATION_TYPES.COUNT]: (values) => values.length,
    [AGGREGATION_TYPES.SUM]: (values) => sum(values),
    [AGGREGATION_TYPES.MIN]: (values) => min(values),
    [AGGREGATION_TYPES.MAX]: (values) => max(values),
    [AGGREGATION_TYPES.AVG]: (values) => mean(values),
    [AGGREGATION_TYPES.STD]: (values) => std(values),
    [AGGREGATION_TYPES.P5]: (values) => quantileSeq(values, 0.05, false),
    [AGGREGATION_TYPES.P25]: (values) => quantileSeq(values, 0.25, false),
    [AGGREGATION_TYPES.P50]: (values) => quantileSeq(values, 0.50, false),
    [AGGREGATION_TYPES.P75]: (values) => quantileSeq(values, 0.75, false),
    [AGGREGATION_TYPES.P95]: (values) => quantileSeq(values, 0.95, false)
};

// Function to perform the specified aggregation
function performAggregation(values, aggregationType) {
    if (!Array.isArray(values)) {
        throw new Error(`Invalid values: ${values}`);
    }

    const numbers = values.filter((val) => isNumber(val));

    if (numbers.length === 0) {
        return null;
    }

    const aggFunc = AGGREGATIONS[aggregationType];

    if (!aggFunc || typeof aggFunc !== "function") {
        throw new Error(`Invalid aggregation type: ${aggregationType}`);
    }

    return aggFunc(numbers);
}


// Main function to group and aggregate data
// Main function to group and aggregate data
export function aggregateData(data, groupByField, series) {
    // Step 1: Group data by the specified field
    const groupedData = groupByUsingMap(data, groupByField);

    // Step 2: Aggregate data
    const aggregatedResults = [];
    groupedData.forEach((items, key) => {
        const aggregatedObject = {[groupByField]: key};

        series.forEach(({column, aggregation}) => {
            const prices = items.map((item) => item[column]);
            const seriesKey = `${column}__${aggregation}`;
            aggregatedObject[seriesKey] = performAggregation(prices, aggregation);
        });

        aggregatedResults.push(aggregatedObject);
    });

    return aggregatedResults;

    /*
    const groupedData = groupBy(data, groupByField);
    const aggregatedResults = Object.keys(groupedData).map((key) => {
        const items = groupedData[key];
        const aggregatedObject = {[groupByField]: key};

        series.forEach(({column, aggregation}) => {
            const prices = items.map((item) => item[column]);
            const seriesKey = `${column}__${aggregation}`;
            aggregatedObject[seriesKey] = performAggregation(prices, aggregation);
        });

        return aggregatedObject;
    });
    return aggregatedResults;
     */
}

/**
 *
 * @type {[{object_type: string, sold_price: number},{object_type: string, sold_price: number},{object_type: string, sold_price: number},{object_type: string, sold_price: number},{object_type: string, sold_price: number}]}
 // Example usage
 const data = [
 { object_type: 'house', sold_price: 300000, size: 2000 },
 { object_type: 'apartment', sold_price: 150000, size: 800 },
 { object_type: 'house', sold_price: 320000, size: 2100 },
 { object_type: 'house', sold_price: 310000, size: 2200 },
 { object_type: 'apartment', sold_price: 170000, size: 850 }
 ];

 const series = [
 { field: 'sold_price', aggregation: AGGREGATION_TYPES.AVG },
 { field: 'sold_price', aggregation: AGGREGATION_TYPES.MIN },
 { field: 'size', aggregation: AGGREGATION_TYPES.MAX }
 ];

 const result = aggregateData(data, 'object_type', series);
 console.log(result);
 */
