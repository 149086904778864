import React from "react";
import PropTypes from "prop-types";
import {formatNumber} from "src/utils/formatting.js";
import {isNullOrUndefined} from "src/utils/misc.js";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {HeatmapColor} from "src/components/TableGrid/utils/heatmap_color.js";
import {
    LIME
// eslint-disable-next-line max-len
} from "src/components/TableGrid/Header/ColumnHeaderSecondary/components/ColumnMenu/components/ColumnDisplaySettings/components/DisplayHeatmap/color_schemes.js";


function buildDisplayColumns(columns, targets, tableStats) {
    const displayColumns = [];

    columns.forEach((column) => {
        displayColumns.push({
            key: column,
            id: column,
            header: <span>{column}</span>,
            render: (val) => (
                <span>{isNullOrUndefined(val) ? <span className="text-neutral-500">missing/null</span> : val}</span>
            )
        });
    });

    displayColumns.push({
        key: "__total",
        id: "__total",
        header: <span>Total items</span>,
        render: (value) => (
            <div className="text-left">{formatNumber(value, 0)}</div>
        )
    });

    targets.forEach((column) => {
        const targetName = column.name;
        const heatmapHelper = new HeatmapColor(tableStats[targetName], LIME);

        displayColumns.push({
            key: `${targetName}__avg`,
            id: `${targetName}__avg`,
            header: <span>{`AVG ${targetName}`}</span>,
            render: (value) => {
                return (
                    <div className="text-right">{formatNumber(value)}</div>
                );
            },
            getValue: (row) => {
                const statsKey = `${targetName}__stats`;
                const stats = row[statsKey];
                return stats?.avg;
            },
            getStyle: (value) => {
                return {background: heatmapHelper.getColor(value)};
            }
        });

        displayColumns.push({
            key: `${targetName}__p25`,
            id: `${targetName}__p25`,
            header: <span>{`P25 ${targetName}`}</span>,
            render: (value) => {
                return (
                    <div className="text-right">{formatNumber(value)}</div>
                );
            },
            getValue: (row) => {
                const statsKey = `${targetName}__stats`;
                const stats = row[statsKey];
                return stats?.p25;
            },
            getStyle: (value) => {
                return {background: heatmapHelper.getColor(value)};
            }
        });
        displayColumns.push({
            key: `${targetName}__p75`,
            id: `${targetName}__p75`,
            header: <span>{`P75 ${targetName}`}</span>,
            render: (value) => {
                return (
                    <div className="text-right">{formatNumber(value)}</div>
                );
            },
            getValue: (row) => {
                const statsKey = `${targetName}__stats`;
                const stats = row[statsKey];
                return stats?.p75;
            },
            getStyle: (value) => {
                return {background: heatmapHelper.getColor(value)};
            }
        });
    });

    return displayColumns;
}


export function PivotTable({data, columns, targets, tableStats, maxRows}) {
    const displayColumns = buildDisplayColumns(columns, targets, tableStats);

    return (
        <div>
            <SimpleTable
                data={data.slice(0, maxRows)}
                columns={displayColumns}
            />
        </div>
    );
}

PivotTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.string),
    targets: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        variableType: PropTypes.string,
        dataType: PropTypes.string
    })),
    tableStats: PropTypes.object,
    maxRows: PropTypes.number
};

PivotTable.defaultProps = {
    maxRows: 50
};
