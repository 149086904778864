import {useConvertPercentageColumn} from "src/api/tableColumns/useConvertPercentageColumn.js";
import {useRemoveColumn} from "src/api/tableColumns/useRemoveColumn.js";
import {useMemo} from "react";

export function useTableActions(tableId, {onCompleted} = {}) {
    const [convertColumnToPercentage] = useConvertPercentageColumn(tableId, {onCompleted});
    const [removeColumn] = useRemoveColumn(tableId, {onCompleted});

    const actions = useMemo(() => ({
        convertColumnToPercentage,
        removeColumn
    }), [convertColumnToPercentage, removeColumn]);

    return [actions, {}];
}
