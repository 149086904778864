import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Button} from "src/primitives/button.jsx";
import {Stepper} from "src/primitives/Stepper/index.jsx";
import {Title} from "src/primitives/title.jsx";
import {useGetLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";
import {NEW_FILE_STRATEGY} from "src/pages/Home/pages/UploadFilePage/components/options/strategies.js";
import {FiArrowRight} from "react-icons/fi";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {useUploadFile} from "./useUploadFile.jsx";


const uploadSteps = [{
    title: "Prepare",
    description: "Preparing to upload"
}, {
    title: "Upload",
    description: "Uploading file"
}, {
    title: "Process",
    description: "Processing the data"
}];

export function FileUploader({workspaceId, folderId, file, tableId, strategy, tableName, mergeOnColumn}) {
    const getLink = useGetLink();
    const [hasStartedUpload, setHasStartedUpload] = useState(false);
    const [startUpload, {steps, error, isLoading, table: createdTable}] = useUploadFile(workspaceId);

    async function doStartUpload() {
        setHasStartedUpload(true);
        const mergeOptions = strategy === NEW_FILE_STRATEGY.MERGE ? {column: mergeOnColumn} : null;
        const options = {
            tableId,
            folderId,
            name: tableName || file.name, // given name, fallback to file name
            strategy,
            mergeOptions
        };
        await startUpload(file, options);
    }

    const isValidOptions = useMemo(() => {
        if (strategy === NEW_FILE_STRATEGY.MERGE) {
            return tableId && mergeOnColumn;
        }

        if (strategy !== NEW_FILE_STRATEGY.NEW_TABLE) {
            return !!tableId;
        }
        return true;
    }, [strategy, tableId, mergeOnColumn]);

    return (
        <Wrapper>
            {!hasStartedUpload && (
                <Button
                    onClick={doStartUpload}
                    isLoading={isLoading}
                    theme="green"
                    isDisabled={!isValidOptions}
                >
                    Start upload
                </Button>
            )}
            {hasStartedUpload && (
                <div className="mb-6">
                    <div className="mb-2">
                        <Title size="xs">
                            Progress:
                        </Title>
                    </div>
                    <Stepper
                        steps={uploadSteps}
                        loadingStep={steps.loading}
                        completedSteps={steps.completed}
                    />
                </div>
            )}

            {error && (
                <div>
                    <p>Failed to upload file</p>
                    <code>{JSON.stringify(error)}</code>
                </div>
            )}

            {createdTable && (
                <div>
                    <Title>
                        Completed!
                    </Title>
                    <p className="mb-2">Upload successfully completed</p>
                    <div className="mb-4">
                        <Link to={getLink(LINKS.tablePage(createdTable.id))}>
                            <Button>
                                <div className="flex items-center gap-2">
                                    <span>Go to table</span>
                                    <IconWrapper
                                        size="medium"
                                        icon={<FiArrowRight/>}
                                        style={{height: "20px", width: "20px", marginRight: "-10px"}}
                                    />
                                </div>
                            </Button>
                        </Link>
                    </div>
                </div>
            )}
        </Wrapper>
    );
}

FileUploader.propTypes = {
    workspaceId: PropTypes.string.isRequired,
    file: PropTypes.shape({
        name: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string
    }).isRequired,
    tableId: PropTypes.string,
    folderId: PropTypes.string,
    strategy: PropTypes.oneOf(Object.values(NEW_FILE_STRATEGY)),
    tableName: PropTypes.string,
    mergeOnColumn: PropTypes.string
};

const Wrapper = styled.div`
`;
