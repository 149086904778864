
export function getParents(folderId, foldersMap) {
    const currentFolder = foldersMap[folderId];
    if (!currentFolder) {
        console.warn("Folder with id not found: ", folderId);
        return [];
    }

    const parents = [];
    let parentFolder = null;
    let {parentFolderId} = currentFolder;
    while (parentFolderId) {
        parentFolder = foldersMap[parentFolderId];
        parents.push(parentFolder);
        parentFolderId = parentFolder.parentFolderId;
    }

    return parents;
}
