import React from "react";
import PropTypes from "prop-types";
import {useCreateApiKey} from "src/api/apiKeys/useCreateApiKey.js";
import {useGetUserApiKeys} from "src/api/users/useUserApiKeys.js";
import {Button} from "src/primitives/button.jsx";
import {ApiKeysTable} from "./components/ApiKeysTable";


export function ApiKeys({workspaceId}) {
    const [createApiKey, {loading: creating}] = useCreateApiKey(workspaceId);
    const {apiKeys} = useGetUserApiKeys(workspaceId);

    const handleCreateApiKey = () => {
        createApiKey();
    };

    const hasKeys = Array.isArray(apiKeys) && apiKeys.length > 0;

    return (
        <div className="min-h-20">
            {hasKeys && (
                <div className="mb-8 max-w-xl">
                    <ApiKeysTable keys={apiKeys}/>
                </div>
            )}

            <div className="mb-8">
                {!hasKeys && (
                    <div className="my-2">
                        <p>Create a personal API key to access your data.</p>
                    </div>
                )}
                <div>
                    <Button
                        size="small"
                        onClick={handleCreateApiKey}
                        isLoading={creating}
                        theme="secondary"
                    >
                        Create API key
                    </Button>
                </div>
            </div>
        </div>
    );
}

ApiKeys.propTypes = {
    workspaceId: PropTypes.string.isRequired
};
