import {isNullOrUndefined} from "src/utils/misc.js";
import {CATEGORY_DIVIDER, RANGE_DIVIDER} from "src/components/TableGrid/constants.js";

export function fromCategoryValues(values) {
    return values.join(CATEGORY_DIVIDER);
}

export function toCategoryValues(textValue, fallback = []) {
    if (!textValue) {
        return fallback;
    }
    return textValue.split(CATEGORY_DIVIDER);
}

export function fromRangeValue(values) {
    return values.join(RANGE_DIVIDER);
}

export function toRangeValue(textValue, fallback = [1, 100]) {
    if (!textValue) {
        return fallback;
    }
    return textValue.split(RANGE_DIVIDER);
}

export function fromBooleanValue(value) {
    if (isNullOrUndefined(value)) {
        return "NULL";
    }
    return value ? "TRUE" : "FALSE";
}

export function toBooleanValue(textValue, fallback = null) {
    if (isNullOrUndefined(textValue) || textValue === "NULL") {
        return fallback;
    }

    return textValue === "TRUE";
}

export function fromBooleanValues(values) {
    return values.map(fromBooleanValue).join(CATEGORY_DIVIDER);
}

export function textToBooleanValues(textValue, fallback = []) {
    if (!textValue) {
        return fallback;
    }

    const values = textValue.split(CATEGORY_DIVIDER);

    return values.map((v) => toBooleanValue(v));
}
