import React from "react";
import PropTypes from "prop-types";
import {RhoImg} from "src/primitives/Logo/index.jsx";
import {Title} from "src/primitives/title.jsx";
import {TableInfo} from "./TableInfo.jsx";
import {Navbar} from "./Navbar.jsx";


export function PublicTableHeader({tableId, tableName}) {
    return (
        <div className="h-12 w-full px-2 flex items-center bg-slate-950 text-neutral-50">
            <div className="py-2 basis-1/5 flex items-center gap-4">
                <RhoImg/>
                <Title size="xs" style={{margin: 0}}>
                    <span className="monospace">
                        {tableName}
                    </span>
                </Title>
            </div>
            <div className="h-full flex-1 flex justify-center">
                <Navbar tableId={tableId}/>
            </div>
            <div className="py-2 basis-1/5 pr-2">
                <div className="w-full flex justify-end">
                    <TableInfo tableId={tableId}/>
                </div>
            </div>
        </div>
    );
}

PublicTableHeader.propTypes = {
    tableId: PropTypes.string,
    tableName: PropTypes.string
};
