import {max, mean, min} from "mathjs";

import {isNumber} from "src/utils/validators.js";

export function getColor(value, allValues) {
    // https://blog.bioturing.com/2018/09/24/heatmap-color-scale/
    if (value === null) {
        return null;
    }
    const numericValues = allValues.filter((v) => isNumber(v));
    if (!numericValues || numericValues.length < 3) {
        return null;
    }

    const avg = mean(numericValues);
    const minValue = min(numericValues);
    const maxValue = max(numericValues);
    const values = [
        minValue,
        (avg - minValue) / 2.0,
        avg,
        (maxValue - avg) / 2.0,
        maxValue
    ];

    /*
    const colors = [
        "#cef1de",
        "#6dd498",
        "#43a96f",
        "#38a266",
        "#308c57"
    ];
     */

    const colors = [
        "#ff8239",
        "#fe9c4a",
        "#feb460",
        "#feca7a",
        "#ffdf97"
    ];
    colors.reverse();

    const gradient = values.map((val, index) => ({
        value: val,
        color: colors[index]
    }));

    // Find the two gradient colors that the value falls between
    let lowerOption; let
        upperOption;

    for (let i = 0; i < gradient.length - 1; i++) {
        if (value <= gradient[i + 1].value) {
            lowerOption = gradient[i];
            upperOption = gradient[i + 1];
            break;
        }
    }

    // Interpolate between the two colors based on the value's position in the gradient
    const valuePosition = (value - lowerOption.value) / (upperOption.value - lowerOption.value);
    const lowerColor = lowerOption.color;
    const upperColor = upperOption.color;

    const color = {
        // eslint-disable-next-line max-len
        r: Math.floor(parseInt(lowerColor.slice(1, 3), 16) + (parseInt(upperColor.slice(1, 3), 16) - parseInt(lowerColor.slice(1, 3), 16)) * valuePosition),
        // eslint-disable-next-line max-len
        g: Math.floor(parseInt(lowerColor.slice(3, 5), 16) + (parseInt(upperColor.slice(3, 5), 16) - parseInt(lowerColor.slice(3, 5), 16)) * valuePosition),
        // eslint-disable-next-line max-len
        b: Math.floor(parseInt(lowerColor.slice(5, 7), 16) + (parseInt(upperColor.slice(5, 7), 16) - parseInt(lowerColor.slice(5, 7), 16)) * valuePosition)
    };

    // return '#' + ('00' + color.r.toString(16)).slice(-2) + ('00' + color.g.toString(16)).slice(-2) + ('00' + color.b.toString(16)).slice(-2);
    return `rgba(${color.r}, ${color.g}, ${color.b}, 0.75)`;
}
