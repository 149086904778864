import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {FiTrash2} from "react-icons/fi";
import {overrideSystemHandling} from "src/utils/system.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {RemoveApiKeyDialog} from "./RemoveApiKeyDialog.jsx";


export function RemoveApiKeyCell({keyId}) {
    const [dialogOpen, setShowDialog] = useState(false);

    const closeDialog = useCallback(() => {
        setShowDialog(false);
    }, [setShowDialog]);

    const showDialog = useCallback(() => {
        setShowDialog(true);
    }, [setShowDialog]);


    const handleClick = useCallback((e) => {
        overrideSystemHandling(e);
        showDialog();
    }, [showDialog]);

    return (
        <>
            {dialogOpen && (
                <RemoveApiKeyDialog
                    close={closeDialog}
                    keyId={keyId}
                />
            )}

            <div className="text-red-600">
                <IconWrapper
                    icon={<FiTrash2/>}
                    onClick={handleClick}
                    thickness={1.5}
                    itemId={`delete-key-${keyId}`}
                    tooltip="Delete API key"
                />
            </div>
        </>
    );
}

RemoveApiKeyCell.propTypes = {
    keyId: PropTypes.string.isRequired
};
