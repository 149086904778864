import React from "react";
import PropTypes from "prop-types";
import {FiEdit} from "react-icons/fi";
import {ColumnDataType} from "src/pages/Home/pages/TablePage/components/ColumnDataType.jsx";
import {formatTitleCase} from "src/utils/formatting.js";
import {useBooleanState} from "src/utils/hooks/useBooleanState.jsx";
import {DialogModal} from "src/primitives/modals/Dialog.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {Title} from "src/primitives/title.jsx";
import {EditColumnDataType} from "./components/EditColumnDataType/index.jsx";


export function ChangeDataType({tableId, column}) {
    const [isOpen, open, close] = useBooleanState(false);

    return (
        <>
            <DialogModal
                isOpen={isOpen}
                close={close}
                title="Change data type"
                size="lg"
            >
                <div className="py-2">
                    <div className="mb-2">
                        <Title size="xs">Change data type for column <b>&quot;{column.name}&quot;</b></Title>
                    </div>
                    <EditColumnDataType tableId={tableId} column={column}/>
                </div>
            </DialogModal>

            <ColumnDataType column={column}>
                <button
                    type="button"
                    className="hover:underline group flex items-center gap-1"
                    onClick={open}
                >
                    <span>
                        {formatTitleCase(column.dataType)}
                    </span>
                    <span className="opacity-0 group-hover:opacity-75 transition duration-500">
                        <IconWrapper
                            icon={<FiEdit/>}
                            size="xs"
                        />
                    </span>
                </button>
            </ColumnDataType>
        </>
    );
}

ChangeDataType.propTypes = {
    tableId: PropTypes.string.isRequired,
    column: PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataType: PropTypes.string
    })
};
