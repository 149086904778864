import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {useKeyPress} from "src/utils/useKeyPress.js";
import {PageForm} from "./PageForm.jsx";


const buttonClasses = cva({
    // eslint-disable-next-line max-len
    base: "py px-2 flex items-center justify-center bg-transparent text-neutral-900 rounded border border-neutral-950 ",
    variants: {
        state: {
            active: "cursor-pointer hover:bg-black/5",
            disabled: "cursor-not-allowed !text-neutral-500 !border-neutral-500"
        }
    }
});

export function TablePagination({totalRows, pageSize, currentPage, selectPage}) {
    const totalPages = Math.ceil(totalRows / pageSize);

    const isPrevDisabled = currentPage === 1;
    const isNextDisabled = currentPage === totalPages;

    const goToNext = () => {
        if (isNextDisabled) {
            return;
        }
        selectPage(currentPage + 1);
    };

    const goToPrev = () => {
        if (isPrevDisabled) {
            return;
        }
        selectPage(currentPage - 1);
    };

    useKeyPress(goToNext, ["ArrowRight"], {metaKey: true});
    useKeyPress(goToPrev, ["ArrowLeft"], {metaKey: true});

    return (
        <div className="flex items-center gap-1 text-xs monospace">
            <button
                type="button"
                className={buttonClasses({state: isPrevDisabled ? "disabled" : "active"})}
                style={{paddingLeft: 0}}
                onClick={goToPrev}
            >
                <IconWrapper
                    icon={<FiArrowLeft/>}
                    size="xs"
                />
                <span style={{marginLeft: -2}}>
                    Prev
                </span>
            </button>

            <div className="w-24 flex justify-center">
                <PageForm
                    currentPage={currentPage}
                    totalPages={totalPages}
                    selectPage={selectPage}
                />
            </div>

            <button
                type="button"
                className={buttonClasses({state: isNextDisabled ? "disabled" : "active"})}
                style={{paddingRight: 0}}
                onClick={goToNext}
            >
                <span style={{marginRight: -2}}>
                    Next
                </span>
                <IconWrapper
                    icon={<FiArrowRight/>}
                    size="xs"
                />
            </button>
        </div>
    );
}

TablePagination.propTypes = {
    totalRows: PropTypes.number,
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    selectPage: PropTypes.func
};
