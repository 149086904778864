import React from "react";
import PropTypes from "prop-types";
import {Title} from "src/primitives/title.jsx";
import {useUpdateColumnDisplaySettings} from "src/api/tableColumns/useUpdateColumnDisplaySettings.js";
import {TextInputForm} from "src/primitives/controls/TextInputForm.jsx";


export function ColumnSuffix({column}) {
    const [updateDisplaySettings, {loading}] = useUpdateColumnDisplaySettings(column.tableId, column.name);
    const {displaySettings} = column;
    const displaySuffix = displaySettings?.suffix;

    const updateSuffix = (value) => {
        const settings = {suffix: value};
        updateDisplaySettings(settings, displaySettings).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
        }).catch((error) => {
            console.warn(error);
        });
    };

    return (
        <div className="flex flex-col gap-2">
            <Title size="xs">
                Suffix
            </Title>
            <TextInputForm
                value={displaySuffix}
                onSubmit={updateSuffix}
                loading={loading}
            />
        </div>
    );
}

ColumnSuffix.propTypes = {
    column: PropTypes.shape({
        tableId: PropTypes.string,
        name: PropTypes.string,
        displaySettings: PropTypes.object
    })
};
