import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import bgImage from "src/primitives/layout/assets/bg3.jpg";

export function SplitPage({children}) {
    return (
        <Wrapper>
            <Primary
                className="px-8 py-6 bg-gray-50"
            >
                {children}
            </Primary>
            <Secondary className="hidden sm:flex">
                <BackgroundImage $imgUrl={bgImage}/>
            </Secondary>
        </Wrapper>
    );
}

SplitPage.propTypes = {
    children: PropTypes.any
};

const Wrapper = styled.div`

  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const Primary = styled.div`
  flex-basis: 520px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


const Secondary = styled.div`
  flex: 1;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  flex: 1;
  height: 100%;
  max-width: 640px;
  filter: brightness(2);
  transform: rotatez(270deg);
  background-image: ${({$imgUrl}) => `url(${$imgUrl})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;
