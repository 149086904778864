import React from "react";
import PropTypes from "prop-types";
import {SelectItem} from "src/components/TableGrid/Header/ColumnFilter/components/SelectItem.jsx";


export function BooleanFilter({value, updateValue}) {
    const options = [{
        key: "true",
        value: true,
        label: "True"
    }, {
        key: "false",
        value: false,
        label: "False"
    }, {
        key: "null",
        value: null,
        label: "Null / Missing"
    }];

    const isItemSelected = (v) => {
        return value.findIndex((i) => i === v) > -1;
    };

    const handleSelect = (v) => {
        if (isItemSelected(v)) {
            updateValue(value.filter((val) => val !== v));
        } else {
            updateValue([v, ...value]);
        }
    };

    return (
        <div className="my-1">
            {options.map((option) => (
                <SelectItem
                    key={option.key}
                    item={option.label}
                    handleSelect={handleSelect.bind(null, option.value)}
                    isSelected={isItemSelected(option.value)}
                />
            ))}
        </div>
    );
}

BooleanFilter.propTypes = {
    value: PropTypes.array,
    updateValue: PropTypes.func
};
