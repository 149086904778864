import {Openpanel} from "@openpanel/web";
import {config} from "src/config.js";


export class EventTracking {
    constructor() {
        if (config.OPENPANEL_CLIENT_ID) {
            this.engine = new Openpanel({
                clientId: config.OPENPANEL_CLIENT_ID,
                trackScreenViews: true,
                trackOutgoingLinks: true,
                trackAttributes: false
            });
        }
    }
}

// export const eventTracking = new EventTracking();
