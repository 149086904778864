import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Spinner} from "src/primitives/spinner.jsx";
import {cva} from "cva";


const itemClasses = cva({
    base: "flex items-center space-x-4 text-gray-500",
    variants: {
        mode: {
            completed: "text-green-700",
            loading: ""
        }
    },
    defaultVariants: {}
});

const indexClasses = cva({
    base: "flex items-center justify-center w-8 h-8 border-2 border-gray-500 rounded-full shrink-0",
    variants: {
        mode: {
            completed: "border-green-600 text-green-700"
        }
    },
    defaultVariants: {}
});

export function Stepper({steps, loadingStep, completedSteps}) {
    const checkmark = "✓";

    const isCompleted = (stepIndex) => {
        return (completedSteps && stepIndex < completedSteps);
    };

    const getMode = (stepIndex) => {
        if (stepIndex + 1 === loadingStep) {
            return "loading";
        }
        if (isCompleted(stepIndex)) {
            return "completed";
        }

        return "";
    };

    return (
        <Wrapper>
            <div className="flex items-center space-x-12">
                {steps.map((step, index) => (
                    <div className={itemClasses({mode: getMode(index)})} key={step.title}>
                        {index + 1 === loadingStep ? (
                            <div className="w-8 h-8 shrink-0">
                                <Spinner theme="green" />
                            </div>
                        ) : (
                            <span className={indexClasses({mode: getMode(index)})}>
                                {isCompleted(index) ? checkmark : index + 1}
                            </span>
                        )}
                        <span>
                            <h3 className="font-medium leading-tight">
                                {step.title}
                            </h3>
                            <p className="text-sm">
                                {step.description}
                            </p>
                        </span>
                    </div>
                ))}
            </div>
        </Wrapper>
    );
}

Stepper.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.node,
        description: PropTypes.node
    })),
    loadingStep: PropTypes.number,
    completedSteps: PropTypes.number
};

Stepper.defaultProps = {
    steps: [{
        title: "User info",
        description: "Step details here"
    }, {
        title: "Company info",
        description: "Step details here"
    }, {
        title: "Payment info",
        description: "Step details here"
    }],
    loadingStep: 2,
    completedSteps: 1
};

const Wrapper = styled.div`
`;
