import React from "react";
import PropTypes from "prop-types";
import {FiArrowUp, FiArrowDown} from "react-icons/fi";
import {IconWrapper} from "src/primitives/Icon.jsx";


function getIcon(direction) {
    if (direction === "asc") {
        return <FiArrowDown/>;
    }
    if (direction === "desc") {
        return <FiArrowUp/>;
    }
    return null;
}
export function SortIcon({direction}) {
    const icon = getIcon(direction);

    return (
        <div className="w-4 h-4">
            {icon && <IconWrapper icon={icon} size="xxs"/>}
        </div>
    );
}

SortIcon.propTypes = {
    direction: PropTypes.oneOf(["asc", "desc"])
};
