import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Title} from "src/primitives/title.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiMinus, FiPlus} from "react-icons/fi";


export function Accordion({title, children, size}) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = useCallback(() => {
        setIsOpen((prevState) => !prevState);
    }, [setIsOpen]);

    return (
        <Wrapper className="w-full flex flex-col gap-2">
            <Button
                type="button"
                className="w-full py-4 px-2 flex items-center justify-between border-t border-white/10"
                onClick={toggleOpen}
            >
                <Title size={size} style={{margin: 0}}>
                    {title}
                </Title>
                <div>
                    <IconWrapper
                        icon={isOpen ? <FiMinus/> : <FiPlus/>}
                        size="small"
                    />
                </div>
            </Button>

            <div
                className="py-2 px-2 mb-4 max-w-192"
                style={{display: isOpen ? "block" : "none"}}
            >
                {children}
            </div>
        </Wrapper>
    );
}

Accordion.propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
    size: PropTypes.oneOf(["small", "medium", "large"])
};
Accordion.defaultProps = {
    size: "small"
};


const Wrapper = styled.div`
`;

const Button = styled.button`
  &:hover {
    background-color: rgba(255, 255, 255, 0.01);
  }
`;
