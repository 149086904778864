import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_TABLE_STATS_QUERY} from "src/api/tableData/useGetTableStats.js";
import {GET_CATEGORICAL_RELATIONS_QUERY} from "src/api/tableData/useGetCategoricalRelations.js";
import {GET_COLUMN_DISTRIBUTIONS_QUERY} from "src/api/tableData/useGetColumnDistributions.js";
import {GET_FITTED_DISTRIBUTIONS} from "src/api/tableData/useGetFittedDistributions.js";
import {GET_TABLE_PREVIEW} from "src/api/tableData/useGetTablePreview.js";


const MUTATION = gql`
    mutation UpdateColumnVariableType ($data: UpdateColumnVariableType!) {
        updateColumnVariableType(data: $data) {
            ok
            errorCode
            column {
                id
                variableType
            }
        }
    }
`;

export function useUpdateColumnVariableType(tableId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((name, variableType) => {
        const expectedResult = {
            ok: true,
            errorCode: null,
            column: {
                __typename: "Column",
                id: `${tableId}:${name}`,
                variableType
            }
        };
        const optimisticResponse = {updateColumnVariableType: expectedResult};
        // All queries that hold statistics and column aggregates needs to be refetched.
        // TODO: Better understand which aggregates are affected by change in variable type.
        const refetchQueries = [{
            query: GET_TABLE_STATS_QUERY,
            variables: {id: tableId}
        }, {
            query: GET_TABLE_PREVIEW,
            variables: {id: tableId}
        }, {
            query: GET_COLUMN_DISTRIBUTIONS_QUERY,
            variables: {id: tableId}
        }, {
            query: GET_FITTED_DISTRIBUTIONS,
            variables: {id: tableId}
        }, {
            query: GET_CATEGORICAL_RELATIONS_QUERY,
            variables: {id: tableId}
        }];

        const data = {tableId, name, variableType};
        return mutation({
            optimisticResponse,
            refetchQueries,
            variables: {data}
        }).then((response) => {
            return response.data.updateColumnVariableType;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
