import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";


export const CurrentTableContext = createContext(null);


export function CurrentTableContextProvider({children}) {
    const {tableId} = useParams();

    return (
        <CurrentTableContext.Provider value={tableId}>
            {children}
        </CurrentTableContext.Provider>
    );
}

CurrentTableContextProvider.propTypes = {
    children: PropTypes.element
};

export function useCurrentTableId() {
    return useContext(CurrentTableContext);
}
