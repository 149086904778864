import React from "react";
import PropTypes from "prop-types";
import {Code, SUPPORTED_LANGUAGES, SUPPORTED_THEMES} from "src/primitives/Terminal/code.jsx";
import {IconCopy} from "src/primitives/IconCopy.jsx";


export function CopyCode({code, theme, language, iconColor}) {
    return (
        <div className="relative rounded">
            <Code
                theme={theme}
                showLineNumbers={false}
                code={code}
                language={language}
            />
            <div className="absolute z-10 top-0.5 right-0.5">
                <IconCopy
                    text={code}
                    iconColor={iconColor}
                />
            </div>
        </div>
    );
}

CopyCode.propTypes = {
    code: PropTypes.string,
    language: PropTypes.oneOf(SUPPORTED_LANGUAGES),
    theme: PropTypes.oneOf(SUPPORTED_THEMES),
    iconColor: PropTypes.string
};
