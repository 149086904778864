import React from "react";
import PropTypes from "prop-types";
import {sortByValue} from "src/utils/sorting.js";
import {formatNumber} from "src/utils/formatting.js";
import {CorrelationBar} from "./CorrelationBar.jsx";


function getSortedCorrelationItems(correlationsMap) {
    const correlationItems = Object.keys(correlationsMap).map((key) => {
        return {
            name: key,
            value: correlationsMap[key]
        };
    });
    return sortByValue(correlationItems, "value", "desc");
}

export function ColumnCorrelations({correlations}) {
    if (!correlations) {
        return null;
    }

    const sortedItems = getSortedCorrelationItems(correlations);

    return (
        <div className="mt-3">
            {sortedItems.map((item) => (
                <div key={item.name} className="mb-3">
                    <div className="flex items-center justify-between text-xs monospace mb-1">
                        <div>
                            {item.name}
                        </div>
                        <div>
                            {item.value > 0 && "+"}{formatNumber(100 * item.value)}%
                        </div>
                    </div>
                    <CorrelationBar value={item.value}/>
                </div>
            ))}
        </div>
    );
}

ColumnCorrelations.propTypes = {
    correlations: PropTypes.object
};
