import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const GET_CORRELATION_MATRIX_QUERY = gql`
  query GetCorrelationMatrix($id: String!) {
    table (tableId: $id) {
      id
      correlationMatrix
    }
  }
`;


export function useGetCorrelationMatrix(tableId) {
    const {
        data, loading, error, refetch
    } = useQuery(GET_CORRELATION_MATRIX_QUERY, {variables: {id: tableId}});

    const correlationMatrix = useMemo(() => {
        if (!data?.table?.correlationMatrix) {
            return {};
        }
        const cm = data.table.correlationMatrix;
        window.cm = cm;
        return cm;
    }, [data]);

    return {
        data: correlationMatrix, loading, error, refetch
    };
}
