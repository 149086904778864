import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {FiTrash2} from "react-icons/fi";
import {useDeleteUser} from "src/api/users/useDeleteUser.js";
import {overrideSystemHandling} from "src/utils/system.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {ConfirmDeletionDialog} from "src/primitives/modals/ConfirmDeletionDialog.jsx";
import {useShowToast} from "src/components/toasts/useShowToast.js";


export function DeleteUser({userId}) {
    const {showError, showSuccess} = useShowToast();
    const [deleteUser, {loading}] = useDeleteUser();
    const [dialogOpen, setShowDialog] = useState(false);
    const closeDialog = useCallback(() => {
        setShowDialog(false);
    }, [setShowDialog]);

    const showDialog = useCallback(() => {
        setShowDialog(true);
    }, [setShowDialog]);

    const doDeleteUser = useCallback((e) => {
        overrideSystemHandling(e);
        deleteUser(userId).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
            showSuccess({message: "User deleted successfully"});
            closeDialog();
        }).catch((error) => {
            console.error(error);
            showError();
        });
    }, [userId, deleteUser, showError, showSuccess, closeDialog]);


    const handleClick = useCallback((e) => {
        overrideSystemHandling(e);
        showDialog();
    }, [showDialog]);

    return (
        <>
            {dialogOpen && (
                <ConfirmDeletionDialog
                    close={closeDialog}
                    confirm={doDeleteUser}
                    loading={loading}
                >
                    <p>It is not possible to recover the user once deleted.</p>
                </ConfirmDeletionDialog>
            )}

            <button
                type="button"
                className="text-red-600 flex items-center hover:bg-black/5 pr-4 rounded-md"
                onClick={handleClick}
            >
                <IconWrapper
                    icon={<FiTrash2/>}
                    thickness={1.5}
                    itemId={`delete-user-${userId}`}
                    tooltip="Delete user"
                />
                <span className="text-xs">
                    Delete user
                </span>
            </button>
        </>
    );
}

DeleteUser.propTypes = {
    userId: PropTypes.string.isRequired
};
