import React from "react";
import PropTypes from "prop-types";
import {TextEditable} from "src/primitives/controls";
import {formatSafeString} from "src/utils/formatting.js";
import {useUpdateTable} from "src/api/tables/useUpdateTable.js";
import {useShowToast} from "src/components/toasts/useShowToast.js";


export function EditTableName({tableId, name}) {
    const {showLoading, showSuccess, showError} = useShowToast();
    const [updateTable] = useUpdateTable(tableId);

    const handleSubmit = (newValue) => {
        showLoading();
        updateTable({name: newValue}).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
            showSuccess();
        }).catch((error) => {
            console.warn(error);
            showError();
        });
    };

    return (
        <TextEditable
            text={name}
            formatValue={formatSafeString}
            onSubmit={handleSubmit}
        />
    );
}

EditTableName.propTypes = {
    tableId: PropTypes.string,
    name: PropTypes.string
};
