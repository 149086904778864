export const CHART_TYPES = {
    BAR: "bar",
    LINE: "line",
    SCATTER: "scatter"
};

export const AXIS_TYPES = {
    /**
     * Source:
     * https://echarts.apache.org/en/option.html#xAxis.type
     */
    // Numerical axis, suitable for continuous data.
    VALUE: "value",
    // Category axis, suitable for discrete category data. Category data can be auto retrieved from series.data or dataset.source, or can be specified via xAxis.data.
    CATEGORY: "category",
    // Time axis, suitable for continuous time series data. As compared to value axis, it has a better formatting for time and a different tick calculation method. For example, it decides to use month, week, day or hour for tick based on the range of span.
    TIME: "time",
    // Log axis, suitable for log data. Stacked bar or line series with type: 'log' axes may lead to significant visual errors and may have unintended effects in certain circumstances. Their use should be avoided.
    LOG: "log"
};

export const REGRESSION_TYPES = {
    // https://github.com/ecomfe/echarts-stat?tab=readme-ov-file#regression
    LINEAR: "linear",
    EXPONENTIAL: "exponential",
    LOGARITHMIC: "logarithmic",
    POLYNOMIAL: "polynomial"
};
