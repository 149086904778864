import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation UpdateColumnDisplaySettings ($data: UpdateColumnDisplaySettingsInput!) {
  updateColumnDisplaySettings(data: $data) {
    ok
    errorCode
    column {
      id
      displaySettings
    }
  }
}
`;

export function useUpdateColumnDisplaySettings(tableId, name) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((newSettings, existingSettings) => {
        const displaySettings = existingSettings ? {...newSettings, ...existingSettings} : newSettings;
        const expectedResult = {
            ok: true,
            errorCode: null,
            column: {
                __typename: "Column",
                id: `${tableId}:${name}`,
                displaySettings
            }
        };
        const optimisticResponse = {updateColumnDisplaySettings: expectedResult};

        const data = {tableId, name, displaySettings: newSettings};
        return mutation({
            optimisticResponse,
            variables: {data}
        }).then((response) => {
            return response.data.updateColumnDisplaySettings;
        });
    }, [mutation, tableId, name]);

    return [doMutate, {loading, error}];
}
