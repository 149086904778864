import React from "react";
import {PageContent, PageLayout} from "src/primitives/layout/index.jsx";
import {useGetAllWorkspaces} from "src/api/admin/useGetAllWorkspaces.js";
import {WorkspacesTable} from "src/pages/Admin/pages/Workspaces/components/WorkspacesTable/index.jsx";


export function WorkspacesPage() {
    const {workspaces, loading} = useGetAllWorkspaces();

    return (
        <PageLayout title="Workspaces" loading={loading}>
            <PageContent title="Workspaces">

                <WorkspacesTable workspaces={workspaces}/>

            </PageContent>
        </PageLayout>
    );
}
