export function interpolateColor(lowerColor, upperColor, valuePosition, opacity = 0.75) {
    const color = {
        // eslint-disable-next-line max-len
        r: Math.floor(parseInt(lowerColor.slice(1, 3), 16) + (parseInt(upperColor.slice(1, 3), 16) - parseInt(lowerColor.slice(1, 3), 16)) * valuePosition),
        // eslint-disable-next-line max-len
        g: Math.floor(parseInt(lowerColor.slice(3, 5), 16) + (parseInt(upperColor.slice(3, 5), 16) - parseInt(lowerColor.slice(3, 5), 16)) * valuePosition),
        // eslint-disable-next-line max-len
        b: Math.floor(parseInt(lowerColor.slice(5, 7), 16) + (parseInt(upperColor.slice(5, 7), 16) - parseInt(lowerColor.slice(5, 7), 16)) * valuePosition)
    };

    return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`;
}

