import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {PageContent} from "src/primitives/layout/PageContent.jsx";
import {EaseIn} from "src/primitives/EaseIn.jsx";
import {DeleteTable} from "src/pages/Home/pages/TablePage/components/DeleteTable/index.jsx";
import {LargeCard} from "src/pages/Home/pages/TablePage/components/LargeCard.jsx";
import {ColumnSettings} from "./components/ColumnSettings/index.jsx";
import {SearchSettings} from "./components/SearchSettings/index.jsx";
import {TriggerAnalysis} from "./components/TriggerAnalysis/index.jsx";


export function TableSettingsPage({tableId, table}) {
    return (
        <PageContent title="Settings" size="xl">
            <EaseIn>
                <Wrapper className="flex flex-col gap-8 mb-8">
                    <LargeCard
                        title="Columns"
                    >
                        <div className="my-4">
                            <ColumnSettings
                                tableId={tableId}
                                columns={table?.columns}
                            />
                        </div>
                    </LargeCard>

                    <LargeCard
                        title="Search settings"
                    >
                        <SearchSettings tableId={tableId} searchIndex={table?.searchIndex}/>
                    </LargeCard>

                    <LargeCard
                        title="Delete table"
                    >
                        <p>It is not possible to recover a table once deleted</p>
                        <div className="my-4">
                            <DeleteTable tableId={tableId}/>
                        </div>
                    </LargeCard>
                    <LargeCard title="Rerun analysis">
                        <p>Sometimes it is helpful to re-run the analysis of the table</p>
                        <div className="my-4">
                            <TriggerAnalysis tableId={tableId}/>
                        </div>
                    </LargeCard>
                </Wrapper>
            </EaseIn>
        </PageContent>
    );
}

TableSettingsPage.propTypes = {
    tableId: PropTypes.string,
    table: PropTypes.shape({
        columns: PropTypes.array,
        searchIndex: PropTypes.arrayOf(PropTypes.string)
    })
};

const Wrapper = styled.div`
`;
