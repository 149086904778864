import React from "react";
import PropTypes from "prop-types";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {ShareResource} from "./index.jsx";
import {ResourceType} from "./constants.js";


export function ShareTable({tableId}) {
    const {table} = useGetTable(tableId);

    return (
        <ShareResource
            resourceId={tableId}
            resourceType={ResourceType.TABLE}
            data={table?.sharedResources}
        />
    );
}

ShareTable.propTypes = {
    tableId: PropTypes.string.isRequired
};
