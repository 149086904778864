import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {formatColumns} from "src/components/DataGrid/columns.js";
import {ColumnHeader} from "src/components/DataGrid/components/ColumnHeader.jsx";
import {TableRow} from "src/components/DataGrid/components/TableRow.jsx";
import {Th, Tr} from "./components/cells.jsx";


export function DataGrid({data, columns, onRowClick, fullWidth, defaultSorting}) {
    const [sorting, setSorting] = useState(defaultSorting);

    // TODO: Implement pagination
    const table = useReactTable({
        data,
        columns: formatColumns(columns),
        state: {
            sorting,
            pagination: {
                pageIndex: 0, // initial page index
                pageSize: 10 // default page size
            }
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel()
    });

    return (
        <TableContainer width={fullWidth ? "100%" : "auto"}>
            <Table size="sm">
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <Th key={header.id}>
                                    <ColumnHeader header={header}/>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>

                <Tbody>
                    {table.getRowModel().rows.map((row) => (
                        <TableRow
                            key={row.id}
                            row={row}
                            onRowClick={onRowClick}
                        />
                    ))}
                </Tbody>

                <Tfoot>
                    {table.getFooterGroups().map((footerGroup) => (
                        <Tr key={footerGroup.id}>
                            {footerGroup.headers.map((header) => (
                                <Th key={header.id} className="!border-0">
                                    {header.isPlaceholder ? null : (
                                        flexRender(
                                            header.column.columnDef.footer,
                                            header.getContext()
                                        ))}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Tfoot>
            </Table>
        </TableContainer>
    );
}

DataGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        header: PropTypes.node,
        render: PropTypes.func
    })),
    onRowClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    defaultSorting: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        desc: PropTypes.bool
    }))
};

DataGrid.defaultProps = {
    defaultSorting: []
};

const TableContainer = styled.div`
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  width: 100%;
  white-space: nowrap;
`;

const Table = styled.table`
  font-variant-numeric: lining-nums tabular-nums;
  border-collapse: collapse;
  width: 100%;
`;


const Tbody = styled.tbody``;

const Tfoot = styled.tfoot``;

const Thead = styled.thead``;
