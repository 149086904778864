import React from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";


export function WorkspaceError({workspaceId, error, retry}) {
    console.warn("Workspace error", workspaceId, error);

    return (
        <div className="p-4">
            <h1>Failed to load workspace</h1>
            <p>Workspace ID: {workspaceId}</p>
            <Button
                onClick={retry}
            >
                Retry
            </Button>
        </div>
    );
}

WorkspaceError.propTypes = {
    workspaceId: PropTypes.string,
    error: PropTypes.object,
    retry: PropTypes.func
};
