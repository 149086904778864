import React from "react";
import PropTypes from "prop-types";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiX} from "react-icons/fi";
import {Circle} from "src/primitives/circle.jsx";
import {Badge} from "src/primitives/badge.jsx";
import {formatDate, formatNumber, tryParseFloat} from "src/utils/formatting.js";
import {RANGE_DIVIDER} from "src/components/TableGrid/constants.js";
import {isColumnDate} from "src/utils/tableData.js";
import {useLoadWithOptions} from "src/pages/Home/pages/TablePage/pages/TableGridPage/table_data_context.jsx";

function renderOperator(operator) {
    switch (operator) {
    case "equals":
        return "matches";
    case "in":
        return "one of";
    case "in_range":
        return "in between";
    case "is":
        return "is";
    default:
        return "Unknown operator";
    }
}

function formatRangeValue(valueString) {
    const [firstValue, secondValue] = valueString.split(RANGE_DIVIDER);

    const format = (v) => {
        return formatNumber(tryParseFloat(v), 2, {minDecimals: 0});
    };

    const text = `[${format(firstValue)} - ${format(secondValue)}]`;

    return (
        <div className="monospace text-xs">
            {text}
        </div>
    );
}

function formatDateRangeValue(valueString) {
    const [firstValue, secondValue] = valueString.split(RANGE_DIVIDER);

    const text = `[${formatDate(firstValue)} - ${formatDate(secondValue)}]`;

    return (
        <div className="monospace text-xs">
            {text}
        </div>
    );
}


function formatCategory(value) {
    const items = value.split(":");

    return (
        <div className="text-xs overflow-y-auto" style={{maxHeight: 48}}>
            {items.map((item) => (
                <div key={item} className="truncate">
                    {item}
                </div>
            ))}
        </div>
    );
}

function formatBoolean(value) {
    const isTrue = value === "TRUE";

    return (
        <Badge theme={isTrue ? "green" : "red"}>
            {isTrue ? "True" : "False"}
        </Badge>
    );
}


function renderValue(operator, value, columnInfo) {
    switch (operator) {
    case "equals":
        return <span className="italic">{value}</span>;
    case "in":
        return formatCategory(value);
    case "in_range":
        if (isColumnDate(columnInfo)) {
            return formatDateRangeValue(value);
        }
        return formatRangeValue(value);
    case "is":
        return formatBoolean(value);
    default:
        return value;
    }
}


export function QueryFilterItem({filterItem, columnInfo}) {
    const {removeFilter} = useLoadWithOptions();
    const {name, value, op} = filterItem;

    const handleRemoveFilter = () => {
        removeFilter(name);
    };

    return (
        <div className="flex items-center justify-between" style={{minWidth: 520}}>
            <div className="flex items-center text-sm gap-2 mr-2 flex-1">
                <div className="shrink-0">
                    <Circle theme="blue"/>
                </div>
                <div className="truncate shrink-0">
                    <span className="italic">{name}</span>
                </div>
                <div className="shrink-0 flex justify-center">
                    <Badge theme="blue">
                        {renderOperator(op)}
                    </Badge>
                </div>
                <div className="flex-1">
                    {renderValue(op, value, columnInfo)}
                </div>
            </div>

            <IconWrapper
                icon={<FiX/>}
                size="xs"
                onClick={handleRemoveFilter}
            />
        </div>
    );
}

QueryFilterItem.propTypes = {
    filterItem: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        op: PropTypes.string
    }),
    columnInfo: PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    })
};
