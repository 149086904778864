import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import debounce from "lodash.debounce";
import {getInstanceByDom, init, registerTransform} from "echarts";
import ecStat from "echarts-stat";
import {getDemoOptions} from "src/charts/demo.js";


registerTransform(ecStat.transform.histogram);
registerTransform(ecStat.transform.regression);


export function ChartWrapper({
    option,
    style,
    settings,
    loading,
    theme,
    height,
    events
}) {
    const chartRef = useRef(null);

    useEffect(() => {
        // Resize chart when parent div is resized
        const debouncedCallback = debounce(() => {
            if (chartRef.current) {
                const chart = getInstanceByDom(chartRef.current);
                chart?.resize();
            }
        }, 100);

        const observer = new ResizeObserver(debouncedCallback);

        if (chartRef.current) {
            observer.observe(chartRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        // Initialize chart
        let chart;
        if (chartRef.current !== null) {
            chart = init(chartRef.current, theme);
        }

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        function resizeChart() {
            console.log("Resize");
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [theme]);

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            chart.setOption(option, settings);
        }
        // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function
    }, [option, settings, theme]);

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (loading === true) {
                chart.showLoading();
            } else {
                chart.hideLoading();
            }
        }
    }, [loading, theme]);

    useEffect(() => {
        if (!chartRef.current || !events) {
            return;
        }

        const chart = getInstanceByDom(chartRef.current);

        Object.keys(events).forEach((event) => {
            const eventHandler = events[event];
            chart.on(event, (param) => {
                eventHandler(param, chart);
            });
        });
    }, [events]);

    return <Wrapper ref={chartRef} style={style} $height={height}/>;
}

ChartWrapper.propTypes = {
    option: PropTypes.object,
    style: PropTypes.object,
    settings: PropTypes.object,
    loading: PropTypes.bool,
    theme: PropTypes.string,
    height: PropTypes.number,
    events: PropTypes.object
};

ChartWrapper.defaultProps = {
    option: getDemoOptions(),
    height: 360
};

const Wrapper = styled.div`
  width: 100%;
  height: ${({$height}) => `${$height}px`};
  // height: 100%;
`;
