import React from "react";
import styled from "styled-components";
import {useAcceptInvitation} from "src/api/workspaceMembers/useAcceptInvitation.js";
import {useGetPendingInvitations} from "src/api/workspaceMembers/useGetPendingInvitations.js";
import {Button} from "src/primitives/button.jsx";
import {Title} from "src/primitives/title.jsx";


export function WorkspaceInvitations() {
    const {invitations, loading} = useGetPendingInvitations();
    const [acceptInvitation, {loading: accepting}] = useAcceptInvitation();

    const handleClick = (workspaceId) => {
        return acceptInvitation({workspaceId});
    };

    if (loading) {
        return null;
    }

    if (invitations.length === 0) {
        return null;
    }

    return (
        <Wrapper>
            <div>
                <Title size="small">
                    Invitations
                </Title>
            </div>
            {invitations.map(({workspace}) => (
                <div
                    key={workspace.id}
                    className="mb-4 flex justify-between items-center rounded-lg border border-neutral-300 p-2"
                >
                    <div>
                        {workspace.name}
                    </div>
                    <div>
                        <Button
                            onClick={() => handleClick(workspace.id)}
                            isLoading={accepting}
                        >
                            Accept
                        </Button>
                    </div>
                </div>
            ))}
        </Wrapper>
    );
}

const Wrapper = styled.div`
`;

