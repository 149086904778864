import React, {useCallback, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {COLUMN_SIZES} from "src/components/TableGrid/constants.js";


export function ColumnResizer({name, updateColumnWidth}) {
    const ref = useRef();
    const [isActive, setIsActive] = useState(false);

    const handleDoubleClick = useCallback(() => {
        updateColumnWidth(name, COLUMN_SIZES.DEFAULT_WIDTH);
    }, [name, updateColumnWidth]);

    const handleDragStart = useCallback((e) => {
        if (!ref.current) {
            return;
        }

        setIsActive(true);

        const colWidth = ref.current.parentElement.getBoundingClientRect().width;
        const initialX = e.clientX;

        const handleDragMove = (moveEvent) => {
            moveEvent.preventDefault();
            const deltaX = moveEvent.clientX - initialX;
            const newWidth = Math.min(Math.max(COLUMN_SIZES.MIN_WIDTH, colWidth + deltaX), COLUMN_SIZES.MAX_WIDTH);

            updateColumnWidth(name, newWidth);
        };

        const handleDragEnd = () => {
            setIsActive(false);
            window.document.removeEventListener("mousemove", handleDragMove);
            window.document.removeEventListener("mouseup", handleDragEnd);
        };

        window.document.addEventListener("mousemove", handleDragMove);
        window.document.addEventListener("mouseup", handleDragEnd);
    }, [name, updateColumnWidth, setIsActive]);

    return (
        <Wrapper
            ref={ref}
            className="group"
            onMouseDown={handleDragStart}
            onDoubleClick={handleDoubleClick}
        >
            <InnerWrapper
                $isActive={isActive}
                className="rounded-md bg-neutral-500 group-hover:opacity-90"
            />
        </Wrapper>
    );
}

ColumnResizer.propTypes = {
    name: PropTypes.string.isRequired,
    updateColumnWidth: PropTypes.func.isRequired
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -8px;
  width: 15px;
  cursor: col-resize;
  background-color: transparent;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  height: 90%;
  width: 5px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  ${({$isActive}) => $isActive ? `
    opacity: 1;
  ` : ""};
`;
