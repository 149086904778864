import React, {useContext, useRef} from "react";
import PropTypes from "prop-types";
import {useShareFolder} from "src/api/sharing/useShareFolder.js";
import {isValidEmail} from "src/utils/validators.js";
import {TextForm} from "src/primitives/controls/forms/TextForm.jsx";
import {useShowToast} from "src/components/toasts/useShowToast.js";
import {AuthUserContext} from "src/pages/contexts/user_context.jsx";


export function ShareFolder({folderId}) {
    const createdCounter = useRef(0);
    const [shareFolder, {loading}] = useShareFolder(folderId);
    const {showLoading, showSuccess, showError} = useShowToast();
    const authUser = useContext(AuthUserContext);

    const onSubmit = (email) => {
        if (email === authUser.primaryEmailAddress.emailAddress) {
            const message = "Cannot invite self, try another email!";
            showError({message});
            return;
        }

        showLoading();
        shareFolder({email}).then((result) => {
            const {ok, errorCode} = result;
            if (!ok) {
                throw new Error(errorCode);
            }
            showSuccess();
        }).catch((error) => {
            console.log(error);
            showError();
        });
    };

    return (
        <TextForm
            key={`share-folder-${createdCounter.current}`}
            loading={loading}
            buttonText="Invite"
            validator={isValidEmail}
            onSubmit={onSubmit}
            inputType="email"
        />
    );
}

ShareFolder.propTypes = {
    folderId: PropTypes.string
};
