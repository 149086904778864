import {DataTypes, VariableTypes} from "src/utils/tableData.js";
import {arrayToMap} from "src/utils/misc.js";


const dataTypeOrder = [
    DataTypes.INTEGER,
    DataTypes.FLOAT,
    DataTypes.STRING,
    DataTypes.BOOLEAN,
    DataTypes.DATE,
    DataTypes.DATETIME
];

const variableTypeOrder = [
    VariableTypes.CONTINUOUS,
    VariableTypes.CATEGORICAL,
    VariableTypes.BINARY,
    VariableTypes.TEXT,
    VariableTypes.IDENTIFIER,
    VariableTypes.CONSTANT
];


export function sortFeaturesByVariables(features, columns) {
    if (!Array.isArray(features) || !Array.isArray(columns)) {
        return [];
    }

    const columnMap = arrayToMap(columns, "name");

    const copy = features.slice();
    return copy.sort((a, b) => {
        const colA = columnMap[a.name];
        const colB = columnMap[b.name];

        // Compare DataTypes based on custom order
        const dataTypeIndexA = dataTypeOrder.indexOf(colA.dataType);
        const dataTypeIndexB = dataTypeOrder.indexOf(colB.dataType);

        if (dataTypeIndexA !== dataTypeIndexB) {
            return dataTypeIndexA - dataTypeIndexB;
        }

        // If DataTypes are the same, compare VariableTypes based on custom order
        const variableTypeIndexA = variableTypeOrder.indexOf(colA.variableType);
        const variableTypeIndexB = variableTypeOrder.indexOf(colB.variableType);

        if (variableTypeIndexA !== variableTypeIndexB) {
            return variableTypeIndexA - variableTypeIndexB;
        }

        // If VariableTypes are the same, compare names
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA.localeCompare(nameB);
    });
}
