import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";
import {useCreateChart} from "src/api/charts/useCreateChart.js";
import {Navigate} from "react-router-dom";
import {LINKS} from "src/links.js";

export function CreateChart({tableId}) {
    const [createdChartId, setCreatedChartId] = useState(null);
    const [createChart, {loading}] = useCreateChart(tableId);

    const handleClick = () => {
        createChart().then((result) => {
            const {ok,
                errorCode,
                chart} = result;
            if (!ok) {
                throw new Error(errorCode);
            }
            setCreatedChartId(chart.id);
        }).catch((error) => {
            console.error(error);
        });
    };

    if (createdChartId) {
        return (
            <Navigate to={LINKS.editChart(tableId, createdChartId)} />
        );
    }

    return (
        <Button
            onClick={handleClick}
            isLoading={loading}
            size="xs"
        >
            New chart
        </Button>
    );
}
CreateChart.propTypes = {
    tableId: PropTypes.string.isRequired
};
