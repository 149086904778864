import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {CHART_FIELDS} from "../fragments.js";

export const GET_CHARTS_QUERY = gql`
${CHART_FIELDS}
query GetCharts ($tableId: String!) {
  charts (tableId: $tableId) {
    ...ChartFields
  }
}
`;

export function useGetCharts(tableId) {
    const variables = {tableId};
    const {data, loading, error, refetch} = useQuery(GET_CHARTS_QUERY, {variables});

    const charts = useMemo(() => {
        return data?.charts || [];
    }, [data]);

    return {
        charts, loading, error, refetch
    };
}
