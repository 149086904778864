import React from "react";
import PropTypes from "prop-types";
import RGL, {WidthProvider} from "react-grid-layout";
import "react-grid-layout/css/styles.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import "react-resizable/css/styles.css";
import "./rgl.css";
import {generateMockItems, generateMockLayout} from "./mocks.jsx";


const ReactGridLayout = WidthProvider(RGL);

export class BasicLayout extends React.PureComponent {
    /**
     * Based on demo: https://react-grid-layout.github.io/react-grid-layout/examples/1-basic.html
     *
     * @param props
     */
    constructor(props) {
        super(props);

        const {items, layout} = this.props;
        this.state = {layout: layout || generateMockLayout(items.length)};
    }

    onLayoutChange(layout) {
        const {onLayoutChange} = this.props;

        onLayoutChange(layout);
    }

    render() {
        const {layout} = this.state;
        const {items, isEditable} = this.props;

        return (
            <ReactGridLayout
                containerPadding={[0, 0]}
                margin={[10, 10]}
                layout={layout}
                onLayoutChange={this.onLayoutChange}
                isDraggable={isEditable}
                isResizable={isEditable}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...this.props}
            >
                {items}
            </ReactGridLayout>
        );
    }
}

BasicLayout.propTypes = {
    className: PropTypes.string,
    rowHeight: PropTypes.number,
    cols: PropTypes.number,
    layout: PropTypes.arrayOf(PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        w: PropTypes.number,
        h: PropTypes.number,
        i: PropTypes.string // Should match "key" for 'items'
    })),
    items: PropTypes.arrayOf(PropTypes.node),
    onLayoutChange: PropTypes.func,
    isEditable: PropTypes.bool
};


BasicLayout.defaultProps = {
    className: "layout",
    items: generateMockItems(),
    rowHeight: 30,
    onLayoutChange() {},
    cols: 6,
    isEditable: true
};

