import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
mutation EvaluateModel($tableId: String!) {
    evaluateModel(tableId: $tableId) {
        ok
        errorCode
        result
    }
}
`;


export function useEvaluateModel(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        return mutation({
            variables: {tableId}
        }).then((response) => {
            return response.data.evaluateModel;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error, result: data?.evaluateModel?.result}];
}
