import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {PageContent} from "src/primitives/layout";
import {ContentList} from "src/pages/Home/components/ContentList/index.jsx";
import {EditWorkspaceName} from "src/pages/Home/pages/WorkspacePage/components/EditWorkspaceName/index.jsx";
import {AddItemMenu} from "src/pages/Home/components/AddItemMenu/index.jsx";
import {IconButton} from "src/primitives/button.jsx";
import {FiPlus} from "react-icons/fi";
import {TablesPageEmptyState} from "./components/EmptyState/index.jsx";


export function WorkspaceHome({workspaceId, workspace}) {
    return (
        <PageContent
            size="lg"
            title={(
                <EditWorkspaceName
                    workspaceId={workspaceId}
                    name={workspace?.name}
                />
            )}
            subTitle={(
                <AddItemMenu workspaceId={workspaceId} goToCreatedFolder={false}>
                    <IconButton theme="secondary" icon={<FiPlus/>} text="Add"/>
                </AddItemMenu>
            )}
        >
            <Wrapper>
                <ContentList
                    folderId={null}
                    workspaceId={workspaceId}
                    empty={(
                        <TablesPageEmptyState/>
                    )}
                />
            </Wrapper>

        </PageContent>
    );
}

WorkspaceHome.propTypes = {
    workspaceId: PropTypes.string,
    workspace: PropTypes.shape({
        name: PropTypes.string
    })
};

const Wrapper = styled.div`
`;
