import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";


export const GET_WORKSPACE_MEMBERS_QUERY = gql`
  query GetWorkspaceMembers ($id: String!) {
    workspace (workspaceId: $id) {
      id
      owner {
        id
        firstName
        lastName
        email
      }
      members {
        id
        createdAt
        acceptedAt
        declinedAt
        userId
        email
        status
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;


export function useGetWorkspaceMembers(workspaceId) {
    const variables = {id: workspaceId};
    const {
        data, loading, error, refetch
    } = useQuery(GET_WORKSPACE_MEMBERS_QUERY, {variables});

    const members = useMemo(() => {
        return data?.workspace?.members || [];
    }, [data]);
    const owner = useMemo(() => {
        return data?.workspace?.owner;
    }, [data]);

    return {
        members, owner, loading, error, refetch
    };
}
