import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {SHARED_RESOURCE_FIELDS} from "src/api/fragments.js";


const MUTATION = gql`
${SHARED_RESOURCE_FIELDS}
mutation ShareTable ($data: ShareResourceInput!) {
  shareTable (data: $data) {
    ok
    errorCode
    result {
      ...SharedResourceFields
    }
  }
}
`;


function updateCache(cache, response, tableId) {
    const table = {
        id: tableId,
        __typename: "Table"
    };

    const {ok, result} = response.data.shareTable;
    if (!ok) {
        return;
    }

    const newRef = cache.writeFragment({
        data: result,
        fragment: SHARED_RESOURCE_FIELDS
    });

    cache.modify({
        id: cache.identify(table),
        fields: {
            sharedResources(existingCommentRefs) {
                return [newRef, ...existingCommentRefs];
            }
        }
    });
}

export function useShareTable(tableId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(({email}) => {
        const variables = {data: {resourceId: tableId, email}};

        return mutation({
            variables,
            update(cache, response) {
                updateCache(cache, response, tableId);
            }
        }).then((response) => response.data?.shareTable);
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
