import React from "react";
import PropTypes from "prop-types";
import {Avatar} from "src/primitives/Avatar.jsx";
import {Badge} from "src/primitives/badge.jsx";
import {formatDate} from "src/utils/formatting.js";
import {EditAccess} from "./EditAccess.jsx";


export function UserRow({
    id,
    user,
    email,
    sharedAt,
    status,
    authUser
}) {
    const userEmail = user ? user.email : email;
    const isAuthUser = user?.id === authUser.id;

    return (
        <div className="flex items-center gap-2 py-2 px-1 hover:bg-neutral-100 transition rounded-lg">
            <div className="basis-7">
                <Avatar
                    user={user}
                    size="small"
                    theme="orange"
                />
            </div>

            <div className="flex flex-grow flex-col">
                <span className="flex gap-2 text-sm">
                    <span>
                        {userEmail}
                    </span>
                    <span>
                        {isAuthUser && (
                            <span className="italic">(you)</span>
                        )}
                    </span>
                </span>
                <span className="text-xs flex items-center gap-2">
                    <Badge size="xs" theme="orange">
                        {status}
                    </Badge>
                    <span>
                        Invited {formatDate(sharedAt)}
                    </span>
                </span>
            </div>

            <div className="flex-0 w-24 flex justify-end">
                <EditAccess objectId={id} disabled={isAuthUser} email={userEmail}/>
            </div>
        </div>
    );
}

UserRow.propTypes = {
    id: PropTypes.string,
    user: PropTypes.object,
    email: PropTypes.string,
    sharedAt: PropTypes.string,
    status: PropTypes.string,
    authUser: PropTypes.object
};
