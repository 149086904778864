import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const GET_WORKSPACES_QUERY = gql`
query GetWorkspaces {
  workspaces {
    id
    createdAt
    name
    owner {
      id
      email
    }
  }
}
`;

export function useGetWorkspaces() {
    const {
        data, loading, error, refetch
    } = useQuery(GET_WORKSPACES_QUERY);

    const workspaces = useMemo(() => (data ? data.workspaces : []), [data]);

    return {
        workspaces, loading, error, refetch
    };
}
