import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {sortByString} from "src/utils/sorting.js";
import {SelectItem} from "./SelectItem.jsx";


export function CategoryFilter({columnValues, value, updateValue}) {
    const isItemSelected = (item) => {
        return value.findIndex((v) => v === item) > -1;
    };

    const handleSelect = (item) => {
        let newValues;
        if (isItemSelected(item)) {
            newValues = value.filter((v) => v !== item);
        } else {
            newValues = [item, ...value];
        }
        updateValue(newValues);
    };

    const items = columnValues.map(({name}) => name);
    const sortedItems = sortByString(items);

    return (
        <Wrapper>
            <div className="my-1">
                {sortedItems.map((item) => (
                    <SelectItem
                        key={item}
                        item={item}
                        handleSelect={handleSelect}
                        isSelected={isItemSelected(item)}
                    />
                ))}
            </div>
        </Wrapper>
    );
}

CategoryFilter.propTypes = {
    columnValues: PropTypes.array,
    value: PropTypes.arrayOf(PropTypes.string),
    updateValue: PropTypes.func
};

const Wrapper = styled.div`
  min-height: 80px;
  max-height: 360px;
  overflow-y: scroll;
`;
