import {useCallback, useMemo} from "react";
import {gql, useLazyQuery} from "@apollo/client";

const QUERY = gql`
query MakePivotQuery($tableId: String!, $columns: [String!]!, $aggregations: [Aggregation!]!) {
    pivotQuery (tableId: $tableId, columns: $columns, aggregations: $aggregations) {
        id
        time
        rows
        columns
        totalRows
    }
}
`;

export function useMakePivotQuery(tableId) {
    const [query, {
        data, loading, error, refetch
    }] = useLazyQuery(QUERY);

    const result = useMemo(() => {
        const tp = data?.pivotQuery;
        window.tp = tp;
        return tp;
    }, [data]);

    const errors = useMemo(() => {
        if (!error) {
            return null;
        }
        // Generally we are only interested in the GraphqlErrors
        return error.graphQLErrors.map(({message}) => message);
    }, [error]);

    const makeQuery = useCallback(({columns, aggregations}) => {
        return query({variables: {tableId, columns, aggregations}});
    }, [tableId, query]);

    return [makeQuery, {
        result, loading, errors, refetch
    }];
}
