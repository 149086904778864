import React from "react";
import PropTypes from "prop-types";
import {DB_BACKENDS} from "src/pages/CreateWorkspace/components/CreateWorkspaceForm/constants.js";

export function BackendDescription({backend}) {
    if (backend === DB_BACKENDS.gcp) {
        return (
            <div>
                Hosted on Google Cloud.
            </div>
        );
    }

    if (backend === DB_BACKENDS.rho) {
        return (
            <div>
                Hosted backend service.
            </div>
        );
    }
    return null;
}

BackendDescription.propTypes = {
    backend: PropTypes.oneOf(Object.values(DB_BACKENDS)).isRequired
};

