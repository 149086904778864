/* eslint-disable max-len */
import React from "react";
import {MarkdownContent} from "src/primitives/Markdown";
import {PageWrapper} from "src/pages/Web/components/layouts.jsx";


const subProcessorContent = `
# Sub-processors
These are third party entities that process data on our behalf.

### Google 
We host our backend services on Google Cloud Platform ("GCP").

### Cloudflare
We use Cloudflare Pages to host our frontend services. 

### Clerk
Clerk is a startup that provides identity and user management services.

### Neon
We use the serverless postgres features offered by Neon.

### Resend
Transactional emails in the application are sent using Resend.

### Tinybird
Tinybird is used to collect some user events for logged in users. 

### Openpanel
We use Openpanel to collect general web statistics.

`;


export function SubProcessorsPage() {
    return (
        <PageWrapper>
            <MarkdownContent
                content={subProcessorContent}
            />
        </PageWrapper>
    );
}
