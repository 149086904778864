import React from "react";
import PropTypes from "prop-types";
import {isColumnContinuous} from "src/utils/tableData.js";
import {useColumnDetailsState} from "src/pages/Home/pages/TablePage/components/ColumnDetailsSidebar/states.jsx";
import {LargeCard} from "src/pages/Home/pages/TablePage/components/LargeCard.jsx";
import {ColumnsGeneralTable} from "./components/ColumnsGeneralTable/index.jsx";
import {ColumnsOverviewTable} from "./components/ColumnsOverviewTable/index.jsx";


export function ColumnsOverview({columns, tableStats, distributions, fittedDistributions}) {
    const selectColumn = useColumnDetailsState((state) => state.select);

    const continuousColumns = columns.filter((column) => isColumnContinuous(column));
    const generalColumns = columns.filter((column) => !isColumnContinuous(column));

    return (
        <>
            <LargeCard title="Continuous columns">
                <ColumnsOverviewTable
                    columns={continuousColumns}
                    selectColumn={selectColumn}
                    tableStats={tableStats}
                    distributions={distributions}
                    fittedDistributions={fittedDistributions}
                />
            </LargeCard>

            <LargeCard title="Other columns">
                <ColumnsGeneralTable
                    columns={generalColumns}
                    selectColumn={selectColumn}
                    tableStats={tableStats}
                />
            </LargeCard>
        </>
    );
}

ColumnsOverview.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        dataType: PropTypes.string,
        variableType: PropTypes.string
    })),
    tableStats: PropTypes.object,
    distributions: PropTypes.object,
    fittedDistributions: PropTypes.object
};
