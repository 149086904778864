import React from "react";
import PropTypes from "prop-types";
import {useGetTableStats} from "src/api/tableData/useGetTableStats.js";
import {useGetColumnDistributions} from "src/api/tableData/useGetColumnDistributions.js";
import {useGetCorrelationMatrix} from "src/api/tableData/useGetCorrelationMatrix.js";
import {useGetTablePreview} from "src/api/tableData/useGetTablePreview.js";
import {useGetFittedDistributions} from "src/api/tableData/useGetFittedDistributions.js";
import {getNumericalColumnNames} from "src/utils/tableData.js";
import {useKeyPress} from "src/utils/useKeyPress.js";
import {useColumnDetailsState} from "src/pages/Home/pages/TablePage/components/ColumnDetailsSidebar/states.jsx";
import {SidebarDrawer} from "src/primitives/drawer/index.jsx";
import {TriggerEvent} from "src/api/events/TriggerEvent.jsx";
import {EVENTS} from "src/api/events/events.js";
import {useGetTextAnalysis} from "src/api/tableData/useGetTextAnalysis.js";
import {ColumnDetailsHeader} from "./header.jsx";
import {ColumnDetails} from "./components/ColumnDetails/index.jsx";


export function ColumnDetailsSidebar({table}) {
    const {data: tableStats} = useGetTableStats(table.id);
    const {data: tablePreview} = useGetTablePreview(table.id);
    const {data: correlationMatrix} = useGetCorrelationMatrix(table.id);
    const {data: distributions} = useGetColumnDistributions(table.id, getNumericalColumnNames(table?.columns));
    const {data: fittedDistributions} = useGetFittedDistributions(table.id);
    const {data: textAnalysis} = useGetTextAnalysis(table.id);
    const [selectedColumnName, clearSelectedColumn] = useColumnDetailsState((state) => [state.columnName, state.clear]);

    const disableKeyPress = () => {
        //  if not columns is selected, disable key press
        return !selectedColumnName;
    };

    useKeyPress(clearSelectedColumn, ["Escape"], {}, disableKeyPress);

    if (!Array.isArray(table.columns) || !tablePreview || !tableStats || !distributions) {
        return null;
    }

    const column = table.columns.find((c) => c.name === selectedColumnName);

    return (
        <SidebarDrawer
            isOpen={!!selectedColumnName}
            close={clearSelectedColumn}
            size={680}
            title={<ColumnDetailsHeader column={column}/>}
        >
            {column && (
                <>
                    {table.id && column.name && (
                        <TriggerEvent
                            name={EVENTS.COLUMN_DETAILS_VIEWED}
                            tableId={table.id}
                            data={{columnName: column.name}}
                        />
                    )}

                    <ColumnDetails
                        column={column}
                        correlations={correlationMatrix[column.name]}
                        values={tablePreview[column.name]}
                        stats={tableStats[column.name]}
                        distribution={distributions[column.name]}
                        fittedDistributions={fittedDistributions && fittedDistributions[column.name]}
                        textAnalysis={textAnalysis && textAnalysis[column.name]}
                    />
                </>
            )}
        </SidebarDrawer>
    );
}


ColumnDetailsSidebar.propTypes = {
    table: PropTypes.shape({
        id: PropTypes.string,
        columns: PropTypes.arrayOf(PropTypes.object)
    })
};

export function ColumnDetailsConditional({table}) {
    const {loading: loadingStats} = useGetTableStats(table.id);
    const {loading: loadingPreview} = useGetTablePreview(table.id);

    if (loadingStats || loadingPreview) {
        return null;
    }

    return (
        <ColumnDetailsSidebar
            table={table}
        />
    );
}

ColumnDetailsConditional.propTypes = {
    table: PropTypes.shape({
        id: PropTypes.string,
        columns: PropTypes.arrayOf(PropTypes.object)
    })
};
