import {useCallback, useMemo} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import {formatQueryResult} from "src/api/utils.js";
import {QUERY_RESULT_FIELDS} from "src/api/fragments.js";

const QUERY = gql`
${QUERY_RESULT_FIELDS}
query GetTimeSeries($tableId: String!, $options: TimeSeriesOptions!) {
  getTimeSeries (tableId: $tableId, options: $options) {
    ... QueryResultFields
  }
}
`;


export function useGetTimeSeries(tableId) {
    const [query, {
        data, loading, error, refetch
    }] = useLazyQuery(QUERY);

    const result = useMemo(() => {
        if (!data || !data.getTimeSeries) {
            return null;
        }
        return formatQueryResult(data.getTimeSeries);
    }, [data]);

    const errors = useMemo(() => {
        if (!error) {
            return null;
        }
        // Generally we are only interested in the GraphqlErrors
        return error.graphQLErrors.map(({message}) => message);
    }, [error]);

    const makeQuery = useCallback((options) => {
        return query({variables: {tableId, options}}).then((response) => {
            return formatQueryResult(response.data.getTimeSeries);
        });
    }, [query, tableId]);

    return [makeQuery, {
        result, loading, errors, refetch
    }];
}
