import {useCallback, useMemo} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
  mutation DeleteFolder($folderId: String!) {
    deleteFolder (folderId: $folderId) {
      ok
      errorCode
      folderId
    }
  }
`;


function removeFolderFromCache(cache, response) {
    const {ok, folderId} = response.data.deleteFolder;
    if (!ok) {
        return;
    }
    const model = {
        id: folderId,
        __typename: "Folder"
    };
    const normalizedId = cache.identify(model);
    cache.evict({id: normalizedId});
    cache.gc();
}

export function useDeleteFolder() {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((folderId) => {
        return mutation({
            variables: {folderId},
            update(cache, response) {
                removeFolderFromCache(cache, response);
            }
        });
    }, [mutation]);

    const result = useMemo(() => {
        return data?.deleteFolder;
    }, [data]);

    return [doMutate, {loading, error, result}];
}
