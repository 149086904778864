import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {COLUMN_FIELDS, CORE_TABLE_FIELDS, SHARED_RESOURCE_FIELDS} from "src/api/fragments.js";

export const GET_TABLE_QUERY = gql`
${CORE_TABLE_FIELDS}
${COLUMN_FIELDS}
${SHARED_RESOURCE_FIELDS}
query GetTable($id: String!) {
  table (tableId: $id) {
    ... CoreTableFields
    columns {
      ...ColumnFields
    }
    sharedResources {
    ... SharedResourceFields
    }
  }
}
`;

function formatTable(data) {
    if (!data || !data.table) {
        return null;
    }

    const columnMap = data.table.columns.reduce((acc, item) => {
        return Object.assign({}, acc, {[item.name]: item});
    }, {});
    const tableData = {...data.table, columnMap};

    window.t = tableData;

    return tableData;
}


export function useGetTable(tableId) {
    const options = useMemo(() => {
        return {variables: {id: tableId}};
    }, [tableId]);

    const {
        data, loading, error, refetch, startPolling, stopPolling
    } = useQuery(GET_TABLE_QUERY, options);

    const table = useMemo(() => {
        return formatTable(data);
    }, [data]);

    return {
        table, loading, error, refetch, startPolling, stopPolling
    };
}
