import React from "react";
import PropTypes from "prop-types";


function getColor(value, avg, min, max) {
    if (value === null || avg === null || min === null || max === null) {
        return null;
    }
    const values = [
        min,
        (avg - min) / 2,
        avg,
        (max - avg) / 2,
        max * 2
    ];
    /*
    const colors = [
        "#cef1de",
        "#6dd498",
        "#43a96f",
        "#38a266",
        "#308c57"
    ];
     */

    const colors = [
        "#ff8239",
        "#fe9c4a",
        "#feb460",
        "#feca7a",
        "#ffdf97"
    ];
    colors.reverse();
    const gradient = values.map((val, index) => ({
        value: val,
        color: colors[index]
    }));

    // Find the two gradient colors that the value falls between
    let lowerOption = gradient[0];
    let upperOption = gradient[1];

    for (let i = 0; i < gradient.length - 1; i++) {
        if (value <= gradient[i + 1].value) {
            lowerOption = gradient[i];
            upperOption = gradient[i + 1];
            break;
        }
    }

    // Interpolate between the two colors based on the value's position in the gradient
    const valuePosition = (value - lowerOption.value) / (upperOption.value - lowerOption.value);
    const lowerColor = lowerOption.color;
    const upperColor = upperOption.color;

    const color = {
        // eslint-disable-next-line max-len
        r: Math.floor(parseInt(lowerColor.slice(1, 3), 16) + (parseInt(upperColor.slice(1, 3), 16) - parseInt(lowerColor.slice(1, 3), 16)) * valuePosition),
        // eslint-disable-next-line max-len
        g: Math.floor(parseInt(lowerColor.slice(3, 5), 16) + (parseInt(upperColor.slice(3, 5), 16) - parseInt(lowerColor.slice(3, 5), 16)) * valuePosition),
        // eslint-disable-next-line max-len
        b: Math.floor(parseInt(lowerColor.slice(5, 7), 16) + (parseInt(upperColor.slice(5, 7), 16) - parseInt(lowerColor.slice(5, 7), 16)) * valuePosition)
    };

    // return '#' + ('00' + color.r.toString(16)).slice(-2) + ('00' + color.g.toString(16)).slice(-2) + ('00' + color.b.toString(16)).slice(-2);
    return `rgba(${color.r}, ${color.g}, ${color.b}, 0.75)`;
}

export function HeatmapCell(props) {
    if (!props) {
        return null;
    }
    const {value, stats} = props;
    let color = null;
    if (value && stats) {
        const {avg, min, max} = stats;
        color = getColor(value, avg, min, max);
    }
    // eslint-disable-next-line no-param-reassign,react/destructuring-assignment
    props.TD.style.backgroundColor = color;

    return <span>{value}</span>;
}

HeatmapCell.propTypes = {
    value: PropTypes.any,
    stats: PropTypes.shape({
        avg: PropTypes.number,
        sd: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number
    }),
    TD: PropTypes.element
};

export const MemoizedHeatmapCell = React.memo(HeatmapCell);
