import React from "react";
import PropTypes from "prop-types";
import {Circle} from "src/primitives/circle.jsx";
import {cva} from "cva";

const itemClasses = cva({
    // eslint-disable-next-line max-len
    base: "px-2 py-1 flex items-center gap-2 cursor-pointer rounded-md transition-colors border border-transparent hover:bg-amber-200/40 hover:border-amber-200/80",
    variants: {
        state: {
            active: "!border-amber-500 bg-amber-200/10"
        }
    }
});


export function ListOptions({options, onSelect, isSelected}) {
    return (
        <div className="flex flex-col gap-1">
            {options.map(({key, label}) => (
                <button
                    type="button"
                    key={key}
                    className={itemClasses({state: isSelected(key) ? "active" : null})}
                    onClick={onSelect.bind(null, key)}
                >
                    <Circle mode={isSelected(key) ? "" : "outline"}/>
                    <div className="ml-1">
                        {label}
                    </div>
                </button>
            ))}
        </div>
    );
}

ListOptions.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.node
    })),
    onSelect: PropTypes.func,
    isSelected: PropTypes.func
};

ListOptions.defaultProps = {
    options: [],
    onSelect: () => null,
    isSelected: () => null
};
