import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";

const wrapperClasses = cva({
    base: "flex border rounded-md overflow-hidden transition cursor-pointer",
    variants: {
        state: {
            normal: "border-black/10 hover:border-black/20",
            active: "border-black/30 outline outline-1 outline-black/20"
        }
    }
});

export function ColorSchemePreview({colors, isSelected}) {
    return (
        <div className={wrapperClasses({state: isSelected ? "active" : "normal"})}>
            {colors.map((color) => (
                <div
                    key={color}
                    className="h-8 w-[25%]"
                    style={{backgroundColor: color}}
                />
            ))}
        </div>
    );
}

ColorSchemePreview.propTypes = {
    colors: PropTypes.arrayOf(PropTypes.string),
    isSelected: PropTypes.bool
};
