import {safeArrayToMap} from "src/utils/misc.js";
import {CHART_COLORS} from "src/charts/colors.js";

export function formatBooleanValues(values) {
    const valuesMap = safeArrayToMap(values, "name");

    return [{
        name: "True",
        // color: "#65a30d",
        // color: "#65a30d",
        color: CHART_COLORS.green,
        value: valuesMap["true"]?.value || 0,  // eslint-disable-line
        p: valuesMap["true"]?.p || 0,  // eslint-disable-line
    }, {
        name: "False",
        // color: "#ef4444",
        color: CHART_COLORS.red,
        value: valuesMap["false"]?.value || 0,  // eslint-disable-line
        p: valuesMap["false"]?.p || 0,  // eslint-disable-line
    }, {
        name: "Missing/Null",
        // color: "#737373",
        color: CHART_COLORS.gray,
        value: valuesMap[null]?.value || 0,  // eslint-disable-line
        p: valuesMap[null]?.p || 0,  // eslint-disable-line
    }];
}
