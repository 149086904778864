import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";


const classes = cva({
    base: "bg-clip-text bg-gradient-to-tl text-transparent",
    variants: {
        theme: {
            green: "from-lime-300 to-lime-600",
            white: "from-neutral-100 to-neutral-400"
        }
    },
    defaultVariants: {
        theme: "green"
    }
});

const lineClasses = cva({
    base: "h-[1px] mt-[5px] w-full mx-4 opacity-25",
    variants: {
        theme: {
            green: "from-lime-300 to-lime-800",
            white: "from-neutral-100 to-neutral-700"
        },
        direction: {
            left: "bg-gradient-to-l",
            right: "bg-gradient-to-r"
        }
    },
    defaultVariants: {
        theme: "green",
        direction: "right"
    }
});

export const TextHighlight = ({children, theme}) => (
    <div className="flex items-center">
        <div className="flex-grow flex items-center">
            <div className={lineClasses({theme, direction: "left"})}/>
        </div>

        <div className="flex-shrink">
            <span className={classes({theme})}>
                {children}
            </span>
        </div>

        <div className="flex-grow flex items-center">
            <div className={lineClasses({theme})}/>
        </div>
    </div>
);

TextHighlight.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.string
};
