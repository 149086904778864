import React, {createContext, useContext} from "react";
import PropTypes from "prop-types";
import {useUser} from "@clerk/clerk-react";


export const AuthUserContext = createContext(null);


export function AuthUserContextProvider({children}) {
    const {user} = useUser();

    return (
        <AuthUserContext.Provider value={user}>
            {children}
        </AuthUserContext.Provider>
    );
}

AuthUserContextProvider.propTypes = {
    children: PropTypes.element
};

export function useAuthenticatedUser() {
    return useContext(AuthUserContext);
}
