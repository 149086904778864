import React from "react";
import PropTypes from "prop-types";
import {useUpdateColumnDisplaySettings} from "src/api/tableColumns/useUpdateColumnDisplaySettings.js";
import {Title} from "src/primitives/title.jsx";
import {NumberInput} from "src/primitives/controls/Number/index.jsx";
import {coalesceNumber} from "src/utils/misc.js";


export const DEFAULT_DECIMALS = 2;


export function DisplayDecimals({column}) {
    const {tableId, name, displaySettings} = column;
    const [updateDisplaySettings] = useUpdateColumnDisplaySettings(tableId, name);
    const decimals = coalesceNumber(displaySettings?.decimals, DEFAULT_DECIMALS);

    const saveDecimals = (newDecimals) => {
        const settings = {decimals: newDecimals};

        updateDisplaySettings(settings, displaySettings).then((result) => {
            if (!result.ok) {
                throw new Error(result.errorCode);
            }
        }).catch((error) => {
            console.warn(error);
        });
    };

    return (
        <div>
            <div className="mb-2">
                <Title
                    size="xs"
                >
                    Decimals
                </Title>
            </div>
            <NumberInput
                value={decimals}
                onChange={saveDecimals}
            />
        </div>
    );
}

DisplayDecimals.propTypes = {
    column: PropTypes.shape({
        tableId: PropTypes.string,
        name: PropTypes.string,
        displaySettings: PropTypes.object
    })
};
