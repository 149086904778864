import React from "react";
import {useGetUsers} from "src/api/admin/useGetUsers.js";
import {UsersTable} from "src/pages/Admin/pages/Users/components/UsersTable/index.jsx";
import {PageContent, PageLayout} from "src/primitives/layout/index.jsx";


export function UsersPage() {
    const {users, loading} = useGetUsers();

    return (
        <PageLayout title="Users" loading={loading}>
            <PageContent title="Users">
                <UsersTable users={users} />
            </PageContent>
        </PageLayout>
    );
}
