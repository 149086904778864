import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
    mutation CreateDemoDataForWorkspace($workspaceId: String!) {
        createDemoData(workspaceId: $workspaceId) {
            ok
            errorCode
        }
    }
`;

export function useCreateDemoDataForWorkspace() {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((workspaceId) => {
        return mutation({variables: {workspaceId}}).then((response) => {
            return response.data.createDemoData;
        });
    }, [mutation]);

    return [doMutate, {loading, error}];
}
