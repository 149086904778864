import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";


export const TextImage = ({src, alt, className, ...props}) => {
    // eslint-disable-next-line no-param-reassign
    className += " my-2 outline outline-black/5 shadow-md rounded-md";

    return (
        <ImageWrapper
            src={src}
            alt={alt}
            className={className}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
        />
    );
};

TextImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string
};
TextImage.defaultProps = {
    className: ""
};

const ImageWrapper = styled.img`
`;
