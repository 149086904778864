import {useMemo} from "react";
import {gql, useFragment, useQuery} from "@apollo/client";
import {CORE_FOLDER_FIELDS, SHARED_RESOURCE_FIELDS} from "../fragments.js";

export const GET_FOLDERS_QUERY = gql`
${CORE_FOLDER_FIELDS}
${SHARED_RESOURCE_FIELDS}
query GetFolders ($id: String!) {
  workspace (workspaceId: $id) {
    id
    folders {
      ... CoreFolderFields
      shareInfo {
        ...SharedResourceFields
      }
    }
  }
}
`;

export function useGetFolders(workspaceId) {
    const variables = {id: workspaceId};
    const {data, loading, error, refetch} = useQuery(GET_FOLDERS_QUERY, {variables});

    const folders = useMemo(() => {
        return data?.workspace?.folders || [];
    }, [data]);

    return {
        folders, loading, error, refetch
    };
}

export function useReadFolder(folderId) {
    const object = {
        id: folderId,
        __typename: "Folder"
    };
    const result = useFragment({from: object, fragment: CORE_FOLDER_FIELDS});

    return {
        folder: result.data,
        exists: result.complete
    };
}
