import React from "react";
import PropTypes from "prop-types";
import {Circle} from "src/primitives/circle.jsx";


export function SelectItem({item, isSelected, handleSelect}) {
    return (
        <button
            key={item}
            type="button"
            onClick={handleSelect.bind(null, item)}
            className="flex items-center gap-2 hover:bg-neutral-100 w-full rounded-md py-1/2 px-1"
        >
            <div>
                <Circle mode={isSelected ? "" : "outline"}/>
            </div>
            <div className="py-1" style={{fontWeight: isSelected ? "bold" : "normal"}}>
                {item}
            </div>
        </button>
    );
}

SelectItem.propTypes = {
    item: PropTypes.string,
    handleSelect: PropTypes.func,
    isSelected: PropTypes.bool
};
