import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";


const buttonClasses = cva({
    // eslint-disable-next-line max-len
    base: "flex-1 flex items-center justify-center font-semibold border border-blue-600 transition-all outline-0",
    variants: {
        mode: {
            normal: "bg-blue-50/50 text-neutral-600 hover:bg-blue-200/50 hover:text-neutral-700",
            isActive: "bg-blue-600 text-white"
        },
        size: {
            xs: "text-xs py-1 px-4",
            small: "text-sm py-1 px-6 min-w-18",
            medium: "text-base py-2 px-8 min-w-36"
        },
        place: {
            left: "rounded-l-md border-r-0",
            right: "rounded-r-md border-l-0"
        }
    },
    defaultVariants: {
        mode: "normal",
        size: "small"
    }
});

function getPlacement(index, options) {
    if (index === 0) {
        return "left";
    }
    if (index === options.length - 1) {
        return "right";
    }
    return null;
}

export function Options({options, selectedValue, onChange}) {
    const handleClick = (option) => {
        if (option?.value !== selectedValue) {
            onChange(option);
        }
    };

    return (
        <div className="w-full flex">
            {options.map((item, index) => {
                const props = {
                    mode: item.value === selectedValue ? "isActive" : "normal",
                    size: "small",
                    place: getPlacement(index, options)
                };

                return (
                    <button
                        key={item.value}
                        type="button"
                        className={buttonClasses(props)}
                        onClick={handleClick.bind(null, item)}
                    >
                        {item.name}
                    </button>
                );
            })}
        </div>
    );
}

Options.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.node
    })),
    selectedValue: PropTypes.string,
    onChange: PropTypes.func
};
