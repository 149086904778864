import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";

export const GET_TABLE_STATS_QUERY = gql`
query GetTableStats($id: String!) {
  table (tableId: $id) {
    id
    stats
  }
}
`;

export function useGetTableStats(tableId) {
    const options = {variables: {id: tableId}};
    const {data, loading, error, refetch} = useQuery(GET_TABLE_STATS_QUERY, options);

    const tableStats = useMemo(() => {
        if (!data?.table?.stats) {
            return null;
        }
        const {stats} = data.table;
        window.ts = stats;
        return stats;
    }, [data]);

    return {
        data: tableStats, loading, error, refetch
    };
}
