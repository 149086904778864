import React from "react";
import {useUser} from "@clerk/clerk-react";
import {CenteredContent, FlexLayout, SplitPage} from "src/primitives/layout/index.jsx";
import {RhoHeader} from "src/pages/components/RhoHeader/index.jsx";
import {InitAccount} from "src/pages/StartPage/components/InitAccount/index.jsx";
import {Navigate} from "react-router-dom";
import {LINKS} from "src/links.js";


export function StartPage() {
    const {user, isLoaded} = useUser();

    if (!isLoaded) {
        return null;
    }

    if (isLoaded && !user) {
        return <Navigate to={LINKS.WEB}/>;
    }

    return (
        <SplitPage>
            <FlexLayout
                header={<RhoHeader/>}
            >
                <CenteredContent>
                    <InitAccount user={user}/>
                </CenteredContent>
            </FlexLayout>
        </SplitPage>
    );
}

StartPage.propTypes = {
};
