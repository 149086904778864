import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";


export const TitleText = ({children}) => (
    <h2 className="block font-semibold text-neutral-50 text-xl md:text-2xl lg:text-3xl">
        {children}
    </h2>
);

TitleText.propTypes = {
    children: PropTypes.node
};

const classes = cva({
    base: "mx-auto max-w-lg md:max-w-xl lg:max-w-2xl",
    variants: {
        align: {
            left: "text-left",
            center: "text-center",
            right: "text-right"
        }
    },
    defaultVariants: {
        align: "center"
    }
});

export function Title({children, align}) {
    return (
        <div className={classes({align})}>
            <TitleText>
                {children}
            </TitleText>
        </div>
    );
}

Title.propTypes = {
    children: PropTypes.node,
    align: PropTypes.oneOf(["left", "center", "right"])
};
