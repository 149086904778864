import React from "react";
import PropTypes from "prop-types";
import {cva} from "cva";
import {FiCheck} from "react-icons/fi";
import {getState} from "src/primitives/controls/SelectInput/utils.js";


const selectOptionText = cva({
    base: "block truncate font-normal",
    variants: {
        state: {
            selected: "font-medium",
            active: "",
            disabled: "text-neutral-500"
        }
    }
});

export function SelectOption({
    option,
    active,
    selected,
    disabled,
    render
}) {
    const state = getState({active, selected, disabled});

    return (
        <div className="relative pl-8 pr-4 py-2">
            {selected ? (
                // eslint-disable-next-line max-len
                <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-amber-600">
                    <FiCheck className="h-4 w-4" aria-hidden="true" />
                </span>
            ) : null}

            <span
                className={selectOptionText({state})}
            >
                {render ? render(option) : option.name}
            </span>
        </div>
    );
}

SelectOption.propTypes = {
    option: PropTypes.shape({
        name: PropTypes.node,
        value: PropTypes.string
    }),
    active: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    render: PropTypes.func
};
