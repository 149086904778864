import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {useGetTableData} from "src/api/data/useGetTableData.js";
import {formatDataFromQueryResult} from "src/utils/tableData.js";
import {isNullOrUndefined, safeArrayToMap} from "src/utils/misc.js";
import {formatFilterOption, formatSortOptions} from "src/components/TableGrid/utils.js";


export const TableDataContext = createContext(null);

export const FetchTableDataContext = createContext(null);

export const LoadTableDataOptionsContext = createContext(null);


function getDataOptions(newOptions = {}, existingOptions = {}) {
    const {sort, filters, page, q} = newOptions;
    // console.log(filters, existingOptions.filters);

    return {
        sort: isNullOrUndefined(sort) ? existingOptions.sort : sort,
        filters: isNullOrUndefined(filters) ? existingOptions.filters : filters,
        page: page || (existingOptions.page || 1),
        q: isNullOrUndefined(q) ? existingOptions.q : q
    };
}

export function TableDataContextProvider({children, tableId}) {
    const [loadData, {loading, result, refetch}] = useGetTableData(tableId);
    const [latestOptions, setOptions] = useState(getDataOptions());

    const fetch = useCallback((options) => {
        setOptions(((existingOptions) => {
            return getDataOptions(options, existingOptions);
        }));

        return loadData(options);
    }, [loadData, setOptions]);

    const data = useMemo(() => {
        if (!result) {
            return [];
        }

        const {columns, rows} = result;

        return formatDataFromQueryResult(columns, rows);
    }, [result]);

    return (
        <TableDataContext.Provider value={{loading, data, result, refetch}}>
            <LoadTableDataOptionsContext.Provider value={latestOptions}>
                <FetchTableDataContext.Provider value={[fetch]}>
                    {children}
                </FetchTableDataContext.Provider>
            </LoadTableDataOptionsContext.Provider>
        </TableDataContext.Provider>
    );
}

TableDataContextProvider.propTypes = {
    children: PropTypes.node,
    tableId: PropTypes.string.isRequired
};

export function useTableDataResult() {
    return useContext(TableDataContext);
}

export function useIsTableDataLoading() {
    const {loading} = useTableDataResult();

    return loading;
}

export function useRefetchTableData() {
    const tableDataResult = useTableDataResult();

    // Might be used outside of context scope
    return tableDataResult?.refetch;
}


export function useLoadTableData() {
    const result = useTableDataResult();
    const [loadData] = useContext(FetchTableDataContext);

    return [{loadData}, result];
}

export function useLatestOptions() {
    return useContext(LoadTableDataOptionsContext);
}

export function useLoadWithOptions() {
    const [loadData] = useContext(FetchTableDataContext);
    const latestOptions = useContext(LoadTableDataOptionsContext);

    const loadWithOptions = useCallback((optionsOrFunc) => {
        if (typeof optionsOrFunc === "function") {
            return loadData(optionsOrFunc(latestOptions));
        }
        const mergedOptions = getDataOptions(optionsOrFunc, latestOptions);

        return loadData(mergedOptions);
    }, [loadData, latestOptions]);

    const handlePagination = useCallback((newPage) => {
        return loadWithOptions({page: newPage});
    }, [loadWithOptions]);

    const handleSearch = useCallback((newSearchValue) => {
        // reset page when applying new search filter!
        const page = 1;

        // Fetch data!
        return loadWithOptions({
            q: newSearchValue,
            page
        });
    }, [loadWithOptions]);

    const handleSort = useCallback((name, direction) => {
        // reset page when sorting!
        const page = 1;
        // Sort object
        const newSortOptions = {[name]: direction};
        // Fetch data!
        return loadWithOptions({
            sort: formatSortOptions(newSortOptions),
            page
        });
    }, [loadWithOptions]);

    const handleFilter = useCallback((columnName, newFilter) => {
        const mergeFunction = (existingOptions) => {
            const filtersMap = safeArrayToMap(existingOptions.filters, "name");
            if (newFilter) {
                filtersMap[columnName] = formatFilterOption(columnName, newFilter);
            } else {
                delete filtersMap[columnName];
            }

            const newFilters = Object.values(filtersMap);

            // reset page when new filter!
            const page = 1;
            return {
                filters: newFilters,
                page,
                sort: existingOptions.sort,
                q: existingOptions.q
            };
        };
        return loadWithOptions(mergeFunction);
    }, [loadWithOptions]);

    const removeFilter = useCallback((columnName) => {
        const mergeFunction = ({filters, sort, q}) => {
            const newFilters = Array.isArray(filters) ? filters.filter((filterItem) => {
                return filterItem.name !== columnName;
            }) : [];

            // reset page when new filter!
            const page = 1;
            return {
                filters: newFilters,
                page,
                sort,
                q
            };
        };
        return loadWithOptions(mergeFunction);
    }, [loadWithOptions]);

    const clearFilters = useCallback(() => {
        // reset page when new filter!
        const page = 1;
        return loadWithOptions({
            filters: [],
            page
        });
    }, [loadWithOptions]);

    return {
        loadWithOptions,
        handlePagination,
        handleSearch,
        handleSort,
        handleFilter,
        removeFilter,
        clearFilters
    };
}
