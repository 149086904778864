import React from "react";
import PropTypes from "prop-types";
import {Button} from "src/primitives/button.jsx";
import {useReAnalyzeTable} from "src/api/tableData/useReAnalyseTable.js";


export function TriggerAnalysis({tableId}) {
    const [analyse, {loading}] = useReAnalyzeTable(tableId);

    const handleSubmit = () => {
        analyse();
    };

    return (
        <div>
            <Button
                isLoading={loading}
                onClick={handleSubmit}
            >
                Run analysis
            </Button>
        </div>
    );
}

TriggerAnalysis.propTypes = {
    tableId: PropTypes.string.isRequired
};
