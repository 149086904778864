import {cva} from "cva";

export const buttonClasses = cva({
    // eslint-disable-next-line max-len
    base: "pl-5 pr-8 py-1 rounded-md flex items-center cursor-pointer hover:bg-black/40 transition-colors",
    variants: {
        state: {
            active: "bg-black/30 text-lime-400"
        }
    }
});
