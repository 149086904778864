import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Dropdown} from "src/primitives/Dropdown/index.jsx";
import {ALL_VARIABLE_TYPES, DataTypes, TECHNICALLY_POSSIBLE_TYPES, VariableTypes} from "src/utils/tableData.js";
import {Badge} from "src/primitives/badge.jsx";
import {formatTitleCase} from "src/utils/formatting.js";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiChevronDown} from "react-icons/fi";
import {useUpdateColumnVariableType} from "src/api/tableColumns/useUpdateColumnVariableType.js";
import {useGetTableStats} from "src/api/tableData/useGetTableStats.js";


function VariableTypeBadge({variableType, isOpen}) {
    return (
        <Badge isClickable={true} isActive={isOpen}>
            <div className="flex items-center gap-1 mr-[-4px]">
                <span>
                    {formatTitleCase(variableType)}
                </span>
                <IconWrapper icon={<FiChevronDown/>} size="xs"/>
            </div>
        </Badge>
    );
}

VariableTypeBadge.propTypes = {
    variableType: PropTypes.string,
    isOpen: PropTypes.bool
};

function hasUniqueValues(stats) {
    return stats && stats.distinct && stats.values && stats.distinct === stats.values;
}

function getPossibleVariableTypes(dataType, columnStats) {
    // start from the technically possible values
    const possibleTypes = new Set(TECHNICALLY_POSSIBLE_TYPES[dataType]);

    if (possibleTypes.has(VariableTypes.IDENTIFIER)) {
        // verify that values are unique
        if (!hasUniqueValues(columnStats)) {
            possibleTypes.delete(VariableTypes.IDENTIFIER);
        }
    }

    if (possibleTypes.has(VariableTypes.CONSTANT)) {
        // only mark as constant if 1 distinct value
        if (columnStats && columnStats.distinct > 1) {
            possibleTypes.delete(VariableTypes.CONSTANT);
        }
    }

    return possibleTypes;
}


export function ChangeVariableType({tableId, column}) {
    const {data: tableStats} = useGetTableStats(tableId);

    const [updateType] = useUpdateColumnVariableType(tableId);

    const handleSelect = useCallback((option) => {
        updateType(column.name, option.value).then((response) => {
            const {ok, errorCode} = response;
            if (!ok) {
                throw new Error(errorCode);
            }
        }).catch((error) => {
            console.warn(error);
        });
    }, [updateType, column.name]);

    const options = useMemo(() => {
        const columnStats = tableStats ? tableStats[column.name] : {};
        const possibleTypes = getPossibleVariableTypes(column.dataType, columnStats);

        return ALL_VARIABLE_TYPES.map((variableType) => {
            return {
                value: variableType,
                title: formatTitleCase(variableType),
                isDisabled: !possibleTypes.has(variableType)
            };
        });
    }, [column.dataType, column.name, tableStats]);

    const isSelectedItem = useCallback((option) => {
        return option.value === column.variableType;
    }, [column.variableType]);

    return (
        <Wrapper>
            <Dropdown
                options={options}
                isSelectedItem={isSelectedItem}
                onSelect={handleSelect}
                render={({open}) => (
                    <VariableTypeBadge
                        variableType={column.variableType}
                        isOpen={open}
                    />
                )}
            />
        </Wrapper>
    );
}

ChangeVariableType.propTypes = {
    tableId: PropTypes.string,
    column: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        dataType: PropTypes.oneOf(Object.values(DataTypes)),
        variableType: PropTypes.oneOf(Object.values(VariableTypes))
    })
};

const Wrapper = styled.div`
`;
