import React, {useState} from "react";
import PropTypes from "prop-types";
import {Spinner} from "src/primitives/spinner.jsx";
import {useGetModel} from "src/api/dataModels/useGetModel.js";
import {Title} from "src/primitives/title.jsx";
import {usePredict} from "src/api/dataModels/usePredict.js";
import {Button} from "src/primitives/button.jsx";
import {formatNumber} from "src/utils/formatting.js";
import {ModelResult} from "src/pages/Home/pages/TablePage/pages/TablePredictPage/components/ModelResult/index.jsx";
import {useGetColumns} from "src/api/tableColumns/useGetColumns.js";
import {sortFeaturesByVariables} from "src/pages/Home/pages/TablePage/pages/TablePredictPage/utils.js";
import {FeaturesForm} from "./components/FeatureForm/index.jsx";
import {PredictionUrl} from "./components/PredictionUrl/index.jsx";


export function ModelPreview({modelId, tableId}) {
    const [featureData, setFeatureData] = useState({});
    const {columns} = useGetColumns(tableId);
    const {model, loading} = useGetModel(modelId);
    const [predict, {loading: loadingPredictions, result: predictions}] = usePredict(modelId);

    if (loading) {
        return (
            <div>
                <Spinner/>
            </div>
        );
    }

    if (!model) {
        console.log("No model found with id", modelId);
        return null;
    }

    const sortedFeatures = sortFeaturesByVariables(model?.features, columns);

    return (
        <div>
            <div className="flex flex-col gap-8">
                <div className="w-112">
                    <Title size="small">
                        <span className="monospace">{model?.model}</span>
                    </Title>
                    <ModelResult result={model?.result}/>
                </div>
                <div className="w-112">
                    <Title size="small">
                        Values
                    </Title>
                    <FeaturesForm
                        tableId={tableId}
                        features={sortedFeatures}
                        onChange={setFeatureData}
                    />
                </div>
                <div className="flex gap-4 items-center justify-between w-112">
                    <Button
                        isLoading={loadingPredictions}
                        onClick={() => predict([featureData])}
                        isDisabled={!featureData}
                        theme="orange"
                    >
                        Predict
                    </Button>
                    <div className="font-bold monospace">
                        {predictions ? formatNumber(predictions[0]) : ""}
                    </div>
                </div>
                <div>
                    <PredictionUrl
                        modelId={tableId}
                        features={sortedFeatures}
                        featureData={featureData}
                    />
                </div>
            </div>
        </div>
    );
}

ModelPreview.propTypes = {
    modelId: PropTypes.string.isRequired,
    tableId: PropTypes.string.isRequired
};
