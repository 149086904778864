import React from "react";
import PropTypes from "prop-types";
import {formatQueryResult} from "src/api/utils.js";
import {formatDataFromQueryResult} from "src/utils/tableData.js";
import {Code} from "src/primitives/Terminal/code.jsx";
import {format} from "sql-formatter";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {Title} from "src/primitives/title.jsx";


function getData(queryResult) {
    if (!queryResult) {
        return [];
    }

    const qr = formatQueryResult(queryResult);

    return formatDataFromQueryResult(qr.columns, qr.rows);
}

export function HistoryItem({item}) {
    const {question} = item;
    const data = getData(item?.data);
    const isSuccess = (item.sql && item.data);
    window.ad = data;

    return (
        <div className="mb-3 px-4 py-3 rounded-md bg-neutral-100/20 border border-neutral-100">
            <div className="my-2 font-bold">
                {question}
            </div>

            <div>
                {item.comment}
            </div>

            {isSuccess && (
                <div className="flex flex-col gap-2">
                    <div className="p-2">
                        <Title size="small">
                            Query:
                        </Title>
                        <Code
                            theme="github"
                            language="sql"
                            code={format(item.sql)}
                        />
                    </div>
                    <div>
                        <Title size="small">
                            Data:
                        </Title>
                        <SimpleTable data={data} headers={Object.keys(data[0])}/>
                    </div>
                </div>
            )}
        </div>
    );
}

HistoryItem.propTypes = {
    item: PropTypes.shape({
        question: PropTypes.string,
        sql: PropTypes.string,
        chart: PropTypes.string,
        comment: PropTypes.string,
        error: PropTypes.string,
        data: PropTypes.object
    })
};
