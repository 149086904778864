import React from "react";
import PropTypes from "prop-types";
import {ProgressBarLabeled} from "src/primitives/ProgressBar.jsx";
import {EaseIn} from "src/primitives/EaseIn.jsx";
import {ColumnDetailsInstructions} from "./instructions/ColumnDetailsInstruction.jsx";
import {useOnboardingProgress} from "./useOnboardingChecklist.jsx";
import {ChecklistItem} from "./components/ChecklistItem/index.jsx";
import {NewFileInstructions} from "./instructions/NewFileInstructions.jsx";
import {PythonSdkInstructions} from "./instructions/PythonSdkInstructions.jsx";
import {PublicLinkInstructions} from "./instructions/PublicLinkInstructions.jsx";
import {ShareResourceInstructions} from "./instructions/ShareResourceInstruction.jsx";


const Divider = () => (
    <div className="w-full my-4 rounded-xl h-px bg-black/5"/>
);

export function OnboardingChecklist({workspaceId}) {
    const {
        loading,
        totalItems,
        completedItems,
        steps
    } = useOnboardingProgress(workspaceId);
    const {
        newFileCompleted,
        columnDetailsCompleted,
        pythonSdkCompleted,
        publicLinkEnabled,
        invitedFriend
    } = steps;

    return (
        <div className="max-w-144">
            <EaseIn loading={loading}>
                <div className="mb-4">
                    <ProgressBarLabeled
                        title="Progress"
                        subtitle={`${completedItems}/${totalItems} completed`}
                        progress={Math.round(100 * (completedItems / totalItems))}
                    />
                </div>

                <Divider/>

                <ChecklistItem
                    name="new_file"
                    title="Upload a CSV file using the file uploader"
                    description="Create your first table by uploading a new file."
                    isCompleted={newFileCompleted}
                    successMessage="Completed"
                    instructions={<NewFileInstructions/>}
                />
                <Divider/>

                <ChecklistItem
                    name="column_details_viewed"
                    title="View column details"
                    description="View automated analysis of a column."
                    isCompleted={columnDetailsCompleted}
                    successMessage="Completed"
                    instructions={<ColumnDetailsInstructions/>}
                />
                <Divider/>

                <ChecklistItem
                    name="use_sdk"
                    title="Use the Python SDK to fetch a table"
                    description="Install the Python library and fetch a table."
                    isCompleted={pythonSdkCompleted}
                    successMessage="Completed"
                    instructions={<PythonSdkInstructions workspaceId={workspaceId}/>}
                />
                <Divider/>

                <ChecklistItem
                    name="public_link"
                    title="Enable a public link"
                    description="Make dataset public by enabling a public link."
                    isCompleted={publicLinkEnabled}
                    successMessage="Completed"
                    instructions={<PublicLinkInstructions/>}
                />
                <Divider/>

                <ChecklistItem
                    name="share_resource"
                    title="Invite a collaborator"
                    description="Use the sharing feature to invite someone to a table or folder."
                    isCompleted={invitedFriend}
                    successMessage="Completed"
                    instructions={<ShareResourceInstructions/>}
                />
                <Divider/>
            </EaseIn>
        </div>
    );
}

OnboardingChecklist.propTypes = {
    workspaceId: PropTypes.string
};
