import React, {useState} from "react";
import PropTypes from "prop-types";
import {UserProfile} from "@clerk/clerk-react";
import {PageContent, PageLayout} from "src/primitives/layout/index.jsx";
import {LINKS} from "src/links.js";
import "./style.css";
import {useGetUser} from "src/api/users/useGetUser.js";
import {Navigate} from "react-router-dom";
import {LargeCard} from "src/pages/Home/pages/TablePage/components/LargeCard.jsx";
import {DeleteAccount} from "./components/DeleteAccount";
import {ApiKeys} from "./components/ApiKeys";
import {UserInformation} from "./components/UserInformation";


export function AccountPage({workspaceId}) {
    const {user} = useGetUser();
    const [isDeleted, setIsDeleted] = useState(false);

    if (isDeleted) {
        return <Navigate to={LINKS.SIGN_OUT}/>;
    }

    return (
        <PageLayout title="Account settings" l>
            <PageContent title="Account settings">
                <LargeCard
                    title="User information"
                >
                    <UserInformation/>
                </LargeCard>

                <LargeCard title="Personal API keys">
                    <ApiKeys workspaceId={workspaceId}/>
                </LargeCard>

                <LargeCard title="Delete account">
                    <div>
                        <div>
                            Once deleted, your account cannot be recovered.
                        </div>
                        <div className="my-2 w-96">
                            {user && (
                                <DeleteAccount
                                    onDeleted={() => setIsDeleted(true)}
                                    userId={user?.id}
                                />
                            )}
                        </div>
                    </div>
                </LargeCard>

                {window.showClerkAccount && (
                    <div className="mb-8">
                        <UserProfile
                            path={LINKS.ACCOUNT}
                            routing="path"
                        />
                    </div>
                )}
            </PageContent>
        </PageLayout>
    );
}

AccountPage.propTypes = {
    workspaceId: PropTypes.string
};
