import React from "react";
import PropTypes from "prop-types";
import {useMinutesFromDate} from "src/utils/dateUtils.jsx";
import {Badge} from "src/primitives/badge.jsx";


export function NewBadge({item}) {
    const value = useMinutesFromDate(item.createdAt);

    if (value >= 10) {
        return null;
    }

    return (
        <Badge
            size="xs"
            theme="blue"
        >
            {value} min
        </Badge>
    );
}

NewBadge.propTypes = {
    item: PropTypes.shape({
        createdAt: PropTypes.string
    })
};
