import React from "react";
import PropTypes from "prop-types";
import {useRenameColumn} from "src/api/tableColumns/useRenameColumn.js";
import {TextEditable} from "src/primitives/controls/index.jsx";
import {formatSafeString} from "src/utils/formatting.js";
import {useRefetchTableData} from "src/pages/Home/pages/TablePage/pages/TableGridPage/table_data_context.jsx";


export function EditColumnName({tableId, name}) {
    const [renameColumn] = useRenameColumn(tableId);
    const refetchData = useRefetchTableData();

    const handleSubmit = (newName) => {
        renameColumn({name, newName}).then(({ok, errorCode}) => {
            if (ok) {
                if (refetchData) {
                    refetchData();
                }
            } else {
                throw new Error(errorCode);
            }
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <TextEditable
            text={name}
            onSubmit={handleSubmit}
            formatValue={formatSafeString}
        />
    );
}

EditColumnName.propTypes = {
    tableId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};
