import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_WORKSPACES_QUERY} from "src/api/workspaces/useGetWorkspaces.js";

const MUTATION = gql`
mutation DeleteWorkspace ($workspaceId: String!) {
  deleteWorkspace (workspaceId: $workspaceId) {
    ok
    errorCode
    workspaceId
  }
}
`;

function removeWorkspaceFromCache(cache, workspaceId) {
    const data = cache.readQuery({query: GET_WORKSPACES_QUERY});

    if (!data || !Array.isArray(data?.workspaces)) {
        // Query never been loaded
        return;
    }

    const filteredWorkspaces = data.workspaces.filter((workspace) => workspace.id !== workspaceId);
    const newData = {workspaces: filteredWorkspaces};

    cache.writeQuery({
        query: GET_WORKSPACES_QUERY,
        data: newData
    });
}


export function useDeleteWorkspace(workspaceId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(() => {
        return mutation({
            variables: {workspaceId},
            update(cache) {
                removeWorkspaceFromCache(cache, workspaceId);
            }
        }).then((response) => {
            return response?.data?.deleteWorkspace;
        });
    }, [mutation, workspaceId]);

    return [doMutate, {loading, error}];
}
