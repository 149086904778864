import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {QUERY_RESULT_FIELDS} from "src/api/fragments.js";


const MUTATION = gql`
${QUERY_RESULT_FIELDS}
mutation AskQuestion($question: String!, $tableId: String!) {
  askQuestion(tableId: $tableId, question: $question) {
    ok
    errorCode
    result {
      question
      sql
      chart
      comment
      error
      
      data {
        ... QueryResultFields
      }
    }
  }
}
`;


export function useAskQuestion(tableId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback(({question}) => {
        return mutation({
            variables: {tableId, question}
        }).then((response) => {
            return response.data.askQuestion;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error, result: data?.askQuestion?.result}];
}
