import React from "react";
import PropTypes from "prop-types";
import {SimpleTable} from "src/components/SimpleTable/index.jsx";
import {getIqrLimits, isColumnIdentifier, isColumnNumeric} from "src/utils/tableData.js";
import {formatNumber, formatPercent} from "src/utils/formatting.js";


export function ColumnStatistics({column, columnStats}) {
    const {rows, nulls, values, distinct} = columnStats;

    const showNumericStats = (isColumnNumeric(column) && !isColumnIdentifier(column));
    const data = [];

    if (!showNumericStats) {
        data.push({
            title: "Total values",
            value: values,
            percent: formatPercent(values / rows)
        });
        data.push({
            title: "Missing",
            value: nulls,
            percent: formatPercent(nulls / rows)
        });
        data.push({
            title: "Unique",
            value: distinct,
            percent: formatPercent(distinct / rows)
        });
    } else {
        data.push({
            title: "Average",
            value: (
                <span>{formatNumber(columnStats.avg)}</span>
            )
        });
        data.push({
            title: "Standard Deviation",
            value: (
                <span>{formatNumber(columnStats.sd)}</span>
            )
        });
        data.push({
            title: "Min - Max",
            value: (
                <div className="flex">
                    <span>{formatNumber(columnStats.min)}</span>
                    <span className="text-center w-12">-</span>
                    <span>{formatNumber(columnStats.max)}</span>
                </div>
            )
        });
        const iqr = getIqrLimits(columnStats);
        data.push({
            title: "Inter Quartile Range",
            value: (
                <div className="flex">
                    <span>{formatNumber(iqr.lower)}</span>
                    <span className="text-center w-12">-</span>
                    <span>{formatNumber(iqr.upper)}</span>
                </div>
            )
        });
    }

    return (
        <div className="max-w-96">
            <SimpleTable
                // headers={["", "Value", "Percent"]}
                data={data}
            />
        </div>
    );
}
ColumnStatistics.propTypes = {
    column: PropTypes.shape({
        dataType: PropTypes.string
    }),
    columnStats: PropTypes.shape({
        rows: PropTypes.number,
        nulls: PropTypes.number,
        values: PropTypes.number,
        distinct: PropTypes.number,
        // Date-columns have min/max as strings
        min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        avg: PropTypes.number,
        sd: PropTypes.number,
        p50: PropTypes.number
    })
};
