import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {LINKS} from "src/links.js";
import {Button} from "src/primitives/button.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiArrowRight} from "react-icons/fi";


export function GoToWorkspaceButton({workspaceId}) {
    return (
        <Link to={`${LINKS.HOME}/workspace?wid=${workspaceId}`}>
            <Button size="small" theme="green">
                <div className="flex gap-2 items-center" style={{marginRight: -14}}>
                    <span>Get started</span>
                    <IconWrapper icon={<FiArrowRight/>} size="xs"/>
                </div>
            </Button>
        </Link>
    );
}


GoToWorkspaceButton.propTypes = {
    workspaceId: PropTypes.string.isRequired
};
