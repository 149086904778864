import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_WORKSPACE_MEMBERS_QUERY} from "./useGetWorkspaceMembers.js";


const MUTATION = gql`
  mutation InviteUser($email: String!, $workspaceId: String!) {
    inviteUser (email: $email, workspaceId: $workspaceId) {
      ok
      errorCode
      member {
        id
        userId
        email
        status
      }
    }
  }
`;


export function useInviteUser(workspaceId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const mutate = useCallback(({email}) => {
        return mutation({
            variables: {email, workspaceId},
            refetchQueries: [{query: GET_WORKSPACE_MEMBERS_QUERY, variables: {id: workspaceId}}]
        }).then((response) => {
            const {
                ok,
                errorCode,
                member
            } = response.data.inviteUser;
            if (ok) {
                return member;
            }
            throw new Error(errorCode);
        });
    }, [mutation, workspaceId]);

    return [mutate, {loading, error, data}];
}
