import React, {useState} from "react";
import PropTypes from "prop-types";


export function ApiKeyCell({value}) {
    const [showValue, setShowValue] = useState(false);

    return (
        <div>
            {showValue ? (
                <span>{value}</span>
            ) : (
                <div className="flex">
                    <span>***</span>
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <span
                        onClick={() => setShowValue(true)}
                        className="cursor-pointer hover:underline px-2"
                    >
                        Show key
                    </span>
                    <span>***</span>
                </div>
            )}
        </div>
    );
}
ApiKeyCell.propTypes = {
    value: PropTypes.string
};
