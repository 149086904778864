import React from "react";
import PropTypes from "prop-types";
import {PageContent} from "src/primitives/layout/PageContent.jsx";
import {useGetColumnDistributions} from "src/api/tableData/useGetColumnDistributions.js";
import {getNumericalColumnNames} from "src/utils/tableData.js";
import {SelectVisibleColumns} from "src/pages/Home/pages/TablePage/components/SelectVisibleColumns/index.jsx";
import {useGetTableStats} from "src/api/tableData/useGetTableStats.js";
import {useGetFittedDistributions} from "src/api/tableData/useGetFittedDistributions.js";
import {
    DuplicatesOverview
} from "src/pages/Home/pages/TablePage/pages/ProfilingPage/components/DuplicatesOverview/index.jsx";
import {
    CategoricalRelationsAnalysis,
    ColumnsOverview,
    ColumnsSidebar,
    CorrelationAnalysis
} from "./components/index.jsx";


export function TableProfilingPage({table, alignPage, hideColumnSelector}) {
    const showCategoricalCorrelations = false;

    const {data: tableStats} = useGetTableStats(table.id);
    const {data: distributions} = useGetColumnDistributions(table.id, getNumericalColumnNames(table?.columns));
    const {data: fittedDistributions} = useGetFittedDistributions(table.id);

    if (!table || !tableStats) {
        return null;
    }

    const visibleColumns = table.columns.filter((column) => !column.isHidden);

    return (
        <>
            <ColumnsSidebar
                tableId={table.id}
                tableStats={tableStats}
            />
            <PageContent
                align={alignPage}
                size="xl"
                title="Profiling"
                subTitle={hideColumnSelector ? null : (
                    <SelectVisibleColumns
                        tableId={table.id}
                        columns={table.columns}
                    />
                )}
            >
                <div className="mb-8">
                    <DuplicatesOverview tableId={table.id}/>

                    <ColumnsOverview
                        tableId={table.id}
                        columns={visibleColumns}
                        tableStats={tableStats}
                        distributions={distributions}
                        fittedDistributions={fittedDistributions}
                    />

                    <CorrelationAnalysis
                        tableId={table.id}
                        columns={visibleColumns}
                    />

                    {showCategoricalCorrelations && (
                        <CategoricalRelationsAnalysis
                            tableId={table.id}
                            columns={visibleColumns}
                        />
                    )}
                </div>
            </PageContent>
        </>
    );
}


TableProfilingPage.propTypes = {
    table: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        columns: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            dataType: PropTypes.string,
            variableType: PropTypes.string,
            isHidden: PropTypes.bool
        }))
    }),
    alignPage: PropTypes.string,
    hideColumnSelector: PropTypes.bool
};
