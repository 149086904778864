import React from "react";
import {useGetUser} from "src/api/users/useGetUser.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {DescriptionList} from "src/primitives/DescriptionList.jsx";


export function UserInformation() {
    const {user, loading} = useGetUser();

    if (loading) {
        return (
            <Spinner/>
        );
    }

    const items = [{
        key: "First name",
        value: user.firstName
    }, {
        key: "Last name",
        value: user.lastName
    }, {
        key: "Email",
        value: user.email
    }];

    return (
        <div className="max-w-xl">
            <DescriptionList items={items}/>
        </div>
    );
}

UserInformation.propTypes = {
};
