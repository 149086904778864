import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";


const MUTATION = gql`
  mutation RemoveMember($memberId: String!) {
    removeMember (memberId: $memberId) {
      ok
      errorCode
      memberId
    }
  }
`;

function removeWorkspaceMemberFromCache(cache, response) {
    const {ok, memberId} = response.data.removeMember;
    if (!ok) {
        return;
    }
    const model = {
        id: memberId,
        __typename: "WorkspaceMember"
    };
    const normalizedId = cache.identify(model);
    cache.evict({id: normalizedId});
    cache.gc();
}


export function useRemoveMember() {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const mutate = useCallback((memberId) => {
        return mutation({
            variables: {memberId},
            update(cache, response) {
                removeWorkspaceMemberFromCache(cache, response);
            }
        }).then((response) => {
            const {removeMember} = response.data;
            if (removeMember.ok) {
                return removeMember.memberId;
            }
            throw new Error(removeMember.errorCode);
        });
    }, [mutation]);

    return [mutate, {loading, error, data}];
}
