import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_TABLE_STATS_QUERY} from "src/api/tableData/useGetTableStats.js";
import {GET_TABLE_PREVIEW} from "src/api/tableData/useGetTablePreview.js";
import {GET_TABLE_QUERY} from "src/api/tables/useGetTable.js";
import {GET_CORRELATION_MATRIX_QUERY} from "src/api/tableData/useGetCorrelationMatrix.js";
import {GET_FITTED_DISTRIBUTIONS} from "src/api/tableData/useGetFittedDistributions.js";


const MUTATION = gql`
    mutation RenameColumn($tableId: String!, $name: String!, $newName: String!) {
        renameColumn(tableId: $tableId, columnName: $name, newName: $newName) {
            ok
            errorCode
            column {
                id
                name
            }
        }
    }
`;


function updateTableStatsCache(cache, tableId, originalColumn, newColumn) {
    const options = {
        query: GET_TABLE_STATS_QUERY,
        variables: {id: tableId}
    };
    const tableStatsResult = cache.readQuery(options);
    const newStats = Object.assign({}, tableStatsResult.table.stats, {
        [newColumn]: tableStatsResult.table.stats[originalColumn]
    });
    const newResult = {
        table: {
            __typename: "Table",
            id: tableId,
            stats: newStats
        }
    };
    cache.writeQuery({query: options.query, variables: options.variables, data: newResult});
}

function updateTablePreviewCache(cache, tableId, originalColumn, newColumn) {
    const options = {
        query: GET_TABLE_PREVIEW,
        variables: {id: tableId}
    };
    const tablePreviewResult = cache.readQuery(options);
    const newPreview = Object.assign({}, tablePreviewResult.table.preview, {
        [newColumn]: tablePreviewResult.table.preview[originalColumn]
    });
    const newResult = {
        table: {
            __typename: "Table",
            id: tableId,
            preview: newPreview
        }
    };
    cache.writeQuery({query: options.query, variables: options.variables, data: newResult});
}


export function useRenameColumn(tableId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback(({name, newName}) => {
        function handleUpdate(cache) {
            updateTableStatsCache(cache, tableId, name, newName);
            updateTablePreviewCache(cache, tableId, name, newName);
        }

        const refetchQueries = [{
            query: GET_TABLE_QUERY,
            variables: {id: tableId}
        }, {
            query: GET_CORRELATION_MATRIX_QUERY,
            variables: {id: tableId}
        }, {
            query: GET_FITTED_DISTRIBUTIONS,
            variables: {id: tableId}
        }];

        const variables = {tableId, name, newName};

        return mutation({
            variables,
            refetchQueries,
            update: handleUpdate
        }).then((response) => {
            return response.data.renameColumn;
        });
    }, [mutation, tableId]);

    return [doMutate, {loading, error}];
}
