

export function isTableReady(table) {
    if (!table) {
        return false;
    }

    const {status, columns} = table;

    if (status !== "READY") {
        return false;
    }

    // If no columns, keep refreshing (cache issue...)
    return (Array.isArray(columns) && columns.length > 0);
}
