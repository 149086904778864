import {useCallback} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {LINKS} from "src/links.js";


function paramsToString(params, base) {
    if (base) {
        // more params
        // eslint-disable-next-line no-param-reassign
        base += "&";
    } else {
        // no other params
        // eslint-disable-next-line no-param-reassign
        base = "?";
    }

    return Object.keys(params).reduce((acc, key) => `${acc}${key}=${params[key]}`, base);
}


export function useGetLink() {
    const {search} = useLocation();

    return useCallback((path, params = null) => {
        const searchString = params ? paramsToString(params, search) : search;

        return {
            pathname: path,
            search: searchString
        };
    }, [search]);
}


export function useGoToPath() {
    const getLink = useGetLink();
    const navigate = useNavigate();

    return useCallback((path, params) => {
        const link = getLink(path, params);
        navigate(link);
    }, [navigate, getLink]);
}

export function useGoToTable() {
    const goToPath = useGoToPath();

    return useCallback((tableId) => {
        const path = LINKS.tablePage(tableId);
        goToPath(path);
    }, [goToPath]);
}

export function useGoToFolder() {
    const goToPath = useGoToPath();

    return useCallback((folderId) => {
        const path = LINKS.folderPage(folderId);
        goToPath(path);
    }, [goToPath]);
}

export function useGoToUploadPage() {
    const goToPath = useGoToPath();

    return useCallback(() => {
        goToPath(LINKS.UPLOAD);
    }, [goToPath]);
}


export function useIsActiveLink(includeParent = false) {
    const location = useLocation();

    return useCallback((path) => {
        if (!path) {
            return null;
        }

        if (includeParent) {
            return location.pathname.startsWith(path);
        }
        return path === location.pathname;
    }, [location.pathname, includeParent]);
}
