import {create} from "zustand";
import {reverseArray} from "src/utils/sorting.js";


export const useSelectedColumns = create((set) => ({
    columns: null,
    clear: () => set({columns: null}),
    select: (columns) => set({columns}),
    inverse: () => set((state) => ({columns: reverseArray(state.columns)}))
}));
