import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {
    isColumnBoolean,
    isColumnCategorical,
    isColumnContinuous,
    isColumnDate,
    isColumnIdentifier,
    isColumnNumeric,
    isColumnText
} from "src/utils/tableData.js";
import {SelectRange} from "src/primitives/controls/SelectRange/index.jsx";
import {FilterOperator} from "src/components/TableGrid/constants.js";
import {formatTime, formatTimestamp} from "src/utils/formatting.js";
import {BooleanFilter} from "./components/BooleanFilter.jsx";
import {CategoryFilter} from "./components/CategoryFilter.jsx";
import {NumericFilter} from "./components/NumericFilter.jsx";
import {TextFilter} from "./components/TextFilter.jsx";
import {
    fromBooleanValues,
    fromCategoryValues,
    fromRangeValue,
    textToBooleanValues,
    toCategoryValues,
    toRangeValue
} from "./utils.jsx";


export function ColumnFilter({
    columnInfo,
    columnValues,
    stats,
    clearFilter,
    textValue,
    setValue,
    operator,
    setOperator
}) {
    const updateTextValue = useCallback((value) => {
        setValue(value);
        setOperator(FilterOperator.EQUALS);
    }, [setValue, setOperator]);

    const updateRangeValue = useCallback((value) => {
        setValue(fromRangeValue(value));
        setOperator(FilterOperator.IN_RANGE);
    }, [setValue, setOperator]);

    const updateDateRange = useCallback((values) => {
        const dateValues = values.map((v) => formatTimestamp(v));
        updateRangeValue(dateValues);
    }, [updateRangeValue]);

    const updateCategoryValues = useCallback((values) => {
        setValue(fromCategoryValues(values));
        setOperator(FilterOperator.IN);
    }, [setValue, setOperator]);

    const updateBooleanValue = useCallback((value) => {
        setValue(fromBooleanValues(value));
        setOperator(FilterOperator.IN);
    }, [setValue, setOperator]);

    if (isColumnText(columnInfo, false) && !isColumnCategorical(columnInfo)) {
        return (
            <TextFilter
                value={textValue}
                updateValue={updateTextValue}
                clear={clearFilter}
            />
        );
    }

    if (isColumnIdentifier(columnInfo)) {
        return (
            <TextFilter
                value={textValue}
                updateValue={updateTextValue}
                clear={clearFilter}
            />
        );
    }

    if (isColumnCategorical(columnInfo)) {
        return (
            <CategoryFilter
                columnValues={columnValues}
                value={toCategoryValues(textValue)}
                updateValue={updateCategoryValues}
            />
        );
    }

    if (isColumnNumeric(columnInfo)) {
        return (
            <NumericFilter
                columnInfo={columnInfo}
                stats={stats}
                value={textValue}
                updateRange={updateRangeValue}
            />
        );
    }

    if (isColumnDate(columnInfo) && isColumnContinuous(columnInfo)) {
        const fallback = [formatTime(stats.min), formatTime(stats.max)];
        const rangeValue = toRangeValue(textValue, fallback);

        return (
            <SelectRange
                min={formatTime(stats.min)}
                max={formatTime(stats.max)}
                lower={formatTime(rangeValue[0])}
                upper={formatTime(rangeValue[1])}
                onChange={updateDateRange}
                formatLabel={formatTimestamp}
            />
        );
    }

    if (isColumnBoolean(columnInfo)) {
        return (
            <BooleanFilter
                value={textToBooleanValues(textValue)}
                updateValue={updateBooleanValue}
            />
        );
    }

    return (
        <div>
            {operator}
        </div>
    );
}

ColumnFilter.propTypes = {
    columnInfo: PropTypes.object,
    columnValues: PropTypes.array,
    stats: PropTypes.object,
    clearFilter: PropTypes.func,
    textValue: PropTypes.string,
    setValue: PropTypes.func,
    operator: PropTypes.oneOf(Object.values(FilterOperator)),
    setOperator: PropTypes.func
};
