import React from "react";
import "./style.css";

export const StaticGlowLineEffect = () => {
    return (
        <div className="glow-line-container full fixed inert">
            <svg className="glow-line-svg" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="lineGradient" x1="50%" y1="0%" x2="50%" y2="100%">
                        <stop offset="0%" stopColor="rgba(255, 255, 255, 0)" />
                        <stop offset="50%" stopColor="rgba(255, 255, 255, 1)" />
                        <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />
                    </linearGradient>
                </defs>
                <line
                    x1="50%"
                    y1="0%"
                    x2="50%"
                    y2="100%"
                    stroke="url(#lineGradient)"
                    strokeWidth="2"
                    className="glow-line"
                />
                <rect
                    x="0"
                    y="25%"
                    width="100%"
                    height="50%"
                    fill="url(#lineGradient)"
                    className="glow-rect"
                />
            </svg>
        </div>
    );
};
