import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";

const MUTATION = gql`
mutation UpdateChartTitle($chartId: String!, $title: String!) {
  updateChartTitle (chartId: $chartId, title: $title) {
    ok
    errorCode
    chart {
      id
      title
    }
  }
}
`;

export function useUpdateChartTitle(chartId) {
    const [mutation, {loading, error}] = useMutation(MUTATION);

    const doMutate = useCallback((title) => {
        const expectedResponse = {
            ok: true,
            errorCode: null,
            chart: {
                __typename: "Chart",
                id: chartId,
                title
            }
        };
        return mutation({
            variables: {chartId, title},
            optimisticResponse: {updateChartTitle: expectedResponse}
        }).then((response) => {
            return response.data.updateChartTitle;
        });
    }, [mutation, chartId]);

    return [doMutate, {loading, error}];
}
