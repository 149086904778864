import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {formatPrettyString} from "src/utils/formatting.js";
import {Title} from "src/primitives/title.jsx";
import {ListOptions} from "src/primitives/controls/ListOptions.jsx";
import {NEW_FILE_STRATEGY} from "./strategies.js";


export function SelectStrategy({strategy, onChange, excludeStrategies}) {
    const isSelected = (key) => NEW_FILE_STRATEGY[key] === strategy;
    const onSelect = (key) => onChange(NEW_FILE_STRATEGY[key]);

    const options = useMemo(() => {
        const keys = Object.keys(NEW_FILE_STRATEGY).filter((key) => {
            if (excludeStrategies) {
                return excludeStrategies.indexOf(key) < 0;
            }
            return true;
        });
        return keys.map((key) => {
            return {
                key,
                label: (
                    <span>
                        {formatPrettyString(NEW_FILE_STRATEGY[key])}
                    </span>
                )
            };
        });
    }, [excludeStrategies]);

    return (
        <div>
            <div className="mb-2">
                <Title size="xs">
                    Merge strategy:
                </Title>
            </div>
            <ListOptions
                options={options}
                isSelected={isSelected}
                onSelect={onSelect}
            />
        </div>
    );
}

SelectStrategy.propTypes = {
    strategy: PropTypes.oneOf(Object.values(NEW_FILE_STRATEGY)),
    onChange: PropTypes.func.isRequired,
    excludeStrategies: PropTypes.arrayOf(PropTypes.string)
};
