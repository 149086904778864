import React from "react";
import PropTypes from "prop-types";
import {SignUp, useAuth} from "@clerk/clerk-react";
import {CenteredContent, FlexLayout, SplitPage} from "src/primitives/layout/index.jsx";
import {LINKS} from "src/links.js";
import {RhoHeader} from "src/pages/components/RhoHeader/index.jsx";
import {Title} from "src/primitives/title.jsx";
import {Link} from "react-router-dom";
import {Spinner} from "src/primitives/spinner.jsx";
import {Button} from "src/primitives/button.jsx";
import {IconWrapper} from "src/primitives/Icon.jsx";
import {FiArrowRight} from "react-icons/fi";


function SignedInInformation({signOut}) {
    const doSignOut = () => {
        signOut();
    };

    const buttonStyle = {marginLeft: -10, marginRight: -20, width: 120};

    return (
        <div className="w-full">
            <Title>
                You are already signed in.
            </Title>
            <div className="flex flex-col gap-4">
                <div>
                    <Link className="link" to={LINKS.HOME} >
                        <Button size="small" theme="green">
                            <div className="flex gap-2 items-center justify-between" style={buttonStyle}>
                                <span>Go to home</span>
                                <IconWrapper icon={<FiArrowRight/>} size="xs"/>
                            </div>
                        </Button>
                    </Link>
                </div>
                <div>
                    <Button size="small" theme="secondary" onClick={doSignOut}>
                        <div className="flex gap-2 items-center justify-between" style={buttonStyle}>
                            <span>Sign out</span>
                            <IconWrapper icon={<FiArrowRight/>} size="xs"/>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
}

SignedInInformation.propTypes = {
    signOut: PropTypes.func
};

export function SignUpPage() {
    const {isLoaded, isSignedIn, signOut} = useAuth();

    return (
        <SplitPage>
            <FlexLayout
                header={<RhoHeader/>}
            >
                <CenteredContent>
                    {!isLoaded && <Spinner/>}
                    {(isLoaded && isSignedIn) && (
                        <SignedInInformation signOut={signOut}/>
                    )}
                    {(isLoaded && !isSignedIn) && (
                        <SignUp
                            path={LINKS.SIGN_UP}
                            redirectUrl={LINKS.START}
                            signInUrl={LINKS.SIGN_IN}
                            afterSignUpUrl={LINKS.START}
                            afterSignInUrl={LINKS.HOME}
                        />
                    )}
                </CenteredContent>
            </FlexLayout>
        </SplitPage>
    );
}
