import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {PageContent} from "src/primitives/layout/index.jsx";
import {LargeCard} from "src/pages/Home/pages/TablePage/components/LargeCard.jsx";
import {DeleteFolder} from "./components/DeleteFolder/index.jsx";


export function FolderSettings({folderId}) {
    return (
        <PageContent
            title="Settings"
            size="md"
        >
            <Wrapper>
                <LargeCard
                    title="Delete folder"
                >
                    <div className="mb-4">
                        <p>This action is non recoverable</p>
                        <div className="my-4">
                            <DeleteFolder folderId={folderId}/>
                        </div>
                    </div>
                </LargeCard>
            </Wrapper>
        </PageContent>
    );
}

FolderSettings.propTypes = {
    folderId: PropTypes.string
};

const Wrapper = styled.div`
`;
