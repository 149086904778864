import React from "react";
import PropTypes from "prop-types";
import {Navigate, Route, Routes, useParams, useSearchParams} from "react-router-dom";
import {LINKS} from "src/links.js";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {CenteredContent} from "src/primitives/layout/index.jsx";
import {Spinner} from "src/primitives/spinner.jsx";
import {Title} from "src/primitives/title.jsx";
import {TableGridPageWrapper, TableProfilingPage} from "src/pages/Home/pages/TablePage/pages/index.jsx";
import {PublicTableHeader} from "src/pages/Public/components/PublicHeader/index.jsx";
import {ColumnDetailsSidebar} from "src/pages/Home/pages/TablePage/components/ColumnDetailsSidebar/index.jsx";
import {RetryError} from "src/pages/Public/RetryError.jsx";


export function RenderPublicPage() {
    const [searchParams] = useSearchParams();
    const {tableId} = useParams();

    if (!tableId) {
        return <Navigate to={LINKS.WEB}/>;
    }

    const tableKey = searchParams.get("pk");

    if (!tableKey) {
        return (
            <div>
                Missing key
            </div>
        );
    }

    return (
        <PublicPage
            key={tableId}
            tableId={tableId}
            publicKey={tableKey}
        />
    );
}


export function PublicPage({tableId, publicKey}) {
    const {table, loading, error, refetch} = useGetTable(tableId);

    if (loading) {
        return (
            <CenteredContent>
                <Spinner size="xl"/>
            </CenteredContent>
        );
    }

    if (error) {
        return (
            <CenteredContent>
                <div className="flex flex-col gap-2">
                    <Title>
                        Failed to load table
                    </Title>
                    <div>
                        <RetryError
                            retry={refetch}
                            error={error}
                        />
                    </div>
                </div>
            </CenteredContent>
        );
    }

    if (!table) {
        return (
            <CenteredContent>
                <div>
                    <Title>404</Title>
                    <div>Not found</div>
                </div>
            </CenteredContent>
        );
    }

    return (
        <>
            <ColumnDetailsSidebar table={table}/>
            <div className="w-full h-full flex flex-col">
                <PublicTableHeader tableId={tableId} tableName={table.name}/>

                <Routes>
                    <Route
                        path=""
                        element={(
                            <TableGridPageWrapper
                                tableId={tableId}
                                table={table}
                            />
                        )}
                    />
                    <Route
                        path="/profiling"
                        element={(
                            <TableProfilingPage
                                table={table}
                                alignPage="center"
                                hideColumnSelector={true}
                            />
                        )}
                    />
                    <Route
                        path="*"
                        element={<Navigate to={`${LINKS.publicTable(tableId)}?pk=${publicKey}`} />}
                    />
                </Routes>
            </div>
        </>
    );
}

PublicPage.propTypes = {
    tableId: PropTypes.string.isRequired,
    publicKey: PropTypes.string
};
