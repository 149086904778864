import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useGetTimeSeries} from "src/api/data/useGetTimeSeries.js";
import {formatDataFromQueryResult} from "src/utils/tableData.js";
import {EaseIn} from "src/primitives/EaseIn.jsx";
import {TimeSeriesOptions} from "./TimeSeriesOptions.jsx";
import {TimeSeriesChart} from "./TimeSeriesChart.jsx";


export function TimeSeries({tableId, tableColumns}) {
    const [makeQuery, {result, loading}] = useGetTimeSeries(tableId);
    const [queryOptions, setQueryOptions] = useState({});

    const handleChange = (options) => {
        setQueryOptions(Object.assign({}, queryOptions, options));
    };

    useEffect(() => {
        if (!tableId) {
            return;
        }
        const {
            timeColumn,
            valueColumn
        } = queryOptions;
        if (!timeColumn || !valueColumn) {
            return;
        }
        const filters = [{name: "object_type", value: "Villa"}];
        const options = {timeColumn, valueColumn, filters};
        makeQuery(options).then((res) => {
            console.log(res);
        });
    }, [makeQuery, tableId, queryOptions]);

    const data = formatDataFromQueryResult(result?.columns, result?.rows);
    const {
        timeColumn,
        valueColumn
    } = queryOptions;
    const title = timeColumn && valueColumn ? `"${valueColumn}" over "${timeColumn}"` : "-";

    return (
        <Wrapper>
            <EaseIn loading={loading} loadingHeight={360}>
                <TimeSeriesChart
                    title={title}
                    data={data}
                />
            </EaseIn>

            <div className="my-4 p-4">
                <TimeSeriesOptions
                    columns={tableColumns}
                    onChange={handleChange}
                    timeColumn={queryOptions?.timeColumn}
                    valueColumn={queryOptions?.valueColumn}
                />
            </div>
        </Wrapper>
    );
}

TimeSeries.propTypes = {
    tableId: PropTypes.string,
    tableColumns: PropTypes.array
};

const Wrapper = styled.div`
`;
