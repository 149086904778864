export function convertDictsToRows(data) {
    if (!data || Object.keys(data).length === 0) {
        return [];
    }

    const keys = Object.keys(data);
    // Initialize the result as an array of arrays
    const result = Array(data[keys[0]].length).fill().map(() => []);

    // Iterate through the keys and their corresponding lists
    keys.forEach((key) => {
        data[key].forEach((value, index) => {
            result[index].push(value);
        });
    });

    return result;
}
